import { Link, useParams } from 'react-router-dom';
import { Tab, Nav, Card, Badge, Row, Col } from 'react-bootstrap';
import Layout from '../../../layout/default';
import Block from '../../../components/Block/Block';
import { Image, Icon, Media } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FarmerDetails, ProfileDetails } from '../store/store';
import { get } from '../api/Api';
import Purchase from './Purchase';
import Loader from '../shared/Loader';

import FarmerChart from './ChartDetails';
import CenterPurchase from './CenterPurchase';


const ProfilePage = () => {

    const dispatch = useDispatch()
    const { id, type } = useParams()
    const [rateChart, setRateChart] = useState([])
    const [activeTab, setActiveTab] = useState("details");
    const fetchChart = async (id) => {
        const url = `/api/rate-chart/${id}/details`;
        try {
            const response = await get(url);
            if (response.status) {
                setRateChart(response.data)
            }

        } catch (error) {
            console.error("Error while posting data:", error);
        }
    }

    useEffect(() => {
        dispatch(ProfileDetails())
        if (type === "direct" || type === "center") {
            dispatch(FarmerDetails(id))
        }

    }, [dispatch, id])

    const { details, isLoading } = useSelector((state) => state.farmerDetails)


    useEffect(() => {
        let rateID

        if (type === "direct" || type === "center") {
            rateID = details?.ratechart_id?.[0]
        }

        if (rateID) {
            if (rateID?.cow) {
                fetchChart(rateID?.cow)
            } else if (rateID?.buffalo) {
                fetchChart(rateID?.buffalo)
            } else {
                fetchChart(rateID?.common)
            }
        }

    }, [details?.ratechart_id])


    function getChartType(type) {
        switch (type) {
            case 1:
                return "FLAT";
            case 2:
                return "FAT&SNF";
            case 3:
                return "FAT";
            case 4:
                return "SNF";
            case 5:
                return "TS";
            case 6:
                return "FLAT(FAT&SNF)";
            default:
                return "Unknown";
        }
    }

    function formatPaymentCycleCC(row) {

        if (row.payment_cycle === "daily") {
            if (row.session === "0" || row.session === 0 || row.session === "morning") {
                return "MORNING";
            } else if (row.session === "1" || row.session === 1 || row.session === "evening") {
                return "EVENING";
            } else if (row.session === "2" || row.session === 2 || row.session === "both") {
                return "BOTH";
            }
        } else if (row.payment_cycle === "weekly") {
            const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
            const sessionIndex = parseInt(row.session);
            if (!isNaN(sessionIndex) && sessionIndex >= 0 && sessionIndex < daysOfWeek.length) {
                return "W - " + daysOfWeek[sessionIndex];
            }
        }
        // Return a default value or handle other cases as needed
        return "Unknown";
    }

    function formatProducerInfo(producerInfo) {
        let formattedInfo = '';

        if (producerInfo?.door_no) {
            formattedInfo += `Door No: ${producerInfo?.door_no}, `;
        }
        if (producerInfo?.village_name) {
            formattedInfo += `Village: ${producerInfo?.village_name}, `;
        }
        if (producerInfo?.district) {
            formattedInfo += `District: ${producerInfo?.district}, `;
        }
        if (producerInfo?.state) {
            formattedInfo += `State: ${producerInfo?.state}`;
        }

        // Remove trailing comma and space
        formattedInfo = formattedInfo.replace(/,\s*$/, '');

        return formattedInfo;
    }

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    return (
        <Layout title="My Profile" content="container">
            <Loader loading={isLoading} />
            <Tab.Container defaultActiveKey="details" activeKey={activeTab}
                onSelect={handleTabChange}>
                <Block.HeadBetween>
                    <div className="gap-col">
                        <Nav variant="pills" className="nav-pills-border gap g-3">
                            <Nav.Item>
                                <Nav.Link eventKey="details">Overview</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="purchase">Purchase</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="chart">Chart</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link>Accounts</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link>Inventory</Nav.Link>
                            </Nav.Item>

                        </Nav>
                    </div>
                    <div className="gap-col">
                        <ul className="d-flex gap g-2">
                            <li className="d-none d-md-block">
                                <Link to="/farmer-view/direct" className="btn btn-soft btn-primary">
                                    <Icon name="arrow-long-left"></Icon>
                                    <span>Back</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Block.HeadBetween>

                <Block className="mt-4">
                    <Tab.Content>
                        <Tab.Pane eventKey="details">
                            <Card className="card-gutter-md">
                                <div className="card-row card-row-lg col-sep col-sep-lg ">

                                    <Col lg="12" >
                                        <Row className="g-3">

                                            <Col md="4">
                                                <div style={{ width: '100%' }}>
                                                    <Card.Body>
                                                        <div className="bio-block d-flex flex-column align-items-center text-center">
                                                            {/* Badge in the top-left corner */}
                                                            <Badge bg="primary" className="position-absolute top-0 start-0 m-4" style={{ zIndex: 1, fontSize: '16px', width: '120px' }}>
                                                                #{details?.code}
                                                            </Badge>

                                                            <Card style={{ width: "100%" }}  >
                                                                <Card.Body className="mt-2">


                                                                    {/* Avatar */}
                                                                    <Media size="huge" shape="circle">
                                                                        <Image src="/images/avatar/c.jpg" staticImage thumbnail alt="user" />
                                                                    </Media>

                                                                    {/* User Info */}
                                                                    <div className="mt-3">
                                                                        <h3 className="title mb-2">{details?.name}</h3>
                                                                        <span className="fw-medium">{details?.route?.code}-{details?.route?.name?.toUpperCase()}</span>

                                                                        {/* Additional Info */}
                                                                        <ul className="list-group list-group-borderless small">
                                                                            <li className="list-group-item">
                                                                                <span className="small">{details?.mobile_no}</span>
                                                                            </li>
                                                                            {details?.producer_info?.length !== 0 && <li className="list-group-item"><Icon name="map-pin"></Icon>  <span className="small">{formatProducerInfo(details?.producer_info?.[0])} </span></li>
                                                                            }
                                                                            {details?.communication_info?.[0]?.communication[1] !== 0 && <li className="list-group-item">  <Icon name="whatsapp"></Icon>  <span className="small">{details?.communication_info?.[0]?.whatsappNo}</span></li>
                                                                            }
                                                                            {details?.communication_info?.[0]?.communication[3] !== 0 && <li className="list-group-item">  <Icon name="mail"></Icon>   <span className="small">{details?.communication_info?.[0]?.email}</span></li>
                                                                            }
                                                                            {details?.communication_info?.[0]?.communication[2] !== 0 && <li className="list-group-item">  <Icon name="chat"></Icon>   <span className="small">{details?.communication_info?.[0]?.sms}</span></li>
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>


                                                        </div>
                                                    </Card.Body>
                                                </div>
                                            </Col>

                                            <Col md="4">
                                                <div style={{ width: '100%' }}>
                                                    <Card.Body>
                                                        <div className="bio-block d-flex flex-column align-items-center text-center">
                                                            {details?.mode_payment_info?.[0]?.mode_of_payment === 1 &&
                                                                <Card style={{ width: '100%' }} >
                                                                    <Card.Body>
                                                                        {/* User Info */}
                                                                        <div>
                                                                            <div className="position-absolute top-0 start-0" style={{ zIndex: 1, }} >
                                                                                <Badge bg="warning" style={{ width: '80px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '16px' }}>
                                                                                    <Icon name="home"></Icon> Bank
                                                                                </Badge>
                                                                            </div>

                                                                            {/* Additional Info */}
                                                                            <ul className="list-group list-group-borderless small">
                                                                                <li className="list-group-item">
                                                                                    <Icon name="user-alt-fill"></Icon>  <span className="fw-medium">{details?.mode_payment_info?.[0]?.bank_info?.HolderName}</span>
                                                                                </li>

                                                                                <li className="list-group-item">
                                                                                    <Icon name="cc-alt-fill"></Icon>  <span className="fw-medium">{details?.mode_payment_info?.[0]?.bank_info?.AccNo}</span>

                                                                                </li>


                                                                                <li className="list-group-item">
                                                                                    <Icon name="home-fill"></Icon> <span className="fw-medium">{details?.mode_payment_info?.[0]?.bank_info?.BankName} {details?.mode_payment_info?.[0]?.bank_info?.BranchName}</span>

                                                                                </li>

                                                                                <li className="list-group-item">
                                                                                    <Icon name="hash"></Icon> <span className="fw-medium">{details?.mode_payment_info?.[0]?.bank_info?.ifsc}</span>
                                                                                </li>

                                                                                <li className="list-group-item">
                                                                                    <Icon name="coins"></Icon>  <span className="fw-medium">{details?.mode_payment_info?.[0]?.bank_info?.AccType?.toUpperCase()}</span>

                                                                                </li>

                                                                            </ul>
                                                                        </div>
                                                                    </Card.Body>
                                                                </Card>}
                                                            <Card className="mt-3" style={{ width: '100%' }}>
                                                                <Card.Body >
                                                                    <Badge bg="primary" className="position-absolute top-0 start-0 " style={{ zIndex: 1, fontSize: '16px', width: '200px' }}>
                                                                        Rate Chart
                                                                    </Badge>
                                                                    <div >
                                                                        <div className="d-flex flex-column align-items-center text-center mb-3">

                                                                        </div>

                                                                        {/* Additional Info */}
                                                                        <ul className="list-group list-group-borderless small">
                                                                            <li className="list-group-item">
                                                                                <span className="title fw-medium w-40 d-inline-block">RateChart Type:</span>
                                                                                <span className="text">{getChartType(rateChart?.rate_type)}</span>
                                                                            </li>
                                                                            <li className="list-group-item">
                                                                                <span className="title fw-medium w-40 d-inline-block">RateChart Name:</span>
                                                                                <span className="text">{rateChart?.rate_chart_name}</span>
                                                                            </li>

                                                                        </ul>
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>

                                                        </div>
                                                    </Card.Body>
                                                </div>
                                            </Col>

                                            <Col md="4">
                                                <Card className="mt-4" style={{ width: '93%' }}>
                                                    <Card.Body >
                                                        {/* User Info */}
                                                        <div >
                                                            <div className="position-absolute top-0 start-0 mb-2" style={{ zIndex: 1, }}>
                                                                <Badge bg="success" style={{ width: '180px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '16px' }}>
                                                                    Office Purpose
                                                                </Badge>
                                                            </div>

                                                            {/* Additional Info */}
                                                            <div className='mt-2'>
                                                                <ul className="list-group list-group-borderless small">
                                                                    <li className="list-group-item">
                                                                        <span className="title fw-medium w-50 d-inline-block">Convert Factor(Kg - Lit):</span>
                                                                        <span className="text">{details?.kg_ltr_factor}</span>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        <span className="title fw-medium w-50 d-inline-block">Payment Mode:</span>
                                                                        <span className="text">{details?.payment_cycle ? details?.payment_cycle?.[0]?.payment_cycle?.toUpperCase() : undefined}</span>
                                                                    </li>
                                                                    <li className="list-group-item">
                                                                        <span className="title fw-medium w-50 d-inline-block">Payment Cycle:</span>
                                                                        <span className="text">{details?.payment_cycle ? formatPaymentCycleCC(details?.payment_cycle?.[0]) : undefined}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </Card.Body>
                                                </Card>


                                            </Col>

                                        </Row>
                                    </Col>
                                </div>
                            </Card>
                        </Tab.Pane>
                    </Tab.Content>
                </Block>

                <Block className="mt-4">
                    {activeTab === "purchase" && <Tab.Content>
                        <Tab.Pane eventKey="purchase">
                            {type === "direct" && <Purchase />}
                            {type === "center" && <CenterPurchase />}
                        </Tab.Pane>
                    </Tab.Content>}
                </Block>

                <Block className="mt-4">
                    {activeTab === "chart" && <Tab.Content>
                        <Tab.Pane eventKey="chart">
                            <FarmerChart />
                        </Tab.Pane>
                    </Tab.Content>}
                </Block>
            </Tab.Container>

        </Layout >
    )
}

export default ProfilePage