import React, { useState } from 'react'
import { Block } from '../../../components';
import { Button, Card, Modal } from 'react-bootstrap';
import { menuColumn, menuData } from '../../../components/DataTable/TableData';

import DataTable from '../../../components/DataTable/DataTable';
import { useNavigate } from 'react-router-dom';

 const MenuView = () => {
    const navigate = useNavigate()
    const [fullscreenMd, setFullscreenMd] = useState(false);
    const handleCell = () => {
        setFullscreenMd(true)
    }

    const handleNavigate = (id) => {
        navigate(`/add-role/${id}`)
    }   

    return (
        <div>

            <Block>
                <Card>
                    <DataTable tableClassName="data-table-head-light table-responsive" data={menuData} columns={menuColumn(handleCell, handleNavigate)} />
                </Card>
            </Block>

            <Modal show={fullscreenMd}
                onHide={() => setFullscreenMd(false)}
                animation={false}
                style={{ maxWidth: '100%' }}>
                <Modal.Header
                    closeButton
                    style={{ justifyContent: 'center' }}  // Center the header content
                >
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>

                <Modal.Body
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ fontSize: '0.9rem', textAlign: 'center' }}  // Reduce font size and center text
                >
                    Are you sure ? You want to Remove
                </Modal.Body>

                <Modal.Footer className="d-flex justify-content-center">
                    <Button size="sm" variant="success" onClick={() => setFullscreenMd(false)}>
                        Yes!
                    </Button>
                    <Button size="sm" variant="danger" onClick={() => setFullscreenMd(false)}>
                        Cancel
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>


    )
}
export default MenuView