import React from "react";
import {
  Card,
  Form,
  FloatingLabel,
  Col,
  Row,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import { Icon } from "semantic-ui-react";
import Block from "../../components/Block/Block";

// import Layout from "../../layout/default";

const FarmerCreationFields = () => {
  return (
    <form>

      <Row>
        <Col md="4">
          <label htmlFor="exampleInputid" className="form-label">
            ID
          </label>
          <input
            type="number"
            className="form-control"
            id="exampleInputid"
            aria-describedby="idHelp"
            disabled
          />
        </Col>

        <Col md="4">
          <label htmlFor="exampleInputproducerName" className="form-label">
            Producer Name
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputproducerName"
          />
        </Col>

        <Col md="4">
          <label htmlFor="exampleInputproducerName" className="form-label">
            Gender
          </label>
          <br />
          <ButtonGroup aria-label="Basic example">
            <Button variant="outline-primary">Male</Button>
            <Button variant="outline-primary">Female</Button>
            <Button variant="outline-primary">Both</Button>
          </ButtonGroup>
        </Col>

        <Col md="4">
          <label htmlFor="exampleInputproducerName" className="form-label">
            Producer Name
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputproducerName"
          />
        </Col>

        <Col md="4">
          <label htmlFor="exampleInputMobileNumber " className="form-label">
            Mobile Number
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputMobileNumber"
          />
        </Col>

        <Col md="4">
          <label htmlFor="exampleInputAlternateMobile" className="form-label">
            Alternate Mobile No
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputAlternateMobile"
          />
        </Col>

        <Col md="4">
          <label
            htmlFor="exampleInputCommunicationMobile"
            className="form-label"
          >
            Communication Mobile No
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputCommunicationMobile"
          />
        </Col>

        <Col md="4">
          <label htmlFor="exampleInputemail" className="form-label">
            E-Mail
          </label>
          <input type="email" className="form-control" id="exampleInputemail" />
        </Col>
      </Row>
      <br />
      <button type="submit" className="btn btn-primary">
        Submit
      </button>
    </form>
  );
};

export default FarmerCreationFields;
