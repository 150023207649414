// ResponsiveForm.js
import React, { useState, useEffect, useRef } from "react";
import "../css/Responsive.css"; // Import the CSS file for styling
import { Icon } from "../../../components";
import { Card, Button, ButtonGroup, Col, Row, Form } from "react-bootstrap";
import Swal from "sweetalert2";

import Block from "../../../components/Block/Block";
import TimePicker from "../../../components/Form/TimePicker";
import { get, post, postToast } from "../api/Api";
import { createBody } from "../shared/api-structure/CollectionSettingStructure";
import { CodeDropDown } from "../shared/dropDown/CodeDropDown";
import { SettingsDetails, fetchFarmerList } from "../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationModal from "../modal/ConfirmationModal";

const CollectionSetting = () => {
  const [setLimit, setSetLimit] = useState("0");
  const farmerRef = useRef(null);
  const [centerId, setCenterId] = useState(null);
  const [centerCode, setCenterCode] = useState("");
  const centerRef = useRef(null);
  const [center_name, setCenterName] = useState("0");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [setTypeValue, SetSetTypeValue] = useState("0");
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { id } = useParams();
  const [centerList, setCenterList] = useState(null)

  useEffect(() => {
    if (parseFloat(id) !== 0) dispatch(SettingsDetails(id));
  }, [dispatch, id]);

  const { settingsDetails } = useSelector((state) => state.SettingsDetails);

  console.log(settingsDetails, "settingsDetails");
  // const editingData = JSON.parse(settingsDetails?.collection_settings)



  const findDefaultByTitle = (dataArray, title) => {
    const item = dataArray.find(item => item.title === title);
    return item ? item.default : null;
  };

  useEffect(() => {
    if (settingsDetails?.length !== 0 && parseFloat(id) !== 0) {
      const data = settingsDetails?.settings?.collection_settings;
      const details = settingsDetails?.center_details
      const modeInfoMatch = data?.mode_info;
      const decimalInfoMatch = data?.decimal_info;
      const actionInfoMatch = data?.action_info;
      const session_info = data?.session_info;
      const other_info = data?.other_info;
      console.log(data, "details&&==>");

      if (modeInfoMatch) {
        setChooseModeFat(modeInfoMatch[0]?.default)
        setChooseModeSNF(modeInfoMatch[1]?.default)
        setChooseModeQty(modeInfoMatch[2]?.default)
      }

      if (decimalInfoMatch) {
        setdecimalModeFAT(decimalInfoMatch[0]?.default)
        setdecimalModeSNF(decimalInfoMatch[1]?.default)
        setdecimalModeQTY(decimalInfoMatch[2]?.default)
      }

      if (other_info)
        setDefaultUOM(other_info?.[0].default_uom === 0 ? "Litre" : "KG")

      if (session_info)
        setTimePicker({
          morningFrom: session_info?.morning_session?.start_time,
          morningTo: session_info?.morning_session?.end_time,
          eveningFrom: session_info?.evening_session?.start_time,
          eveningTo: session_info?.evening_session?.end_time
        })
      const cowData = other_info?.find(item => item.rate_type === 'cow');
      const buffaloData = other_info?.find(item => item.rate_type === 'buffalo');
      const commonData = other_info?.find(item => item.rate_type === 'common');
      console.log("cowData", cowData);

      if (cowData)
        setCowValues({
          minCowSNF: cowData?.min_limit[0]?.snf,
          minCowFAT: cowData?.min_limit[0]?.fat,
          minCowQTY: cowData?.min_limit[0]?.qty,
          maxCowFAT: cowData?.max_limit[0]?.fat,
          maxCowSNF: cowData?.max_limit[0]?.snf,
          maxCowQTY: cowData?.max_limit[0]?.qty,
        })
      if (buffaloData)
        setBuffaloValues({
          minBuffaloSNF: buffaloData?.min_limit[0]?.snf,
          minBuffaloFAT: buffaloData?.min_limit[0]?.fat,
          minBuffaloQTY: buffaloData?.min_limit[0]?.qty,
          maxBuffaloFAT: buffaloData?.max_limit[0]?.fat,
          maxBuffaloSNF: buffaloData?.max_limit[0]?.snf,
          maxBuffaloQTY: buffaloData?.max_limit[0]?.qty,
        })

      if (commonData)
        setCommonValues({
          minCommonSNF: commonData?.min_limit[0]?.snf,
          minCommonFAT: commonData?.min_limit[0]?.fat,
          minCommonQTY: commonData?.min_limit[0]?.qty,
          maxCommonFAT: commonData?.max_limit[0]?.fat,
          maxCommonSNF: commonData?.max_limit[0]?.snf,
          maxCommonQTY: commonData?.max_limit[0]?.qty,
        })

      if (actionInfoMatch) {
        setSetLimit(findDefaultByTitle(actionInfoMatch, "Set Limit"))
        setadd_previous_session_data(findDefaultByTitle(actionInfoMatch, "Add Previous Session Data"))
        setsample_testing(findDefaultByTitle(actionInfoMatch, "Sample Testing"))
        setallow_date_change(findDefaultByTitle(actionInfoMatch, "Allow Date Change"))
        setenable_can_no(findDefaultByTitle(actionInfoMatch, "Enable Can No"))
        setdelete_collection_data(findDefaultByTitle(actionInfoMatch, "Delete Collection Data"))
        setprint_previous_bill(findDefaultByTitle(actionInfoMatch, "Print Previous Bill"))
        setallow_zero_value_in_rate(findDefaultByTitle(actionInfoMatch, "Allow Zero Value in Rate"))
      }


      if (details) {
        const data = centerData(details)
        console.log(data, "data--->");
        if (data)
          setCenterCode(data)
      }

      if (id)
        setCenterId(parseInt(id))


    }
  }, [id, settingsDetails]);

  const centerData = (details) => {
    return `${details.code}-${details.name}`
  }
  const [cowValues, setCowValues] = useState({
    minCowSNF: "6.5",
    minCowFAT: "2.0",
    minCowQTY: "0.0",
    maxCowFAT: "9.5",
    maxCowSNF: "7.5",
    maxCowQTY: "42",
  });
  const [commonValues, setCommonValues] = useState({
    minCommonSNF: "6.5",
    minCommonFAT: "2.0",
    minCommonQTY: "0.0",
    maxCommonFAT: "12.0",
    maxCommonSNF: "10.0",
    maxCommonQTY: "42",
  });
  const [buffaloValues, setBuffaloValues] = useState({
    minBuffaloSNF: "7.0",
    minBuffaloFAT: "5.0",
    minBuffaloQTY: "0.0",
    maxBuffaloFAT: "12.0",
    maxBuffaloSNF: "10.0",
    maxBuffaloQTY: "42",
  });
  const [validationMessages, setValidationMessages] = useState({
    centerCode: "",
    center: "",
    cow: {
      minCowSNF: "",
      minCowFAT: "",
      minCowQTY: "",
      maxCowFAT: "",
      maxCowSNF: "",
      maxCowQTY: "",
    },
    buffalo: {
      minBuffaloSNF: "",
      minBuffaloFAT: "",
      minBuffaloQTY: "",
      maxBuffaloFAT: "",
      maxBuffaloSNF: "",
      maxBuffaloQTY: "",
    },
    common: {
      minCommonSNF: "",
      minCommonFAT: "",
      minCommonQTY: "",
      maxCommonFAT: "",
      maxCommonSNF: "",
      maxCommonQTY: "",
    },
  });

  const [savedValues, setSavedValues] = useState({
    cow: {
      minCowSNF: "",
      minCowFAT: "",
      minCowQTY: "",
      maxCowFAT: "",
      maxCowSNF: "",
      maxCowQTY: "",
    },
    buffalo: {
      minBuffaloSNF: "",
      minBuffaloFAT: "",
      minBuffaloQTY: "",
      maxBuffaloFAT: "",
      maxBuffaloSNF: "",
      maxBuffaloQTY: "",
    },
  });

  const handleSetLimitChange = (value) => {
    setSetLimit(value);

    setValidationMessages({
      cow: {},
      buffalo: {},
      centerCode: "",
      center: "",
      common: {},
    });
    setCowValues({
      minCowSNF: "",
      minCowFAT: "",
      minCowQTY: "",
      maxCowFAT: "",
      maxCowSNF: "",
      maxCowQTY: "",
    });
    setBuffaloValues({
      minBuffaloSNF: "",
      minBuffaloFAT: "",
      minBuffaloQTY: "",
      maxBuffaloFAT: "",
      maxBuffaloSNF: "",
      maxBuffaloQTY: "",
    });
    setCommonValues({
      minCommonSNF: "",
      minCommonFAT: "",
      minCommonQTY: "",
      maxCommonFAT: "",
      maxCommonSNF: "",
      maxCommonQTY: "",
    });
  };


  const handleCowValueChange = (field, value) => {
    setCowValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
    setValidationMessages((prevMessages) => ({
      ...prevMessages,
      cow: {
        ...prevMessages.cow,
        [field]: "",
      },
    }));
  };

  const handleCommonValueChange = (field, value) => {
    setCommonValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
    setValidationMessages((prevMessages) => ({
      ...prevMessages,
      common: {
        ...prevMessages.common,
        [field]: "",
      },
    }));
  };

  const handleBuffaloValueChange = (field, value) => {
    setBuffaloValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
    setValidationMessages((prevMessages) => ({
      ...prevMessages,
      buffalo: {
        ...prevMessages.buffalo,
        [field]: "",
      },
    }));
  };

  const [chooseModeFat, setChooseModeFat] = useState("0");
  const [chooseModeSNF, setChooseModeSNF] = useState("0");
  const [chooseModeQty, setChooseModeQty] = useState("0");

  // Choose Mode End

  //Decimal Mode Start

  const [DecimalModeFAT, setdecimalModeFAT] = useState("0");
  const [DecimalModeSNF, setdecimalModeSNF] = useState("0");
  const [DecimalModeQTY, setdecimalModeQTY] = useState("0");

  //Decimal Mode End

  const [add_previous_session_data, setadd_previous_session_data] = useState("0");
  const [sample_testing, setsample_testing] = useState("1"); // Set the default value to 'No'
  const [allow_date_change, setallow_date_change] = useState("1");
  const [enable_can_no, setenable_can_no] = useState("1"); // Set the default value to 'No'
  const [delete_collection_data, setdelete_collection_data] = useState("1"); // Set the default value to 'No'
  const [print_previous_bill, setprint_previous_bill] = useState("1");
  const [allow_zero_value_in_rate, setallow_zero_value_in_rate] = useState("1");
  // 8Field Card End

  const [DefaultUOM, setDefaultUOM] = useState("Litre");

  const handleNumericInput = (e) => {
    // Allow only numeric characters and a dot in the second position onwards
    e.target.value = e.target.value.replace(/[^0-9.]/g, '');

    // Ensure dot is only allowed after the first character
    const parts = e.target.value.split('.');
    const firstChar = parts[0];

    if (firstChar === '.' || e.target.value.startsWith('.')) {
      // If the first character is a dot or the input starts with a dot, remove it
      e.target.value = firstChar.slice(1);
    } else if (parts.length > 1) {
      // If there is a dot, limit to two decimal places
      const decimalPart = parts[1].slice(0, 2);
      e.target.value = `${firstChar}.${decimalPart}`;
    }

    // Disable the dot button if the length of the decimal part is 3 or more
    const disableDotButton = parts.length > 1 && parts[1].length >= 2;
    const dotButton = e.target.nextElementSibling;
    if (dotButton && dotButton.tagName === 'BUTTON') {
      dotButton.disabled = disableDotButton;
    }
  };






  //New Code Start

  const [selectedTime, setSelectedTime] = useState("AM");

  const [timePicker, setTimePicker] = useState({
    morningFrom: null,
    morningTo: null,
    eveningFrom: null,
    eveningTo: null
  })

  const handleTimeButtonClick = (time) => {
    setSelectedTime(time)
  };

  const handleTimeChange = (value, session, noon) => {
    console.log("Time Picker: " + value);

    if ((session === "AM") && (noon === "From")) {
      setTimePicker({ ...timePicker, morningFrom: value })
    } else if ((session === "AM") && (noon === "To")) {
      setTimePicker({ ...timePicker, morningTo: value })
    } else if ((session === "PM") && (noon === "From")) {
      setTimePicker({ ...timePicker, eveningFrom: value })
    } else if ((session === "PM") && (noon === "To")) {
      setTimePicker({ ...timePicker, eveningTo: value })
    }
  };



  //New Code End

  const [formData, setFormData] = useState({
    mode_info: {
      chooseModeFat: "0",
      chooseModeSNF: "0",
      chooseModeQty: "0",
    },
    timePicker: timePicker,
    // center_name: initialcenter_name,
    centerCode: "",
    decimal_info: {
      DecimalModeFAT: "0",
      DecimalModeSNF: "0",
      DecimalModeQTY: "0",
    },

    add_previous_session_data: "1",
    sample_testing: "1",
    allow_date_change: "1",
    enable_can_no: "1",
    delete_collection_data: "1",
    print_previous_bill: "1",
    allow_zero_value_in_rate: "1",
    DefaultUOM: "1",
    set_limit: "1",
    savedValues, //Cow And Buffalos Set Limit Values
  });

  console.log("Final FormData ====> ", formData);

  //New

  const handleSave = (e) => {
    e.preventDefault();

    try {
      let validationErrors = {
        centerCode: "",
        center: "",
        cow: {},
        buffalo: {},
        common: {},
      };

      if (setLimit === "0") {
        // Validate cow values
        if (setTypeValue === "0") {
          Object.entries(cowValues).forEach(([field, value]) => {
            if (!value) {
              validationErrors.cow[
                field
              ] = `Please enter a value for Cow field ${field}.`;
            }
          });
        }

        // Validate buffalo values
        if (setTypeValue === "0") {
          Object.entries(buffaloValues).forEach(([field, value]) => {
            if (!value) {
              validationErrors.buffalo[
                field
              ] = `Please enter a value for Buffalo field ${field}.`;
            }
          });
        }

        // Validate buffalo values
        if (setTypeValue === "1") {
          Object.entries(commonValues).forEach(([field, value]) => {
            if (!value) {
              validationErrors.common[
                field
              ] = `Please enter a value for Buffalo field ${field}.`;
            }
          });
        }
      }

      setValidationMessages(validationErrors);

      if (
        Object.keys(validationErrors.cow).length > 0 ||
        Object.keys(validationErrors.buffalo).length > 0 ||
        Object.keys(validationErrors.common).length > 0
      ) {
        return;
      }

      // Create a new object with the current form data
      const centerData = {
        mode_info: { chooseModeFat, chooseModeSNF, chooseModeQty },
        decimal_info: { DecimalModeFAT, DecimalModeSNF, DecimalModeQTY },
        add_previous_session_data,
        sample_testing,
        allow_date_change,
        enable_can_no,
        delete_collection_data,
        print_previous_bill,
        allow_zero_value_in_rate,
        DefaultUOM,
        setLimit,
        cowValues,
        buffaloValues,
        setTypeValue,
        timePicker
      };

      const transformedData = createBody(centerData, "center", centerId);

      const createMethod = async () => {
        const url = `/api/settings/update`;
        // Send the data to the server
        const response = await post(url, transformedData);
        try {
          if (response.status) {
            console.log("Server response:", response);
            navigate(`/settings-view/equipment-settings/${id}`)
            // Store the values
            setSavedValues({
              cow: { ...cowValues },
              buffalo: { ...buffaloValues },
              common: { ...commonValues },
            });

            // Reset validation message and form fields
            setValidationMessages({
              cow: {},
              buffalo: {},
              centerCode: "",
              center: "",
            });

            setCowValues({
              field1: "", // Reset other cow field values
              field2: "",
              // ... other cow fields
            });
            setBuffaloValues({
              field1: "", // Reset other buffalo field values
              field2: "",
              // ... other buffalo fields
            });

            // Reset setLimit to its default value
            setSetLimit("default"); // You can replace 'default' with your actual default value
            setCenterCode("");
            setSetLimit("0");
          }
        } catch (error) {
          console.error("Error while posting data:", error);
        } finally {
        }
      };

      createMethod();

      // Your save logic here
      console.log("Save clicked");

      console.log("Form data saved to local storage!");
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const filtered_list = async (body) => {
    const url = `/api/direct-farmer/filtered_list`;
    try {
      const response = await postToast(url, body);
      if (response.status === true) {
        console.log("Server farmer response:", response.data.data);

        setCenterList(response.data.data)

        // setLoading(false);
      } else {
        // setLoading(false);
      }
    } catch (error) {
      console.error("Error while posting data:", error);
      // setLoading(false);
    }
  };





  useEffect(() => {

    const body = { type: ['1'] }
    if (body)
      filtered_list(body)

  }, []);

  const handleFarmerCode = (value) => {
    // Simple validation check
    if (!value || value.trim() === "") {
      return "Farmer name with Code is required.";
    }
    return ""; // No error
  };

  // Get Center Name and Center Code
  const handleInputCenter = (value, id, data) => {
    console.log(data, "data");
    setCenterCode(value);
    setCenterId(data.id);
  };

  const handleCenter = (name) => {
    // setInputValue(name)
  };

  const handleCenterCodeChange = (value) => {
    // Validate the selected value
    if (value.trim() === "") {
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        centerCode: "Center Code Is Required.",
      }));
    } else {
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        centerCode: "", // Clear the error message if a valid center is chosen
      }));
    }

    // Update the state with the selected value
    // setCenterName(value);
    setCenterCode("");
  };

  const handleCenterNameChange = (value) => {
    // Validate the selected value
    if (value === "0") {
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        center: "Please choose a center.",
      }));
    } else {
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        center: "", // Clear the error message if a valid center is chosen
      }));
    }

    // Update the state with the selected value
    setCenterName(value);
  };

  const handleSetType = (e) => {
    console.log(e.target.value, "====>");
    SetSetTypeValue(e.target.value);
  };

  const handleYesClick = () => {
    navigate(`/settings-view/equipment-settings/${id}`)
    setShowConfirmationModal(false); // Close the confirmation modal
  };

  const askModal = () => {
    setShowConfirmationModal(true)
  }

  return (
    <Card>
      <Card.Body>

        <ConfirmationModal
          show={showConfirmationModal}
          title="Closing Confirmation"
          body="Are you sure?"
          onCancel={() => {
            setShowConfirmationModal(false)
          }}
          onConfirm={handleYesClick}
        />

        <Form className="responsive-form">
          <Row>
            <Col md="4">
              <Form.Group className="form-group">
                <CodeDropDown
                  data={centerList}
                  placeholder="Center Code"
                  name="Center Code"
                  nextInputRef={farmerRef}
                  onValidate={handleFarmerCode}
                  onSelect={(selectedItem, type, data, error) => {
                    handleInputCenter(selectedItem, type, data, error);
                  }}
                  // value={center}
                  func={handleCenter}
                  inputRef={centerRef}
                  style={{ width: "155px" }}
                  value={centerCode}
                  onChange={(e) => handleCenterCodeChange(e.target.value)}
                />

                {validationMessages.centerCode && (
                  <div className="text-danger mt-2">
                    {validationMessages.centerCode}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group className="form-group">
                <Form.Label className="me-2">Default UOM</Form.Label>
                <ButtonGroup title="UOM" id="DefaultUOM" name="DefaultUOM">
                  <input
                    type="radio"
                    className="btn-check"
                    id="DefaultUOM-Litre"
                    autoComplete="off"
                    name="DefaultUOM"
                    checked={DefaultUOM === "Litre"}
                    onChange={() => setDefaultUOM("Litre")}
                  />
                  <Button
                    as="label"
                    variant="outline-primary"
                    htmlFor="DefaultUOM-Litre"
                  >
                    Litre
                  </Button>

                  <input
                    type="radio"
                    className="btn-check"
                    id="DefaultUOM-KG"
                    autoComplete="off"
                    name="DefaultUOM"
                    checked={DefaultUOM === "KG"}
                    onChange={() => setDefaultUOM("KG")}
                  />
                  <Button
                    as="label"
                    variant="outline-primary"
                    htmlFor="DefaultUOM-KG"
                  >
                    KG
                  </Button>
                </ButtonGroup>
              </Form.Group>
            </Col>

            <Col lg="2">
              <Form.Label htmlFor="PrimaryRoute">Set Time AM/PM</Form.Label>
              <Form.Group className="form-group" controlId="validationCustom01">
                <ButtonGroup aria-label="Session Selection">
                  <input
                    type="radio"
                    className="btn-check"
                    value='am'
                    name="SESSION_NAME_PLACEHOLDER"
                    id="SESSION_ID_PLACEHOLDER_1"
                    autoComplete="off"
                    checked={selectedTime === "AM"}
                    onChange={() => handleTimeButtonClick("AM")}
                  />
                  <Button as="label" variant="outline-primary" htmlFor="SESSION_ID_PLACEHOLDER_1">
                    <Icon name="sun-fill"></Icon>
                  </Button>

                  <input
                    type="radio"
                    className="btn-check"
                    value='pm'
                    name="SESSION_NAME_PLACEHOLDER"
                    id="SESSION_ID_PLACEHOLDER_2"
                    autoComplete="off"
                    checked={selectedTime === "PM"}
                    onChange={() => handleTimeButtonClick("PM")}
                  />
                  <Button as="label" variant="outline-primary" htmlFor="SESSION_ID_PLACEHOLDER_2">
                    <Icon name="moon-fill"></Icon>
                  </Button>
                </ButtonGroup>

              </Form.Group>
            </Col>

            {selectedTime === "AM" && <Col lg="2">
              <Form.Label htmlFor="PrimaryRoute">From <Icon name="sun-fill" /></Form.Label>
              <Form.Group className="form-group" controlId="validationCustom01">
                <TimePicker
                  format={12}
                  value={timePicker.morningFrom}
                  placeholder="hh:mm"
                  minDetail="hour"
                  maxDetail="hour"
                  autoComplete="off"
                  maxTime={new Date(0, 0, 0, 11, 59)}
                  onChange={(value) => handleTimeChange(value, "AM", "From")}
                />

              </Form.Group>
            </Col>}
            {selectedTime === "AM" && <Col lg="2">
              <Form.Label htmlFor="PrimaryRoute">To <Icon name="sun-fill" /></Form.Label>
              <Form.Group className="form-group" controlId="validationCustom01">
                <TimePicker
                  format={12}
                  value={timePicker.morningTo}
                  placeholder="hh:mm"
                  minDetail="hour"
                  maxDetail="hour"
                  autoComplete="off"
                  maxTime={new Date(0, 0, 0, 11, 59)}
                  onChange={(value) => handleTimeChange(value, "AM", "To")}
                />

              </Form.Group>
            </Col>}
            {selectedTime === "PM" && <Col lg="2">
              <Form.Label htmlFor="PrimaryRoute">From <Icon name="moon-fill" /></Form.Label>
              <Form.Group className="form-group" controlId="validationCustom01">
                <TimePicker
                  format={12}
                  value={timePicker.eveningFrom}
                  placeholder="hh:mm"
                  minDetail="hour"
                  maxDetail="hour"
                  autoComplete="off"
                  maxTime={new Date(0, 0, 0, 11, 59)}
                  onChange={(value) => handleTimeChange(value, "PM", "From")}
                />

              </Form.Group>
            </Col>}
            {selectedTime === "PM" && <Col lg="2">
              <Form.Label htmlFor="PrimaryRoute">To <Icon name="moon-fill" /></Form.Label>
              <Form.Group className="form-group" controlId="validationCustom01">
                <TimePicker
                  format={12}
                  value={timePicker.eveningTo}
                  placeholder="hh:mm"
                  minDetail="hour"
                  maxDetail="hour"
                  autoComplete="off"
                  maxTime={new Date(0, 0, 0, 11, 59)}
                  onChange={(value) => handleTimeChange(value, "PM", "To")}
                />

              </Form.Group>
            </Col>}


          </Row>

          {/* </Row> */}

          <br />
          <Row>
            <Col md="8">
              <Card>
                <Card.Body>
                  {/* Choose Mode fat */}
                  <Row>
                    <Col md="6">
                      <Block.Title tag="h4">Choose Mode :</Block.Title>

                      <Form.Group className="form-group">
                        <Form.Label
                          className="me-2"
                          style={{ marginTop: "0.7cm" }}
                        >
                          FAT%
                        </Form.Label>
                        <ButtonGroup
                          title="FAT"
                          id="mode_infoFat"
                          name="mode_infoFat"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            id="mode_infoFat-Automatic"
                            autoComplete="off"
                            name="mode_infoFat"
                            checked={chooseModeFat === "0"}
                            onChange={() => setChooseModeFat("0")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="mode_infoFat-Automatic"
                            style={{ width: "85px" }}
                          >
                            Automatic
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            id="mode_infoFat-Manual"
                            autoComplete="off"
                            name="mode_infoFat"
                            checked={chooseModeFat === "1"}
                            onChange={() => setChooseModeFat("1")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="mode_infoFat-Manual"
                          >
                            Manual
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            id="mode_infoFat-Both"
                            autoComplete="off"
                            name="mode_infoFat"
                            checked={chooseModeFat === "2"}
                            onChange={() => setChooseModeFat("2")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="mode_infoFat-Both"
                          >
                            Both
                          </Button>
                        </ButtonGroup>
                      </Form.Group>

                      {/* Choose Mode SNF% */}
                      <Form.Group className="form-group">
                        <Form.Label
                          className="me-2"
                          style={{ marginTop: "0.5cm" }}
                        >
                          SNF%
                        </Form.Label>
                        <ButtonGroup
                          title="SNF"
                          id="mode_infoSNF"
                          name="mode_infoSNF"
                        >
                          <input
                            defaultChecked
                            type="radio"
                            className="btn-check"
                            id="mode_infoSNF-Automatic"
                            autoComplete="off"
                            name="mode_infoSNF"
                            checked={chooseModeSNF === "0"}
                            onChange={() => setChooseModeSNF("0")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            style={{ width: "85px" }}
                            htmlFor="mode_infoSNF-Automatic"
                          >
                            Automatic
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            id="mode_infoSNF-Manual"
                            autoComplete="off"
                            name="mode_infoSNF"
                            checked={chooseModeSNF === "1"}
                            onChange={() => setChooseModeSNF("1")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="mode_infoSNF-Manual"
                          >
                            Manual
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            id="mode_infoSNF-Both"
                            autoComplete="off"
                            name="mode_infoSNF"
                            checked={chooseModeSNF === "2"}
                            onChange={() => setChooseModeSNF("2")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="mode_infoSNF-Both"
                          >
                            Both
                          </Button>
                        </ButtonGroup>
                      </Form.Group>
                      {/* </Col> */}

                      {/* Choose Mode Qty */}
                      <Form.Group className="form-group">
                        <Form.Label
                          className="me-4"
                          style={{ marginTop: "0.5cm" }}
                        >
                          QTY
                          {/*  <span className="me-2">
                          <i className="fa fa-exclamation-triangle text-danger">
                            *
                          </i>
                        </span> */}
                        </Form.Label>
                        <ButtonGroup
                          // horizontal
                          title="QTY"
                          id="mode_infoQty"
                          name="mode_infoQty"
                        >
                          <input
                            defaultChecked
                            type="radio"
                            className="btn-check"
                            id="mode_infoQty-Automatic"
                            autoComplete="off"
                            name="mode_infoQty"
                            checked={chooseModeQty === "0"}
                            onChange={() => setChooseModeQty("0")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="mode_infoQty-Automatic"
                            style={{ width: "85px" }}
                          >
                            Automatic
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            id="mode_infoQty-Manual"
                            autoComplete="off"
                            name="mode_infoQty"
                            checked={chooseModeQty === "1"}
                            onChange={() => setChooseModeQty("1")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="mode_infoQty-Manual"
                          >
                            Manual
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            id="mode_infoQty-Both"
                            autoComplete="off"
                            name="mode_infoQty"
                            checked={chooseModeQty === "2"}
                            onChange={() => setChooseModeQty("2")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="mode_infoQty-Both"
                          >
                            Both
                          </Button>
                        </ButtonGroup>
                      </Form.Group>
                    </Col>

                    <Col md="6">
                      <Block.Title tag="h4">Decimal Mode :</Block.Title>
                      <Form.Group
                        className="form-group"
                        style={{ marginTop: "0.5cm" }}
                      >
                        <Form.Label className="label-desktop me-2">
                          FAT
                        </Form.Label>
                        <ButtonGroup
                          // className="mx-1"
                          // horizontal
                          style={{ height: "44px" }}
                          title="FAT"
                          id="DecimalModeFat"
                          name="DecimalModeFat"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            id="DecimalModeFat-0.00"
                            autoComplete="off"
                            name="DecimalModeFat"
                            checked={DecimalModeFAT === "0"}
                            onChange={() => setdecimalModeFAT("0")}
                          />
                          <Button
                            style={{ width: "60px" }}
                            as="label"
                            variant="outline-primary"
                            htmlFor="DecimalModeFat-0.00"
                          >
                            0.00
                          </Button>

                          <input
                            defaultChecked
                            type="radio"
                            className="btn-check"
                            id="DecimalModeFat-RoundOff0.0"
                            autoComplete="off"
                            name="DecimalModeFat"
                            checked={DecimalModeFAT === "1"}
                            onChange={() => setdecimalModeFAT("1")}
                          />
                          <Button
                            style={{
                              width: "100px",
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                            }}
                            as="label"
                            variant="outline-primary"
                            htmlFor="DecimalModeFat-RoundOff0.0"
                          >
                            RoundOff 0.0
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            id="DecimalModeFat-Truncate"
                            autoComplete="off"
                            name="DecimalModeFat"
                            checked={DecimalModeFAT === "2"}
                            onChange={() => setdecimalModeFAT("2")}
                          />
                          <Button
                            style={{
                              width: "100px",
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                            }}
                            as="label"
                            variant="outline-primary"
                            htmlFor="DecimalModeFat-Truncate"
                          >
                            Truncate 0.0
                          </Button>
                        </ButtonGroup>
                      </Form.Group>

                      {/* Choose Mode SNF% */}
                      <Form.Group
                        className="form-group"
                        style={{ marginTop: "0.28cm" }}
                      >
                        <Form.Label className="label-desktop me-2">
                          SNF
                        </Form.Label>
                        <ButtonGroup
                          style={{ height: "44px" }}
                          title="SNF"
                          id="DecimalModeSNF"
                          name="DecimalModeSNF"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            id="DecimalModeSNF-0.00"
                            autoComplete="off"
                            name="DecimalModeSNF"
                            checked={DecimalModeSNF === "0"}
                            onChange={() => setdecimalModeSNF("0")}
                          />
                          <Button
                            style={{ width: "60px" }}
                            as="label"
                            variant="outline-primary"
                            htmlFor="DecimalModeSNF-0.00"
                          >
                            0.0
                          </Button>

                          <input
                            defaultChecked
                            type="radio"
                            className="btn-check"
                            id="DecimalModeSNF-RoundOff0.00"
                            autoComplete="off"
                            name="DecimalModeSNF"
                            checked={DecimalModeSNF === "1"}
                            onChange={() => setdecimalModeSNF("1")}
                          />
                          <Button
                            style={{
                              width: "100px",
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                            }}
                            as="label"
                            variant="outline-primary"
                            htmlFor="DecimalModeSNF-RoundOff0.00"
                          // style={{ width: "100px" }} // Adjust the width as needed
                          >
                            RoundOff 0.0
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            id="DecimalModeSNF-Truncate0.0"
                            autoComplete="off"
                            name="DecimalModeSNF"
                            checked={DecimalModeSNF === "2"}
                            onChange={() => setdecimalModeSNF("2")}
                          />
                          <Button
                            style={{
                              width: "100px",
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                            }}
                            as="label"
                            variant="outline-primary"
                            htmlFor="DecimalModeSNF-Truncate0.0"
                          >
                            Truncate 0.0
                          </Button>
                        </ButtonGroup>
                      </Form.Group>
                      {/* </Col> */}

                      {/* Choose Mode Qty */}
                      <Form.Group
                        className="form-group"
                        style={{ marginTop: "0.28cm" }}
                      >
                        <Form.Label className="label-desktop me-2">
                          QTY
                        </Form.Label>
                        <ButtonGroup
                          style={{ height: "44px" }}
                          // horizontal
                          title="QTY"
                          id="DecimalModeQty"
                          name="DecimalModeQty"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            id="DecimalModeQty-0.000"
                            autoComplete="off"
                            name="DecimalModeQty"
                            checked={DecimalModeQTY === "0"}
                            onChange={() => setdecimalModeQTY("0")}
                          />
                          <Button
                            style={{ width: "55px" }}
                            as="label"
                            variant="outline-primary"
                            htmlFor="DecimalModeQty-0.000"
                          >
                            0.000
                          </Button>

                          <input
                            defaultChecked
                            type="radio"
                            className="btn-check"
                            id="DecimalModeQty-Round-Off 0.00"
                            autoComplete="off"
                            name="DecimalModeQty"
                            checked={DecimalModeQTY === "1"}
                            onChange={() => setdecimalModeQTY("1")}
                          />
                          <Button
                            style={{
                              width: "105px",
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                            }}
                            as="label"
                            variant="outline-primary"
                            htmlFor="DecimalModeQty-Round-Off 0.00"
                          >
                            RoundOff 0.00
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            id="DecimalModeQty-Truncate 0.00"
                            autoComplete="off"
                            name="DecimalModeQty"
                            checked={DecimalModeQTY === "2"}
                            onChange={() => setdecimalModeQTY("2")}
                          />
                          <Button
                            style={{
                              width: "100px",
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                            }}
                            as="label"
                            variant="outline-primary"
                            htmlFor="DecimalModeQty-Truncate 0.00"
                          >
                            Truncate 0.00
                          </Button>
                        </ButtonGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

          </Row>
          <br />
          <Row>
            <Col md="5">
              <Card>
                <Card.Body>
                  <Row>
                    <Col md="5">
                      <Form.Group className="form-group">
                        <Row>
                          <Form.Label className="me-2">Set Limit</Form.Label>
                        </Row>
                        <ButtonGroup
                          id="SetLimitExample"
                          name="SetLimitExample"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            id="SetLimitExample-Yes"
                            autoComplete="off"
                            name="SetLimitExample"
                            onChange={() => handleSetLimitChange("0")}
                            checked={setLimit === "0"}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="SetLimitExample-Yes"
                          >
                            Yes
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            id="SetLimitExample-No"
                            autoComplete="off"
                            name="SetLimitExample"
                            onChange={() => handleSetLimitChange("1")}
                            checked={setLimit === "1"}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="SetLimitExample-No"
                          >
                            No
                          </Button>
                        </ButtonGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="form-group">
                        <Row>
                          <Form.Label className="me-2">
                            Add Previous Session Data
                          </Form.Label>
                        </Row>
                        <ButtonGroup
                          // className="mx-1"
                          // horizontal
                          // title="QTY"
                          id="add_previous_session_data"
                          name="add_previous_session_data"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            id="add_previous_session_data-Yes"
                            autoComplete="off"
                            name="add_previous_session_data"
                            checked={add_previous_session_data === "0"}
                            onChange={() => setadd_previous_session_data("0")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="add_previous_session_data-Yes"
                          >
                            Yes
                          </Button>

                          <input
                            defaultChecked
                            type="radio"
                            className="btn-check"
                            id="add_previous_session_data-No"
                            autoComplete="off"
                            name="add_previous_session_data"
                            checked={add_previous_session_data === "1"}
                            onChange={() => setadd_previous_session_data("1")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="add_previous_session_data-No"
                          >
                            No
                          </Button>
                        </ButtonGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Row 2 */}
                  <Row>
                    <Col md="5">
                      <Form.Group className="form-group">
                        <Row style={{ marginTop: "0.3cm" }}>
                          <Form.Label className="me-2">
                            Sample Testing
                          </Form.Label>
                        </Row>
                        <ButtonGroup id="sample_testing" name="sample_testing">
                          <input
                            type="radio"
                            className="btn-check"
                            id="sample_testing-Yes"
                            autoComplete="off"
                            name="sample_testing"
                            checked={sample_testing === "0"}
                            onChange={() => setsample_testing("0")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="sample_testing-Yes"
                          >
                            Yes
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            id="sample_testing-No"
                            autoComplete="off"
                            name="sample_testing"
                            checked={sample_testing === "1"}
                            onChange={() => setsample_testing("1")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="sample_testing-No"
                          >
                            No
                          </Button>
                        </ButtonGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="form-group">
                        <Row style={{ marginTop: "0.3cm" }}>
                          <Form.Label className="me-2">
                            Allow Date Change
                          </Form.Label>
                        </Row>
                        <ButtonGroup
                          id="allow_date_change"
                          name="allow_date_change"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            id="allow_date_change-Yes"
                            autoComplete="off"
                            name="allow_date_change"
                            checked={allow_date_change === "0"}
                            onChange={() => setallow_date_change("0")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="allow_date_change-Yes"
                          >
                            Yes
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            id="allow_date_change-No"
                            autoComplete="off"
                            name="allow_date_change"
                            checked={allow_date_change === "1"}
                            onChange={() => setallow_date_change("1")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="allow_date_change-No"
                          >
                            No
                          </Button>
                        </ButtonGroup>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Row 3 */}

                  <Row>
                    <Col md="5">
                      <Form.Group className="form-group">
                        <Row style={{ marginTop: "0.3cm" }}>
                          <Form.Label className="me-2">
                            Enable Can No
                          </Form.Label>
                        </Row>
                        <ButtonGroup id="enable_can_no" name="enable_can_no">
                          <input
                            type="radio"
                            className="btn-check"
                            id="enable_can_no-Yes"
                            autoComplete="off"
                            name="enable_can_no"
                            checked={enable_can_no === "0"}
                            onChange={() => setenable_can_no("0")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="enable_can_no-Yes"
                          >
                            Yes
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            id="enable_can_no-No"
                            autoComplete="off"
                            name="enable_can_no"
                            checked={enable_can_no === "1"}
                            onChange={() => setenable_can_no("1")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="enable_can_no-No"
                          >
                            No
                          </Button>
                        </ButtonGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="form-group">
                        <Row style={{ marginTop: "0.3cm" }}>
                          <Form.Label className="me-2">
                            Delete Collection Data
                          </Form.Label>
                        </Row>
                        <ButtonGroup
                          id="delete_collection_data"
                          name="delete_collection_data"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            id="delete_collection_data-Yes"
                            autoComplete="off"
                            name="delete_collection_data"
                            checked={delete_collection_data === "0"}
                            onChange={() => setdelete_collection_data("0")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="delete_collection_data-Yes"
                          >
                            Yes
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            id="delete_collection_data-No"
                            autoComplete="off"
                            name="delete_collection_data"
                            checked={delete_collection_data === "1"}
                            onChange={() => setdelete_collection_data("1")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="delete_collection_data-No"
                          >
                            No
                          </Button>
                        </ButtonGroup>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Row 4 */}
                  <Row>
                    <Col md="5">
                      <Form.Group className="form-group">
                        <Row style={{ marginTop: "0.3cm" }}>
                          <Form.Label className="me-2">
                            Print Previous Bill
                          </Form.Label>
                        </Row>
                        <ButtonGroup
                          id="print_previous_bill"
                          name="print_previous_bill"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            id="print_previous_bill-Yes"
                            autoComplete="off"
                            name="print_previous_bill"
                            checked={print_previous_bill === "0"}
                            onChange={() => setprint_previous_bill("0")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="print_previous_bill-Yes"
                          >
                            Yes
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            id="print_previous_bill-No"
                            autoComplete="off"
                            name="print_previous_bill"
                            checked={print_previous_bill === "1"}
                            onChange={() => setprint_previous_bill("1")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="print_previous_bill-No"
                          >
                            No
                          </Button>
                        </ButtonGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="form-group">
                        <Row style={{ marginTop: "0.3cm" }}>
                          <Form.Label className="me-2">
                            Allow Zero Value In Rate
                          </Form.Label>
                        </Row>
                        <ButtonGroup
                          id="allow_zero_value_in_rate"
                          name="allow_zero_value_in_rate"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            id="allow_zero_value_in_rate-Yes"
                            autoComplete="off"
                            name="allow_zero_value_in_rate"
                            checked={allow_zero_value_in_rate === "0"}
                            onChange={() => setallow_zero_value_in_rate("0")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="allow_zero_value_in_rate-Yes"
                          >
                            Yes
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            id="allow_zero_value_in_rate-No"
                            autoComplete="off"
                            name="allow_zero_value_in_rate"
                            checked={allow_zero_value_in_rate === "1"}
                            onChange={() => setallow_zero_value_in_rate("1")}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="allow_zero_value_in_rate-No"
                          >
                            No
                          </Button>
                        </ButtonGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md="7">
              {setLimit === "0" && (
                <Col md="12">
                  <Card>
                    <Card.Body>
                      <Block.Title tag="h4" className="me-2">
                        Set Limit :
                      </Block.Title>

                      <Row>
                        <Col md="12">
                          {setLimit === "0" && (
                            <Col md="12">
                              <Col lg="4">
                                <Form.Group className="form-group">
                                  <ButtonGroup aria-label="Basic radio toggle button group">
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="milkTypeChart"
                                      id="cowBuffaloYes"
                                      autoComplete="off"
                                      value="0"
                                      checked={setTypeValue === "0"}
                                      onChange={handleSetType}
                                    />
                                    <Button
                                      as="label"
                                      variant="outline-primary"
                                      htmlFor="cowBuffaloYes"
                                      style={{
                                        width: "90px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Cow/Buffalo
                                    </Button>

                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="milkTypeChart"
                                      id="common"
                                      autoComplete="off"
                                      value="1"
                                      checked={setTypeValue === "1"}
                                      onChange={handleSetType}
                                    />
                                    <Button
                                      as="label"
                                      variant="outline-primary"
                                      htmlFor="common"
                                      style={{
                                        width: "90px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Common
                                    </Button>
                                  </ButtonGroup>
                                </Form.Group>
                              </Col>
                              <Row>
                                <Col md="12">
                                  {setTypeValue === "0" && (
                                    <div>
                                      <Form.Group className="form-group">
                                        <Form.Label htmlFor="Cowset_limitField">
                                          Min-Cow
                                          <span className="me-2">
                                            <i className="fa fa-exclamation-triangle text-danger">
                                              *
                                            </i>
                                          </span>
                                        </Form.Label>
                                        <Row>
                                          <Col md="4">
                                            <div className="form-control-wrap">
                                              <Form.Control
                                                onInput={handleNumericInput}
                                                type="text"
                                                maxLength={10}
                                                placeholder={`FAT`}
                                                value={cowValues[`minCowFAT`]}
                                                onChange={(e) =>
                                                  handleCowValueChange(
                                                    `minCowFAT`,
                                                    e.target.value
                                                  )
                                                }
                                                className="me-2 "
                                                isInvalid={
                                                  validationMessages.cow[
                                                  `minCowFAT`
                                                  ]
                                                }
                                              />
                                              {validationMessages.cow[
                                                `minCowFAT`
                                              ] && (
                                                  <div className="text-danger mt-2">
                                                    {
                                                      validationMessages.cow[
                                                      `minCowFAT`
                                                      ]
                                                    }
                                                  </div>
                                                )}
                                            </div>
                                          </Col>
                                          <Col md="4">
                                            <div className="form-control-wrap">
                                              <Form.Control
                                                onInput={handleNumericInput}
                                                type="text"
                                                maxLength={10}
                                                placeholder={`SNF`}
                                                value={cowValues[`minCowSNF`]}
                                                onChange={(e) =>
                                                  handleCowValueChange(
                                                    `minCowSNF`,
                                                    e.target.value
                                                  )
                                                }
                                                className="me-2 "
                                                isInvalid={
                                                  validationMessages.cow[
                                                  `minCowSNF`
                                                  ]
                                                }
                                              />
                                              {validationMessages.cow[
                                                `minCowSNF`
                                              ] && (
                                                  <div className="text-danger mt-2">
                                                    {/* Error Msg Shown */}
                                                  </div>
                                                )}
                                            </div>
                                          </Col>
                                          <Col md="4">
                                            <div className="form-control-wrap">
                                              <Form.Control
                                                onInput={handleNumericInput}
                                                type="text"
                                                maxLength={10}
                                                placeholder={`QTY`}
                                                value={cowValues[`minCowQTY`]}
                                                onChange={(e) =>
                                                  handleCowValueChange(
                                                    `minCowQTY`,
                                                    e.target.value
                                                  )
                                                }
                                                className="me-2"
                                                isInvalid={
                                                  validationMessages.cow[
                                                  `minCowQTY`
                                                  ]
                                                }
                                              />
                                              {validationMessages.cow[
                                                `minCowQTY`
                                              ] && (
                                                  <div className="text-danger mt-2"></div>
                                                )}
                                            </div>
                                          </Col>
                                        </Row>
                                        <Col>
                                          <Form.Label htmlFor="Cowset_limitField">
                                            Max-Cow
                                            <span className="me-2">
                                              <i className="fa fa-exclamation-triangle text-danger">
                                                *
                                              </i>
                                            </span>
                                          </Form.Label>
                                          <Row>
                                            <Col md="4">
                                              <Form.Control
                                                onInput={handleNumericInput}
                                                type="text"
                                                maxLength={10}
                                                placeholder={`FAT`}
                                                value={cowValues[`maxCowFAT`]}
                                                onChange={(e) =>
                                                  handleCowValueChange(
                                                    `maxCowFAT`,
                                                    e.target.value
                                                  )
                                                }
                                                className="me-2"
                                                isInvalid={
                                                  validationMessages.cow[
                                                  `maxCowFAT`
                                                  ]
                                                }
                                              />
                                              {validationMessages.cow[
                                                `maxCowFAT`
                                              ] && (
                                                  <div className="text-danger mt-2"></div>
                                                )}
                                            </Col>
                                            <Col md="4">
                                              <div className="form-control-wrap">
                                                <Form.Control
                                                  onInput={handleNumericInput}
                                                  type="text"
                                                  maxLength={10}
                                                  placeholder={`SNF`}
                                                  value={cowValues[`maxCowSNF`]}
                                                  onChange={(e) =>
                                                    handleCowValueChange(
                                                      `maxCowSNF`,
                                                      e.target.value
                                                    )
                                                  }
                                                  className="me-2"
                                                  isInvalid={
                                                    validationMessages.cow[
                                                    `maxCowSNF`
                                                    ]
                                                  }
                                                />
                                                {validationMessages.cow[
                                                  `maxCowSNF`
                                                ] && (
                                                    <div className="text-danger mt-2"></div>
                                                  )}
                                              </div>
                                            </Col>

                                            <Col md="4">
                                              <Form.Control
                                                onInput={handleNumericInput}
                                                type="text"
                                                maxLength={10}
                                                placeholder={`QTY`}
                                                value={cowValues[`maxCowQTY`]}
                                                onChange={(e) =>
                                                  handleCowValueChange(
                                                    `maxCowQTY`,
                                                    e.target.value
                                                  )
                                                }
                                                className="me-2"
                                                isInvalid={
                                                  validationMessages.cow[
                                                  `maxCowQTY`
                                                  ]
                                                }
                                              />
                                              {validationMessages.cow[
                                                `maxCowQTY`
                                              ] && (
                                                  <div className="text-danger mt-2"></div>
                                                )}
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Form.Group>
                                    </div>
                                  )}

                                  {setTypeValue === "0" && (
                                    <div>
                                      <Form.Group className="form-group">
                                        <Form.Group className="form-group">
                                          <Form.Label htmlFor="Buffaloset_limitField">
                                            Min-Buffalo
                                            <span className="me-2">
                                              <i className="fa fa-exclamation-triangle text-danger">
                                                *
                                              </i>
                                            </span>
                                          </Form.Label>
                                          <Row>
                                            <Col md="4">
                                              <Form.Control
                                                onInput={handleNumericInput}
                                                type="text"
                                                maxLength={10}
                                                placeholder={`FAT`}
                                                value={
                                                  buffaloValues[`minBuffaloFAT`]
                                                }
                                                onChange={(e) =>
                                                  handleBuffaloValueChange(
                                                    `minBuffaloFAT`,
                                                    e.target.value
                                                  )
                                                }
                                                className="me-2"
                                                isInvalid={
                                                  validationMessages.buffalo[
                                                  `minBuffaloFAT`
                                                  ]
                                                }
                                              />
                                              {validationMessages.buffalo[
                                                `minBuffaloFAT`
                                              ] && (
                                                  <div className="text-danger mt-2"></div>
                                                )}
                                            </Col>
                                            <Col md="4">
                                              <Form.Control
                                                placeholder={"SNF"}
                                                value={
                                                  buffaloValues[`minBuffaloSNF`]
                                                }
                                                onChange={(e) =>
                                                  handleBuffaloValueChange(
                                                    `minBuffaloSNF`,
                                                    e.target.value
                                                  )
                                                }
                                                onInput={handleNumericInput}
                                                type="text"
                                                maxLength={10}
                                                className="me-2"
                                                isInvalid={
                                                  validationMessages.buffalo[
                                                  `minBuffaloSNF`
                                                  ]
                                                }
                                              />
                                              {validationMessages.buffalo[
                                                `minBuffaloSNF`
                                              ] && (
                                                  <div className="text-danger mt-2"></div>
                                                )}
                                            </Col>

                                            <Col md="4">
                                              <Form.Control
                                                onInput={handleNumericInput}
                                                type="text"
                                                maxLength={10}
                                                placeholder={`QTY`}
                                                value={
                                                  buffaloValues[`minBuffaloQTY`]
                                                }
                                                onChange={(e) =>
                                                  handleBuffaloValueChange(
                                                    `minBuffaloQTY`,
                                                    e.target.value
                                                  )
                                                }
                                                className="me-2"
                                                isInvalid={
                                                  validationMessages.buffalo[
                                                  `minBuffaloQTY`
                                                  ]
                                                }
                                              />
                                              {validationMessages.buffalo[
                                                `minBuffaloQTY`
                                              ] && (
                                                  <div className="text-danger mt-2"></div>
                                                )}
                                            </Col>
                                          </Row>
                                          <Col>
                                            <Form.Label htmlFor="Buffaloset_limitField">
                                              Max-Buffalo
                                              <span className="me-2">
                                                <i className="fa fa-exclamation-triangle text-danger">
                                                  *
                                                </i>
                                              </span>
                                            </Form.Label>
                                            <Row>
                                              <Col md="4">
                                                <Form.Control
                                                  onInput={handleNumericInput}
                                                  type="text"
                                                  maxLength={10}
                                                  placeholder={`FAT`}
                                                  value={
                                                    buffaloValues[
                                                    `maxBuffaloFAT`
                                                    ]
                                                  }
                                                  onChange={(e) =>
                                                    handleBuffaloValueChange(
                                                      `maxBuffaloFAT`,
                                                      e.target.value
                                                    )
                                                  }
                                                  className="me-2"
                                                  isInvalid={
                                                    validationMessages.buffalo[
                                                    `maxBuffaloFAT`
                                                    ]
                                                  }
                                                />
                                                {validationMessages.buffalo[
                                                  `maxBuffaloFAT`
                                                ] && (
                                                    <div className="text-danger mt-2"></div>
                                                  )}
                                              </Col>
                                              <Col md="4">
                                                <Form.Control
                                                  onInput={handleNumericInput}
                                                  type="text"
                                                  maxLength={10}
                                                  placeholder={`SNF`}
                                                  value={
                                                    buffaloValues[
                                                    `maxBuffaloSNF`
                                                    ]
                                                  }
                                                  isInvalid={
                                                    validationMessages.buffalo[
                                                    `maxBuffaloSNF`
                                                    ]
                                                  }
                                                  onChange={(e) =>
                                                    handleBuffaloValueChange(
                                                      `maxBuffaloSNF`,
                                                      e.target.value
                                                    )
                                                  }
                                                  className="me-2"
                                                />
                                                {validationMessages.buffalo[
                                                  `maxBuffaloSNF`
                                                ] && (
                                                    <div className="text-danger mt-2"></div>
                                                  )}
                                              </Col>
                                              <Col md="4">
                                                <Form.Control
                                                  isInvalid={
                                                    validationMessages.buffalo[
                                                    `maxBuffaloQTY`
                                                    ]
                                                  }
                                                  onInput={handleNumericInput}
                                                  type="text"
                                                  maxLength={10}
                                                  placeholder={`QTY`}
                                                  value={
                                                    buffaloValues[
                                                    `maxBuffaloQTY`
                                                    ]
                                                  }
                                                  onChange={(e) =>
                                                    handleBuffaloValueChange(
                                                      `maxBuffaloQTY`,
                                                      e.target.value
                                                    )
                                                  }
                                                  className="me-2"
                                                />
                                                {validationMessages.buffalo[
                                                  `maxBuffaloQTY`
                                                ] && (
                                                    <div className="text-danger mt-2"></div>
                                                  )}
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Form.Group>
                                      </Form.Group>
                                    </div>
                                  )}

                                  {setTypeValue === "1" && (
                                    <div>
                                      <Form.Group className="form-group">
                                        <Form.Label htmlFor="Cowset_limitField">
                                          Min-Common
                                          <span className="me-2">
                                            <i className="fa fa-exclamation-triangle text-danger">
                                              *
                                            </i>
                                          </span>
                                        </Form.Label>
                                        <Row>
                                          <Col md="4">
                                            <div className="form-control-wrap">
                                              <Form.Control
                                                onInput={handleNumericInput}
                                                type="text"
                                                maxLength={10}
                                                placeholder={`FAT`}
                                                value={
                                                  commonValues.minCommonFAT
                                                }
                                                onChange={(e) =>
                                                  handleCommonValueChange(
                                                    `minCommonFAT`,
                                                    e.target.value
                                                  )
                                                }
                                                className="me-2 "
                                                isInvalid={
                                                  validationMessages.common[
                                                  `minCommonFAT`
                                                  ]
                                                }
                                              />
                                              {validationMessages.common
                                                .minCommonFAT && (
                                                  <div className="text-danger mt-2"></div>
                                                )}
                                            </div>
                                          </Col>
                                          <Col md="4">
                                            <div className="form-control-wrap">
                                              <Form.Control
                                                onInput={handleNumericInput}
                                                type="text"
                                                maxLength={10}
                                                placeholder={`SNF`}
                                                value={
                                                  commonValues[`minCommonSNF`]
                                                }
                                                onChange={(e) =>
                                                  handleCommonValueChange(
                                                    `minCommonSNF`,
                                                    e.target.value
                                                  )
                                                }
                                                className="me-2 "
                                                isInvalid={
                                                  validationMessages.common[
                                                  `minCommonSNF`
                                                  ]
                                                }
                                              />
                                              {validationMessages.common[
                                                `minCommonSNF`
                                              ] && (
                                                  <div className="text-danger mt-2">
                                                    {/* Error Msg Shown */}
                                                  </div>
                                                )}
                                            </div>
                                          </Col>
                                          <Col md="4">
                                            <div className="form-control-wrap">
                                              <Form.Control
                                                onInput={handleNumericInput}
                                                type="text"
                                                maxLength={10}
                                                placeholder={`QTY`}
                                                value={
                                                  commonValues[`minCommonQTY`]
                                                }
                                                onChange={(e) =>
                                                  handleCommonValueChange(
                                                    `minCommonQTY`,
                                                    e.target.value
                                                  )
                                                }
                                                className="me-2"
                                                isInvalid={
                                                  validationMessages.common[
                                                  `minCommonQTY`
                                                  ]
                                                }
                                              />
                                              {validationMessages.common[
                                                `minCommonQTY`
                                              ] && (
                                                  <div className="text-danger mt-2"></div>
                                                )}
                                            </div>
                                          </Col>
                                        </Row>
                                        <Col>
                                          <Form.Label htmlFor="Cowset_limitField">
                                            Max-Common
                                            <span className="me-2">
                                              <i className="fa fa-exclamation-triangle text-danger">
                                                *
                                              </i>
                                            </span>
                                          </Form.Label>
                                          <Row>
                                            <Col md="4">
                                              <Form.Control
                                                onInput={handleNumericInput}
                                                type="text"
                                                maxLength={10}
                                                placeholder={`FAT`}
                                                value={
                                                  commonValues[`maxCommonFAT`]
                                                }
                                                onChange={(e) =>
                                                  handleCommonValueChange(
                                                    `maxCommonFAT`,
                                                    e.target.value
                                                  )
                                                }
                                                className="me-2"
                                                isInvalid={
                                                  validationMessages.common[
                                                  `maxCommonFAT`
                                                  ]
                                                }
                                              />
                                              {validationMessages.common[
                                                `maxCommonFAT`
                                              ] && (
                                                  <div className="text-danger mt-2"></div>
                                                )}
                                            </Col>
                                            <Col md="4">
                                              <div className="form-control-wrap">
                                                <Form.Control
                                                  onInput={handleNumericInput}
                                                  type="text"
                                                  maxLength={10}
                                                  placeholder={`SNF`}
                                                  value={
                                                    commonValues[`maxCommonSNF`]
                                                  }
                                                  onChange={(e) =>
                                                    handleCommonValueChange(
                                                      `maxCommonSNF`,
                                                      e.target.value
                                                    )
                                                  }
                                                  className="me-2"
                                                  isInvalid={
                                                    validationMessages.common[
                                                    `maxCommonSNF`
                                                    ]
                                                  }
                                                />
                                                {validationMessages.common[
                                                  `maxCommonSNF`
                                                ] && (
                                                    <div className="text-danger mt-2"></div>
                                                  )}
                                              </div>
                                            </Col>

                                            <Col md="4">
                                              <Form.Control
                                                onInput={handleNumericInput}
                                                type="text"
                                                maxLength={10}
                                                placeholder={`QTY`}
                                                value={
                                                  commonValues[`maxCommonQTY`]
                                                }
                                                onChange={(e) =>
                                                  handleCommonValueChange(
                                                    `maxCommonQTY`,
                                                    e.target.value
                                                  )
                                                }
                                                className="me-2"
                                                isInvalid={
                                                  validationMessages.common[
                                                  `maxCommonQTY`
                                                  ]
                                                }
                                              />
                                              {validationMessages.common[
                                                `maxCommonQTY`
                                              ] && (
                                                  <div className="text-danger mt-2"></div>
                                                )}
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Form.Group>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <div
                className="gap-col mt-2"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ul className="d-flex align-items-center gap g-3">
                  <li>
                    <Button

                      variant="danger"
                      onClick={askModal}
                    >
                      Cancel
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="submit"
                      onClick={handleSave}
                      variant="success"
                    >
                      Save
                    </Button>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          {/* <label className="label-desktop">Name:</label>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <label className="label-desktop">Email:</label>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <label className="label-desktop">Password:</label>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <label className="label-desktop">Phone Number:</label>
            <input
              type="tel"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />

            <button type="submit">Submit</button> */}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CollectionSetting;
