import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post, put } from "../../../api/Api";



export const fetchVlccFarmerList = createAsyncThunk('vlccFarmer/fetch', async () => {
    try {
        const endpoint = "/api/vcc-farmer/list";
        const url = `${endpoint}`;

        console.log("Fetching data from:", url);

        const response = await get(endpoint);
        console.log("API response:", response);

        return response.data;
    } catch (error) {
        console.error("API error:", error);
        throw error; // Rethrow the error to be caught in the rejected action
    }
});

// Create Farmer (POST)
export const createVlccFarmer = createAsyncThunk('vlccFarmer/create', async (farmerData) => {
    try {
        const endpoint = "/api/vcc-farmer/create";

        console.log("Creating farmer with data:", farmerData);

        const response = await post(endpoint, farmerData);
        console.log("API response:", response);

        return response.data;
    } catch (error) {
        console.error("API error:", error);
        throw error;
    }
});

// Update Farmer (PUT)
export const updateVlccFarmer = createAsyncThunk('vlccFarmer/update', async (updateData) => {
    try {
        const endpoint = `/api/vcc-farmer/${updateData.id}`;

        console.log("Updating farmer with data:", updateData);

        const response = await put(endpoint, updateData);
        console.log("API response:", response);

        return response.data;
    } catch (error) {
        console.error("API error:", error);
        throw error;
    }
});
