import { Link } from 'react-router-dom';
import { Tab, Nav, Card, Button, Alert, Row, Col } from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Image, Icon, Schedule, Media, MediaGroup, MediaText, MediaAction } from '../../components';
import { get } from '../masters/api/Api';
import { useEffect } from 'react';
import { useState } from 'react';
import ConfirmationModal from '../masters/modal/ConfirmationModal';

function MyProfilePage() {
    const [profile, setProfile] = useState([])


    const fetchProfile = async () => {
        const url = "/api/me";

        try {
            const response = await get(url);
            if (response.status) {
                console.log(":", response.data);
                setProfile(response.data)

            }
        } catch (error) {
            console.error("Error while fetching data:", error);

        }
    };
    useEffect(() => {
        fetchProfile()
    }, [])




    return (
        <Layout title="My Profile" content="container">

            <Block.Head>
                <Block.HeadBetween className="align-items-start">
                    <Block.HeadContent>
                        <div className="d-flex flex-column flex-md-row align-items-md-center">
                            <Media size="huge" shape="circle">
                                <Image src="/images/avatar/c.jpg" staticImage thumbnail alt="user" />
                            </Media>
                            <div className="mt-3 mt-md-0 ms-md-3">
                                <h3 className="title mb-1">{profile.user_name}</h3>
                                <span className="small">{profile.user_role}</span>

                            </div>
                        </div>
                    </Block.HeadContent>

                </Block.HeadBetween>
            </Block.Head>

            <Tab.Container defaultActiveKey="tabOne">
                <Block.HeadBetween>
                    <div className="gap-col">
                        <Nav variant="pills" className="nav-pills-border gap g-3">
                            <Nav.Item>
                                <Nav.Link eventKey="tabOne">Overview</Nav.Link>
                            </Nav.Item>

                        </Nav>
                    </div>
                    <div className="gap-col">
                        <ul className="d-flex gap g-2">
                            <li className="d-none d-md-block">
                                <Link to="/admin/profile-settings" className="btn btn-soft btn-primary">
                                    <Icon name="edit"></Icon>
                                    <span>Edit Profile</span>
                                </Link>
                            </li>
                            <li className="d-md-none">
                                <Link to="/admin/profile-settings" className="btn btn-soft btn-primary btn-icon">
                                    <Icon name="edit"></Icon>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Block.HeadBetween>

                <Block className="mt-4">
                    <Tab.Content>
                        <Tab.Pane eventKey="tabOne">
                            <Card className="card-gutter-md">
                                <div className="card-row card-row-lg col-sep col-sep-lg">
                                    <div className="card-aside">
                                        <Card.Body>
                                            <div className="bio-block">
                                                <h4 className="bio-block-title">Details</h4>
                                                <ul className="list-group list-group-borderless small">
                                                    <li className="list-group-item">
                                                        <span className="title fw-medium w-40 d-inline-block">User Code:</span>
                                                        <span className="text">{profile?.user_code}</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <span className="title fw-medium w-40 d-inline-block">Full Name:</span>
                                                        <span className="text">{profile?.user_name}</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <span className="title fw-medium w-40 d-inline-block">Email:</span>
                                                        <span className="text">{profile?.user_email}</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <span className="title fw-medium w-40 d-inline-block">Role:</span>
                                                        <span className="text">{profile?.user_role}</span>
                                                    </li>

                                                </ul>
                                            </div>
                                        </Card.Body>
                                    </div>

                                </div>
                            </Card>
                        </Tab.Pane>
                    </Tab.Content>
                </Block>
            </Tab.Container>

        </Layout>
    )
}

export default MyProfilePage;