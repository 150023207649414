import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { postToast } from '../../pages/masters/api/Api';
import { ProfileDetails } from '../../pages/masters/store/store';
import { dateReverseH, dateSplit, isEdited } from '../../pages/masters/shared/Date';
import DataTable from 'react-data-table-component';

// Expanded View of Data table
const ExpandedComponent = ({ data }) => {



    const collectionData = JSON.parse(data?.collection_details)
    console.log("collectionData", collectionData);

    const [expandData, SetExpandData] = useState(null);
    const dispatch = useDispatch();
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({});
    const [pagination, setPagination] = useState({
        page: 1,
        page_count: 25,
        search: '',
        total_count: null
    })
    const updatePagination = ({ current_page, per_page, total }) => {
        setPagination({
            ...pagination,
            page: current_page,
            page_count: per_page,
            total_count: total
        });
    };

    useEffect(() => {
        dispatch(ProfileDetails())
    }, [dispatch])

    useEffect(() => {
        if (data) {
            const collectionData = JSON.parse(data?.collection_details)
            SetExpandData(collectionData)
        }

    }, [data]);

    const role = useSelector((state) => state.profile)
    const filterInputStyle = {
        width: '95%',
        padding: '5px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '11px'
    };


    const handleFilterChange = (columnId, value) => {
        setFilters(prev => ({ ...prev, [columnId]: value }));
    };


    const toggleFilters = () => {
        setShowFilters(prev => !prev);
        setFilters({});
    };

    const rowStyles = [
        {
            when: (row) => isEdited(row.created_at, row.updated_at),
            style: {
                backgroundColor: '#478ffc', // Or any other color of your choice
                color: "#FFFFFF"
            },
        },
    ];

    const entryList = (showFilters, handleFilterChange) => {

        const column = [
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="FAT"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_fat', e.target.value)}
                    />
                    : "fat",
                selector: (row) => row.tot_fat,
                sortable: !showFilters,
                right: true,
                minWidth: "60px",
                reorder: true,

            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="SNF"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_snf', e.target.value)}
                    />
                    : "snf",
                selector: (row) => row.tot_snf,
                sortable: !showFilters,
                right: true,
                minWidth: "75px",
                reorder: true,

            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Kg"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_qty_kg', e.target.value)}
                    />
                    : "Kg",
                selector: (row) => row.tot_qty_kg,
                sortable: !showFilters,
                right: true,
                minWidth: "80px",
                reorder: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Lit"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_qty_litre', e.target.value)}
                    />
                    : "Litre",
                selector: (row) => row.tot_qty_litre,
                sortable: !showFilters,
                right: true,
                minWidth: "80px",
                omit: role?.data?.user_role !== "admin",
                reorder: true,
            },

            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Rate"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_rate', e.target.value)}
                    />
                    : "rate",
                selector: (row) => row.tot_rate,
                sortable: !showFilters,
                right: true,
                minWidth: "80px",
                reorder: true,

            },
        ]

        column.push({
            name: showFilters
                ? <input
                    type="text"
                    placeholder="Amount"
                    style={filterInputStyle}
                    onChange={(e) => handleFilterChange('tot_amount', e.target.value)}
                />
                : "Amount",
            selector: (row) => (
                <div style={{ textAlign: 'right !important' }}>
                    {row.tot_amount}
                </div>
            ),

            sortable: !showFilters,
            right: true,
            minWidth: "105px",
            reorder: true,
        });

        return column;
    }

    const [columns, setColumns] = useState(entryList(showFilters, handleFilterChange));

    // <DataTable tableClassName="data-table-head-light table-responsive" conditionalRowStyles={rowStyles} data={expandData}
    //     columns={columns} showFilters={showFilters} onPaginate={handlePaginate} page_value={pagination}
    // />

    return (
        <div>

            <DataTable tableClassName="data-table-head-light table-responsive" conditionalRowStyles={rowStyles} data={expandData ? expandData : []}
                columns={columns} showFilters={showFilters} page_value={pagination} />
        </div>

    )

};

export default ExpandedComponent