export const dateReverseH = (date) => {
    if (date) {
        const dateParts = date?.split('-');

        // Extract year, month, and day
        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];

        return `${day}-${month}-${year}`
    }
}

export const emptyDate = (date) => {
    if (date) {
        const dateParts = date?.split('-');

        // Extract year, month, and day
        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];

        return `${year}${month}${day}`
    }
}
export const dateReverse2DigiH = (date) => {
    if (date) {
        const dateParts = date?.split('-');

        // Extract year, month, and day
        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];

        return `${day}-${month}`
    }

}

export const dateReverseHM = (date) => {
    if (date) {
        const [datePart, timePart] = date.split(' '); // Split date and time
        const dateParts = datePart.split('-');
        const timeParts = timePart.split(':');

        // Extract year, month, day, hour, and minutes
        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];
        const hour = timeParts[0];
        const minutes = timeParts[1];

        return `${day}-${month}-${year} ${hour}:${minutes}`;
    }
}


export const dateReverseS = (date) => {

    if (date) {
        const dateParts = date?.split('-');

        // Extract year, month, and day
        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];

        return `${day}/${month}/${year}`
    }

}

export const dateMonth = (date) => {

    if (date) {
        const dateParts = date?.split('-');

        // Extract year, month, and day
        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];

        return `${day}/${month}}`
    }

}

export const dateSplit = (date) => {
    if (date) {
        const dateParts = date?.split(' ');
        return dateParts[0];
    }
}

export const timeSplit = (date) => {
    if (date) {
        const dateParts = date?.split(' ');
        return dateParts[1];
    }
}

export function extractDateFromTimestamp(timestamp) {
    console.log("timestamp", timestamp);
    var dateObject = new Date(timestamp);

    // Extract the date part
    var year = dateObject.getFullYear();
    var month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Month is zero-based
    var day = ('0' + dateObject.getDate()).slice(-2);

    // Create the date string in the format YYYY-MM-DD
    var dateOnly = `${year}-${month}-${day}`;
    console.log("dateOnly", dateOnly);
    return dateOnly;
}

export function extractDateAndTimeFromTimestamp(timestamp) {
    console.log("timestamp", timestamp);
    var dateObject = new Date(timestamp);

    // Extract the date part
    var year = dateObject.getFullYear();
    var month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Month is zero-based
    var day = ('0' + dateObject.getDate()).slice(-2);

    // Create the date string in the format YYYY-MM-DD
    var dateOnly = `${year}-${month}-${day}`;
    console.log("dateOnly", dateOnly);
    return dateOnly;
}

export function SperateDateTime(timestamp) {
    console.log("timestamp", timestamp);
    var dateObject = new Date(timestamp);

    // Extract the time part
    var hours = ('0' + dateObject.getHours()).slice(-2);
    var minutes = ('0' + dateObject.getMinutes()).slice(-2);

    // Create the date and time string in the format YYYY-MM-DD HH:mm
    var dateAndTime = ` ${hours}:${minutes}`;
    return dateAndTime;
}


export function dateTime(timestamp) {
    console.log("timestamp", timestamp);
    var dateObject = new Date(timestamp);

    // Extract the date part
    var year = dateObject.getFullYear();
    var month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Month is zero-based
    var day = ('0' + dateObject.getDate()).slice(-2);

    // Extract the time part
    var hours = ('0' + dateObject.getHours()).slice(-2);
    var minutes = ('0' + dateObject.getMinutes()).slice(-2);

    // Create the date and time string in the format YYYY-MM-DD HH:mm
    var dateAndTime = `${month}/${day}/${year} ${hours}:${minutes}`;
    return dateAndTime;
}

export function extractFileDateTime(timestamp) {
    console.log("timestamp", timestamp);
    var dateObject = new Date(timestamp);

    // Extract the date part
    var year = dateObject.getFullYear();
    var month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Month is zero-based
    var day = ('0' + dateObject.getDate()).slice(-2);

    // Extract the time part
    var hours = ('0' + dateObject.getHours()).slice(-2);
    var minutes = ('0' + dateObject.getMinutes()).slice(-2);

    // Create the date and time string in the format YYYY-MM-DD HH:mm
    var dateAndTime = `${month}/${day}/${year}`;
    console.log("dateAndTime", dateAndTime);
    return dateAndTime;
}

export function timestamp(timestamp) {
    console.log("timestamp", timestamp);
    var dateObject = new Date(timestamp);

    // Extract the date part
    var year = dateObject.getFullYear();
    var month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Month is zero-based
    var day = ('0' + dateObject.getDate()).slice(-2);

    // Extract the time part
    var hours = ('0' + dateObject.getHours()).slice(-2);
    var minutes = ('0' + dateObject.getMinutes()).slice(-2);
    var seconds = ('0' + dateObject.getSeconds()).slice(-2);

    // Create the date and time string in the format YYYY-MM-DD HH:mm
    var dateAndTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("dateAndTime", dateAndTime);
    return dateAndTime;
}

export const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export function hasExpired(dateString, timeString) {
    // Parse the date
    const [day, month, year] = dateString.split('/').map(Number);

    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':').map(Number);

    // Get the current date and time
    const currentTime = new Date();
    const currentYear = currentTime.getFullYear();
    const currentMonth = currentTime.getMonth() + 1; // Months are 0-indexed
    const currentDay = currentTime.getDate();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentSeconds = currentTime.getSeconds();

    // Compare the current date and time with the given date and time
    if (currentYear > year ||
        (currentYear === year && currentMonth > month) ||
        (currentYear === year && currentMonth === month && currentDay > day) ||
        (currentYear === year && currentMonth === month && currentDay === day &&
            (currentHours > hours ||
                (currentHours === hours && currentMinutes > minutes) ||
                (currentHours === hours && currentMinutes === minutes && currentSeconds > seconds)))) {
        return true; // Date and time have expired
    } else {
        return false; // Date and time have not expired
    }
}

export function isEdited(createdTime, updatedTime) {
    // Convert createdTime and updatedTime to Date objects
    const createdDate = new Date(createdTime);
    const updatedDate = new Date(updatedTime);

    // If updatedTime is after createdTime, then the item has been edited
    return updatedDate > createdDate;
}


export const findStartMidEnd = (datesArray) => {
    if (datesArray) {
        const startDate = datesArray[0];
        const endDate = datesArray[datesArray.length - 1];
        const midIndex = Math.floor(datesArray.length / 2);
        const midDate = datesArray[midIndex];
        return [startDate, midDate, endDate];
    }
};