import { createSlice } from "@reduxjs/toolkit";
import { routeCode, routeMaster } from "../../thunks/route-thunk/RouteThunk";



const RouteSlice = createSlice({
    name: "routeMaster",
    initialState: {
        data: [],
        isLoading: false,
        error: null

    },
    extraReducers(builder) {
        builder.addCase(routeMaster.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(routeMaster.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            console.log("Data received:", action.payload);
        })
        builder.addCase(routeMaster.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })

    }
})

const RouteCode = createSlice({
    name: "routeCode",
    initialState: {
        code: [],
        isLoading: false,
        error: null

    },
    reducers: {
        updateCode: (state, action) => {
            console.log(action, "action");
            state.code.push(action.payload);
        },
    },
    extraReducers(builder) {
        builder.addCase(routeCode.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(routeCode.fulfilled, (state, action) => {
            state.isLoading = false
            state.code = action.payload
            console.log("Data received:", action.payload);
        })
        builder.addCase(routeCode.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })

    }
})
export const { updateCode } = RouteCode.actions;
export const RouteReducer = RouteSlice.reducer
export const RouteCodeReducer = RouteCode.reducer;