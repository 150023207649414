
export const createBody = (centerData, type, id) => {

    console.log(centerData, "centerData");

    const mode_info = [{ title: "Fat %", type: "manualbtn", default: centerData?.mode_info?.chooseModeFat },
    { title: "SNF %", type: "manualbtn", default: centerData?.mode_info?.chooseModeSNF },
    { title: "Qty ", type: "manualbtn", default: centerData?.mode_info?.chooseModeQty },
    ]

    const decimal_info = [{ title: "Fat %", type: "decimalmode", default: centerData?.decimal_info?.DecimalModeFAT },
    { title: "SNF %", type: "decimalmode", default: centerData?.decimal_info?.DecimalModeSNF },
    { title: "Qty", type: "decimalmode", default: centerData?.decimal_info?.DecimalModeQTY },
    ]
    const action_info = [{ title: "Set Limit", type: "yesorno", default: centerData?.setLimit },
    { title: "Add Previous Session Data", type: "yesorno", default: centerData?.add_previous_session_data },
    { title: "Sample Testing", type: "yesorno", default: centerData?.sample_testing },
    { title: "Allow Date Change", type: "yesorno", default: centerData?.allow_date_change },
    { title: "Enable Can No", type: "yesorno", default: centerData?.enable_can_no },
    { title: "Delete Collection Data", type: "yesorno", default: centerData?.delete_collection_data },
    { title: "Print Previous Bill", type: "yesorno", default: centerData?.print_previous_bill },
    { title: "Allow Zero Value in Rate", type: "yesorno", default: centerData?.allow_zero_value_in_rate },]

    const session_info = {
        morning_session: { start_time: centerData?.timePicker?.morningFrom, end_time: centerData?.timePicker?.morningTo },
        evening_session: { start_time: centerData?.timePicker?.eveningFrom, end_time: centerData?.timePicker?.eveningTo }
    }
    const other_info = [{ default_uom: centerData?.DefaultUOM === "Litre" ? 0 : centerData?.DefaultUOM === "KG" ? 1 : "" },
    {
        rate_type: centerData?.setTypeValue === "1" ? "common" : "",
        max_limit: centerData?.setTypeValue === "1" ?
            [{ fat: centerData?.commonValues?.maxCowFAT, snf: centerData?.commonValues?.maxCowSNF, qty: centerData?.commonValues?.maxCowQTY }] : "",
        min_limit: centerData.setTypeValue === "1" ?
            [{ fat: centerData?.commonValues?.minCowFAT, snf: centerData?.commonValues?.minCowSNF, qty: centerData?.commonValues?.minCowQTY }] : ""
    }]

    const cowBuffalo = [{ default_uom: centerData?.DefaultUOM === "Litre" ? 0 : centerData?.DefaultUOM === "KG" ? 1 : "" },
    {
        rate_type: centerData?.setTypeValue === "0" ? "buffalo" : '',
        max_limit: centerData?.setTypeValue === "0" ?
            [{ fat: centerData?.buffaloValues?.maxBuffaloFAT, snf: centerData?.buffaloValues?.maxBuffaloSNF, qty: centerData?.buffaloValues?.maxBuffaloQTY }] : [],
        min_limit: centerData.setTypeValue === "0" ?
            [{ fat: centerData?.buffaloValues?.minBuffaloFAT, snf: centerData?.buffaloValues?.minBuffaloSNF, qty: centerData?.buffaloValues?.minBuffaloQTY }] : [],



    },
    {
        rate_type: centerData?.setTypeValue === "0" ? "cow" : '',
        max_limit: centerData?.setTypeValue === "0" ?
            [{ fat: centerData?.cowValues?.maxCowFAT, snf: centerData?.cowValues?.maxCowSNF, qty: centerData?.cowValues?.maxCowQTY }] : [],
        min_limit: centerData?.setTypeValue === "0" ?
            [{ fat: centerData?.cowValues?.minCowFAT, snf: centerData?.cowValues?.minCowSNF, qty: centerData?.cowValues?.minCowQTY }] : []
    }

    ]


    const center_Settings = {
        mode_info: mode_info,
        session_info: session_info,
        decimal_info: decimal_info,
        other_info: centerData.setTypeValue === "0" ? cowBuffalo : centerData.setTypeValue === "1" ? other_info : '',
        action_info: action_info
    }

    const printerData = {
        header_info: centerData?.header_info, footer_info: centerData?.footer_info,
        img_path: "", language_type: centerData?.language_type, share_mode: centerData?.share_mode === "enable" ? "0" : "1",
        no_of_copies: centerData?.no_of_copies, is_header_enable: centerData?.header_mode === "enable" ? "Yes" : "No",
        is_footer_enable: centerData?.footer_mode === "enable" ? "Yes" : "No"
    }

    const equipment_settings = {
        analyser_name: centerData?.AnalyserName, weightscale_name: centerData?.BaudRateWS, stirrer_name: centerData?.Stirrer, analyser_baud_rate: centerData?.baudRate,
        weightscale_baud_rate: centerData?.baudRateWS1, Stirrer_baud_rate: "", send_command: centerData?.SendCommand, analyser_mode: centerData?.AnalyseButton,
        stirrer_mode: centerData?.StirrerButton, clean_mode: centerData?.Clean, kg_to_litre: centerData.KgToLitre, tare_value: centerData?.TareWS, mode_value: centerData?.ModeWS,
        is_tare_option: centerData?.Tare, is_mode_option: centerData?.Mode, analyser_cmd: [],
    }



    console.log(centerData, "centerData==>")

    const data = {
        center_id: id,
        collection_settings: type === "center" && center_Settings ? center_Settings : undefined,
        equipment_settings: type === "equipment" && equipment_settings ? equipment_settings : undefined,
        printer_settings: type === "printer" && printerData ? JSON.stringify(printerData) : undefined,
        other_settings: null
    };

    return data
}

