import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { postToast } from '../api/Api';
import { ProfileDetails } from '../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { dateReverseH, dateSplit, isEdited } from '../shared/Date';
import DataTable from '../../../components/DataTable/DataTable';
const ExpandableViewModal = ({ show,
    data,
    onClose,
    closeLabel = "Close" }) => {

    const [expandData, SetExpandData] = useState(null);
    const dispatch = useDispatch();
    const [showFilters, setShowFilters] = useState(false);
    const [filterData, setFilterData] = useState(null)
    const [filters, setFilters] = useState({});
    const [pagination, setPagination] = useState({
        page: 1,
        page_count: 25,
        search: '',
        total_count: null
    })
    const updatePagination = ({ current_page, per_page, total }) => {
        setPagination({
            ...pagination,
            page: current_page,
            page_count: per_page,
            total_count: total
        });
    };

    useEffect(() => {
        dispatch(ProfileDetails())
    }, [dispatch])

    const role = useSelector((state) => state.profile)
    const filterInputStyle = {
        width: '95%',
        padding: '5px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '11px'
    };

    const fetchExpandableData = async (bodyData) => {
        const body = {
            from_date: bodyData?.params?.from_date,
            from_session: bodyData?.params?.from_session,
            to_date: bodyData?.params?.to_date,
            to_session: bodyData?.params?.to_session,
            route_id: bodyData?.route_id,
        };
        const url = "/api/collection-report/generate-payment";
        try {
            const response = await postToast(url, body);
            if (response.status) {
                if (response.data) {
                    SetExpandData(response.data.data);
                    const pageValue = response.data
                    updatePagination(pageValue);
                }

            }
        } catch (error) {
            console.error("Error while fetching data:", error);
            // setLoading(false)
        }
    };

    const fetchExpandableDataPagination = async (bodyData) => {

        const url = "/api/collection-report/generate-payment";
        try {
            const response = await postToast(url, bodyData);
            if (response.status) {
                if (response.data) {
                    SetExpandData(response.data.data);
                    const pageValue = response.data
                    updatePagination(pageValue);
                }

            }
        } catch (error) {
            console.error("Error while fetching data:", error);
            // setLoading(false)
        }
    };

    const handleFilterChange = (columnId, value) => {
        setFilters(prev => ({ ...prev, [columnId]: value }));
    };

    useEffect(() => {
        setFilterData(data)
        if (data) {
            fetchExpandableData(data);
        }
    }, [data]);

    const rowStyles = [
        {
            when: (row) => isEdited(row.created_at, row.updated_at),
            style: {
                backgroundColor: '#478ffc', // Or any other color of your choice
                color: "#FFFFFF"
            },
        },
    ];

    const entryList = (showFilters, handleFilterChange) => {

        const column = [


            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Date"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('bill_date', e.target.value)}
                    />
                    : "date",
                selector: (row) => dateReverseH(dateSplit(row.bill_date)),
                sortable: !showFilters,
                minWidth: "108px",
                center: true,
                reorder: true,

            },

            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="m/e"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('session', e.target.value)}
                    />
                    : "m/e",
                selector: (row) => row.session,
                sortable: !showFilters,
                minWidth: "40px",
                center: true,
                reorder: true

            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Bill"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('bill_no', e.target.value)}
                    />
                    : `Bill`,
                selector: (row) => row.bill_no,
                sortable: !showFilters,
                minWidth: "40px",
                right: true,
                reorder: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Code"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('farmer_code', e.target.value)}
                    />
                    : "code",
                selector: (row) => row.farmer_code,
                sortable: !showFilters,
                minWidth: "70px",
                right: true,
                reorder: true,
            },

            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Producer"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('farmer_name', e.target.value)}
                    />
                    : "farmerName",
                selector: (row) => row.farmer_name,
                sortable: !showFilters,
                minWidth: "150px",
                left: true,
                reorder: true,
                wrap: true,

            },

            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="FAT"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_fat', e.target.value)}
                    />
                    : "fat",
                selector: (row) => (row.tot_fat).toFixed(2),
                sortable: !showFilters,
                right: true,
                minWidth: "60px",
                reorder: true,

            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="SNF"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_snf', e.target.value)}
                    />
                    : "snf",
                selector: (row) => (row.tot_snf).toFixed(2),
                sortable: !showFilters,
                right: true,
                minWidth: "75px",
                reorder: true,

            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Kg"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_qty_kg', e.target.value)}
                    />
                    : "Kg",
                selector: (row) => (row.tot_qty_kg).toFixed(2),
                sortable: !showFilters,
                right: true,
                minWidth: "80px",
                reorder: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Lit"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_qty_litre', e.target.value)}
                    />
                    : "Litre",
                selector: (row) => (row.tot_qty_litre).toFixed(2),
                sortable: !showFilters,
                right: true,
                minWidth: "80px",
                omit: role?.data?.user_role !== "admin",
                reorder: true,
            },

            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Rate"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_rate', e.target.value)}
                    />
                    : "rate",
                selector: (row) => (row.tot_rate).toFixed(2),
                sortable: !showFilters,
                right: true,
                minWidth: "80px",
                reorder: true,

            },
        ]



        column.push({
            name: showFilters
                ? <input
                    type="text"
                    placeholder="Amount"
                    style={filterInputStyle}
                    onChange={(e) => handleFilterChange('tot_amount', e.target.value)}
                />
                : "Amount",
            selector: (row) => (
                <div style={{ textAlign: 'right !important' }}>
                    {(row.tot_amount.toFixed(2)).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </div>
            ),

            sortable: !showFilters,
            right: true,
            minWidth: "105px",
            reorder: true,
        });

        return column;
    }

    const [columns, setColumns] = useState(entryList(showFilters, handleFilterChange));

    const handlePaginate = (currentPage, itemPerPage, searchValue,) => {

        const body = {
            page: currentPage,
            page_count: itemPerPage,
            search: searchValue,
            from_date: filterData?.params?.from_date,
            from_session: filterData?.params?.from_session,
            to_date: filterData?.params?.to_date,
            to_session: filterData?.params?.to_session,
            route_id: filterData?.route_id,
        }

        fetchExpandableDataPagination(body)
    }



    return (
        <Modal show={show} onHide={onClose} size='lg' fullscreen>
            <Modal.Header closeButton>
                <Modal.Title>{data?.code} - {data?.name} ({dateReverseH(data?.params?.from_date)} - {dateReverseH(data?.params?.to_date)})</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DataTable tableClassName="data-table-head-light table-responsive" conditionalRowStyles={rowStyles} data={expandData ? expandData : []}
                    columns={columns} showFilters={showFilters} onPaginate={handlePaginate} page_value={pagination}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>{closeLabel}</Button>
            </Modal.Footer>
        </Modal >
    )
}

export default ExpandableViewModal