import React, { useEffect, useState } from 'react'
import Layout from "../../../../layout/default";
import { Card, Col, Row } from 'react-bootstrap';
import DataTable from '../../../../components/DataTable/DataTable';

export const CenterView = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem("farmerData"))
        setData(data ? data : [])
    }, [])

    console.log("data==>", data);

    const columns = [
        {
            name: "Farmer Name",
            selector: row => row.farmername,
            center: true
        },
        {
            name: "Farmer Code",
            selector: row => row.code,
            center: true
        },
        {
            name: "Farmer Type",
            selector: row => row.type,
            center: true
        },
        {
            name: "Route",
            selector: row => row.route,
            center: true
        },
        {
            name: "Mobile number",
            selector: row => row.mobile,
            center: true
        }, {
            name: "Incentive",
            selector: row => row.incentive,
            center: true
        },
    ]

    const farmerColumn = [
        {
            name: "Center Name",
            selector: row => row.routeName,
            center: true
        },

    ]

    const list = [
        { farmername: "kuppusamy", code: 1, type: "bulk vendor", route: "1-AVINASIPALAYAM", mobile: 8778989533, incentive: "no" }
    ]
    return (
        <Layout title="Form controls" content="container">
            <Row className="g-gs ">
                <Col xxl="12">
                    <div className="d-flex justify-content-start">
                        <h3 >
                            Center Setting List
                        </h3>
                    </div>
                    <Card className="card-gutter-md mt-2 mb-2">
                        <Card.Body>
                            <DataTable tableClassName="data-table-head-light table-responsive" data={list}
                                columns={columns}
                            />
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </Layout>
    )
}
