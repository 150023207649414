import { Card, Form, Col, Row, ButtonGroup, Button } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { CodeDropDown } from "../shared/dropDown/CodeDropDown";
import { useDispatch, useSelector } from "react-redux";
import { fetchFarmerList } from "../store/store";
import {
    Settings,
    SettingsDetails,
} from "../store/thunks/settings-thunk/SettingsThunk";
import { createBody } from "../shared/api-structure/CollectionSettingStructure";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationModal from "../modal/ConfirmationModal";

const PrinterSetting = () => {
    const farmerRef = useRef(null);
    const [centerId, setCenterId] = useState(null);
    const [centerCode, setCenterCode] = useState("");
    const centerRef = useRef(null);
    const [headerInfoError, setHeaderInfoError] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [formData, setFormData] = useState({
        center_id: centerId,
        language_type: "0",
        no_of_copies: "0",
        share_mode: "enable",
        header_mode: "enable",
        header_info: "",
        footer_mode: "enable",
        footer_info: "",
    });
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(fetchFarmerList());
    }, [dispatch]);



    const collectionCenter = useSelector((state) => state.directFarmerList);
    const filterCollection = collectionCenter.data?.filter(
        (el) => el.type === "1"
    );

    useEffect(() => {
        if (parseFloat(id) !== 0) dispatch(SettingsDetails(id));
    }, [dispatch, id]);

    const { settingsDetails } = useSelector((state) => state.SettingsDetails);

    console.log(settingsDetails, "settingsDetails");
    // const editingData = JSON.parse(settingsDetails?.collection_settings)

    const centerData = (details) => {
        return `${details.code} - ${details.name}`
    }

    useEffect(() => {
        if (settingsDetails?.length !== 0 && parseFloat(id) !== 0) {
            const data = settingsDetails?.settings?.printer_settings;
            const details = settingsDetails?.center_details

            const languageTypeMatch = data?.match(/"language_type":"(.*?)"/);
            const headerInfo = data?.match(/"header_info":"(.*?)"/);
            const footerInfo = data?.match(/"footer_info":"(.*?)"/);
            const shareMode = data?.match(/"share_mode":"(.*?)"/);
            const headerEnable = data?.match(/"is_header_enable":"(.*?)"/);
            const noOfcopies = data?.match(/"no_of_copies":"(.*?)"/);
            const footerEnable = data?.match(/"is_footer_enable":"(.*?)"/);

            console.log("shareMode", shareMode);
            if (details) {
                const data = centerData(details)
                if (data)
                    setCenterCode(data)
            }
            if (id)
                setCenterId(parseInt(id))


            setFormData({
                center_id: id,
                language_type: languageTypeMatch ? languageTypeMatch[1] : "",
                no_of_copies: noOfcopies ? noOfcopies[1] : "",
                share_mode: shareMode ? shareMode[1] === "0" ? "enable" : shareMode[1] === "1" ? "disable" : '' : "",
                header_mode: headerEnable ? headerEnable[1] === "Yes" ? "enable" : "disable" : "",
                header_info: headerInfo ? headerInfo[1] : "",
                footer_mode: footerEnable ? footerEnable[1] === "Yes" ? "enable" : "disable" : "",
                footer_info: footerInfo ? footerInfo[1] : "",
            });
        }
    }, [id, settingsDetails]);

    const [formSubmitted, setFormSubmitted] = useState(false);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setFormSubmitted(false);
    };

    const isFieldInvalid = (field) => formData[field] === "0";
    //Validation End

    //No Of no_of_copies Start

    const [share_mode, setshare_mode] = useState("disable");

    const handleshare_modeChange = (option) => {
        setshare_mode(option);
        setFormData((prevFormData) => ({
            ...prevFormData,
            share_mode: option,
        }));
    };

    //No Of no_of_copies End
    // Printer Header Start
    const [header_mode, setheader_mode] = useState("enable");
    const [header_info, setheader_info] = useState("");

    const handleheader_infoChange = (option) => {
        setheader_mode(option);

        setFormData((prevFormData) => ({
            ...prevFormData,
            header_mode: option,
            header_info: option === "enable" ? prevFormData.header_info : "",
        }));
    };

    const handleheader_infoInputChange = (e) => {
        setheader_info(e.target.value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            header_info: e.target.value,
        }));
    };

    const isheader_infoInvalid = () =>
        formData.header_mode === "enable" && formData.header_info.trim() === "";

    //Printer Footer Start

    const [footer_mode, setfooter_mode] = useState("enable");
    const [footer_info, setfooter_info] = useState("");

    const handlefooter_infoChange = (option) => {
        setfooter_mode(option);

        // Clear values when "Disable" is selected
        if (option === "enable") {
            setfooter_info("");
        }
        setFormData((prevFormData) => ({
            ...prevFormData,
            footer_mode: option,
        }));
    };

    const handlefooter_infoInputChange = (e) => {
        setfooter_info(e.target.value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            footer_info: e.target.value,
        })); // Update the form data
    };
    const handleFarmerCode = (value) => {
        // Simple validation check
        if (!value || value.trim() === "") {
            return "Farmer name with Code is required.";
        }
        return ""; // No error
    };

    const isfooter_infoInvalid = () =>
        footer_mode === "enable" && footer_info.trim() === "";

    // Printer Footer End

    //Save Button Function
    const handleSaveClick = () => {

        // Check for empty values before saving
        // Check for empty values before saving
        let form = true
        // if (
        //     formData.language_type === "0" ||
        //     formData.no_of_copies === "0" ||
        //     (formData.header_mode === "enable" &&
        //         formData.header_info.trim() === "") ||
        //     (formData.footer_mode === "enable" && formData.footer_info.trim() === "")
        // ) {
        //     return; // Don't proceed if there are empty form fields
        // }

        if (formData.header_info.trim() === "") {
            setHeaderInfoError("Printer Header is Required")
            form = false
        }
        if (formData.footer_info.trim() === "") {
            setHeaderInfoError("Printer Footer is Required")
            form = false
        }
        if (formData.no_of_copies === "0") {
            setHeaderInfoError("No of Copies is Required")
            form = false
        }
        if (formData.language_type === "0") {
            setHeaderInfoError("Language is Required")
            form = false
        }


        // Perform validation before saving
        if (
            isFieldInvalid("language_type") ||
            isFieldInvalid("no_of_copies")

        ) {
            // Alert or handle validation errors
            // alert('Please fill in all required fields before saving.');
            return; // Don't proceed if there are validation errors
        }

        console.log(formData, "formData==>");
        if (form) {
            const body = createBody(formData, "printer", centerId);
            dispatch(Settings(body)).then((action) => {
                if (action.payload) {
                    navigate('/farmer-view/collection-center')
                }
            })
        }



    };

    // Get Center Name and Center Code
    const handleInputCenter = (value, id, data) => {
        console.log(data, "data");
        setCenterCode(value);
        setCenterId(data.id);

        setFormData((prevFormData) => ({
            ...prevFormData,
            center_id: id,
        }));
    };
    const handleCenter = (name) => {
        // setInputValue(name)
    };

    const askModal = () => {
        setShowConfirmationModal(true)
    }

    const handleYesClick = () => {
        navigate(`/settings-view/collection-settings/${id}`)
        setShowConfirmationModal(false); // Close the confirmation modal
    };

    return (
        <Card>
            <Card.Body>
                <Card>
                    <Card.Body>


                        <ConfirmationModal
                            show={showConfirmationModal}
                            title="Closing Confirmation"
                            body="Are you sure?"
                            onCancel={() => {
                                setShowConfirmationModal(false)
                            }}
                            onConfirm={handleYesClick}
                        />
                        <Row>
                            {/* Center Name */}
                            <Col sm="4">
                                <Form.Group className="form-group">
                                    <CodeDropDown
                                        data={filterCollection}
                                        placeholder="Center Code"
                                        name="Center Code"
                                        nextInputRef={farmerRef}
                                        onValidate={handleFarmerCode}
                                        onSelect={(selectedItem, type, data, error) => {
                                            handleInputCenter(selectedItem, type, data, error);
                                        }}
                                        // value={center}
                                        func={handleCenter}
                                        inputRef={centerRef}
                                        style={{ width: "155px" }}
                                        value={centerCode}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md="2">
                                <Form.Group className="form-group">
                                    <Form.Label>
                                        Choose Language
                                        <span className="me-2">
                                            <i className="fa fa-exclamation-triangle text-danger">
                                                *
                                            </i>
                                        </span>
                                    </Form.Label>
                                    <Form.Select
                                        name="language_type"
                                        onChange={handleInputChange}
                                        value={formData.language_type}
                                        isInvalid={formSubmitted && isFieldInvalid("language_type")}
                                    >
                                        <option value="0">Select Language</option>
                                        <option value="English">English</option>
                                        <option value="Tamil">Tamil</option>
                                        <option value="Hindi">Hindi</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {formSubmitted &&
                                            isFieldInvalid("language_type") &&
                                            "Language is required."}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md="2">
                                <Form.Group className="form-group">
                                    <Form.Label>
                                        No Of Copies
                                        <span className="me-2">
                                            <i className="fa fa-exclamation-triangle text-danger">
                                                *
                                            </i>
                                        </span>
                                    </Form.Label>
                                    <Form.Select
                                        name="no_of_copies"
                                        onChange={handleInputChange}
                                        value={formData.no_of_copies}
                                        isInvalid={formSubmitted && isFieldInvalid("no_of_copies")}
                                    >
                                        <option value="0">Select Copies</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {formSubmitted &&
                                            isFieldInvalid("no_of_copies") &&
                                            "No Of Copies is required."}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            {/* Enable Printer */}

                            <Col md="3">
                                <Form.Group className="form-group">
                                    <Form.Label>
                                        Enable Printer
                                        <span className="me-2">
                                            <i className="fa fa-exclamation-triangle text-danger">
                                                *
                                            </i>
                                        </span>
                                    </Form.Label>
                                    <br />
                                    <ButtonGroup aria-label="Basic radio toggle button group">
                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="share_mode"
                                            id="Enable"
                                            autoComplete="off"
                                            onChange={() => handleshare_modeChange("enable")}
                                            checked={formData.share_mode === "enable"}
                                            defaultChecked
                                        />
                                        <Button
                                            as="label"
                                            variant={
                                                share_mode === "enable"
                                                    ? "outline-primary"
                                                    : "outline-primary"
                                            }
                                            htmlFor="Enable"
                                        >
                                            Enable
                                        </Button>

                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="share_mode"
                                            id="Disable"
                                            autoComplete="off"
                                            onChange={() => handleshare_modeChange("disable")}
                                            checked={formData.share_mode === "disable"}
                                        />
                                        <Button
                                            as="label"
                                            variant={
                                                share_mode === "disable"
                                                    ? "outline-primary"
                                                    : "outline-primary"
                                            }
                                            htmlFor="Disable"
                                        >
                                            Disable
                                        </Button>
                                    </ButtonGroup>
                                </Form.Group>

                                {/* Displaying formData */}
                                {/* <div>FormData: {JSON.stringify(formData)}</div> */}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <br />
                <Card>
                    <Card.Body>
                        {/* Printer Header */}
                        <Row>
                            <Col md="6">
                                <Form.Group className="form-group">
                                    <Form.Label>
                                        Printer Header
                                        <span className="me-2">
                                            <i className="fa fa-exclamation-triangle text-danger">
                                                *
                                            </i>
                                        </span>
                                    </Form.Label>
                                    <br />
                                    <ButtonGroup aria-label="Basic radio toggle button group">
                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="header_mode"
                                            id="HeaderEnable"
                                            autoComplete="off"
                                            onChange={() => handleheader_infoChange("enable")}
                                        />
                                        <Button
                                            as="label"
                                            variant={
                                                header_mode === "enable" ? "primary" : "outline-primary"
                                            }
                                            htmlFor="HeaderEnable"
                                        >
                                            Enable
                                        </Button>

                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="header_mode"
                                            id="HEaderDisable"
                                            autoComplete="off"
                                            onChange={() => handleheader_infoChange("disable")}
                                        />
                                        <Button
                                            as="label"
                                            variant={
                                                header_mode === "disable"
                                                    ? "primary"
                                                    : "outline-primary"
                                            }
                                            htmlFor="HEaderDisable"
                                        >
                                            Disable
                                        </Button>
                                    </ButtonGroup>
                                </Form.Group>

                                {header_mode === "enable" && (
                                    <Col className="lg=8 mt-2">
                                        <Form.Group>
                                            <Form.Label>Printer Header</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                id="header_infoField"
                                                rows="3"
                                                type="text"
                                                placeholder="Enter additional information"
                                                onChange={handleheader_infoInputChange}
                                                value={formData.header_info}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {headerInfoError}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                )}
                            </Col>

                            {/* Printer Footer */}
                            <Col md="6">
                                <Form.Group className="form-group">
                                    <Form.Label>
                                        Printer Footer
                                        <span className="me-2">
                                            <i className="fa fa-exclamation-triangle text-danger">
                                                *
                                            </i>
                                        </span>
                                    </Form.Label>
                                    <br />
                                    <ButtonGroup aria-label="Basic radio toggle button group">
                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="footer_mode"
                                            id="footer_mode_enable"
                                            autoComplete="off"
                                            onChange={() => handlefooter_infoChange("enable")}
                                        />
                                        <Button
                                            as="label"
                                            variant={
                                                footer_mode === "enable" ? "primary" : "outline-primary"
                                            }
                                            htmlFor="footer_mode_enable"
                                        >
                                            Enable
                                        </Button>

                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="footer_mode"
                                            id="footer_mode_disable"
                                            autoComplete="off"
                                            onChange={() => handlefooter_infoChange("disable")}
                                        />
                                        <Button
                                            as="label"
                                            variant={
                                                footer_mode === "disable"
                                                    ? "primary"
                                                    : "outline-primary"
                                            }
                                            htmlFor="footer_mode_disable"
                                        >
                                            Disable
                                        </Button>
                                    </ButtonGroup>
                                </Form.Group>

                                {footer_mode === "enable" && (
                                    <Col className="lg=8 mt-2">
                                        <Form.Group>
                                            <Form.Label>Printer Footer</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                id="printerFooField"
                                                rows="3"
                                                type="text"
                                                placeholder="Enter additional information"
                                                onChange={handlefooter_infoInputChange}
                                                value={formData.footer_info}
                                                isInvalid={formSubmitted}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formSubmitted && "Printer Footer is required when enabled."}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <br />
                <Row>
                    <Col className="col-12">
                        <div
                            className="gap-col"
                            style={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <ul className="d-flex align-items-center gap g-3">
                                <li>
                                    <Button

                                        variant="danger"
                                        onClick={askModal}
                                    >
                                        Cancel
                                    </Button>
                                </li>
                                <li>
                                    <Button
                                        type="submit"
                                        onClick={handleSaveClick}
                                        variant="success"
                                    >
                                        Save
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default PrinterSetting;
