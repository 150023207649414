import React, { useEffect, useRef, useState } from 'react'
import Layout from "../../../../layout/default";
import { Block, Icon, Tags } from '../../../../components';
import { Button, ButtonGroup, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../../../components/DataTable/DataTable';
import ConfirmationModal from '../../modal/ConfirmationModal';
import '../Procurement.css'
import { get, post, postToast, put } from '../../api/Api';
import { useReactToPrint } from 'react-to-print';
import MyComponent from '../../shared/printer/Printer';
import Loader from '../../shared/Loader';
import { SearchDownCode } from '../../shared/dropDown/SearchCode';
import useWebSocket from 'react-use-websocket';
import { isEdited } from '../../shared/Date';
import { useDispatch } from 'react-redux';
import { generateRandomId, getCurrentDateTime } from '../../shared/Service';

const VlccProcForm = () => {

    const [storedData, setStoredData] = useState([])
    const [fat, setFat] = useState('')
    const [snf, setSnf] = useState('')
    const [quantity, setQuantity] = useState(null)
    const [kg, setKg] = useState('')
    const [fatError, setFatError] = useState('')
    const [snfError, setSnfError] = useState('')
    const [quantityError, setQuantityError] = useState('')
    const [rate, setRate] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [fatData, setFatData] = useState([])
    const [snfData, setSnfData] = useState([])
    const [tsData, setTsData] = useState([])
    const [flatData, setFlatData] = useState([])
    const [flatOnlyData, setFlatOnlyData] = useState([])
    const [codeError, setCodeError] = useState('')
    const [isEditMode, setIsEditMode] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const fatRef = useRef(null);
    const snfRef = useRef(null);
    const quantityRef = useRef(null);
    const buttonRef = useRef(null);
    const milkRef = useRef(null)
    const codeRef = useRef(null);
    const [milkType, setMilkType] = useState([])
    const [typeValue, setTypeValue] = useState(null)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [fieldType, setFieldType] = useState('');
    const [ratePerLtr, setRatePerLtr] = useState(0)
    const [abnormal, setAbnormal] = useState([])
    const [rateChartType, setRateChartType] = useState('')
    const [rateChartData, setRateChartData] = useState([])
    const [farmerId, setFarmerId] = useState(null)
    const printRef = useRef();
    const [show, setShow] = useState(false)
    const [bill_no, setBill_no] = useState(null)
    const [numericValue, setNumericValue] = useState([]);
    const [loading, setLoading] = useState(true)
    const [inputValue, setInputValue] = useState('');
    const searchDownRef = useRef();
    const tagsRef = useRef(null);
    const [listData, setListData] = useState([])
    const [editData, setEditData] = useState([])
    const [printer, setPrinter] = useState(true)
    const [value, setValue] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [rowId, setRowId] = useState(null)
    const [lastData, setLastData] = useState([])
    const [findLast, setFindLast] = useState(false)
    const [timeoutId, setTimeoutId] = useState(null);
    const [farmerData, setFarmerData] = useState([])
    const [pagination, setPagination] = useState({
        page: 1,
        page_count: 25,
        search: '',
        total_count: null
    })
    const updatePagination = ({ current_page, per_page, total }) => {
        setPagination({
            ...pagination,
            page: current_page,
            page_count: per_page,
            total_count: total
        });
    };

    const dispatch = useDispatch()

    const socketUrl = 'ws://192.168.0.123:8000';
    const { lastMessage, readyState, sendMessage } = useWebSocket(socketUrl, {
        onOpen: () => {
            console.log('WebSocket connection opened');


            // Set timeout for 30 seconds
            const id = setTimeout(() => {
                console.log('WebSocket data not received within 30 seconds. Closing connection.');
                if (readyState === WebSocket.OPEN) {
                    sendMessage('Closing due to timeout');

                }
            }, 10000);
            setTimeoutId(id);
        },
        onClose: (event) => {
            console.log('WebSocket connection closed:', event);
            clearTimeout(timeoutId); // Clear timeout on close
        },
        shouldReconnect: (closeEvent) => true, // Customize this based on your needs
    });



    useEffect(() => {
        const toggle = localStorage.getItem('printer') || true

        if (toggle === "true") {
            setPrinter(true)
        } else {
            setPrinter(false)
        }

    }, [])

    const clearSearchInput = () => {
        if (searchDownRef.current) {
            searchDownRef.current?.clearInputValue();
        }
    }

    useEffect(() => {
        // Check if in edit mode and fatRef exists before focusing
        if (isEditMode && fatRef?.current) {
            fatRef?.current.focus();
        }
    }, [isEditMode]);


    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        onBeforePrint: () => {
            setShow(true);
        },
        onAfterPrint: () => {
            setShow(false);
            setTimeout(() => {
                searchDownRef.current?.setAutofocus(true);
            }, 500);
        },

    });

    const location = useLocation();
    const { id, cId, accessId } = useParams()
    const navigate = useNavigate()

    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get('mode');
    const type = searchParams.get('type');



    const fetchList = async () => {
        const url = `/api/procurement-access/${accessId}/details`;

        try {
            const response = await get(url);
            if (response.status) {
                const data = response.data
                setStoredData(data)
                console.log(data, "storedData");
                setLoading(false)
                const body = {
                    date: data?.date,
                    route_id: id,
                    session: data.session,
                    center_id: cId
                }
                if (mode === "edit") {
                    fetchData(body)
                }
            } else {
                setLoading(false)
            }

        } catch (error) {
            console.error("Error while posting data:", error);
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }


    const fetchGetEntryData = async () => {
        const url = `/api/procurement-access/getEntries/${accessId}`;

        try {
            const response = await get(url);
            if (response.status) {
                const data = response.data.data
                // setListData(response.data.data.sort((a, b) => b["bill_no"] - a["bill_no"]));
                setListData(data)
                const pageValue = response.data
                updatePagination(pageValue);
                setLoading(false)
                fetchList()
            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchGetEntryData()
    }, []);

    const rowStyles = [
        {
            when: (row) => row.id === rowId,
            style: {
                backgroundColor: '#04af70', // Or any other color of your choice
            },
        },
    ];

    const editStyles = [
        {
            when: (row) => isEdited(row.created_at, row.updated_at),
            style: {
                backgroundColor: '#04af70', // Or any other color of your choice
            },
        },

    ]

    async function editLastData(newRow) {


        const transformedEditData = {
            direct_farmer_id: newRow.id,
            procurement_access_id: parseFloat(accessId),
            route_id: editData.route_id,
            milk_type: editData.milk_type,
            bill_date: storedData?.date,
            session: storedData?.session,
            bill_no: editData.bill_no,
            tot_fat: newRow.fat,
            tot_snf: newRow.snf,
            tot_qty_litre: newRow.quantity,
            tot_qty_kg: newRow.kg,
            tot_rate: newRow.rate,
            tot_amount: newRow.amount,
            incentive_amount: newRow.incentiveRate,
            farmer_code: newRow.farmer_code,
            code: newRow.code,
            collection_details: [
                {
                    tot_fat: newRow.fat,
                    tot_snf: newRow.snf,
                    tot_qty_litre: newRow.quantity,
                    tot_qty_kg: newRow.kg,
                    tot_rate: newRow.rate,
                    tot_amount: newRow.amount,
                    incentive_amount: newRow.incentiveRate,
                    cans_number: numericValue
                },
            ],
        };

        // Edit method
        const updateMethod = async () => {
            const url = `/api/vcc-collection/${rowId}`
            // Send the data to the server
            const response = await put(url, transformedEditData);
            try {
                if (response.status) {
                    setBill_no(response.data.bill_no);

                    setListData((prevListData) => {
                        const updatedListData = prevListData.map((row) =>
                            row.id === rowId ? { ...row, ...response.data } : row
                        );
                        return updatedListData;
                    });

                    searchDownRef.current?.clearInputValueAndFocus();
                    clearSearchInput();

                    if (printer) {
                        setShow(true);
                        setTimeout(() => {
                            handlePrint();
                        }, 100);
                    }
                    searchDownRef.current?.setAutofocus(true);
                }

            } catch (error) {
                console.error("Error while posting data:", error);
            } finally {
                setIsButtonDisabled(false);
            }
        }
        updateMethod()
    }

    console.log("farmerID", farmerId);
    const editFunc = (data, clickId) => {
        // Check if the edited row is the last row in the data array
        const isFirstRow = listData.indexOf(data) === 0;
        // Now you can use the isLastRow variable in your logic
        if (isFirstRow) {
            setFindLast(true)
            setLastData(data)
        }

        console.log("editFunc", data, rateChartType);
        // fetchFarmerRate()

        fetchFarmerData(data.vcc_farmer_id)
        setRowId(clickId)
        setSelectedId(data.id)
        setFat(data.tot_fat)
        setSnf(data.tot_snf)
        setQuantity(data.tot_qty_litre)
        setKg(data.tot_qty_kg)
        setIsEditMode(true)
        setInputValue(data.farmer_code)
        setFarmerId(data.vcc_farmer_id)
        setFarmerName(data.name)
        setPrintFrName(data.name)
        setCode(data.farmer_code)
        setDummyBuffalo(data.milk_type === "1" ? "B" : "")
        setDummyCow(data.milk_type === "0" ? "C" : "")
        setTypeValue(data.milk_type === "0" ? "C" : data.milk_type === "1" ? "B" : "")


        if (clickId) {
            const fetchList = async () => {
                const url = `/api/vcc-collection/list`;
                const body = {
                    from_date: storedData?.date,
                    to_date: storedData?.date,
                    route_id: id,
                    center_id: cId,
                    page_count: 1000,
                    page: 1
                }
                try {
                    const response = await postToast(url, body);
                    if (response.status) {
                        const data = response.data.data
                        const filteredData = data.filter((el) => el.id === clickId)
                        setEditData(filteredData[0])
                        setFat(filteredData[0].tot_fat)
                        setSnf(filteredData[0].tot_snf)
                        setQuantity(filteredData[0].tot_qty_litre)
                        setKg(filteredData[0].tot_qty_kg)
                        setIsEditMode(true)
                        setLoading(false)
                        // setInputValue(`${filteredData[0].farmer_code}-${filteredData[0].name}`)
                        const name = `${filteredData[0].farmer_code}-${filteredData[0].name}`
                        if (name) {
                            handleName(name)
                        }
                    }

                } catch (error) {
                    console.error("Error while posting data:", error);
                    setLoading(false)
                } finally {
                    setLoading(false)
                }
            }
            fetchList()
        }
    };

    const [code, setCode] = useState(null)
    const [farmerTotalData, setFarmerTotalData] = useState([])
    const [kgFactor, setKgFactor] = useState(null)
    const [farmerName, setFarmerName] = useState('')
    const [rateChartId, setRateChartId] = useState(null)
    const [chartID, setChartID] = useState(null)
    const [printFrName, setPrintFrName] = useState('')
    const [dummyCow, setDummyCow] = useState('C')
    const [dummyBuffalo, setDummyBuffalo] = useState('B')

    const rateChartDetails = async (type) => {
        if (type) {
            const url = `/api/rate-chart/${type}/details`;
            try {
                const response = await get(url);
                if (response.status) {
                    const data = response.data;
                    setRateChartData(data.range_jsonmap);
                    setFlatOnlyData(data.range_jsonmap);

                    switch (data.rate_type) {
                        case 1:
                            setRateChartType('FLATONLY');
                            setFlatData(data.range_jsonmap);
                            break;
                        case 2:
                            setRateChartType('FATSNF');
                            break;
                        case 3:
                            setRateChartType('FAT');
                            setFatData(data.range_jsonmap);
                            break;
                        case 4:
                            setRateChartType('SNF');
                            setSnfData(data.range_jsonmap);
                            break;
                        case 5:
                            setRateChartType('TS');
                            setTsData(data.range_jsonmap);
                            break;
                        default:
                            setRateChartType('FLAT');
                            setFlatData(data.range_jsonmap);
                            break;
                    }
                }
            } catch (error) {
                console.error('Error while fetching rate chart details:', error);
            }
        }
    };

    const fetchFarmerData = async (id) => {

        console.log("it's works");

        const url = `/api/direct-farmer/farmer-details/${id}`;
        try {
            const response = await get(url);
            if (response.status) {
                const responseData = response.data
                setRateChartId(responseData[0])
                setFarmerTotalData(responseData)
                setMilkType(responseData[0].milk_info.milk_type)
                setKgFactor(responseData[0].kg_ltr_factor)
                const data = responseData[0].ratechart_id[0]
                let chartType = '';
                if (responseData[0].milk_info.milk_type.includes('cow')) {
                    if (data?.cow) {
                        setChartID(data?.cow);
                        chartType = data?.cow;
                    } else {
                        setChartID(data?.common);
                        chartType = data?.common;
                    }

                } else if (responseData[0].milk_info.milk_type.includes('buffalo')) {
                    if (data?.buffalo) {
                        setChartID(data?.buffalo);
                        chartType = data?.buffalo;
                    } else {
                        setChartID(data?.common);
                        chartType = data?.common;
                    }
                } else {
                    setChartID(data?.common);
                    chartType = data?.common;
                }

                if (!(responseData[0].milk_info.milk_type.includes('cow') && responseData[0].milk_info.milk_type.includes('buffalo'))) {


                    rateChartDetails(chartType);
                }

            }
        } catch (error) {
        }
    }


    const handleInput = (value, id, data, error) => {
        setFarmerId(id)
        setFarmerName(data.name)
        setPrintFrName(data.name)
        setCode(data.code)
        setDummyBuffalo("B")
        setDummyCow("C")
        setInputValue(data.code)
        fetchFarmerData(id)

        console.log(listData, data.code, "finmdddd");
        // Here, it checks if the farmer already exists in tableData and is not in edit mode
        const existingFarmer = listData?.find(row => (mode === "add" ? row.farmer_code : mode === "edit" ? row.code : "") === data.code);

        if (existingFarmer) {
            setShowConfirmationModal(true);

        } else {
            if ((milkType.includes('cow') && !milkType.includes('buffalo')) && (milkType.includes('cow') && !milkType.includes('buffalo'))) {
                fatRef.current?.focus()
            } else {
                milkRef.current?.focus()
            }
        }
    }


    const confirmDeleteForFatRate = (reason) => {
        // 1. Update the state by filtering out the data with the provided idToDelete


        setShowDeleteModal(false);
        setIdToDelete(null);
        setIsEditMode(false)
        setSelectedId(null);

    };
    const cancelDelete = () => {
        setShowDeleteModal(false);
        setIdToDelete(null);
    };

    const handleDelete = (row) => {
        setShowDeleteModal(true);
        setIdToDelete(row.id);  // Assuming 'start' is unique; adjust as needed
    };

    const rateType = rateChartType

    useEffect(() => {
        if (fat && snf) {
            let computedRate = 0;
            let tsFat = parseFloat(fat)
            let tsSnf = parseFloat(snf);

            console.log(tsFat, tsSnf, rateChartType, "Checking value");


            switch (rateChartType) {
                case 'FLATONLY':
                    const flatOnlyRate = flatOnlyData[0]?.flatRate;
                    computedRate = flatOnlyRate ? parseFloat(flatOnlyRate) : 0;
                    break;
                case 'FAT':
                    const fatRate = findRateForFatOnly(fat, fatData);
                    computedRate = fatRate ? computeRateForFatOnly(fat, fatRate) : 0;
                    break;

                case 'SNF':
                    const snfRate = findRateForSnfOnly(snf, snfData);
                    computedRate = snfRate ? computeRateForSnfOnly(snf, snfRate) : 0;
                    break;

                case 'TS':
                    const tsRate = findRateForTS(tsFat, tsSnf, tsData);
                    const tsValue = tsFat + tsSnf
                    computedRate = tsRate ? tsValue * tsRate : 0;
                    break;

                case 'FATSNF':
                    const combinedRate = findPrice(fat, snf);
                    computedRate = combinedRate ? computeRateForFatAndSnf(fat, snf, combinedRate) : 0;
                    break;

                case 'FLAT':
                    const fatRateFlat = findRateForFatOnly(fat, flatData?.formFat); // fatDataFlat is the fat ranges data for flat method
                    const snfRateFlat = findRateForSnfOnly(snf, flatData?.formSnf); // snfDataFlat is the snf ranges data for flat method

                    if (fatRateFlat && snfRateFlat) {
                        computedRate = computeRateForFlat(fat, snf, fatRateFlat, snfRateFlat, flatData.litreRate);
                    } else {
                        computedRate = 0;
                    }
                    break;

                default:
                    computedRate = 0;
            }

            setRate(computedRate);
        }

    }, [fat, snf, quantity]);



    const findRateForFatOnly = (fatValue, data) => {

        const fatFloat = parseFloat(fatValue);
        if (data)
            for (const item of data) {
                if (fatFloat >= item.start && fatFloat <= item.end) {
                    return item.price;
                }
            }
        return false;
    }

    const findRateForSnfOnly = (snfValue, data) => {
        const snfFloat = parseFloat(snfValue);
        if (data)
            for (const item of data) {
                if (snfFloat >= item.start && snfFloat <= item.end) {
                    return item.price;
                }
            }
        return null;
    }
    const findRateForTS = (tsFat, tsSnf, data) => {
        if (data)
            for (const item of data) {
                if (tsFat >= item.start && tsSnf <= item.end) {
                    return item.price;
                }
            }
        return null;
    }


    const computeRateForFatOnly = (fat, rate) => {
        return parseFloat(fat) * rate;
    }

    const computeRateForSnfOnly = (snf, rate) => {
        return parseFloat(snf) * rate;
    }

    const computeRateForFatAndSnf = (fat, snf, rate) => {

        console.log(fat, snf, rate, "RateValues");

        return (parseFloat(fat) + parseFloat(snf)) * rate;
    }

    const computeRateForFlat = (fat, snf, fatRate, snfRate, litreRate) => {
        return ((parseFloat(fat) * fatRate) + (parseFloat(snf) * snfRate) + parseFloat(litreRate)).toFixed(2);
    }

    function findPrice(fatValue, snfValue) {
        // Convert to float for accurate comparisons
        const fatFloat = parseFloat(fatValue);
        const snfFloat = parseFloat(snfValue);

        // Loop through the price data
        if (rateChartData) {
            for (const item of rateChartData) {
                // Check if the fatValue is within the range
                if (fatFloat >= item.fat_start && fatFloat <= item.fat_end) {
                    // Check if the snfValue is within the range
                    if (snfFloat >= item.snf_start && snfFloat <= item.snf_end) {
                        setRatePerLtr(item.price);
                        return item.price; // Return the found price
                    }
                }
            }
        }
        return null; // Return null if no matching price was found
    }


    const handleBlur = (e, setValue, error) => {
        let { value } = e.target;

        // Format to two decimal places
        value = formatToTwoDecimalPlaces(value);
        if (value.trim() === "") {
            e.preventDefault();
            return;
        }

        setValue(value);
    }

    function formatToTwoDecimalPlaces(value) {
        // Parse the value to float, then format it to two decimal places
        let formattedValue = parseFloat(value).toFixed(2);
        // Handle NaN cases (when value cannot be parsed to a number)
        return isNaN(formattedValue) ? "" : formattedValue;
    }

    // Helper function to validate value range
    const isValueInRange = (value, type) => {
        if (!rateChartData) return false;
        for (const item of rateChartData) {

            if (type === "fat" && value >= item.fat_start && value <= item.fat_end) {
                return true;
            } else if (type === "snf" && value >= item.snf_start && value <= item.snf_end) {
                return true;
            }
        }

        return false;
    };

    function handleKeyUp(e) {
        const input = e.target;
        const value = input.value;
        e.preventDefault();

        const fieldType = e.target.getAttribute("data-field");
        setFieldType(fieldType)


        if (fieldType === "fat" || fieldType === "quantity") {
            // Check if the input value is a number
            if (isNaN(value) && !/^\.(\d*)?$/.test(value)) {
                e.target.value = "";  // Clear the input field
                return;
            }
            // Validate "fat" value based on milk type
            if (fieldType === "fat") {
                if (milkType.includes("cow") && (parseFloat(value) > 9 || parseFloat(value) < 0)) {
                    // Invalid "fat" value for cow milk
                    setFatError("Fat value must be between 0 and 9 for cow milk");
                } else if (milkType.includes("buffalo") && (parseFloat(value) > 14 || parseFloat(value) < 0)) {
                    // Invalid "fat" value for buffalo milk
                    setFatError("Fat value must be between 0 and 14 for buffalo milk");
                } else {
                    // Valid "fat" value, clear the error
                    setFatError("");
                }
            }

            // Validate "quantity" to ensure it's less than 200
            if (fieldType === "quantity" && parseFloat(value) > 550) {
                setQuantityError("Quantity must not exceed 550");
            } else {
                setQuantityError("");
            }
        }

        if (!value) return;

        switch (rateType) {
            case 'FAT':
                validateFat(value, fatData);
                break;
            case 'SNF':
                validateSnf(value, snfData);
                break;
            case 'TS':
                validateTS(fat, value, tsData);
                break;
            case "FATSNF":
                if (fieldType === "fat") {
                    if (!isValueInRange(parseFloat(value), "fat")) {
                        setFatError("Entered FAT value is out of range!")
                        // toast.error("Entered FAT value is out of range!");
                    }
                } else if (fieldType === "snf") {
                    if (!isValueInRange(parseFloat(value), "snf")) {
                        setSnfError("Entered SNF value is out of range!")
                    }
                }
                break
            case 'FLAT':
                validateFat(value, flatData.formFat)
                validateSnf(value, snfData.formSnf);
                break;

            default:
                break;
        }

    }

    useEffect(() => {
        // Check for errors after the state has been updated
        const isValidFormat = /^(?:\d{2}(\.\d{2})?)?$/.test(value);

        if (isValidFormat && (value.length === 4 || value.length === 5)) {
            // If the format is valid, move to the next field
            switch (fieldType) {
                case 'fat':
                    if (!fatError) {
                        snfRef.current?.focus();
                    }
                    break;
                case 'snf':
                    if (!snfError) {
                        quantityRef.current?.focus();
                    }
                    break;

                default:
                    break;
            }
        }
    }, [fatError, snfError, value, fieldType]);


    const validateFat = (value, data) => {
        if (fieldType === "fat") {
            const fatFloat = parseFloat(value);

            // Check if fat value is within any range in the data
            const isInValidRange = data.some(item => fatFloat >= item.start && fatFloat <= item.end);

            if (!isInValidRange) {
                setFatError("Value is not within the valid range");
            } else {
                setFatError(""); // Clear error if the value is valid
            }
        }
    }

    const validateSnf = (value, data) => {
        if (fieldType === "snf") {
            const snfFloat = parseFloat(value);

            // Check if snf value is within any range in the data
            const isInValidRange = data.some(item => snfFloat >= item.start && snfFloat <= item.end);

            if (!isInValidRange) {
                setSnfError("Value is not within the valid range");
            } else {
                setSnfError(""); // Clear error if the value is valid
            }
        }
    }
    const validateTS = (tsFatValue, tsSnfValue, data) => {
        const tsFatFloat = parseFloat(tsFatValue);
        const tsSnfFloat = parseFloat(tsSnfValue);

        // Check if tsFat and tsSnf values are within any range in the data
        const isInValidRange = data.some(item => tsFatFloat >= item.start && tsSnfFloat <= item.end);

        if (!isInValidRange) {

        } else {
        }
    }

    const handleKeyDown = (e) => {
        const { value, selectionStart, name } = e.target;
        // Check if Enter is pressed
        if (e.key === 'Enter') {
            if (value === '') {
                e.preventDefault();
                return;
            }
            switch (rateType) {
                case 'FAT':
                    validateFat(value, fatData);
                    break;
                case 'SNF':
                    validateSnf(value, snfData);
                    break;
                case 'TS':
                    validateTS(fat, value, tsData);
                    break;
                case "FATSNF":
                    if (name === "start" && !isValueInRange(parseFloat(value), "fat")) {
                        setFatError("Entered FAT value is out of range!");
                        e.preventDefault();
                    } else if (name === "end" && !isValueInRange(parseFloat(value), "snf")) {
                        setSnfError("Entered SNF value is out of range!");
                        e.preventDefault();
                    }
                    break;
                case 'FLAT':
                    validateFat(value, flatData.formData);
                    validateSnf(value, snfData.formSnf);
                    break;
                default:
                    break;
            }
            switch (e.target.name) {
                case 'FarmerCode':
                    if (typeValue) {
                        if ((milkType.includes('cow') && !milkType.includes('buffalo')) && (milkType.includes('cow') && !milkType.includes('buffalo'))) {
                            fatRef.current?.focus()
                        } else {

                            milkRef.current?.focus();
                        }

                        e.preventDefault();
                    }
                    return;

                case 'start': // This should be the name of the FAT input
                    if (!fatError) {
                        snfRef.current?.focus();
                        e.preventDefault();
                    }
                    return;

                case 'end': // This should be the name of the SNF input
                    if (!snfError) {
                        quantityRef.current?.focus();
                        e.preventDefault();
                    }
                    return;

                case 'start1':
                    if (!quantityError) {  // Assume start1Error for demonstration purposes
                        buttonRef.current?.focus();
                        e.preventDefault();
                    }
                    return;

                default:
                    break;
            }
        }

        // Check if Tab is pressed
        if (e.key === 'Tab' && !e.shiftKey) {
            if (value === '' || (e.target.name === 'FarmerCode' && inputValue) ||
                (e.target.name === 'start' && fatError) ||
                (e.target.name === 'end' && snfError) ||
                (e.target.name === 'start1' && quantityError)) {
                e.preventDefault();
            }
            return;
        }
        // Allow navigation & control keys
        const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab", "."];
        if (allowedKeys.includes(e.key)) {
            // Allow only one dot
            if (e.key === "." && value.includes('.')) {
                e.preventDefault();
            }
            return;
        }
        // If key is not a number or alphabet, prevent input
        if (!/^[a-zA-Z0-9]+$/.test(e.key)) {
            e.preventDefault();
            return;
        }

        // If there's a dot in the value, ensure up to two numbers after it
        const dotIndex = value.indexOf('.');
        if (dotIndex !== -1 && value.split('.')[1].length >= 2 && selectionStart > dotIndex) {
            e.preventDefault();
        }
    }

    const [printerData, setPrinterData] = useState(null)

    const handleSubmit = () => {
        let isValid = true

        if (!fat) {
            setFatError('FAT is required.');
            isValid = false;

        } else {
            setFatError('');
        }
        if (!snf) {
            setSnfError('SNF is required.');
            isValid = false;

        } else {
            setSnfError('');
        }

        console.log(fat, snf, rate, "checkValue");

        // if (!kg) {
        //     setQuantityError('Quantity is required.');
        //     isValid = false;

        // } else {
        //     setQuantityError('');
        // }




        if (!inputValue) {
            setCodeError('Farmer Code is required.');
            isValid = false;

        } else {
            setCodeError('');
        }
        if (!rate) {
            // toast.error("Rate must be higher than 0")
            isValid = false;
        }

        if (isValid) {
            setIsButtonDisabled(true);

            // Find the farmer using the inputValue (farmer code)
            const farmer = farmerTotalData.map(f => f.incentive_info);

            let incentiveRate = 0;
            // If the farmer exists and has an incentive, fetch the incentiveRate
            if (farmer) {
                const incentive = milkType.includes("cow") ? farmer[0].cow : milkType.includes("buffalo") ? farmer[0].buffalo : 0
                incentiveRate = incentive || 0;
            }
            const basicAmount = rate * parseFloat(quantity) || 0;

            // Calculate the incentive for each litre
            const incentiveAmount = parseFloat(quantity) * incentiveRate;

            // Sum basicAmount and incentiveAmount to get the final amount
            const finalAmount = basicAmount + incentiveAmount;

            const newRow = {
                centerName: storedData?.centerName,
                farmer_code: code,
                shift: storedData?.session,
                farmerName: inputValue,
                id: farmerId,
                milkType: milkType,
                fat: fat,
                snf: snf,
                quantity: quantity,
                kg: kg,
                rate: (rate).toFixed(2),
                incentiveRate: incentiveRate,
                ratePerLitre: ratePerLtr,
                amount: (finalAmount).toFixed(2), // Use the finalAmount here
                code: code
            };

            console.log("newRow///", newRow);
            if (isEditMode && selectedId !== null) {
                const editBodyData = {

                    direct_farmer_id: cId,
                    vcc_farmer_id: farmerId,
                    route_id: editData.route_id,
                    milk_type: editData.milk_type,
                    bill_date: storedData?.date,
                    session: storedData?.session,
                    bill_no: editData.bill_no,
                    tot_fat: newRow.fat,
                    tot_snf: newRow.snf,
                    tot_qty_litre: newRow.quantity,
                    tot_qty_kg: newRow.kg,
                    tot_rate: newRow.rate,
                    tot_amount: newRow.amount,
                    incentive_amount: newRow.incentiveRate,
                    farmer_code: newRow.farmer_code,
                    unique_id: editData?.unique_id,
                    device_type: "WEB",
                    code: newRow.code,
                    collection_details: [
                        {
                            tot_fat: newRow.fat,
                            tot_snf: newRow.snf,
                            tot_qty_litre: newRow.quantity,
                            tot_qty_kg: newRow.kg,
                            tot_rate: newRow.rate,
                            tot_amount: newRow.amount,
                            incentive_amount: newRow.incentiveRate,
                            cans_number: numericValue
                        },
                    ],
                };

                setPrinterData(newRow);

                // Edit method
                const updateMethod = async () => {
                    const url = `/api/vcc-collection/${rowId}`
                    // Send the data to the server
                    const response = await put(url, editBodyData);
                    try {
                        if (response.status) {
                            setBill_no(response.data.bill_no);

                            setListData((prevListData) => {
                                const updatedListData = prevListData.map((row) =>
                                    row.id === rowId ? { ...row, ...response.data } : row
                                );
                                return updatedListData;
                            });

                            searchDownRef.current?.clearInputValueAndFocus();
                            clearSearchInput();

                            if (printer) {
                                setShow(true);
                                setTimeout(() => {
                                    handlePrint();

                                }, 100);
                            }
                            searchDownRef.current?.setAutofocus(true);
                            setIsButtonDisabled(false);
                        }

                    } catch (error) {
                        console.error("Error while posting data:", error);
                    } finally {
                        setIsButtonDisabled(false);
                    }
                }

                if (findLast) {
                    editLastData(newRow)
                    // Reset fields
                    setFat('')
                    setSnf('')
                    setQuantity('')
                    setRate('')
                    setKg('')
                    setIsEditMode(false);
                    setSelectedId(null);
                    setInputValue('');
                    setMilkType('')
                    setFarmerName('')
                    setRowId('')
                    setFindLast(null)

                    codeRef.current?.focus();
                } else {

                    if (mode === "edit" && !findLast) {
                        updateMethod()

                        // Reset fields
                        setFat('')
                        setSnf('')
                        setQuantity('')
                        setRate('')
                        setKg('')
                        setIsEditMode(false);
                        setSelectedId(null);
                        setInputValue('');
                        setMilkType('')
                        setFarmerName('')
                        setRowId('')

                        codeRef.current?.focus();

                    } else {
                        toast.error("You don't have rights to Edit")
                    }
                }
            } else {
                // // Here, it checks if the farmer already exists in tableData and is not in edit mode
                addOrUpdateRow(newRow); // If farmer doesn't exist, add the new row

            }
            setRemoveButton(false)

        } else {
            toast.error("Form is invalid")
        }
    }

    const addOrUpdateRow = async (newRow) => {
        try {
            let updatedData;
            if (isEditMode && selectedId !== null) {
                // Update the row in the state
                updatedData = (mode === "add" ? [...tableData] : [...abnormal]);
                updatedData[selectedId] = newRow;
            } else {
                // Add the new row to the state
                updatedData = isEditMode ? [...tableData] : [...tableData, newRow];
            }

            // Update the state with the new table data
            if (mode === "add") {
                setTableData(updatedData);
            } else {
                setAbnormal(updatedData);
            }

            setPrinterData(newRow);

            console.log("newRow", newRow)

            // Prepare the data to be sent to the server
            const transformedData = [{
                local_id: 0,
                procurement_access_id: parseFloat(accessId),
                vcc_farmer_id: farmerId,
                direct_farmer_id: cId,
                route_id: id,
                milk_type: milkType.includes("cow") ? "0" : milkType.includes("buffalo") ? "1" : "2",
                bill_no: 1,
                bill_date: storedData?.date,
                session: storedData?.session,
                unique_id: generateRandomId(),
                ctime: getCurrentDateTime(),
                tot_fat: newRow.fat,
                tot_snf: newRow.snf,
                tot_qty_litre: newRow.quantity,
                tot_qty_kg: newRow.kg,
                tot_rate: newRow.rate,
                tot_amount: newRow.amount,
                incentive_amount: newRow.incentiveRate,
                device_type: "WEB",
                collection_details: [
                    {
                        tot_fat: newRow.fat,
                        tot_snf: newRow.snf,
                        tot_qty_litre: newRow.quantity,
                        tot_qty_kg: newRow.kg,
                        tot_rate: newRow.rate,
                        tot_amount: newRow.amount,
                        incentive_amount: newRow.incentiveRate,
                        cans_number: numericValue
                    },
                ],
            }]

            const url = "/api/vcc-collection/create";
            if (mode === "add") {
                // Send the data to the server
                setIsButtonDisabled(true);
                const response = await post(url, transformedData);

                if (response.status) {
                    setBill_no(response.data[0].bill_no);

                    console.log("response", response.data);

                    if (!isEditMode) {
                        setListData((prevListData) => {
                            const newListData = [...prevListData, response.data[0]];
                            return newListData.sort((a, b) => b["bill_no"] - a["bill_no"]); // Sort in descending order
                        });
                        searchDownRef.current.clearInputValueAndFocus();
                        clearSearchInput();
                        if (printer) {
                            setShow(true);
                            setTimeout(() => {
                                handlePrint();
                            }, 100);
                        }
                    }
                    searchDownRef.current.setAutofocus(true);
                }
                // Move setIsButtonDisabled(false) here to execute after the state updates
                setIsButtonDisabled(false);
            } else {
                toast.error("You don't have rights to Add");
                setIsButtonDisabled(false);
            }


        } catch (error) {
            console.error("Error while posting data:", error);
            setIsButtonDisabled(false);
        }
    };



    const handleYesClick = () => {

        if ((milkType.includes('cow') && !milkType.includes('buffalo')) && (milkType.includes('cow') && !milkType.includes('buffalo'))) {
            fatRef?.current?.focus()
        } else {
            milkRef.current?.focus()
        }

        setShowConfirmationModal(false); // Close the confirmation modal

    };

    const pattern = /^\d*\.?\d{0,2}$/;

    const hasTwoDecimals = (value) => {
        return pattern.test(value);
    }

    const handleInputChange = (e, setValue, setError) => {
        const { value, name } = e.target;

        if (name !== 'start1') {
            const regex = /^\d{0,2}(\.\d{0,2})?$/;
            if (!regex.test(value) && value !== "") {
                e.preventDefault();
                return;
            }
        }

        if (name === 'start1') {  // If the input field is for 'quantity'
            const parsedValue = parseFloat(value);

            if (parseFloat(inputValue) > 550.00) {
                setError('Quantity cannot be greater than 550.00');
            } else {
                setError('');
            }

            if (!isNaN(parsedValue)) {
                const calculatedValue = (parsedValue * kgFactor)
                const decimalPlaces = 2;
                const convertedNumber = Math.floor(calculatedValue * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
                setKg(convertedNumber);
            } else {
                setKg(''); // set it to empty or some default value if the parsed value is NaN
            }
        }
        setValue(value);

        // Validate for exactly two decimals
        if (!hasTwoDecimals(value)) {
            setError(`${name.charAt(0).toUpperCase() + name.slice(1)} must be a number with exactly two decimal places.`);
        } else {
            setError(""); // Clear the error message
        }
    }
    const buttonStyle = {
        padding: '0.7px 4px ',
        fontSize: '10px',
        lineHeight: '1',
    };

    const singlePrint = (row) => {

        const data = {
            fat: parseFloat(row.tot_fat).toFixed(2) || 0,
            snf: parseFloat(row.tot_snf).toFixed(2) || 0,
            quantity: parseFloat(row.tot_qty_litre).toFixed(2) || 0,
            rate: parseFloat(row.tot_rate).toFixed(2) || 0,
            amount: parseFloat(row.tot_amount).toFixed(2) || 0,
        }

        setPrinterData(data)
        setPrintFrName(row.name)
        setCode(row.farmer_code)
        setBill_no(row.bill_no)
        setShow(true)
        setTimeout(() => {
            handlePrint()
        }, 100);
    }

    const plantColumn = (handleCell, handleEdit, selectedId,) => [
        {
            name: "BILL NO",
            selector: row => row.bill_no,
            center: true,
            minWidth: '50px',
            sortable: true,
            sortDescending: true

        },
        {
            name: "FARMER CODE",
            selector: row => row.farmer_code,
            center: true,
            minWidth: '120px',

        },
        {
            name: "FARMER NAME",
            selector: row => mode === "add" ? row.name : mode === "edit" ? row.name : "",
            left: true,
            minWidth: '150px',
        },
        {
            name: "MILK TYPE",
            selector: row => row.milk_type === "0" ? "cow" : row.milk_type === "1" ? "buffalo" : row.milk_type === "2" ? "both" : "",
            center: true,
        },
        {
            name: "FAT",
            cell: (row) => {
                const fatValue = parseFloat(row.tot_fat);
                // Check if the value is outside the range (3.8 to 4.8)
                const isOutOfRange = fatValue < 3.8 || fatValue > 4.8;
                // Apply red color if the value is below the range, blue if above the range, otherwise inherit
                const cellStyle = {
                    color: isOutOfRange ? (fatValue < 3.8 ? "red" : "blue") : 'inherit',
                };
                return <div style={cellStyle}>{row.tot_fat ? parseFloat(row.tot_fat).toFixed(2) : ''}</div>;
            },
            right: true,
            minWidth: '50px',
        },
        {
            name: "SNF",
            cell: (row) => {
                const snfValue = parseFloat(row.tot_snf);
                // Check if the value is outside the range (3.8 to 4.8)
                const isOutOfRange = snfValue < 7.7 || snfValue > 8.5;
                // Apply red color if the value is below the range, blue if above the range, otherwise inherit
                const cellStyle = {
                    color: isOutOfRange ? (snfValue < 7.7 ? "red" : "blue") : 'inherit',
                };
                return <div style={cellStyle}>{row.tot_snf ? parseFloat(row.tot_snf).toFixed(2) : ''}</div>;
            },
            right: true,
            minWidth: '60px',
        },
        {
            name: "QTY(Kg)",
            selector: row => (
                <div>
                    {row.tot_qty_kg}
                    {row.symbol === "up" ?
                        <div className="triangle-up"></div>
                        : row.symbol === "down" ?
                            <div className="triangle-down"></div>
                            : null}
                </div>
            ),
            right: true,

        },
        {
            name: "QTY(Lit)",
            selector: row => (
                <div>
                    {row.tot_qty_litre}
                    {row.symbol === "up" ?
                        <div className="triangle-up"></div>
                        : row.symbol === "down" ?
                            <div className="triangle-down"></div>
                            : null}
                </div>
            ),
            right: true,

        },

        {
            name: "ACTION",
            center: true,

            cell: (row) => {
                if (selectedId !== null && row.id !== rowId) {
                    return null;  // Return nothing if a row is selected and it's not the current row
                }

                return (
                    <div  >
                        <Button
                            className="mx-1"
                            style={buttonStyle}
                            variant="primary"
                            onClick={() => handleEdit(row, row.id)}
                        >
                            <Icon name="edit"></Icon>
                        </Button>

                        {selectedId === null && (
                            <Button
                                className="mx-1"
                                style={buttonStyle}
                                variant="info"
                                onClick={() => singlePrint(row)}
                            >
                                <Icon name="printer"></Icon>
                            </Button>
                        )}
                    </div>
                );
            },
            sortable: false,
            hide: "md",
        }
    ]



    const handleSave = () => {
        // Initialize isValid to true.
        let isValid = true;
        // Clear any existing error.
        setCodeError('');
        if (isValid) {
            navigate('/vlcc-procurement/active')
            // Exit edit mode and reset the selected ID
            setIsEditMode(false);
            setSelectedId(null);
            setMilkType('')
        }
    }

    const [removeButton, setRemoveButton] = useState(false)

    const handleFarmerCode = (value) => {
        // Simple validation check
        if (!value || value.trim() === '') {
            return 'Farmer name with Code is required.';
        }
        return ''; // No error
    };


    const setType = (e) => {
        const value = e.target.value
        setTypeValue(value)
        setFat('')
        setSnf('')
        setQuantity('')
        setKg('')

        const inputValue = e.target.value.toUpperCase(); // Convert input to uppercase

        const pattern = /^[CB]*$/;

        // Check if the input matches the pattern
        if (pattern.test(inputValue)) {
            // Update the state only if the input is valid
            setTypeValue(inputValue);
        } else {
            setTypeValue('')
        }

        const data = rateChartId?.ratechart_id[0]



        let chartID = 0
        if (value.toLowerCase() === "c" || value.toLowerCase() === "b") {

            if (value.toLowerCase().toLowerCase() === "c") {
                if (data?.cow) {
                    chartID = data?.cow;
                } else {
                    chartID = data?.common;
                }
            } else if (value.toLowerCase() === "b") {
                if (data?.buffalo) {
                    chartID = data?.buffalo;
                } else {
                    chartID = data?.common;
                }
            }
        }
        if (chartID) {

            const fn = async () => {
                const url = `/api/rate-chart/${chartID}/details`;
                try {
                    const response = await get(url);
                    if (response.status) {
                        const data = response.data
                        setRateChartData(data.range_jsonmap)
                        setFlatOnlyData(data.range_jsonmap)
                        const type = data.rate_type === 1 ? "FLATONLY" : data.rate_type === 2 ? "FATSNF" : data.rate_type === 3 ? "FAT" : data.rate_type === 4 ? "SNF" : data.rate_type === 5 ? "TS" : "FLAT"
                        setRateChartType(type)
                        if (data.rate_type === 3) {
                            setFatData(data?.range_jsonmap)
                        } else if (data.rate_type === 4) {
                            setSnfData(data?.range_jsonmap)
                        } else if (data.rate_type === 5) {
                            setTsData(data?.range_jsonmap)
                        } else {
                            setFlatData(data?.range_jsonmap)
                        }
                    }
                } catch (error) {
                    console.error("Error while posting data:", error);
                }
            }
            fn()
        }
    }

    const handleNumericValueChange = (value) => {
        setNumericValue(value);
    };

    const handleName = (name) => {
        // setInputValue(name)
    }

    const handleRemove = () => {
        setFat('')
        setSnf("")
        setQuantity('')
        setKg('')
        clearSearchInput();
        setFarmerName('')
        setRowId('')
        setTypeValue('')
        // setInputValue('')
        if (milkType.includes('cow')) {
            setDummyCow('')
        } else if (milkType.includes('buffalo')) {
            setDummyBuffalo('')
        } else {
            setTypeValue('')
        }

        if (selectedId) {
            setSelectedId(null)
            setIsEditMode(false)
        }

    }

    const handleKeyPress = () => {
        setFat('')
        setSnf("")
        setQuantity('')
        setKg('')
        setInputValue("")
        setDummyCow('')
        setDummyBuffalo('')
        setFarmerName('')
        setMilkType('')
    }


    const handlePaginate = (currentPage, itemPerPage, searchValue) => {
        const bodyData = {
            page: currentPage,
            page_count: itemPerPage,
            search: searchValue,
            date: storedData?.date,
            route_id: id,
            session: storedData.session,
        }
        // if (bodyData)
        fetchData(bodyData)
    }

    const fetchData = async (body) => {
        const url = "/api/vcc-collection/list";
        try {
            const response = await postToast(url, body);
            if (response.status) {
                const data = response.data.data
                const pageValue = response.data
                updatePagination(pageValue);


                if (data) {
                    // setListData(data?.sort((a, b) => b["bill_no"] - a["bill_no"]));
                    setListData(data)
                }
                setLoading(false)

            } else {
                setLoading(false)
            }
        } catch (error) {
            console.error("Error while fetching data:", error);
            setLoading(false)
        }
    };



    useEffect(() => {
        // fetchListData()
        fetchList()
    }, [])


    const fetchFarmerRate = async () => {
        const url = `/api/vcc-farmer/centersinroute/${id}`;

        try {
            const response = await get(url);
            if (response.status) {
                const responseData = response.data
                const milk_type = JSON.parse(responseData[0].milk_info)
                setRateChartId(JSON.parse(responseData[0].ratechart_id)?.[0])
                setFarmerTotalData(JSON.parse(responseData[0].incentive_info))
                // setMilkType(milk_type.milk_type)
                setKgFactor(1.04)
                const data = JSON.parse(responseData[0].ratechart_id)?.[0]

                let chartType = '';
                if (milk_type.milk_type.includes('cow')) {
                    if (data?.cow) {
                        setChartID(data?.cow);
                        chartType = data?.cow;
                    } else {
                        setChartID(data?.common);
                        chartType = data?.common;
                    }

                } else if (milk_type.milk_type.includes('buffalo')) {
                    if (data?.buffalo) {
                        setChartID(data?.buffalo);
                        chartType = data?.buffalo;
                    } else {
                        setChartID(data?.common);
                        chartType = data?.common;
                    }
                } else {
                    setChartID(data?.common);
                    chartType = data?.common;
                }

                if (!(milk_type.milk_type.includes('cow') && milk_type.milk_type.includes('buffalo'))) {
                    rateChartDetails(chartType);
                }

            }

        } catch (error) {
            console.error("Error while posting data:", error);
        } finally {
            setLoading(false)
        }
    };


    const fetchFarmer = async () => {
        const url = `/api/vcc-farmer/center/${cId}`;
        try {
            const response = await get(url);
            if (response.status) {
                const responseData = response.data
                setFarmerData(responseData)
                setLoading(false)
            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error while posting data:", error);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (cId) {
            fetchFarmer()
        }

        if (id) {
            fetchFarmerRate()
        }
    }, [cId, id]);


    return (
        <Layout title="Procurement" content="container">
            <Loader loading={loading} />
            <Row className="g-gs">
                <Col xxl="12">
                    <Block.Head >
                        <Block.HeadBetween>
                            <Block.HeadContent>
                                <h3 >
                                    {type === "vlcc" ? "Collection Center Entry" : type === "direct" ? "Plant Collection Entry" : type === "bulk" || "vlcc-entry" ? "Plant Collection Entry" : ""}
                                </h3>
                            </Block.HeadContent>
                            <div className="d-flex justify-content-end ">
                                <Button
                                    as="label"
                                    variant="primary"
                                    onClick={() => navigate(`/direct-vendor/active`)}
                                >
                                    <Icon name="eye"></Icon>
                                    View List
                                </Button>
                            </div>
                        </Block.HeadBetween>
                    </Block.Head>


                    <Card className="card-gutter-md">
                        <Card.Body>
                            <ConfirmationModal
                                show={showConfirmationModal}
                                title="Existing Farmer"
                                body="This farmer already exists. Do you still want to add?"
                                onCancel={() => {
                                    clearSearchInput()
                                    handleRemove()
                                    setShowConfirmationModal(false)
                                    searchDownRef?.current?.setAutofocus(true)
                                }}
                                onConfirm={handleYesClick}
                            />
                            <ConfirmationModal
                                show={showDeleteModal}
                                title="Delete Confirmation"
                                askReason
                                onCancel={cancelDelete}
                                onConfirm={(reason) => confirmDeleteForFatRate(reason)}
                            />

                            <Row className="g-gs">
                                {/* Center Name at top-center */}
                                <Col xxl="12" className="text-center" >
                                    <Row className="g-gs">
                                        <Col lg="4">
                                            <h3 className="chartname">
                                                {storedData?.direct_farmers?.code || ''} - {storedData?.direct_farmers?.name || ''}
                                            </h3>
                                        </Col>
                                        <Col lg="1" style={{ marginTop: "2px" }}>
                                            <Row className="g-gs">
                                                <Col lg="2" style={{ backgroundColor: ' #ff9966', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Icon name="printer" style={{ color: '#ff66cc', fontSize: '24px' }}></Icon>
                                                </Col>

                                                <Col lg="6">
                                                    <Form.Group className="form-group" controlId="validation">
                                                        <ButtonGroup aria-label="Mode Selection">
                                                            <input
                                                                type="radio"
                                                                className="btn-check"
                                                                value='true'
                                                                name="MODE_NAME_PLACEHOLDER"
                                                                id="MODE_ID_PLACEHOLDER_1"
                                                                autoComplete="off"
                                                                checked={printer === true}
                                                                onChange={() => {
                                                                    setPrinter(true)
                                                                    localStorage.setItem('printer', true)
                                                                }}
                                                            />
                                                            <Button as="label" variant="outline-success" htmlFor="MODE_ID_PLACEHOLDER_1" className="btn-sm">
                                                                <Icon name="check"></Icon> yes
                                                            </Button>
                                                            <input
                                                                type="radio"
                                                                className="btn-check"
                                                                value='false'
                                                                name="MODE_NAME_PLACEHOLDER"
                                                                id="MODE_ID_PLACEHOLDER_2"
                                                                autoComplete="off"
                                                                checked={printer === false}
                                                                onChange={() => {
                                                                    setPrinter(false)
                                                                    localStorage.setItem('printer', false)
                                                                }}
                                                            />
                                                            <Button as="label" variant="outline-danger" htmlFor="MODE_ID_PLACEHOLDER_2" className="btn-sm">
                                                                <Icon name="cross"></Icon> No
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>


                                        <Col lg="2">
                                            <div className="dateContainer">
                                                <Icon name="calendar" className="iconMarginRight" style={{ marginRight: '5px' }} /> {/* Adjust based on your Icon component */}
                                                {storedData?.date}
                                            </div>
                                        </Col>
                                        <Col lg="1">
                                            <div className="sessionContainer" >
                                                <Icon name="clock" className="iconMarginRight" style={{ marginRight: '5px' }} />
                                                {storedData?.session}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                {/* Remaining controls can follow the grid layout you provided */}
                                <Col lg='12'>
                                    <Row className="g-gs">
                                        <Col sm="4">
                                            <Row className="g-gs">
                                                <Col lg="4">
                                                    <SearchDownCode
                                                        ref={searchDownRef}
                                                        data={farmerData}
                                                        placeholder="Code"
                                                        name="Code"
                                                        nextInputRef={((milkType.includes('cow') && !milkType.includes('buffalo')) && (milkType.includes('cow') && !milkType.includes('buffalo')))
                                                            ? milkRef : fatRef}
                                                        onValidate={handleFarmerCode}
                                                        onSelect={(selectedItem, type, data, error) => {
                                                            handleInput(selectedItem, type, data, error)
                                                        }}
                                                        value={inputValue}
                                                        func={handleName}
                                                        keyPress={handleKeyPress}
                                                    />
                                                </Col>

                                                <Col sm={inputValue ? "5" : "8"} >
                                                    <Form.Group className="form-group" >
                                                        <Form.Label htmlFor="start" >Farmer Name</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control
                                                                type="text"
                                                                name='farmername'
                                                                value={farmerName}
                                                                autoComplete='off'
                                                                disabled
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </Col>


                                                {(milkType.includes('cow') && milkType.includes('buffalo')) &&
                                                    <Col sm="3" >

                                                        <Form.Group className="form-group" >
                                                            <Form.Label htmlFor="start" >Milktype(C/B)</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control
                                                                    type="text"
                                                                    name='milkType'
                                                                    value={typeValue}
                                                                    autoComplete='off'
                                                                    onChange={setType}
                                                                    ref={milkRef}
                                                                    maxLength={1}
                                                                />
                                                            </div>
                                                        </Form.Group>

                                                    </Col>}

                                                {((milkType[0] === "cow") && !milkType.includes('buffalo')) &&
                                                    <Col sm="3" >
                                                        <Form.Group className="form-group" >
                                                            <Form.Label htmlFor="start" >Milktype</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control
                                                                    type="text"
                                                                    name='milkType'
                                                                    value={dummyCow}
                                                                    autoComplete='off'
                                                                    disabled
                                                                />
                                                            </div>
                                                        </Form.Group>

                                                    </Col>}
                                                {(milkType[0] === "buffalo") &&
                                                    <Col sm="3" >
                                                        <Form.Group className="form-group" >
                                                            <Form.Label htmlFor="start" >Milktype</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control
                                                                    type="text"
                                                                    name='milkType'
                                                                    value={dummyBuffalo}
                                                                    autoComplete='off'
                                                                    disabled
                                                                />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>}
                                            </Row>
                                        </Col>


                                        <Col lg="5">
                                            <Row className="g-gs">
                                                <Col sm="3">
                                                    <Form.Group className="form-group" >
                                                        <Form.Label htmlFor="start" className='mx-4'>FAT%</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control
                                                                type="text"
                                                                name='start'
                                                                data-field="fat"
                                                                value={fat}
                                                                placeholder="Fat"
                                                                onKeyDown={handleKeyDown}
                                                                onKeyUp={(e) => handleKeyUp(e, setFat)}
                                                                onBlur={(e) => handleBlur(e, setFat)}
                                                                onChange={(e) => handleInputChange(e, setFat, setFatError)}
                                                                autoComplete='off'
                                                                ref={fatRef}
                                                                required
                                                                isInvalid={!!fatError} // Use isInvalid prop for bootstrap validation styling
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {fatError}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm="3">
                                                    <Form.Group className="form-group" >
                                                        <Form.Label htmlFor="end" className='mx-4'>SNF%</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control
                                                                type="text"
                                                                name='end'
                                                                data-field="snf"
                                                                autoComplete='off'
                                                                value={snf}
                                                                ref={snfRef}
                                                                onKeyDown={handleKeyDown}
                                                                onKeyUp={(e) => handleKeyUp(e, setSnf)}
                                                                placeholder="Snf"
                                                                required
                                                                onFocus={() => {
                                                                    if (fat === '' || fatError) {
                                                                        // Only focus if fat is not empty and has no error
                                                                        fatRef.current.focus();
                                                                    }
                                                                }}
                                                                onBlur={(e) => handleBlur(e, setSnf)}
                                                                onChange={(e) => handleInputChange(e, setSnf, setSnfError)}
                                                                isInvalid={!!snfError}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {snfError}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg="3">
                                                    <Form.Group className="form-group" >
                                                        <Form.Label htmlFor="start" className='mx-3'>QTY(Lit)</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control
                                                                type="text"
                                                                name='start1'
                                                                value={quantity}
                                                                data-field="quantity"
                                                                ref={quantityRef}
                                                                autoComplete='off'
                                                                placeholder="Lit"
                                                                onKeyDown={handleKeyDown}
                                                                onKeyUp={handleKeyUp}
                                                                required
                                                                onFocus={() => {
                                                                    if (snf === '' || snfError) {
                                                                        // Only focus if fat is not empty and has no error
                                                                        snfRef.current.focus();
                                                                    }
                                                                }}
                                                                onChange={(e) => handleInputChange(e, setQuantity, setQuantityError)}
                                                                onBlur={(e) => handleBlur(e, setQuantity)}
                                                                isInvalid={!!quantityError} // Use isInvalid prop for bootstrap validation styling

                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {quantityError}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg="3">
                                                    <Form.Group className="form-group" >
                                                        <Form.Label htmlFor="start" className='mx-3'>QTY(Kg)</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Form.Control
                                                                type="text"
                                                                name='start1'
                                                                defaultValue={kg}
                                                                autoComplete='off'
                                                                placeholder="Kg"
                                                                disabled
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>


                                        <Col lg="3">
                                            <Row className="g-gs">
                                                <Col lg="6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>CANS</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Tags removeItemButton type="text" placeholder="Cans" value={numericValue}
                                                                onValueChange={(value) => handleNumericValueChange(value)} ref={tagsRef} />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg='3'>
                                                    <Button

                                                        style={{ marginTop: "32px" }}
                                                        variant="primary"
                                                        ref={buttonRef}
                                                        onClick={handleSubmit}
                                                        type="submit"
                                                        disabled={isButtonDisabled} // Ensure that this is set to true when you expect it to be disabled
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault();
                                                                handleSubmit();
                                                            }
                                                        }}
                                                    >

                                                        {isButtonDisabled && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                                                        {!isButtonDisabled && <span className="ms-1">  <Icon name={isEditMode ? "edit" : "plus"} /></span>}

                                                    </Button>
                                                </Col>

                                                <Col lg='3'>
                                                    <Button
                                                        type="submit"
                                                        style={{ marginTop: "32px" }}
                                                        variant="danger"
                                                        onClick={handleRemove}
                                                    >
                                                        <Icon name="cross" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>

                                <Block className="mt-2">
                                    <Card>
                                        {type === "vlcc" && <DataTable
                                            persistTableHead
                                            tableClassName="data-table-head-light table-responsive"
                                            className={"data-table-head-light table-responsive"}
                                            conditionalRowStyles={rowStyles}
                                            data={listData}
                                            onPaginate={handlePaginate} page_value={pagination}
                                            columns={plantColumn(handleDelete, editFunc, selectedId)}

                                        />}

                                    </Card>
                                </Block>

                                <Col lg='12' className="mt-6">

                                    <Button
                                        as="label"
                                        variant="success"
                                        onClick={handleSave}
                                    >
                                        Save
                                    </Button>

                                </Col>

                                <div id="element-to-print">
                                    {show && <MyComponent
                                        printerData={printerData}
                                        storedData={storedData}
                                        farmername={printFrName}
                                        code={code}
                                        ref={printRef}
                                        bill_no={bill_no}
                                    />}
                                </div>

                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row >
        </Layout >
    )
}

export default VlccProcForm