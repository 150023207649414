import React, { useEffect, useState } from 'react'
import { DatePicker, Icon } from '../../../components'
import { Badge, Button, ButtonGroup, Card, Col, Form, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import { formatDate, isEdited } from '../shared/Date';
import { get, postToast } from '../api/Api';
import { useDispatch, useSelector } from 'react-redux';

import { FarmerDetails, ProfileDetails, fetchFarmerList } from '../store/store';
import { createFormattedJSON, getColumnDisplayName } from '../shared/Service';
import Loader from '../shared/Loader';
import ExpandableViewModal from '../modal/ExpandableViewModal';


const CenterPurchase = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [listData, setListData] = useState([])
    const [groupData, setGroupData] = useState([])
    const [filters, setFilters] = useState({});
    const [showFilters, setShowFilters] = useState(false);
    const [sessionDay, setSessionDay] = useState("M");
    const [sessionNight, setSessionNight] = useState("E");
    const [advance, setAdvance] = useState(false)
    const [error, setError] = useState('');
    const [sessionError, setSessionError] = useState('');
    const [allData, setAllData] = useState([])
    const [routeData, setRouteData] = useState([])
    const minDate = new Date();
    const date = minDate.setDate(minDate.getDate());
    const [headerValue, setHeaderValue] = useState(null)
    const [sortData, setSortData] = useState(null)
    const dispatch = useDispatch()
    const { id, type } = useParams()
    const current = new Date();
    const fromDate = new Date(current);
    fromDate.setDate(current.getDate() - 10);
    const [clickData, setClickData] = useState(null)
    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(current);
    const [endDate, setEndDate] = useState(formattedToDate);
    const [selectedDate, setSelectedDate] = useState(formattedFromDate);
    const [showDetailsModal, setShowDetailsModal] = useState(false);

    const [pagination, setPagination] = useState({
        page: 1,
        page_count: 25,
        search: '',
        total_count: null
    })
    const updatePagination = ({ current_page, per_page, total }) => {
        setPagination({
            ...pagination,
            page: current_page,
            page_count: per_page,
            total_count: total
        });
    };



    useEffect(() => {
        const body = {
            page: 1,
            page_count: 1000
        }

        dispatch(fetchFarmerList(body))
    }, [dispatch])


    useEffect(() => {
        if (type === "direct")
            dispatch(FarmerDetails(id))
    }, [dispatch, id])

    const { details, isLoading } = useSelector((state) => state.farmerDetails)
    const directFarmerList = useSelector((state) => state.directFarmerList)
    const [directFarmer, setDirectFarmer] = useState([])

    useEffect(() => {
        // Check if directFarmerList.data is available before filtering
        if (directFarmerList.data) {
            const filteredCollection = directFarmerList.data.filter((el) => el.type === "2");
            setDirectFarmer(filteredCollection);
        }
    }, [directFarmerList.data]);





    const fetchRoute = async () => {
        const url = "/api/route/list";
        try {
            const response = await get(url);
            if (response.status) {
                setRouteData(response.data)

            }
        } catch (error) {
            console.error("Error while fetching data:", error);
        }
    };
    useEffect(() => {
        fetchRoute()
    }, [])

    const toggleFilters = () => {
        setShowFilters(prev => !prev);
        setFilters({});
    };

    const addNavigate = () => {
        // navigate(`/procurement-form/${dateDetails.route_id}/${dateDetails.id}?mode=add&type=direct`)
    }

    const reportNavigate = () => {
        navigate('/report')
    }

    const handleFilterChange = (columnId, value) => {
        setFilters(prev => ({ ...prev, [columnId]: value }));
    };

    useEffect(() => {
        dispatch(ProfileDetails())
    }, [dispatch])

    const filterInputStyle = {
        width: '95%',
        padding: '5px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '11px'
    };



    const rowStyles = [
        {
            when: (row) => isEdited(row.created_at, row.updated_at),
            style: {
                backgroundColor: '#478ffc', // Or any other color of your choice
                color: "#FFFFFF"
            },
        },
    ];


    const fetchGroupList = async (body) => {
        const url = "/api/collection-report/group-collection";

        try {
            const response = await postToast(url, body);
            if (response.status) {
                console.log("Server response:", response.data);
                const data = response.data;
                const params = response.params
                setHeaderValue(response.summary)
                let combinedData
                if (data) {
                    combinedData = createFormattedJSON(data, params)
                }
                setGroupData(combinedData)
                setLoading(false)
            } else {
                setLoading(false)
            }
        } catch (error) {
            console.error("Error while fetching data:", error);
            setLoading(false)
        }
    };

    const buttonStyle = {
        padding: '0.7px 7px ',
        fontSize: '10px',
        lineHeight: '1',
    };
    const centerContentStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    };

    const groupView = (showFilters, handleFilterChange) => {
        const column = [

            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Route name"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('name', e.target.value)}
                    />
                    : "Routename",
                selector: (row) => `${row.code} - ${row.name}`,
                minWidth: "150px",
                reorder: true,
                left: true,
                sortable: true,
            },

            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Bill"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('count', e.target.value)}
                    />
                    : `B.Count`,
                selector: (row) => row.count,
                sortable: true,
                minWidth: "120px",
                reorder: true,
                right: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Avg.Fat"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('avg_fat', e.target.value)}
                    />
                    : "Avg.Fat",
                selector: (row) => row.avg_fat,
                sortable: true,
                minWidth: "120px",
                reorder: true,
                right: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="avg_snf"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('avg_snf', e.target.value)}
                    />
                    : "Avg.Snf",
                selector: (row) => row.avg_snf,
                sortable: true,
                minWidth: "100px",
                reorder: true,
                right: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="tot_qty_kg"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_qty_litre', e.target.value)}
                    />
                    : "Tot.lit",
                selector: (row) => row.tot_qty_litre,
                sortable: true,
                minWidth: "100px",
                reorder: true,
                right: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="tot_qty_kg"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_qty_kg', e.target.value)}
                    />
                    : "tot.kg",
                selector: (row) => row.tot_qty_kg,
                minWidth: "100px",
                reorder: true,
                right: true,
                sortable: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Producer"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_rate', e.target.value)}
                    />
                    : "avg.rate",
                selector: (row) => row.tot_rate,
                minWidth: "100px",
                reorder: true,
                right: true,
                sortable: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Producer"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_amount', e.target.value)}
                    />
                    : "tot.amt",
                selector: (row) => row.tot_amount,
                minWidth: "100px",
                reorder: true,
                right: true,
                sortable: true,
            },
            {
                name: "Action",
                cell: (row) => (
                    <div style={centerContentStyle}>
                        <Button className=" mx-1" style={buttonStyle} onClick={() => {
                            setClickData(row)
                            toggleDetailsModal()
                        }} variant="info"><Icon name="info"></Icon></Button>
                    </div >
                ),
                right: true,
                width: "8%",
            },


        ]
        return column;
    }

    const [group, setGroup] = useState(groupView(showFilters, handleFilterChange));

    useEffect(() => {

        setGroup(groupView(showFilters, handleFilterChange))
    }, [showFilters]);


    const rangeData = {
        from_date: selectedDate,
        from_session: sessionDay,
        to_date: endDate,
        to_session: sessionNight,
        farmer_id: id,

    }



    const handleCheck = () => {
        let isValid = true;

        if (endDate < selectedDate) {
            setError('End date must be equal to or higher than the start date');
            isValid = false;
        } else {
            setError('')
        }

        if (isValid) {
            fetchGroupList(rangeData)
        }
    };

    const handleAdvance = () => {
        setAdvance(!advance)

    }

    const handleSession = (e) => {
        setSessionDay(e.target.value);
        document.getElementById('btncheck1').blur();

    };
    const handleEndSession = (e) => {
        setSessionNight(e.target.value);
        document.getElementById('btncheck1').blur();
        // validateSession(e.target.value)

    };

    const handleEndDate = (value) => {
        setEndDate(value);
        validateDates(value);
    };

    const validateDates = (end) => {
        if (end < selectedDate) {
            setError('End date must be equal to or higher than the start date');
        } else {
            setError('');
        }
    };


    const handlePaginate = (currentPage, itemPerPage, searchValue,) => {

        const body = {
            page: currentPage,
            page_count: itemPerPage,
            search: searchValue,
            from_date: selectedDate,
            from_session: sessionDay,
            to_date: endDate,
            to_session: sessionNight,
            farmer_id: id,
            order_by: getColumnDisplayName(sortData?.column),
            order_type: sortData?.direction
        }
        fetchGroupList(body)
    }


    useEffect(() => {
        const empty = {
            farmer_id: id,
            from_date: formattedFromDate,
            to_date: formattedToDate,
            from_session: "M",
            to_session: "M",


        }
        fetchGroupList(empty)

    }, [id])

    const sort = (column, direction) => {
        const data = {
            column: column.name, direction
        }

        setSortData(data)
        handlePaginate("", pagination.page_count, pagination.search)
    }
    const toggleDetailsModal = () => {
        setShowDetailsModal(!showDetailsModal);
    };


    return (
        <Card>
            <Loader loading={loading} />
            <Card.Body>
                <Badge bg="primary" className="position-absolute top-0 start-0 " style={{ zIndex: 1, fontSize: '16px', width: '300px' }}>
                    {details?.code} - {details?.name}
                </Badge>


                {showDetailsModal && (
                    <ExpandableViewModal
                        show={showDetailsModal}
                        data={clickData}
                        onClose={toggleDetailsModal}

                    />
                )}

                {(advance) &&
                    <Card className="card-gutter-md mb-2 mt-4">
                        <Card.Body>
                            <Col xxl="12">
                                <Row className="g-gs">
                                    <Col lg="3">
                                        <Form.Label htmlFor="start">Start date
                                            <span className="me-2">
                                                <i className="fa fa-exclamation-triangle text-danger">
                                                    *
                                                </i>
                                            </span>
                                        </Form.Label>
                                        <Form.Group className="form-group" controlId="validationCustom01">
                                            <div className="form-control-wrap">
                                                <DatePicker onDateChange={(value) => setSelectedDate(value)} value={selectedDate} maxDate={date} />
                                            </div>
                                        </Form.Group>
                                    </Col>

                                    <Col lg="2">
                                        <Form.Group className="form-group" >
                                            <Form.Label htmlFor="start">Start Session
                                                <span className="me-2">
                                                    <i className="fa fa-exclamation-triangle text-danger">
                                                        *
                                                    </i>
                                                </span>
                                            </Form.Label>
                                            <div className="form-control-wrap">
                                                <ButtonGroup aria-label="Basic checkbox toggle button group">
                                                    <input type="radio" className="btn-check" name='session' id="btncheck1" autoComplete="off" value="M" checked={sessionDay === "M"}
                                                        onChange={handleSession} />
                                                    <Button as="label" variant="outline-primary" htmlFor="btncheck1"><Icon name="sun-fill"></Icon></Button>

                                                    <input type="radio" className="btn-check" name='session' id="btncheck2" autoComplete="off" value="E" checked={sessionDay === "E"}
                                                        onChange={handleSession} />
                                                    <Button as="label" variant="outline-primary" htmlFor="btncheck2"><Icon name="moon-fill"></Icon></Button>
                                                </ButtonGroup>
                                            </div>
                                        </Form.Group>
                                    </Col>


                                    <Col lg="3">
                                        <Form.Label htmlFor="start">End date
                                            <span className="me-2">
                                                <i className="fa fa-exclamation-triangle text-danger">
                                                    *
                                                </i>
                                            </span></Form.Label>
                                        <Form.Group className="form-group" controlId="validationCustom01">

                                            <div className="form-control-wrap">
                                                <DatePicker onDateChange={(value) => handleEndDate(value)} value={endDate} maxDate={date} />
                                            </div>
                                            {error && <div style={{ color: 'red' }}>{error}</div>}
                                        </Form.Group>
                                    </Col>
                                    <Col lg="2">

                                        <Form.Group className="form-group" >
                                            <Form.Label htmlFor="start">End Session
                                                <span className="me-2">
                                                    <i className="fa fa-exclamation-triangle text-danger">
                                                        *
                                                    </i>
                                                </span>
                                            </Form.Label>
                                            <div className="form-control-wrap">
                                                <ButtonGroup aria-label="Basic checkbox toggle button group">
                                                    <input type="radio" className="btn-check" name='sessionEnd' id="btncheckEnd1" autoComplete="off" value="M" checked={sessionNight === "M"}
                                                        onChange={handleEndSession} />
                                                    <Button as="label" variant="outline-primary" htmlFor="btncheckEnd1"><Icon name="sun-fill"></Icon></Button>

                                                    <input type="radio" className="btn-check" name='sessionEnd' id="btncheckEnd2" autoComplete="off" value="E" checked={sessionNight === "E"}
                                                        onChange={handleEndSession} />
                                                    <Button as="label" variant="outline-primary" htmlFor="btncheckEnd2"><Icon name="moon-fill"></Icon></Button>
                                                </ButtonGroup>
                                            </div>
                                            {sessionError && <div style={{ color: 'red' }}>{sessionError}</div>}
                                        </Form.Group>

                                    </Col>


                                    <Col lg="2">
                                        <Col xxl="12">
                                            <Row className="g-gs">

                                                <Col>
                                                    <Button
                                                        as="label"
                                                        style={{ marginTop: "32px" }}
                                                        variant="primary"
                                                        onClick={handleCheck}
                                                    >
                                                        Get Data
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Card.Body>
                    </Card>}

                <div >
                    <Col lg="12">
                        <Button
                            className='d-flex float-end'
                            as="label"
                            variant="primary"
                            onClick={handleAdvance}
                        >
                            <Icon name='filter' />

                        </Button>
                        <div>
                            <DataTable tableClassName="data-table-head-light table-responsive" conditionalRowStyles={rowStyles} data={groupData} actions print
                                columns={group} showFilters={showFilters} toggleFilters={toggleFilters} list
                                navigate={addNavigate} reportPage={reportNavigate} pdf headerData={allData}
                                tableHead headerValue={headerValue}
                            />
                        </div>
                    </Col>
                </div>
            </Card.Body>
        </Card>
    )
}

export default CenterPurchase