import Layout from "../../../../layout/default";
import Block from "../../../../components/Block/Block";
import { Card, Col, Row, Button, Form, } from "react-bootstrap";
import { Icon, Media } from "../../../../components";
import { useState } from "react";

import MenuView from "../MenuView";
import { useNavigate } from "react-router-dom";


export const UserRole = () => {

  const navigate = useNavigate()

  return (
    <Layout title="Form controls" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h3">Roles Allocation</Block.Title>

          </Block.HeadContent>

        </Block.HeadBetween>
      </Block.Head>

      <Row className="g-gs">
        <Col sm="3" xxl="3">
          <Card className="h-100" onClick={() => navigate(`/add-role/${'ADMIN'}`)}>
            <Card.Body>
              <div className="card-title-group align-items-start" >
                <div className="card-title">
                  <h4 className="title">Admin</h4>
                </div>
                <Media size="sm" shape="circle" variant="primary-soft">
                  <Icon name="user-alt-fill"></Icon>
                </Media>
              </div>
              <div className="mt-2 mb-4">
                <p>Total users: 10</p>

              </div>

            </Card.Body>
          </Card>
        </Col>
        <Col sm="3" xxl="3">
          <Card className="h-100" onClick={() => navigate(`/add-role/${'EMPLOYEE'}`)}>
            <Card.Body>
              <div className="card-title-group align-items-start" >
                <div className="card-title">
                  <h4 className="title">Employee</h4>
                </div>
                <Media size="sm" shape="circle" variant="primary-soft">
                  <Icon name="user-alt-fill"></Icon>
                </Media>
              </div>
              <div className="mt-2 mb-4">
                <p>Total users: 10</p>
              </div>

            </Card.Body>
          </Card>
        </Col>
        <Col sm="3" xxl="3">
          <Card className="h-100" onClick={() => navigate(`/add-role/${'STAFF'}`)}>
            <Card.Body>
              <div className="card-title-group align-items-start" >
                <div className="card-title">
                  <h4 className="title">Staff</h4>
                </div>
                <Media size="sm" shape="circle" variant="primary-soft">
                  <Icon name="user-alt-fill"></Icon>
                </Media>
              </div>
              <div className="mt-2 mb-4">
                <p>Total users: 10</p>

              </div>

            </Card.Body>
          </Card>
        </Col>
        <Col sm="3" xxl="3">
          <Card className="h-100" onClick={() => navigate(`/add-role/${'SUPER ADMIN'}`)}  >
            <Card.Body>
              <div className="card-title-group align-items-start" >
                <div className="card-title">
                  <h4 className="title">Super Admin</h4>
                </div>
                <Media size="sm" shape="circle" variant="primary-soft">
                  <Icon name="user-alt-fill"></Icon>
                </Media>
              </div>
              <div className="mt-2 mb-4">
                <p>Total users: 10</p>
              </div>

            </Card.Body>
          </Card>
        </Col>

      </Row>
      <Row className="g-gs mt-1">
        <Col xxl="12">
          <div className="gap gy-4">
            <div className="gap-col">
              <Card className="card-gutter-md">
                <Card.Body>
                  <Row className="g-gs">
                    <Col xxl="12">

                      <Row className="g-gs">
                        <Col lg="12">
                          <Button variant="success" className="mb-2" onClick={() => navigate(`/add-role/${'role'}`)}><Icon className='mx-1' name="plus" /> New Role  </Button>
                          <MenuView />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </div>
        </Col>
      </Row>

    </Layout>
  );
}


