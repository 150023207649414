import React, { useEffect, useRef, useState } from 'react'
import { Block, DatePicker, Icon } from '../../../components'
import { Badge, Button, ButtonGroup, Card, Col, Form, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import { dateReverseH, dateSplit, extractDateFromTimestamp, formatDate, isEdited } from '../shared/Date';
import { get, postToast } from '../api/Api';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { SearchDown } from '../shared/dropDown/SearchDown';
import { FarmerDetails, ProfileDetails, fetchFarmerList } from '../store/store';
import { getColumnDisplayName } from '../shared/Service';
import Loader from '../shared/Loader';

const Purchase = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [listData, setListData] = useState([])
    const [groupData, setGroupData] = useState([])
    const [filters, setFilters] = useState({});
    const [data, setData] = useState([])
    const [showFilters, setShowFilters] = useState(false);
    const [sessionDay, setSessionDay] = useState("M");
    const [sessionNight, setSessionNight] = useState("E");
    const [advance, setAdvance] = useState(false)

    const [error, setError] = useState('');
    const [sessionError, setSessionError] = useState('');
    const [allData, setAllData] = useState([])
    const [routeData, setRouteData] = useState([])
    const [show, setShow] = useState(false)
    const printRef = useRef();
    const minDate = new Date();
    const date = minDate.setDate(minDate.getDate());
    const [printData, setPrintData] = useState([])
    const [headerValue, setHeaderValue] = useState(null)
    const [sortData, setSortData] = useState(null)
    const dispatch = useDispatch()
    const { id, type } = useParams()
    const current = new Date();
    const fromDate = new Date(current);
    fromDate.setDate(current.getDate() - 10);

    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(current);
    const [endDate, setEndDate] = useState(formattedToDate);
    const [selectedDate, setSelectedDate] = useState(formattedFromDate);


    const [paymentData, setPaymentData] = useState({
        mode_payment: [],
        payment_cycle: [],
    })

    const [pagination, setPagination] = useState({
        page: 1,
        page_count: 25,
        search: '',
        total_count: null
    })
    const updatePagination = ({ current_page, per_page, total }) => {
        setPagination({
            ...pagination,
            page: current_page,
            page_count: per_page,
            total_count: total
        });
    };

    const [detail, setDetail] = useState({
        farmer_id: "",
        route_id: '',
        start_code: "",
        end_code: '',

    })

    useEffect(() => {
        const body = {
            page: 1,
            page_count: 1000
        }

        dispatch(fetchFarmerList(body))
    }, [dispatch])


    useEffect(() => {
        if (type === "direct")
            dispatch(FarmerDetails(id))
    }, [dispatch, id])

    const { details, isLoading } = useSelector((state) => state.farmerDetails)
    const directFarmerList = useSelector((state) => state.directFarmerList)
    const [directFarmer, setDirectFarmer] = useState([])

    useEffect(() => {
        // Check if directFarmerList.data is available before filtering
        if (directFarmerList.data) {
            const filteredCollection = directFarmerList.data.filter((el) => el.type === "2");
            setDirectFarmer(filteredCollection);
        }
    }, [directFarmerList.data]);


    const handlePrint = useReactToPrint({

        content: () => printRef.current,

        onBeforePrint: () => {
            setShow(true);
        },

        onAfterPrint: () => {
            setShow(false);
        },

    });

    const fetchPrintData = async (body) => {
        const url = "/api/collection-report/generate-payment";

        try {
            const response = await postToast(url, body);
            if (response.status) {

                setPrintData(response.data.data)
                const pageValue = response.data

                updatePagination(pageValue);
                setAllData(response.params)
                setLoading(false)
            } else {
                setLoading(false)
            }

        } catch (error) {
            console.error("Error while fetching data:", error);
            setLoading(false)
        }
    };


    const handleFunc = async () => {

        const body = {
            page: 1,
            page_count: 1000,
            search: "",
            from_date: selectedDate,
            from_session: sessionDay,
            to_date: endDate,
            to_session: sessionNight,
            route_id: detail.route_id,
            mode_of_payment: paymentData.mode_payment,
            payment_cycle: paymentData.payment_cycle,
            farmer_id: detail.farmer_id,
            farmer_start_code: detail.start_code,
            farmer_end_code: detail.end_code,
            method: "print"
        }

        await fetchPrintData(body);
        setShow(true)

        setTimeout(() => {
            handlePrint()
        }, 500);

    }


    const fetchRoute = async () => {
        const url = "/api/route/list";
        try {
            const response = await get(url);
            if (response.status) {
                setRouteData(response.data)

            }
        } catch (error) {
            console.error("Error while fetching data:", error);
        }
    };
    useEffect(() => {
        fetchRoute()
    }, [])

    const toggleFilters = () => {
        setShowFilters(prev => !prev);
        setFilters({});
    };

    const addNavigate = () => {
        // navigate(`/procurement-form/${dateDetails.route_id}/${dateDetails.id}?mode=add&type=direct`)
    }

    const reportNavigate = () => {
        navigate('/report')
    }

    const handleFilterChange = (columnId, value) => {
        setFilters(prev => ({ ...prev, [columnId]: value }));
    };

    useEffect(() => {
        dispatch(ProfileDetails())
    }, [dispatch])

    const role = useSelector((state) => state.profile)
    const filterInputStyle = {
        width: '95%',
        padding: '5px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '11px'
    };

    const filteredData = listData?.filter(row =>
        Object.entries(filters).every(
            ([key, value]) =>
                !value ||
                String(row[key]).toLowerCase().includes(String(value).toLowerCase())
        )
    );

    const rowStyles = [
        {
            when: (row) => isEdited(row.created_at, row.updated_at),
            style: {
                backgroundColor: '#478ffc', // Or any other color of your choice
                color: "#FFFFFF"
            },
        },
    ];


    const entryList = (showFilters, handleFilterChange) => {
        const column = [
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Route name"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('route_name', e.target.value)}
                    />
                    : "Routename",
                selector: (row) => `${row.code} - ${row.route_name}`,
                sortable: !showFilters,
                minWidth: "150px",
                reorder: true,
                wrap: true
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Date"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('bill_date', e.target.value)}
                    />
                    : "date",
                selector: (row) => dateReverseH(dateSplit(row.bill_date)),
                sortable: !showFilters,
                minWidth: "108px",
                center: true,
                reorder: true,

            },

            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="m/e"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('session', e.target.value)}
                    />
                    : "m/e",
                selector: (row) => row.session,
                sortable: !showFilters,
                minWidth: "40px",
                center: true,
                reorder: true

            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Bill"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('bill_no', e.target.value)}
                    />
                    : `Bill`,
                selector: (row) => row.bill_no,
                sortable: !showFilters,
                minWidth: "40px",
                right: true,
                reorder: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="FAT"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_fat', e.target.value)}
                    />
                    : "fat",
                cell: (row) => {
                    const fatValue = parseFloat(row.tot_fat);

                    // Check if the value is outside the range (3.8 to 4.8)
                    const isOutOfRange = fatValue < 3.8 || fatValue > 4.8;

                    // Apply red color if the value is below the range, blue if above the range, otherwise inherit
                    const cellStyle = {
                        color: isOutOfRange ? (fatValue < 3.8 ? "red" : "blue") : 'inherit',
                    };

                    return <div style={cellStyle}>{row.tot_fat ? parseFloat(row.tot_fat).toFixed(2) : ''}</div>;
                },
                sortable: !showFilters,
                right: true,
                minWidth: "60px",
                reorder: true,

            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="SNF"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_snf', e.target.value)}
                    />
                    : "snf",
                cell: (row) => {
                    const snfValue = parseFloat(row.tot_snf);
                    // Check if the value is outside the range (3.8 to 4.8)
                    const isOutOfRange = snfValue < 7.7 || snfValue > 8.5;
                    // Apply red color if the value is below the range, blue if above the range, otherwise inherit
                    const cellStyle = {
                        color: isOutOfRange ? (snfValue < 7.7 ? "red" : "blue") : 'inherit',
                    };
                    return <div style={cellStyle}>{row.tot_snf ? parseFloat(row.tot_snf).toFixed(2) : ''}</div>;
                },
                sortable: !showFilters,
                right: true,
                minWidth: "75px",
                reorder: true,

            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Kg"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_qty_kg', e.target.value)}
                    />
                    : "Kg",
                selector: (row) => (row.tot_qty_kg).toFixed(2),
                sortable: !showFilters,
                right: true,
                minWidth: "80px",
                reorder: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Lit"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_qty_litre', e.target.value)}
                    />
                    : "Litre",
                selector: (row) => (row.tot_qty_litre).toFixed(2),
                sortable: !showFilters,
                right: true,
                minWidth: "80px",
                omit: role?.data?.user_role !== "admin",
                reorder: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Rate"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_rate', e.target.value)}
                    />
                    : "rate",
                selector: (row) => (row.tot_rate).toFixed(2),
                sortable: !showFilters,
                right: true,
                minWidth: "80px",
                reorder: true,

            },
        ]

        const hasAtLeastOneIncentive = data?.some((row) => (row.incentive_amount).toFixed(2) !== 0);

        if (hasAtLeastOneIncentive) {
            column.push({
                name: showFilters
                    ? (
                        <input
                            type="text"
                            placeholder="Incentive"
                            style={filterInputStyle}
                            onChange={(e) => handleFilterChange('incentive_amount', e.target.value)}
                        />
                    )
                    : "incentive",
                selector: (row) => (row.incentive_amount).toFixed(2),
                sortable: !showFilters,
                center: true,
                minWidth: "65px",
                reorder: true,
            });
        }


        column.push({
            name: showFilters
                ? <input
                    type="text"
                    placeholder="Amount"
                    style={filterInputStyle}
                    onChange={(e) => handleFilterChange('tot_amount', e.target.value)}
                />
                : "Amount",
            selector: (row) => (
                <div style={{ textAlign: 'right !important' }}>
                    {(row.tot_amount.toFixed(2)).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </div>
            ),

            sortable: !showFilters,
            right: true,
            minWidth: "105px",
            reorder: true,
        });

        return column;
    }

    const [columns, setColumns] = useState(entryList(showFilters, handleFilterChange));

    const groupView = (showFilters, handleFilterChange) => {
        const column = [

            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Route name"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('name', e.target.value)}
                    />
                    : "Routename",
                selector: (row) => `${row.code} - ${row.name}`,
                minWidth: "350px",
                reorder: true,
                left: true,
                sortable: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Bill"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('count', e.target.value)}
                    />
                    : `B.Count`,
                selector: (row) => row.count,
                sortable: true,
                minWidth: "120px",
                reorder: true,
                right: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Avg.Fat"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('avg_fat', e.target.value)}
                    />
                    : "Avg.Fat",
                selector: (row) => row.avg_fat,
                sortable: true,
                minWidth: "120px",
                reorder: true,
                right: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="avg_snf"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('avg_snf', e.target.value)}
                    />
                    : "Avg.Snf",
                selector: (row) => row.avg_snf,
                sortable: true,
                minWidth: "100px",
                reorder: true,
                right: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="tot_qty_kg"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_qty_litre', e.target.value)}
                    />
                    : "Tot.lit",
                selector: (row) => row.tot_qty_litre,
                sortable: true,
                minWidth: "100px",
                reorder: true,
                right: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="tot_qty_kg"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_qty_kg', e.target.value)}
                    />
                    : "tot.kg",
                selector: (row) => row.tot_qty_kg,
                minWidth: "100px",
                reorder: true,
                right: true,
                sortable: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Producer"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_rate', e.target.value)}
                    />
                    : "avg.rate",
                selector: (row) => row.tot_rate,
                minWidth: "100px",
                reorder: true,
                right: true,
                sortable: true,
            },

            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Producer"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('tot_amount', e.target.value)}
                    />
                    : "tot.amt",
                selector: (row) => row.tot_amount,
                minWidth: "100px",
                reorder: true,
                right: true,
                sortable: true,
            },
        ]
        return column;
    }

    const [group, setGroup] = useState(groupView(showFilters, handleFilterChange));

    useEffect(() => {
        setColumns(entryList(showFilters, handleFilterChange));
        setGroup(groupView(showFilters, handleFilterChange))
    }, [showFilters]);


    const rangeData = {
        from_date: selectedDate,
        from_session: sessionDay,
        to_date: endDate,
        to_session: sessionNight,

        farmer_id: id,

    }


    const fetchData = async (body) => {
        const url = "/api/collection-report/generate-payment";
        setLoading(true)
        try {
            const response = await postToast(url, body);
            if (response.status) {
                setListData(response.data.data)
                setPrintData(response.data.data)
                setHeaderValue(response.summary)
                const pageValue = response.data
                updatePagination(pageValue);
                setAllData(response.params)
                setLoading(false)
            } else {
                setLoading(false)
            }
        } catch (error) {
            console.error("Error while fetching data:", error);
            setLoading(false)
        }
    };

    // Overall data get from generate-payment API
    const fetchList = async (body) => {
        const url = "/api/collection-report/generate-payment";
        setLoading(true)
        try {
            const response = await postToast(url, body);
            if (response.status) {

                setListData(response.data.data)
                setHeaderValue(response.summary)
                const pageValue = response.data
                updatePagination(pageValue);
                setAllData(response)
                setLoading(false)
            } else {
                setLoading(false)
            }
        } catch (error) {
            console.error("Error while fetching data:", error);
            setLoading(false)
        }
    };

    const handleCheck = () => {
        let isValid = true;

        if (endDate < selectedDate) {
            setError('End date must be equal to or higher than the start date');
            isValid = false;
        } else {
            setError('')
        }


        if (isValid) {
            fetchData(rangeData)
            // fetchGroupList(rangeData)
        }
    };

    const handleAdvance = () => {
        setAdvance(!advance)

    }

    const handleSession = (e) => {

        setSessionDay(e.target.value);
        document.getElementById('btncheck1').blur();

    };
    const handleEndSession = (e) => {
        setSessionNight(e.target.value);
        document.getElementById('btncheck1').blur();
        // validateSession(e.target.value)

    };

    const handleEndDate = (value) => {
        setEndDate(value);
        validateDates(value);
    };

    const validateDates = (end) => {
        if (end < selectedDate) {
            setError('End date must be equal to or higher than the start date');
        } else {
            setError('');
        }
    };


    const handlePaginate = (currentPage, itemPerPage, searchValue,) => {
        const body = {
            page: currentPage,
            page_count: itemPerPage,
            search: searchValue,
            from_date: selectedDate,
            from_session: sessionDay,
            to_date: endDate,
            to_session: sessionNight,
            farmer_id: id,
            order_by: getColumnDisplayName(sortData?.column),
            order_type: sortData?.direction
        }
        fetchData(body)
    }


    useEffect(() => {
        const empty = {
            farmer_id: id,
            from_date: formattedFromDate,
            to_date: formattedToDate,
            from_session: "M",
            to_session: "M",


        }
        fetchList(empty)

    }, [id])

    const sort = (column, direction) => {
        const data = {
            column: column.name, direction
        }

        setSortData(data)
        handlePaginate("", pagination.page_count, pagination.search)
    }

    return (
        <Card>
            <Loader loading={loading} />
            <Card.Body>
                <Badge bg="primary" className="position-absolute top-0 start-0 " style={{ zIndex: 1, fontSize: '16px', width: '300px' }}>
                    {details?.code} - {details?.name}
                </Badge>
                {(advance) &&
                    <Card className="card-gutter-md mb-2 mt-4">
                        <Card.Body>
                            <Col xxl="12">
                                <Row className="g-gs">
                                    <Col lg="3">
                                        <Form.Label htmlFor="start">Start date
                                            <span className="me-2">
                                                <i className="fa fa-exclamation-triangle text-danger">
                                                    *
                                                </i>
                                            </span>
                                        </Form.Label>
                                        <Form.Group className="form-group" controlId="validationCustom01">
                                            <div className="form-control-wrap">
                                                <DatePicker onDateChange={(value) => setSelectedDate(value)} value={selectedDate} maxDate={date} />
                                            </div>
                                        </Form.Group>
                                    </Col>

                                    <Col lg="2">
                                        <Form.Group className="form-group" >
                                            <Form.Label htmlFor="start">Start Session
                                                <span className="me-2">
                                                    <i className="fa fa-exclamation-triangle text-danger">
                                                        *
                                                    </i>
                                                </span>
                                            </Form.Label>
                                            <div className="form-control-wrap">
                                                <ButtonGroup aria-label="Basic checkbox toggle button group">
                                                    <input type="radio" className="btn-check" name='session' id="btncheck1" autoComplete="off" value="M" checked={sessionDay === "M"}
                                                        onChange={handleSession} />
                                                    <Button as="label" variant="outline-primary" htmlFor="btncheck1"><Icon name="sun-fill"></Icon></Button>

                                                    <input type="radio" className="btn-check" name='session' id="btncheck2" autoComplete="off" value="E" checked={sessionDay === "E"}
                                                        onChange={handleSession} />
                                                    <Button as="label" variant="outline-primary" htmlFor="btncheck2"><Icon name="moon-fill"></Icon></Button>
                                                </ButtonGroup>
                                            </div>
                                        </Form.Group>
                                    </Col>


                                    <Col lg="3">
                                        <Form.Label htmlFor="start">End date
                                            <span className="me-2">
                                                <i className="fa fa-exclamation-triangle text-danger">
                                                    *
                                                </i>
                                            </span></Form.Label>
                                        <Form.Group className="form-group" controlId="validationCustom01">

                                            <div className="form-control-wrap">
                                                <DatePicker onDateChange={(value) => handleEndDate(value)} value={endDate} maxDate={date} />
                                            </div>
                                            {error && <div style={{ color: 'red' }}>{error}</div>}
                                        </Form.Group>
                                    </Col>
                                    <Col lg="2">
                                        <Form.Group className="form-group" >
                                            <Form.Label htmlFor="start">End Session
                                                <span className="me-2">
                                                    <i className="fa fa-exclamation-triangle text-danger">
                                                        *
                                                    </i>
                                                </span>
                                            </Form.Label>
                                            <div className="form-control-wrap">
                                                <ButtonGroup aria-label="Basic checkbox toggle button group">
                                                    <input type="radio" className="btn-check" name='sessionEnd' id="btncheckEnd1" autoComplete="off" value="M" checked={sessionNight === "M"}
                                                        onChange={handleEndSession} />
                                                    <Button as="label" variant="outline-primary" htmlFor="btncheckEnd1"><Icon name="sun-fill"></Icon></Button>

                                                    <input type="radio" className="btn-check" name='sessionEnd' id="btncheckEnd2" autoComplete="off" value="E" checked={sessionNight === "E"}
                                                        onChange={handleEndSession} />
                                                    <Button as="label" variant="outline-primary" htmlFor="btncheckEnd2"><Icon name="moon-fill"></Icon></Button>
                                                </ButtonGroup>
                                            </div>
                                            {sessionError && <div style={{ color: 'red' }}>{sessionError}</div>}
                                        </Form.Group>

                                    </Col>
                                    <Col lg="2">
                                        <Col xxl="12">
                                            <Row className="g-gs">

                                                <Col>
                                                    <Button
                                                        as="label"
                                                        style={{ marginTop: "32px" }}
                                                        variant="primary"
                                                        onClick={handleCheck}
                                                    >
                                                        Get Data
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Card.Body>
                    </Card>}

                <div >
                    <Col lg="12">
                        <Button
                            className='d-flex float-end'
                            as="label"
                            variant="primary"
                            onClick={handleAdvance}
                        >
                            <Icon name='filter' />

                        </Button>
                        <div>
                            <DataTable tableClassName="data-table-head-light table-responsive" conditionalRowStyles={rowStyles} data={filteredData} actions
                                columns={columns} showFilters={showFilters} toggleFilters={toggleFilters} printFunc={handleFunc} list
                                navigate={addNavigate} reportPage={reportNavigate} headerData={allData} sort={sort}
                                tableHead onPaginate={handlePaginate} page_value={pagination} headerValue={headerValue}
                            />
                        </div>
                    </Col>
                </div>
            </Card.Body>
        </Card>
    )
}

export default Purchase