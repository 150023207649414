import React from 'react'
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';

export const ProtectedRoute = ({ children }) => {
    let { isLoggedIn } = useAuth();
    const token = localStorage.getItem('token')

    return token ? children : <Navigate to="/auth-login" replace />;
}


