import React from 'react';
import { Modal, Button, Col } from 'react-bootstrap';

function BankDetailsModal({
    show,
    bankDetails,
    onClose,
    closeLabel = "Close",
}) {

    const columns = [

        {
            name: "Acc Type",
            selector: row => row.bank_info.AccType,
            center: true,
            width: '12%',
        },
        {
            name: "Bank Name",
            selector: row => row.bank_info.BankName,
            center: true,
            width: '17%',
        },
        {
            name: "Branch Name",
            selector: row => row.bank_info.BranchName,
            center: true,
            width: '17%',
        },
        {
            name: "Account Number",
            selector: row => row.bank_info.AccNo,
            center: true,
            width: '22%',
        },
        {
            name: "Holder Name",
            selector: row => row.bank_info.HolderName,
            center: true,
            width: '17%',
        },
        {
            name: "IFSC Code",
            selector: row => row.bank_info.ifsc,
            center: true,
            width: '17%',
        },

    ];

    const upiColumn = [
        {
            name: "upi",
            selector: row => row.bank_info.upi,
            center: true,

        },
    ]

    console.log(bankDetails, "bankDetails==>");


    const findData = bankDetails[0].mode_of_payment

    const bankInfo = bankDetails[0].bank_info;

    console.log(bankDetails, "bankDetails");

    return (
        <Modal show={show} onHide={onClose} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>Bank Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    {findData === 1 && <Col xl="12">
                        <p><strong>Holder Name:</strong> {bankInfo.HolderName ? bankInfo.HolderName : bankInfo.name}</p>
                        <p><strong>Account Number:</strong> {bankInfo.AccNo ? bankInfo.AccNo : bankInfo.acc_no ? bankInfo.acc_no : ""}</p>
                        <p><strong>Bank Name:</strong> {bankInfo.BankName ? bankInfo.BankName : bankInfo.bank_name ? bankInfo.bank_name : ""}</p>
                        <p><strong>Branch Name:</strong> {bankInfo.BranchName ? bankInfo.BranchName : bankInfo.branch_name ? bankInfo.branch_name : ""}</p>
                        <p><strong>IFSC Code:</strong> {bankInfo.ifsc ? bankInfo.ifsc : bankInfo.ifsc ? bankInfo.ifsc : ""}</p>
                    </Col>}
                    {findData === 2 && <Col xl="12">
                        <p><strong>UPI :</strong> {bankInfo.upi}</p>
                    </Col>}

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>{closeLabel}</Button>
            </Modal.Footer>
        </Modal >
    );
}

export default BankDetailsModal;
