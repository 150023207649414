
import { saveAs } from 'file-saver';
import { emptyDate, extractDateFromTimestamp, extractFileDateTime } from './Date';

let isHeaderPrinted = false;


const formatDataEntry = (entry, totalCount, filterValue, finalCount) => {

    console.log("entry", filterValue);
    // Extract data from entry
    const { farmer_code, farmer_name } = entry;
    const formattedDate = extractFileDateTime(new Date()); // date MM/dd/YYYY
    const bankDetails = JSON.parse(entry?.mode_payment_info)?.[0]?.bank_info;
    console.log("bankDetails", bankDetails,);

    const routeData = filterValue.type !== "VLCC" ? filterValue.route ? `R${filterValue?.route?.code}` : "" : filterValue.center_details ? filterValue.center_details.code : ""

    // 

    console.log("routeData", routeData)

    // Construct formatted string
    let formattedString = '';

    // Print header line only once
    if (!isHeaderPrinted) {
        formattedString += `FHR|0011|777705252999|INR|${(totalCount?.tot_amount).toFixed(2)}|${finalCount}|${formattedDate}|ADHOC^ \n`;
        isHeaderPrinted = true;
    }



    // name = 32 char only space allowed special character not allowed
    // remark-1 => 21 char 
    // remark-2 => 30 char
    // IFSC code => 11 char
    // [NFT,RTG,IFC] => Payment mode
    // name 32 char only space allowed special character not allowed

    // const cleanedName = farmer_name.replace(/[^\w\s]/gi, ' ').replace(/\s+/g, ' ');
    const cleanedName = filterValue?.type === "VLCC" ? bankDetails?.[0]?.name.replace(/[^\w\s]/gi, ' ').replace(/\s+/g, ' ') : bankDetails?.HolderName.replace(/[^\w\s]/gi, ' ').replace(/\s+/g, ' ');

    formattedString += `APO|NFT|${(entry?.tot_amount).toFixed(2)}|INR|777705252999|0011|${filterValue?.type === "VLCC" ? bankDetails?.[0]?.ifsc : bankDetails?.ifsc}|${filterValue?.type === "VLCC" ? bankDetails?.[0]?.acc_no : bankDetails?.AccNo}|0011|${filterValue?.type === "VLCC" ? `${filterValue?.center_details?.code} ${farmer_code} ${cleanedName}` : `${farmer_code} ${cleanedName}`}|${emptyDate(filterValue?.from_date)}${filterValue?.from_session}to${emptyDate(filterValue?.to_date)}${filterValue?.to_session} ${routeData}|${emptyDate(filterValue?.from_date)}${filterValue?.from_session}to${emptyDate(filterValue?.to_date)}${filterValue?.to_session} ${routeData}^\n`;

    return formattedString;
};

export const createTextFile = (dataEntries, filterValue, headerValue) => {
    // Reset header print status before processing data entries
    isHeaderPrinted = false;

    console.log("headerValue", headerValue)

    // Create an object to store combined entries based on code and name
    const combinedEntries = {};

    // Iterate through data entries to combine entries with the same code and name
    dataEntries.forEach(entry => {
        const key = `${entry.farmer_code}_${entry.farmer_name}`;
        if (!combinedEntries[key]) {
            combinedEntries[key] = {
                farmer_code: entry.farmer_code,
                farmer_name: entry.farmer_name,
                tot_amount: 0,
                mode_payment_info: entry.mode_payment_info,
                count: 0
            };
        }
        combinedEntries[key].tot_amount += entry.tot_amount;
    });

    // Get the final count of data entries
    const finalCount = Object.keys(combinedEntries).length;

    // Convert combined entries object to formatted strings
    const formattedEntries = Object.values(combinedEntries).map(entry => {
        return formatDataEntry(entry, headerValue, filterValue, finalCount);
    });

    // // Convert combined entries object to formatted strings
    // const formattedEntries = Object.values(combinedEntries).map(entry => {
    //     return formatDataEntry(entry, headerValue, filterValue);
    // });

    // Combine formatted strings into a single string
    const textData = formattedEntries.join('');

    // Create Blob
    const blob = new Blob([textData], { type: 'text/plain;charset=utf-8' });

    let fileName
    if (filterValue.type !== "VLCC") {
        fileName = filterValue?.route ? `${filterValue?.route.code}_${emptyDate(filterValue?.from_date)}_${filterValue?.from_session}_${emptyDate(filterValue?.to_date)}_${filterValue?.to_session}_${emptyDate(extractDateFromTimestamp(new Date()))}` : "ALL";
    } else if (filterValue.type === "VLCC") {
        fileName = filterValue?.center_details ? `${filterValue?.center_details.code}_${emptyDate(filterValue?.from_date)}_${filterValue?.from_session}_${emptyDate(filterValue?.to_date)}_${filterValue?.to_session}_${emptyDate(extractDateFromTimestamp(new Date()))}` : "ALL";
    }
    // Save file
    saveAs(blob, `${fileName}.txt`);

    // route_name date and time => fileName
};

export const createVlccTextFile = (dataEntries, filterValue, headerValue) => {
    // Reset header print status before processing data entries
    isHeaderPrinted = false;

    console.log("headerValue", headerValue)

    // Create an object to store combined entries based on code and name
    const combinedEntries = {};

    // Iterate through data entries to combine entries with the same code and name
    dataEntries.forEach(entry => {
        const key = `${entry.farmer_code}_${entry.farmer_name}`;
        if (!combinedEntries[key]) {
            combinedEntries[key] = {
                farmer_code: entry.farmer_code,
                farmer_name: entry.farmer_name,
                tot_amount: 0,
                mode_payment_info: entry.mode_payment_info,
                count: 0
            };
        }
        combinedEntries[key].tot_amount += entry.tot_amount;
    });

    // Get the final count of data entries
    const finalCount = Object.keys(combinedEntries).length;

    // Convert combined entries object to formatted strings
    const formattedEntries = Object.values(combinedEntries).map(entry => {
        return formatDataEntry(entry, headerValue, filterValue, finalCount);
    });

    // // Convert combined entries object to formatted strings
    // const formattedEntries = Object.values(combinedEntries).map(entry => {
    //     return formatDataEntry(entry, headerValue, filterValue);
    // });

    // Combine formatted strings into a single string
    const textData = formattedEntries.join('');

    // Create Blob
    const blob = new Blob([textData], { type: 'text/plain;charset=utf-8' });
    let fileName
    if (filterValue.type !== "VLCC") {
        fileName = filterValue?.route ? `${filterValue?.route.code}_${emptyDate(filterValue?.from_date)}_${filterValue?.from_session}_${emptyDate(filterValue?.to_date)}_${filterValue?.to_session}_${emptyDate(extractDateFromTimestamp(new Date()))}` : "ALL";
    } else if (filterValue.type === "VLCC") {
        fileName = filterValue?.center_details ? `${filterValue?.center_details.code}_${emptyDate(filterValue?.from_date)}_${filterValue?.from_session}_${emptyDate(filterValue?.to_date)}_${filterValue?.to_session}_${emptyDate(extractDateFromTimestamp(new Date()))}` : "ALL";
    }

    // Save file
    saveAs(blob, `${fileName}.txt`);

    // route_name date and time => fileName
};
