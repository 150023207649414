import { createSlice } from "@reduxjs/toolkit";
import { updateFarmer } from "../../thunks/direct-thunk/DirectFarmer";

export const updateFarmerSlice = createSlice({
    name: "updateFarmer",
    initialState: {
        data: [],
        isLoading: false,
        error: null

    },
    extraReducers(builder) {
        builder.addCase(updateFarmer.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateFarmer.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data.push(action.payload);
        });
        builder.addCase(updateFarmer.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        });
    },
});

export const updateFarmerReducer = updateFarmerSlice.reducer;
