import React, { forwardRef } from 'react';

const PrintComponentVlcc = forwardRef(({ data, headerData }, ref) => {
    const printStyles = `
        @media print {
            body {
                font-family: Arial, sans-serif;
                font-size: 12px;
                line-height: 1;
            }

            p, h5, th,td {
                font-weight: bold;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                color: black;
            }

            @page {
                size: auto;
                margin: 5mm;
            }
            .rowPage {
                display: inline-flex;
            }

            .page-break-after {
                page-break-after: always;
            }
        }
    `;

    const headerStyle = {
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center',
        lineHeight: '1',
    };

    const tableStyle = {
        width: '100%',
        margin: '20px auto',

    };

    const tableHeaderStyle = {
        borderBottom: '2px solid black',  // Add border to the bottom of table header cells
    };

    const cellStyle = {
        fontSize: "14px",
        borderLeft: 'none',
        borderRight: 'none',
        textAlign: 'right',
        padding: '8px',
    };



    // Group data by unique combinations of "name" and "code"
    const groupedData = {};
    data?.forEach((entry) => {
        const key = `${entry.name}_${entry.farmer_code}`;
        if (!groupedData[key]) {
            groupedData[key] = [];
        }
        groupedData[key].push(entry);
    });

    console.log("groupedData", Object.keys(groupedData));
    // Sort entries within each group by "bill_date"
    Object.keys(groupedData).forEach(key => {
        groupedData[key].sort((a, b) => new Date(a.bill_date) - new Date(b.bill_date));
    });

    // Sort keys based on farmer code
    const sortedKeys = Object.keys(groupedData).sort((a, b) => {
        const codeA = groupedData[a][0].farmer_code;
        const codeB = groupedData[b][0].farmer_code;
        return codeA - codeB;
    });

    const centerInfoContainerStyle = {
        textAlign: 'left',
    };

    const centerInfoStyle = {
        fontSize: '13px',
        marginTop: '17px',
        display: 'inline-block',
    };

    const labelStyle = {
        width: '55px', // Set a fixed width for labels
        display: 'inline-block',
        fontWeight: 'bold',

    };

    const date = (date) => {
        const datePart = date?.split(' ');
        const final = datePart[0];
        const dateParts = final?.split('-');

        // Extract year, month, and day
        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];

        // Return the formatted date
        return `${day}/${month}`;
    }

    const reverseDate = (date) => {
        let year;
        let month;
        let day;
        const dateParts = date?.split('-');
        if (dateParts) {
            // Extract year, month, and day
            year = dateParts[0];
            month = dateParts[1];
            day = dateParts[2];
        }
        return `${day}/${month}/${year}`
    }
    const logo = require("../../../../assets/images/mask/asm_100.png")
    return (
        <div ref={ref}>


            {sortedKeys.map((groupKey, index) => {
                const group = groupedData[groupKey];
                const { name, farmer_code, center_name, center_code } = group[0];
                const totalQuantity = group.reduce((sum, entry) => sum + parseFloat(entry.tot_qty_litre), 0);
                const totalAmount = group.reduce((sum, entry) => sum + parseFloat(entry.tot_amount), 0);
                return (
                    <div key={groupKey}>
                        <style>{printStyles}</style>
                        <div style={headerStyle}>
                            <img src={logo} alt='ASM DAIRY' style={{ width: '80px', height: '80px' }} />
                            <p style={{ marginTop: "1px", fontSize: "21px" }}>ASM DAIRY</p>
                            <p style={{ marginTop: "-11px" }}>99769 79999</p>

                        </div>

                        <div style={centerInfoContainerStyle}>
                            <div style={centerInfoStyle}>
                                <p style={{ marginTop: "-10px" }}>
                                    <span style={labelStyle}>Center&nbsp;&nbsp;&nbsp;:</span>&nbsp;&nbsp;&nbsp;
                                    <span style={{ display: 'inline-block' }}>{`${center_code} - ${center_name}`}</span>
                                </p>

                                <p style={{ marginTop: "-10px" }}>
                                    <span style={labelStyle}>Code&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>&nbsp;&nbsp;&nbsp;
                                    <span style={{ display: 'inline-block' }}>{farmer_code}</span>
                                </p>
                                <p style={{ marginTop: "-10px" }}>
                                    <span style={labelStyle}>Name&nbsp;&nbsp;&nbsp;&nbsp;:</span>&nbsp;&nbsp;&nbsp;
                                    <span style={{ display: 'inline-block' }}>{name}</span>
                                </p>

                                <p style={{ marginTop: "-10px" }}>
                                    <span style={labelStyle}>Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>&nbsp;&nbsp;&nbsp;
                                    <span style={{ display: 'inline-block' }}>{reverseDate(headerData?.from_date)} {headerData?.from_session} - {reverseDate(headerData?.to_date)} {headerData?.to_session}</span>
                                </p>

                            </div>
                        </div>

                        <table style={tableStyle}>
                            <thead>
                                <tr>
                                    <th style={{ ...cellStyle, ...tableHeaderStyle, fontWeight: 'bold', width: '70px' }}>DATE(S)</th>
                                    <th style={{ ...cellStyle, ...tableHeaderStyle, fontWeight: 'bold' }}>FAT</th>
                                    <th style={{ ...cellStyle, ...tableHeaderStyle, fontWeight: 'bold' }}>SNF</th>
                                    <th style={{ ...cellStyle, ...tableHeaderStyle, fontWeight: 'bold' }}>RATE</th>
                                    <th style={{ ...cellStyle, ...tableHeaderStyle, fontWeight: 'bold' }}>QTY</th>
                                    <th style={{ ...cellStyle, ...tableHeaderStyle, fontWeight: 'bold' }}>AMT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {group.map(({ id, bill_date, session, tot_fat, tot_snf, tot_rate, tot_qty_litre, tot_amount }) => (
                                    <tr key={id}>
                                        <td style={cellStyle}>{date(bill_date)} {session}</td>
                                        <td style={cellStyle}>{parseFloat(tot_fat).toFixed(2)}</td>
                                        <td style={cellStyle}>{parseFloat(tot_snf).toFixed(2)}</td>
                                        <td style={cellStyle}>{parseFloat(tot_rate).toFixed(2)}</td>
                                        <td style={cellStyle}>{parseFloat(tot_qty_litre).toFixed(2)}</td>
                                        <td style={cellStyle}>{parseFloat(tot_amount).toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div style={{ borderTop: '2px solid black', textAlign: 'right' }}>

                            <p style={cellStyle}>
                                <span style={{ fontWeight: 'bold', fontSize: '15px', textAlign: 'left' }}>TOTAL:</span>
                                <span style={{ marginLeft: '175px', marginRight: '17px', fontSize: '15px' }}>{totalQuantity.toFixed(2)}</span>
                                <span style={{ fontSize: '15px' }}>{totalAmount.toFixed(2)}</span>
                            </p>

                        </div>
                        <p style={{ ...headerStyle, textAlign: 'center', marginTop: '10px', marginBottom: '30px' }} >
                            Thank you
                        </p>
                        {index < Object.keys(groupedData).length - 1 && <div className="page-break-after" />}

                    </div>
                );
            })}


        </div >
    );
});

export default PrintComponentVlcc;
