import React, { useEffect, useRef, useState } from 'react'
import { Button, ButtonGroup, Card, Col, Form, Row } from 'react-bootstrap'
import { DatePicker } from '../../../../components'
import { SearchDown } from '../dropDown/SearchDown'
import { useDispatch } from 'react-redux'
import { ProfileDetails, fetchFarmerList, routeMaster } from '../../store/store'
import { useSelector } from 'react-redux'
import { extractDateFromTimestamp } from '../Date'
import { get } from '../../api/Api'

const AdvanceFilter = ({ getFilterValue }) => {
    const dispatch = useDispatch()
    const [frName, setFrName] = useState(null)
    const farmerCode = useRef(null)
    const [rName, setRName] = useState(null)
    const codeFr = useRef(null)
    const [details, setDetails] = useState({

        route_id: '',
        route: "",
        sessionDay: "M",
        sessionNight: "E",
        startDate: extractDateFromTimestamp(new Date()),
        endDate: extractDateFromTimestamp(new Date()),
        farmer_id: "",
        payment_cycle: [],
        mode_payment: [],
        start_code: "",
        end_code: ""

    })

    const validateFarmer = (value) => {
        if (!value || value.trim() === '') {
            // setNameError('Center Name is required.');
            return false;
        } else {
            // Add other validation checks as needed
            // setNameError('');
        }
    }

    useEffect(() => {
        dispatch(ProfileDetails())
    }, [dispatch])

    const role = useSelector((state) => state.profile)

    useEffect(() => {
        const body = {
            page: 1,
            page_count: 1000
        }
        dispatch(fetchFarmerList(body))
    }, [dispatch])

    const frList = useSelector((state) => state.directFarmerList)

    const [directFarmer, setDirectFarmer] = useState([])

    useEffect(() => {
        // Check if directFarmerList.data is available before filtering
        if (frList.data) {
            const filteredCollection = frList.data.filter((el) => el.type === "2");
            setDirectFarmer(filteredCollection);
        }
    }, [frList.data]);

    useEffect(() => {
        dispatch(routeMaster())
    }, [dispatch])

    const routeList = useSelector((state) => state.route)


    const handleFarmer = (value, id, code) => {
        // setFrName(`${code.code} - ${code.name}`)
        console.log(value, id, code, "data====>");
        console.log(id, "routeCode");
        // setFrCode(id)

        setDetails({ ...details, farmer_id: id })
        // setCode(code.id)
    }

    const handleName = (name) => {
        // setFrName(name)
    }


    const getRouteList = async (id) => {
        const url = `/api/direct-farmer/route/${id}`;
        try {
            const response = await get(url);
            if (response.status) {
                console.log("Server response:", response.data);
                setDirectFarmer(response.data)
            }
        } catch (error) {
            console.error("Error while fetching data:", error);
        }
    }

    const handleRoute = (value, id, code) => {
        // setFrName(`${code.code} - ${code.name}`)
        console.log(value, id, code, "data====>");
        console.log(id, "routeCode");



        if (id) {
            getRouteList(id);
        } else {
            if (frList.data) {
                const filteredCollection = frList.data.filter((el) => el.type === "2");
                setDirectFarmer(filteredCollection);
            }
        }


        // setCode(code.id)
        setDetails({ ...details, route_id: id, route: code })
    }

    function numOnly(event) {
        // Allow only numeric characters
        event.target.value = event.target.value.replace(/\D/g, '');
    }



    // const handleCycle = (value, id) => {
    //     document.getElementById(id).blur();
    //     setDetails({ ...details, payment_cycle: value });
    // }

    const handleCheck = () => {
        getFilterValue(details)

    };
    const handleCycle = (value, isChecked, id) => {
        document.getElementById(id).blur();

        setDetails(prevFormData => {
            let updatedCycle;

            if (isChecked) {
                // If the checkbox is checked, create a new array by spreading the existing values
                // and adding the new value to it
                updatedCycle = [...prevFormData.payment_cycle, value];
                // setSelectMilkType(updatedMilkType)
            } else {
                // If the checkbox is unchecked, filter out the value from the array
                updatedCycle = prevFormData.payment_cycle.filter(item => item !== value);
                // setSelectMilkType(updatedMilkType)
            }

            return {
                ...prevFormData,
                payment_cycle: updatedCycle,
            };
        });


    };




    const handleModeOfPayment = (value, isChecked, id) => {
        document.getElementById(id).blur();

        setDetails(prevFormData => {
            let updatedMode;

            if (isChecked) {
                // If the checkbox is checked, create a new array by spreading the existing values
                // and adding the new value to it
                updatedMode = [...prevFormData.mode_payment, parseFloat(value)];
                // setSelectMilkType(updatedMilkType)
            } else {
                // If the checkbox is unchecked, filter out the value from the array
                updatedMode = prevFormData.mode_payment.filter(item => item !== parseFloat(value));
                // setSelectMilkType(updatedMilkType)
            }


            return {
                ...prevFormData,
                mode_payment: updatedMode,
            };
        });

    }


    console.log("details", details);

    console.log(details.farmer_id, "id===>");





    return (
        <Card className="card-gutter-md mt-2 mb-2">
            <Card.Body>
                <Col xxl="12">
                    <Row className="g-gs">

                        <Col lg="3">
                            <SearchDown

                                data={routeList.data}
                                placeholder="Route name"
                                name="Route name"
                                errors="Route name is required"
                                value={rName}
                                nextInputRef={codeFr}
                                onValidate={validateFarmer}
                                onSelect={(selectedItem, id, code) => {
                                    handleRoute(selectedItem, id, code)
                                }}
                                func={handleName}
                                star

                            />
                        </Col>
                        <Col lg="3">
                            <SearchDown
                                star
                                data={directFarmer}
                                placeholder="Farmer Name"
                                name="Farmer Name"
                                errors="Farmer name is required"
                                value={frName}
                                nextInputRef={farmerCode}
                                onValidate={validateFarmer}
                                onSelect={(selectedItem, id, code) => {
                                    handleFarmer(selectedItem, id, code)
                                }}
                                func={handleName}

                            />
                        </Col>


                        <Col md="3">
                            <Form.Group>
                                <Form.Label>
                                    Start Farmer Code

                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Start Farmer Code"
                                    name="producerCode"
                                    id="producerCode"
                                    autoComplete='off'
                                    value={details.start_code}
                                    onChange={(e) => setDetails({ ...details, start_code: e.target.value })}
                                    disabled={details.farmer_id > 0}
                                    onKeyUp={numOnly}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Producer Code is Required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col md="3">
                            <Form.Group>
                                <Form.Label>
                                    End Farmer Code
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="End Farmer Code"
                                    name="Farmer Code"
                                    id="Farmer Code"
                                    autoComplete='off'
                                    onKeyUp={numOnly}
                                    value={details.end_code}
                                    disabled={details.farmer_id}
                                    onChange={(e) => setDetails({ ...details, end_code: e.target.value })}
                                />

                            </Form.Group>
                        </Col>

                        <Col lg="3">
                            <Row >
                                <Form.Group className="form-group">
                                    <Form.Label>
                                        Mode of Payment

                                    </Form.Label>

                                    <ButtonGroup aria-label="Basic radio toggle button group">
                                        <input
                                            type="checkbox"
                                            className="btn-check"
                                            name="paymentMethodcash"
                                            id="cash"
                                            autoComplete="off"
                                            value={0}
                                            onChange={(e) => handleModeOfPayment(e.target.value, e.target.checked, e.target.id)}

                                        />
                                        <Button
                                            as="label"
                                            variant="outline-primary"
                                            htmlFor="cash"
                                        >
                                            Cash
                                        </Button>

                                        <input
                                            type="checkbox"
                                            className="btn-check"
                                            name="paymentMethodBank"
                                            id="bank"
                                            autoComplete="off"
                                            value={1}
                                            onChange={(e) => handleModeOfPayment(e.target.value, e.target.checked, e.target.id)}


                                        />
                                        <Button
                                            as="label"
                                            variant="outline-primary"
                                            htmlFor="bank"
                                        >
                                            Bank
                                        </Button>

                                        <input
                                            type="checkbox"
                                            className="btn-check"
                                            name="paymentMethod"
                                            id="upi"
                                            autoComplete="off"
                                            value={2}
                                            onChange={(e) => handleModeOfPayment(e.target.value, e.target.checked, e.target.id)}
                                        />
                                        <Button
                                            as="label"
                                            variant="outline-primary"
                                            htmlFor="upi"
                                        >
                                            UPI
                                        </Button>
                                    </ButtonGroup>
                                </Form.Group>

                            </Row>
                        </Col>



                        <Col md="3">
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="validationCustom03">
                                    Payment Cycle

                                </Form.Label>
                                <div className="form-control-wrap">
                                    <ButtonGroup aria-label="Basic radio toggle button group">
                                        <input
                                            type="checkbox"
                                            className="btn-check"
                                            name="paymentCycleDaily"
                                            id="dailyRadio"
                                            value="daily"
                                            autoComplete="off"
                                            onChange={(e) => handleCycle(e.target.value, e.target.checked, e.target.id)}
                                        />
                                        <Button
                                            as="label"
                                            variant="outline-primary"
                                            htmlFor="dailyRadio"
                                        >
                                            Daily
                                        </Button>

                                        <input
                                            type="checkbox"
                                            className="btn-check"
                                            name="paymentCycleWeekly"
                                            id="weeklyRadio"
                                            value="weekly"
                                            autoComplete="off"
                                            onChange={(e) => handleCycle(e.target.value, e.target.checked, e.target.id)}

                                        />
                                        <Button
                                            as="label"
                                            variant="outline-primary"
                                            htmlFor="weeklyRadio"
                                        >
                                            Weekly
                                        </Button>

                                        <input
                                            type="checkbox"
                                            className="btn-check"
                                            name="paymentCycleMonthly"
                                            id="monthlyRadio"
                                            value="monthly"
                                            autoComplete="off"
                                            onChange={(e) => handleCycle(e.target.value, e.target.checked, e.target.id)}

                                        />
                                        <Button
                                            as="label"
                                            variant="outline-primary"
                                            htmlFor="monthlyRadio"
                                        >
                                            Monthly
                                        </Button>
                                    </ButtonGroup>
                                </div>

                            </Form.Group>
                        </Col>

                        <Col lg="2">
                            <Col xxl="12">
                                <Row className="g-gs">

                                    <Col>
                                        <Button
                                            as="label"
                                            style={{ marginTop: "32px" }}
                                            variant="primary"
                                            onClick={handleCheck}
                                        >
                                            Get Data
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>

                        </Col>

                    </Row>


                </Col>
            </Card.Body>
        </Card>
    )
}

export default AdvanceFilter