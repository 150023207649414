import React, { useEffect, useState } from 'react'
import Layout from "../../../../layout/default";
import { Button, Card, Col, Row } from 'react-bootstrap';
import DataTable from '../../../../components/DataTable/DataTable';
import { get, postToast, put } from '../../api/Api';
import Loader from '../../shared/Loader';
import { Block, Icon } from '../../../../components';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchFarmerList, rateChartDetails } from '../../store/store';
import { useSelector } from 'react-redux';
import BankDetailsModal from '../../modal/BankDetails';
import FarmerDetailsModal from '../../modal/FarmerDetails';
import GenerationModal from '../../modal/GenerationModal';


export const CenterMasterView = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
    const [showKeyModal, setShowKeyModal] = useState(false);
    const [showCodeModal, setShowCodeModal] = useState(false);
    const [clickData, setClickData] = useState([])
    const [clickId, setClickId] = useState(null)
    const [centerListData, setCenterListData] = useState([])
    const dispatch = useDispatch()
    const [showKeyModalOneTime, setShowKeyModalOneTime] = useState(false);
    const [tokenData, settokenData] = useState(null)
    const toggleBankDetailsModal = () => {
        setShowBankDetailsModal(!showBankDetailsModal);
    };

    const toggleCodeModal = () => {
        setShowCodeModal(!showCodeModal)
    }

    const toggleKeyModal = () => {
        setShowKeyModal(!showKeyModal)
    }

    const navigate = useNavigate()


    useEffect(() => {
        const data = JSON.parse(localStorage.getItem("farmerData"))
        // setData(data ? data : [])
    }, [])



    const handleNavigate = (id) => {
        navigate(`/collection-center/${id}`)
    }

    const fetchList = async (id) => {
        const url = `/api/vlcc/token-details/${id}`;
        try {
            const response = await get(url);
            if (response.status) {
                console.log("Server response:", response.data);
                const data = response.data
                setClickData(data ? data : [])
                setLoading(false)
            }

        } catch (error) {
            console.error("Error while posting data:", error);
            setLoading(false)
        }
    }

    const generateView = (id) => {
        setClickId(id)
        fetchList(id)
        setShowKeyModal(true)
    }


    useEffect(() => {
        dispatch(fetchFarmerList())
    }, [dispatch])

    // const { data } = useSelector((state) => state.directFarmerList)

    // const filterData = data?.filter((el) => el.type === "1")
    const [pagination, setPagination] = useState({
        page: 1,
        page_count: 25,
        search: '',
        total_count: null
    })
    const updatePagination = ({ current_page, per_page, total }) => {
        setPagination({
            ...pagination,
            page: current_page,
            page_count: per_page,
            total_count: total
        });
    };

    const filtered_list = async (body) => {
        const url = `/api/direct-farmer/filtered_list`;
        try {
            const response = await postToast(url, body);
            if (response.status === true) {
                console.log("Server farmer response:", response.data.data);
                setData(response.data.data);
                if (body.type.includes('1')) {
                    setCenterListData(response.data.data)
                }

                const pageValue = response.data
                updatePagination(pageValue);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error("Error while posting data:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        const body = {
            type: ["1"]
        }
        filtered_list(body)
    }, [])


    const buttonStyle = {
        padding: '0.7px 6px ',
        fontSize: '10px',
        lineHeight: '1',
    };


    function formatPaymentCycle(row) {
        console.log(row, "row==>");
        if (row.payment_cycle === "daily") {
            if (row.session === "0" || row.session === 0) {
                return "D - Mor";
            } else if (row.session === "1" || row.session === 1) {
                return "D - Eve";
            } else if (row.session === "2" || row.session === 2) {
                return "D - Both";
            }
        } else if (row.payment_cycle === "weekly") {
            const daysOfWeek = ["0", "1", "2", "3", "4", "5", "6"];
            const sessionIndex = parseInt(row.session);
            if (!isNaN(sessionIndex) && sessionIndex >= 0 && sessionIndex < daysOfWeek.length) {
                return "W - " + daysOfWeek[sessionIndex];
            }
        } else if (row.payment_cycle === "monthly") {
            return "Month-end"
        }

        // Return a default value or handle other cases as needed
        return "Unknown";
    }


    const modeOfPayment = (data) => {
        console.log(data, "data");
        return data.mode_of_payment ? "CASH" : data.mode_of_payment ? "BANK" : data.mode_of_payment ? "UPI" : ""
    }

    const centerContentStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    };
    const { details } = useSelector((state) => state.rateChartDetails)

    const rateChart = (id, milktype) => {
        console.log(id, milktype, "))=====>");

        if (milktype === "0") {
            // If milktype is "0", use id.cow and dispatch an action
            const cowId = id.cow;
            console.log("cowId", cowId);
            if (cowId) {
                dispatch(rateChartDetails(cowId));
            }

        } else if (milktype === "1") {
            // If milktype is "1", use id.buffalo and dispatch a different action
            const buffaloId = id.buffalo;
            console.log("buffaloId", buffaloId);
            // Dispatch the action for buffaloId
            // dispatch(rateChartDetails(buffaloId));
        }
    };

    console.log(details, "==//>");

    const handleNavigateSettings = (id) => {
        navigate(`/settings-view/collection-settings/${id}`)
    }

    const generateViewOneTime = (id) => {
        setClickId(id)
        fetchListOneTime(id)
        setShowKeyModalOneTime(true)
    }

    const fetchListOneTime = async (id) => {
        const url = `/api/vlcc/getAccessCode/${id}`;
        try {
            const response = await get(url);
            if (response.status) {
                console.log("Server response:", response.data);
                const data = response.token
                settokenData(data)
                setLoading(false)
            }

        } catch (error) {
            console.error("Error while posting data:", error);
            setLoading(false)
        }
    }

    const listColumn = [
        {
            name: "Route",
            selector: row => `${row.route.code}-${row.route.name}`,
            center: true,
            sortable: true
        },
        {
            name: "Center Code",
            selector: row => row.code,
            center: true,
            sortable: true
        },
        {
            name: "Center Name",
            selector: row => row.name,
            center: true
        },
        // {
        //     name: "Rate Chart",
        //     selector: row => rateChart(row.ratechart_id[0], row.milk_info.milk_type),
        //     center: true
        // },
        {
            name: 'Payment',
            selector: (row) => (

                <span
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    onClick={() => {
                        if (row.mode_payment_info[0].mode_of_payment !== 0)
                            toggleBankDetailsModal()
                        setClickData(row.mode_payment_info)
                    }}
                >
                    {modeOfPayment(row.mode_payment_info[0])}
                </span>
            ),
            center: true,
            width: '9%',
            sortable: true,
        },

        {
            name: "Cycle",
            selector: row => formatPaymentCycle(row.payment_cycle[0]),
            center: true,
            width: '8%',
            sortable: true
        },
        {
            name: "Status",
            cell: (row) => (
                <div style={centerContentStyle}>
                    <Button className=" mx-1" style={buttonStyle} onClick={() => handleCCStatus(row.id, row.name)} variant={row.status === '1' ? "success" : "danger"}><Icon name={row.status === '1' ? "check" : "cross"}></Icon></Button>
                </div>
            ),
            right: true,
            width: '8%',
        },

        {
            name: "ACTION",
            right: true,
            maxWidth: '220px',
            cell: (row) => {

                return (
                    <div>
                        <Button
                            className="mx-1"
                            style={buttonStyle}
                            variant="secondary"
                            onClick={() => handleNavigateSettings(row.id)}
                        >
                            <Icon name="setting-alt"></Icon>
                        </Button>
                        <Button
                            className="mx-1"
                            style={buttonStyle}
                            variant="primary"
                            onClick={() => handleNavigate(row.id)}
                        >
                            <Icon name="edit"></Icon>
                        </Button>
                        <Button
                            className="mx-1"
                            style={buttonStyle}
                            variant="info"
                            onClick={() => generateView(row.id)}
                        >
                            <Icon name="lock-alt"></Icon>
                        </Button>
                        <Button
                            className="mx-1"
                            style={buttonStyle}
                            variant="success"
                            onClick={() => generateViewOneTime(row.id)}
                        >
                            <Icon name="signin"></Icon>
                        </Button>


                    </div>
                );
            },
            sortable: false,
            hide: "md",
        }
    ]
    // < Loader loading = { loading } />

    const handleCCStatus = (id, name) => {
        const updateStatus = async () => {

            const url = `/api/vcc-farmer/status-update/${id}`;
            try {
                const response = await put(url, name);
                if (response.status) {
                    console.log("Server response:", response.data);
                    dispatch(fetchFarmerList())
                }
            } catch (error) {
                console.error("Error while posting data:", error);
            }
        };
        updateStatus();
    }

    const handlePaginate = (currentPage, itemPerPage, searchValue) => {
        console.log("page_value", currentPage, itemPerPage);

        const body = {
            page: currentPage,
            page_count: itemPerPage,
            search: searchValue,
        }
        console.log("pagiate_data", body);

        if (body)
            filtered_list(body);

    }

    const toggleKeyModalOneTime = () => {
        setShowKeyModalOneTime(!showKeyModalOneTime)
    }

    return (
        <Layout title="Collection Center" content="container">
            {showBankDetailsModal && (
                <BankDetailsModal
                    show={showBankDetailsModal}
                    bankDetails={clickData}
                    onClose={toggleBankDetailsModal}
                />
            )}
            {showCodeModal && (
                <FarmerDetailsModal
                    show={showCodeModal}
                    farmerDetails={clickData}
                    onClose={toggleCodeModal}
                />
            )}
            {showKeyModal && (
                <GenerationModal
                    show={showKeyModal}
                    data={clickData}
                    id={clickId}
                    onClose={toggleKeyModal}
                    regenerate={fetchList}
                />
            )}

            {showKeyModalOneTime && (
                <GenerationModal
                    show={showKeyModalOneTime}
                    data={tokenData}
                    id={clickId}
                    onClose={toggleKeyModalOneTime}
                    regenerateOneTime={fetchListOneTime}
                    oneTime
                />
            )}
            <Row className="g-gs ">
                <Col xxl="12">
                    <div className="d-flex justify-content-start">
                        <h3>
                            Collection Center Master List
                        </h3>
                    </div>

                    <Card className="card-gutter-md mt-2 mb-2">
                        <Card.Body>
                            <Block.HeadContent>
                                <ul className="d-flex justify-content-end">

                                    <li>
                                        <Link
                                            to="/collection-center/0"
                                            className="btn btn-primary d-none d-md-inline-flex mx-3"
                                        >
                                            <Icon name="plus" />
                                            <span>Create Center</span>
                                        </Link>
                                    </li>
                                </ul>

                            </Block.HeadContent>
                            <DataTable tableClassName="data-table-head-light table-responsive" data={data}
                                columns={listColumn} onPaginate={handlePaginate} page_value={pagination}
                            />
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </Layout>
    )
}
