import { createSlice } from "@reduxjs/toolkit";
import { createVlccFarmer } from "../../thunks/vlcc-thunk/VlccCollection";


export const createVlccFarmerSlice = createSlice({
    name: "createFarmer",
    initialState: {
        data: [],
        isLoading: false,
        error: null

    },
    extraReducers(builder) {
        builder.addCase(createVlccFarmer.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createVlccFarmer.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data.push(action.payload);
            console.log("Created farmer:", action.payload);
        });
        builder.addCase(createVlccFarmer.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        });
    },
});

export const createVlccFarmerReducer = createVlccFarmerSlice.reducer;
