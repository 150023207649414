import React from 'react';
import { Col, Form, ButtonGroup, Button } from 'react-bootstrap'; // Assuming you are using Bootstrap components
import { Icon } from '../../../../components';

const SessionEndSelector = ({ onChange, value }) => {
    const handleEndSession = (value) => {
        if (onChange) {
            onChange(value);
        }
    };

    console.log("valueSession", value);

    return (

        <Form.Group className="form-group">
            <Form.Label htmlFor="end">End Session</Form.Label>
            <div className="form-control-wrap">
                <ButtonGroup aria-label="Basic checkbox toggle button group">
                    <input
                        type="radio"
                        className="btn-check"
                        name="sessionEnd"
                        id="btncheckEnd1"
                        autoComplete="off"
                        value="M"
                        onChange={() => handleEndSession('M')}
                        checked={value === "M"}
                    />
                    <Button as="label" variant="outline-primary" htmlFor="btncheckEnd1">
                        <Icon name="sun-fill"></Icon>
                    </Button>

                    <input
                        type="radio"
                        className="btn-check"
                        name="sessionEnd"
                        id="btncheckEnd2"
                        autoComplete="off"
                        value="E"
                        checked={value === "E"}
                        onChange={() => handleEndSession('E')}
                    />
                    <Button as="label" variant="outline-primary" htmlFor="btncheckEnd2">
                        <Icon name="moon-fill"></Icon>
                    </Button>
                </ButtonGroup>
            </div>
        </Form.Group>

    );
};

export default SessionEndSelector;
