import React, { useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { DatePicker, Icon } from '../../../../components'
import Session from '../session/Session'
import { extractDateFromTimestamp } from '../Date'

const AttendenceFilter = ({ attendenceFilter }) => {
    const [filterValue, setFilterValue] = useState({
        date: extractDateFromTimestamp(new Date()),
        session: null
    })

    console.log(filterValue, "***>");

    const handleCheck = () => {

        attendenceFilter(filterValue)
    }

    return (
        <>
            <Col md="12">
                <Row className="g-gs">
                    <Col lg="5" className="mb-2">
                        <div className="form-control-wrap">
                            <DatePicker onDateChange={(value) => setFilterValue({ ...filterValue, date: value })} value={filterValue.date} />
                        </div>
                    </Col>
                    <Col lg="4" className="mb-2">
                        <div className="form-control-wrap">
                            <Session onChange={(value) => setFilterValue({ ...filterValue, session: value })} value={filterValue.session} />
                        </div>
                    </Col>
                    <Col lg="2" className="mb-2">
                        <Button
                            as="label"
                            variant="primary"
                            onClick={handleCheck}
                        >
                            <Icon name="search"></Icon>
                        </Button>
                    </Col >
                </Row>
            </Col>
        </>
    )
}

export default AttendenceFilter