import React, { useState } from 'react'
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap'
import { Block, OverlineTitle } from '../../../components'
import Layout from "../../../layout/default";
import DataTable from '../../../components/DataTable/DataTable';
import { useNavigate, useParams } from 'react-router-dom';
import { Cryptography } from '../../cryptography/Cryptography';


export const NewRole = ({ data }) => {

    const menuOptions = [
        {
            id: 'dashboardCheckbox', label: 'Dashboard', submenu: [{ id: "dashboard", label: "View" }]
        },
        {
            id: 'farmerCheckbox', label: 'Farmer Creation', submenu: [
                { id: 'farmerCreate', label: 'Create' },
                { id: 'farmerUpdate', label: 'Update' },
                { id: 'farmerDelete', label: 'Delete' },
                { id: 'farmerView', label: 'View' }
            ]
        },
        {
            id: 'collectionCheckbox', label: 'Collection Setting', submenu: [
                { id: 'collectionCreate', label: 'Create' },
                { id: 'collectionUpdate', label: 'Update' },
                { id: 'collectionDelete', label: 'Delete' },
                { id: 'collectionView', label: 'View' }
            ]
        },
        {
            id: 'routeMasterCheckbox', label: 'Route Master', submenu: [
                { id: 'routeCreate', label: 'Create' },
                { id: 'routeUpdate', label: 'Update' },
                { id: 'routeDelete', label: 'Delete' },
                { id: 'routeView', label: 'View' }
            ]
        },
        {
            id: 'vehicleMasterCheckbox', label: 'Vehicle Master', submenu: [
                { id: 'vehicleCreate', label: 'Create' },
                { id: 'vehicleUpdate', label: 'Update' },
                { id: 'vehicleDelete', label: 'Delete' },
                { id: 'vehicleView', label: 'View' }
            ]
        },
        {
            id: 'rateChartCheckbox', label: 'Rate Chart Master', submenu: [
                { id: 'rateCreate', label: 'Create' },
                { id: 'rateUpdate', label: 'Update' },
                { id: 'rateDelete', label: 'Delete' },
                { id: 'rateView', label: 'View' }
            ]
        },
        {
            id: 'EquipmentCheckbox', label: 'Equipment Setting', submenu: [
                { id: 'EquipmentCreate', label: 'Create' },
                { id: 'EquipmentUpdate', label: 'Update' },
                { id: 'EquipmentDelete', label: 'Delete' },
                { id: 'EquipmentView', label: 'View' }
            ]
        },
        {
            id: 'printerCheckbox', label: 'Printer Setting', submenu: [
                { id: 'printerCreate', label: 'Create' },
                { id: 'printerUpdate', label: 'Update' },
                { id: 'printerDelete', label: 'Delete' },
                { id: 'printerView', label: 'View' }
            ]
        }
    ];

    const tableData = [
        { id: 1, name: "CR7" },
        { id: 2, name: "messi" },
        { id: 3, name: "neymar" },
    ]

    const CenteredHeader = ({ title }) => {
        return (
            <div style={{ textAlign: 'center', width: '100%' }}>
                {title}
            </div>
        );
    };

    const column = [
        {
            name: <CenteredHeader title="Name" />,
            cell: row => row.name,
            center: true
        },
    ]

    const { id } = useParams();
    const navigate = useNavigate()

    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        const newValue = !selectAll;
        setSelectAll(newValue);

        // Set all checkboxes to the new value
        const newCheckboxStates = {};
        menuOptions.forEach(menu => {
            if (menu.submenu) {
                menu.submenu.forEach(subitem => {
                    newCheckboxStates[subitem.id] = newValue;
                });
            }
        });
        setCheckboxStates(newCheckboxStates);
    };



    const [checkboxStates, setCheckboxStates] = useState({}); // Here, we will store each checkbox state by its id
  

    const handleCheckboxChange = (id) => {
        // Toggle the checkbox that was clicked
        setCheckboxStates(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));

        // After updating checkboxStates, determine the state of the "Select All" checkbox
        setCheckboxStates(prevState => {
            // Check if all checkboxes are checked
            const allChecked = menuOptions.every(menu =>
                menu.submenu.every(subitem => prevState[subitem.id] === true)
            );
            setSelectAll(allChecked); // Set the "Select All" checkbox state
            return prevState; // Return the existing state since we only want to update selectAll
        });
    };

    const encryptData = Cryptography.encrypt(tableData);
    console.log(encryptData, "encrypt==>"); // Should show encrypted string

    const decryptData = Cryptography.decrypt(encryptData);
    console.log(decryptData, "decrypt==>"); // Should show original tableData object

    console.log(checkboxStates, "checkboxStates")

    return (
        <Layout title="Form controls" content="container">
            <Row className="g-gs ">

                <Col xxl="7">
                    <Block.Title tag="h3">Roles Allocation</Block.Title>

                    <div className="gap gy-4 mt-1">
                        <div className="gap-col">
                            <Card className="card-gutter-md">
                                <Card.Body >
                                    <Row className="g-gs">
                                        <Col xxl="12">
                                            {id !== "role" && <Button
                                                onClick={() => navigate(`/add-role/${'role'}`)}
                                                as="label"
                                                variant="primary"
                                            >
                                                New Role
                                            </Button>}
                                            <Col sm="5" className='mt-3'>
                                                <Form.Control
                                                    type="text"
                                                    name='Role'
                                                    id="RoleInput"
                                                    placeholder="Role Name"
                                                    value={id === "role" ? "" : id}
                                                    required
                                                />
                                            </Col>
                                            <Col lg="12" className="mt-2">
                                                <Table responsive className="table-middle mb-0" style={{ borderBottom: '1px solid #ddd' }}>
                                                    <thead className="table-light table-head-sm">
                                                        <tr>
                                                            <th className="tb-col" style={{ verticalAlign: 'middle', textAlign: 'center', border: '1px solid #ddd' }}>
                                                                <OverlineTitle tag="span">Menu</OverlineTitle>
                                                            </th>
                                                            <th className="tb-col tb-col-end" style={{ verticalAlign: 'middle', textAlign: 'center', borderLeft: '1px solid #ddd' }}>
                                                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        checked={selectAll}
                                                                        onChange={() => handleSelectAll()}
                                                                        style={{ marginRight: '10px' }} // added some margin for spacing between the checkbox and the text
                                                                    />
                                                                    <OverlineTitle tag="span">Rights</OverlineTitle>
                                                                </div>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {menuOptions.map((menu, index) =>
                                                            <tr key={index}>
                                                                <td className="tb-col" style={{ verticalAlign: 'middle', textAlign: 'center', borderRight: '1px solid #ddd' }}>
                                                                    {menu.label}
                                                                </td>
                                                                <td className="tb-col tb-col-end" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                                    {menu.submenu ?
                                                                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                                                            {menu.submenu.map((subitem, idx) =>
                                                                                <Form.Check
                                                                                    key={subitem.id}
                                                                                    className="form-check-sm"
                                                                                    type="checkbox"
                                                                                    id={subitem.id}
                                                                                    label={subitem.label}
                                                                                    checked={checkboxStates[subitem.id]}
                                                                                    onChange={() => handleCheckboxChange(subitem.id)}
                                                                                    style={{ marginRight: idx === menu.submenu.length - 1 ? '0px' : '10px' }}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        : "N/A"}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>

                                            </Col>
                                            <Col lg='2' className="mt-2">
                                                <Button
                                                    as="label"
                                                    variant="success"
                                                >
                                                    Submit
                                                </Button>
                                            </Col>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>


                </Col>
                {id !== "role" && <Col xxl="5">
                    <Block.Title tag="h3">Staff members using this role</Block.Title>
                    <div className="gap gy-4 mt-1">
                        <div className="gap-col">
                            <Card className="card-gutter-md">
                                <Card.Body>
                                    <Row className="g-gs">
                                        <Col lg="12">
                                            <Block>
                                                <Card>
                                                    <DataTable tableClassName="data-table-head-light table-responsive" data={tableData} columns={column} />
                                                </Card>
                                            </Block>
                                        </Col>

                                    </Row>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </Col>}
            </Row>

        </Layout>
    )
}
