import { createSlice } from "@reduxjs/toolkit";
import { fetchFarmerList } from "../../thunks/direct-thunk/DirectFarmer";


const directCollectionSlice = createSlice({
    name: "directFarmerList",
    initialState: {
        data: [],
        isLoading: false,
        error: null

    },
    extraReducers(builder) {
        builder.addCase(fetchFarmerList.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchFarmerList.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload.data
            console.log("Data received:", action.payload);
        })
        builder.addCase(fetchFarmerList.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })

    }
})

export const DirectCollectionReducer = directCollectionSlice.reducer
