import { createSlice } from "@reduxjs/toolkit";
import { createFarmer } from "../../thunks/direct-thunk/DirectFarmer";

export const createFarmerSlice = createSlice({
    name: "createFarmer",
    initialState: {
        data: [],
        isLoading: false,
        error: null,


    },
    extraReducers(builder) {
        builder.addCase(createFarmer.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createFarmer.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data.push(action.payload);
            // state.status =
            console.log("Created farmer:", state, action);
        });
        builder.addCase(createFarmer.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        });
    },
});

export const createFarmerReducer = createFarmerSlice.reducer;
