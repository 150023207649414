import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Card, Form, Col, Row, ButtonGroup, Button, Dropdown, DropdownButton, Container, } from "react-bootstrap";
import { Icon } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { get, post, put } from "./api/Api";
import { SearchDown } from "./shared/dropDown/SearchDown";
import { useEffect } from "react";
import { useRef } from "react";
import { transformData, transformDataEdit } from "./shared/api-structure/CollectionCenterStructure";
import Loader from "./shared/Loader";

const CollectionCenter = () => {

    const navigate = useNavigate()

    const { id } = useParams()
    const [editData, setEditData] = useState([])
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [loading, showLoading] = useState(true);



    useEffect(() => {
        const url = `/api/direct-farmer/get-upcoming-code`;
        const fetchCode = async () => {
            try {
                const response = await get(url);
                if (response.status) {
                    setFormData({ ...formData, centerCode: response.data.code });
                    showLoading(false)
                }

            } catch (error) {
                showLoading(false)
                console.error("Error while posting data:", error);

            }
        }
        fetchCode()

    }, [])

    const fetchList = async () => {
        const url = `/api/direct-farmer/${id}/details`;
        try {
            const response = await get(url);
            if (response.status) {
                const data = response.data
                // const filteredData = data.filter((el) => el.id === parseFloat(id))

                showLoading(false)
                if (data) {
                    setEditData(data)
                }
            }
        } catch (error) {
            showLoading(false)
            console.error("Error while posting data:", error);
        }
    };

    useEffect(() => {
        if (parseFloat(id) !== 0) {
            fetchList();
        }
    }, [id])

    // const data = JSON.parse(editData.user_info)
    useEffect(() => {
        if (editData?.length !== 0 && parseFloat(id) !== 0) {

            setFormData({
                centerCode: editData?.code,
                centerName: editData?.name,
                taulkName: editData?.producer_info?.[0]?.taluk_name || '',
                address: editData?.producer_info?.[0]?.address || '',
                pincode: editData?.producer_info?.[0]?.pincode || '',
                latLang: editData?.producer_info?.[0]?.latLang || '',
                villageName: editData?.producer_info?.[0]?.village_name || '',
                routeName: editData?.route?.name,
                rateType: "",
                cowRT: "",
                buffaloRT: "",
                bothRT: "",
                incentive: editData?.incentive_info?.incentive,
                cowIncentive: editData?.incentive_info?.incentive_info?.cow,
                buffaloIncentive: editData?.incentive_info?.incentive_info?.buffalo,
                paymentCycle: editData?.payment_cycle?.[0]?.payment_cycle,
                session: editData?.payment_cycle?.[0]?.session,
                communication: editData?.communication_info?.[0]?.communication,
                paymentMethod: editData?.mode_payment_info?.[0]?.mode_of_payment,
                farmerSetting: editData?.action_info?.[0]?.default === 0 ? "yes" : "no",
                rateSetting: editData?.action_info?.[1]?.default === 0 ? "yes" : "no",
                equipmentSetting: editData?.action_info?.[2]?.default === 0 ? "yes" : "no",
                collectionSetting: editData?.action_info?.[3]?.default === 0 ? "yes" : "no",
                printerSetting: editData?.action_info?.[4]?.default === 0 ? "yes" : "no",
                paymentCycleEdit: editData?.action_info?.[5]?.default === 0 ? "yes" : "no",
                incentiveEdit: editData?.action_info?.[6]?.default === 0 ? "yes" : "no",
                milkTypeChart: editData?.ratechart_type?.[0]?.mode,
                rateChart: editData?.ratechart_type[0]?.cow,
                buffaloChart: editData?.ratechart_type[0]?.buffalo,
                commonChart: editData?.ratechart_type[0]?.common,
                cow_chart_id: editData?.ratechart_id[0]?.cow,
                buffalo_chart_id: editData?.ratechart_id[0]?.buffalo,
                common_chart_id: editData?.ratechart_id[0]?.common,
                convertKgLit: editData?.kg_ltr_factor || "",

            });


            const rateChart = editData?.ratechart_type?.[0]
            const idR = editData?.rate_chart?.id
            const rId = editData?.route?.id

            if (rId) {
                setCode(rId)
            }

            if (rateChart) {
                if (rateChart.cow) {
                    setSelectedRate(rateChart.cow)
                    setFindType(1)
                } else if (rateChart.buffalo) {
                    setSelectedRate(rateChart.buffalo)
                    setFindType(2)
                } else {
                    setSelectedRate(rateChart.common)
                    setFindType(3)
                }
            }

            if (idR) {
                setChartType(idR)
            }
        }
    }, [editData, id]);

    const [formData, setFormData] = useState({
        centerCode: "",
        centerName: "",
        taulkName: "Tripur",
        address: "",
        pincode: "",
        latLang: "",
        villageName: "",
        routeName: "",
        rateType: "cowBuffalo",
        cowRT: "",
        buffaloRT: "",
        bothRT: "",
        incentive: "no",
        cowIncentive: "",
        buffaloIncentive: "",
        paymentCycle: "daily",
        session: 0,
        communication: [1, 0, 0, 0],
        paymentMethod: [1, 0, 0],
        farmerSetting: "no",
        rateSetting: "no",
        collectionSetting: "no",
        printerSetting: "no",
        paymentCycleEdit: "no",
        incentiveEdit: "no",
        equipmentSetting: "no",
        rateChart: "",
        chartName: "",
        state: "Tamilnadu",
        city: "Tirupur",
        milkTypeChart: 0,
        buffaloChart: "",
        commonChart: "",
        cow_chart_id: "",
        buffalo_chart_id: "",
        common_chart_id: "",
        rID: "",
        convertKgLit: ""


    });


    const [errors, setErrors] = useState({
        centerCode: "",
        centerName: "",
        taulkName: "",
        address: "",
        pincode: "",
        latLang: "",
        villageName: "",
        routeName: "",
        rateType: "",
        cowRT: "",
        buffaloRT: "",
        bothRT: "",
        incentiveEdit: "",
        cowIncentive: "",
        buffaloIncentive: "",
        paymentCycle: "",
        session: "",
        communication: "",
        state: "",
        city: ""
    })

    const handleInput = (e) => {
        const { name, value } = e.target;
        // Ignore leading spaces for all fields
        if (value.length === 1 && value.trim() === "") {
            return;
        }

        // Capitalize the first letter only for the 'producerName' field
        const updatedValue = name === 'centerName' ? value.charAt(0).toUpperCase() + value.slice(1) : value;
        setFormData({ ...formData, [name]: updatedValue });
        setErrors({ ...errors, [name]: "" }); // Clear error when user interacts

    };


    const [nameError, setNameError] = useState('')

    const [routeData, setRouteData] = useState([])
    const routeNameRef = useRef(null)
    const [code, setCode] = useState(null)
    const [frName, setFrName] = useState(null)
    const [selectedRate, setSelectedRate] = useState('');
    const [chartName, setChartName] = useState([])
    const [buffaloChartName, setBuffaloChartName] = useState([])
    const [commonChartName, setCommonChartName] = useState([])
    const [chartType, setChartType] = useState('')
    const [findtype, setFindType] = useState(null)



    const fetchRoute = async () => {
        const url = "/api/route/list";
        try {
            const response = await get(url);
            if (response.status) {
                setRouteData(response.data)
            }
        } catch (error) {
            console.error("Error while fetching data:", error);
        }
    };
    useEffect(() => {
        fetchRoute()
    }, [])

    const handleButtonClick = (day) => {
        // Add your logic here for handling button clicks
        setFormData({ ...formData, session: day });
        setErrors({ ...errors, session: "" });
    };

    const handleSession = (event) => {
        setFormData({ ...formData, session: parseFloat(event.target.value) });
        setErrors({ ...errors, session: '' });
    }

    const handlecommunicationBillChange = (e) => {
        const isChecked = e.target.checked;

        document.getElementById("Bill").blur();

        const updatedFormData = [...formData.communication];
        updatedFormData[0] = isChecked ? 1 : 0;

        setFormData({ ...formData, communication: updatedFormData });
        setErrors({ ...errors, communication: "" });

    };

    const handlecommunicationWhatsAppChange = (e) => {
        const isChecked = e.target.checked;

        document.getElementById("Whatsapp").blur();

        const updatedFormData = [...formData.communication];
        updatedFormData[1] = isChecked ? 1 : 0;

        setFormData({ ...formData, communication: updatedFormData });
        setErrors({ ...errors, communication: "" });

    };

    const handlecommunicationSMSChange = (e) => {
        const isChecked = e.target.checked;

        document.getElementById("SMS").blur();

        const updatedFormData = [...formData.communication];
        updatedFormData[2] = isChecked ? 1 : 0;

        setFormData({ ...formData, communication: updatedFormData });
        setErrors({ ...errors, communication: "" });

    };

    const handlecommunicationGmailChange = (e) => {
        const isChecked = e.target.checked;

        document.getElementById("Gmail").blur();

        const updatedFormData = [...formData.communication];
        updatedFormData[3] = isChecked ? 1 : 0;

        setFormData({ ...formData, communication: updatedFormData });
        setErrors({ ...errors, communication: "" });

    };

    const handleRoute = (value, id, code) => {
        setFrName(`${code.code} - ${code.name}`)
        setCode(code.id)
        setFormData({ ...formData, rID: id });
        setFormData({ ...formData, routeName: `${code.code} - ${code.name}` });

        setErrors({ ...errors, routeName: "" });
    }
    const handleName = (name) => {
        setFrName(name)
    }

    const validateName = (value) => {
        if (!value || value.trim() === '') {
            setNameError('Center Name is required.');
            return false;
        } else {
            // Add other validation checks as needed
            setNameError('');
        }
    }



    const handleValidate = async (event) => {
        event.preventDefault();
        const newErrors = {};

        // Default fields to validate
        const fieldsToValidate = [
            'centerCode',
            'centerName',
            'taulkName',
            'address',
            'pincode',
            'villageName',
            'routeName',
        ];


        if (formData.incentive === "yes") {
            fieldsToValidate.push("cowIncentive", "buffaloIncentive");

        }
        // Validate the fields
        fieldsToValidate.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;
            }
        });

        if (formData.milkTypeChart === 0) {
            fieldsToValidate.push("rateChart", "cow_chart_id")
        }


        if (formData.milkTypeChart === 1) {
            fieldsToValidate.push("commonChart", "common_chart_id")
        }

        // Set errors state
        setErrors(newErrors);

        // Validate the fields
        fieldsToValidate.forEach((field) => {
            if (field === "milkTypeChart" && (formData[field] === undefined || formData[field].toString().trim() === "")) {
                newErrors[field] = 'Milk Type Chart is required.';
            } else if (field === "milkTypeChart" && parseFloat(formData[field]) === 0) {
                // Treat 0 as a valid value for milkTypeChart
                delete newErrors[field];
            } else if (!formData[field] || formData[field].toString().trim() === "") {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;
            }
        });

        // If there are no errors, proceed with form submission
        if (Object.keys(newErrors).length === 0) {


            if (parseFloat(id) !== 0) {
                setIsButtonDisabled(true);
                const editData = transformDataEdit(formData, code)

                const URL = `/api/direct-farmer/${id}`

                try {
                    const response = await put(URL, editData);
                    if (response.status) {
                        // const vcc = await post(url, reqBody);
                        navigate('/master-view');
                    }
                } catch (error) {
                    console.error("Error while posting data:", error);
                } finally {
                    setIsButtonDisabled(false);
                }
            }

            if (parseFloat(id) === 0) {
                setIsButtonDisabled(true);
                const transformedData = transformData(formData, code)

                try {
                    const DIRECT_URL = '/api/direct-farmer/create'

                    const response = await post(DIRECT_URL, transformedData);

                    if (response.status) {
                        navigate(`/auth-register/${response.data.id}`)

                    }
                } catch (error) {
                    console.error("Error while posting data:", error);
                    scrollToTop();
                } finally {
                    setIsButtonDisabled(false);
                }
            }
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const RateChart = [
        { name: "FLAT", value: 1 },
        { name: "FATSNF", value: 2, },
        { name: "FAT", value: 3, },
        { name: "SNF", value: 4, },
        { name: "TS", value: 5, },
        { name: "FLAT(FAT & SNF)", value: 6, },
    ]



    const handlepaymentCycleTypeChanges = (event) => {

        setFormData({ ...formData, paymentCycle: event.target.value });
        setErrors({ ...errors, paymentCycle: "" }); // Clear error when user interacts
    };

    const [selectMonthEnd, setselectMonthEnd] = useState("MonthEnd");
    const [customValue, setCustomValue] = useState("");

    const handleOptionChange = (option) => {
        setselectMonthEnd(option);

        if (option === "MonthEnd") {
            setCustomValue("");
        }
    };

    const handleDropdownItemClick = (value) => {
        setCustomValue(value);
        setselectMonthEnd("Custom");
    };

    const handleCash = (e) => {
        const isChecked = e.target.checked;
        document.getElementById("cash").blur();

        const updatedFormData = [...formData.paymentMethod];
        updatedFormData[0] = isChecked ? 1 : 0;

        setFormData({ ...formData, paymentMethod: updatedFormData });
        setErrors({ ...errors, paymentMethod: "" });

    };
    const handleBank = (e) => {
        const isChecked = e.target.checked;
        document.getElementById("bank").blur();

        const updatedFormData = [...formData.paymentMethod];
        updatedFormData[1] = isChecked ? 1 : 0;

        setFormData({ ...formData, paymentMethod: updatedFormData });
        setErrors({ ...errors, paymentMethod: "" });

    };
    const handleUpi = (e) => {
        const isChecked = e.target.checked;
        document.getElementById("upi").blur();

        const updatedFormData = [...formData.paymentMethod];
        updatedFormData[2] = isChecked ? 1 : 0;

        setFormData({ ...formData, paymentMethod: updatedFormData });
        setErrors({ ...errors, paymentMethod: "" });

    };

    const fetchFn = async () => {
        const url = `/api/rate-chart/${selectedRate}/list`;
        try {
            const response = await get(url);
            if (response.status) {

                const filterCow = response.data.filter((el) => el.milk_type === 1)
                const filterBuffalo = response.data.filter((el) => el.milk_type === 2)
                const filterCommon = response.data.filter((el) => el.milk_type === 3)

                if (filterCow) {
                    setChartName(filterCow)
                }

                if (filterBuffalo) {
                    setBuffaloChartName(filterBuffalo)
                }

                if (filterCommon) {
                    setCommonChartName(filterCommon)
                }

            }
        } catch (error) {
            console.error("Error while posting data:", error);
        }
    };

    useEffect(() => {
        if (selectedRate) {
            fetchFn()
        }
    }, [selectedRate])



    function numOnly(event) {
        // Allow only numeric characters
        event.target.value = event.target.value.replace(/\D/g, '');
    }

    const handleRatetype = (e) => {
        setFormData({ ...formData, milkTypeChart: parseFloat(e.target.value) });
        setErrors({ ...errors, milkTypeChart: '' });
    }
    const handleChart = (value, type) => {
        setSelectedRate(value)
        setFindType(type)
        if (type === 1) {
            setFormData({ ...formData, rateChart: parseFloat(value) });
            setErrors({ ...errors, rateChart: "" });

        }

        if (type === 2) {
            setFormData({ ...formData, buffaloChart: parseFloat(value) });
            setErrors({ ...errors, buffaloChart: "" });
        }

        if (type === 3) {
            setFormData({ ...formData, commonChart: parseFloat(value) });
            setErrors({ ...errors, commonChart: "" });
        }
    }


    const handleChartType = (value, type) => {
        setChartType(parseFloat(value))
        // setFormData({ ...formData, chartName: parseFloat(value) });
        // setErrors({ ...errors, chartName: "" });

        if (type === 1) {
            setFormData({ ...formData, cow_chart_id: parseFloat(value) });
            setErrors({ ...errors, cow_chart_id: "" });
        }

        if (type === 2) {
            setFormData({ ...formData, buffalo_chart_id: parseFloat(value) });
            setErrors({ ...errors, buffalo_chart_id: "" });
        }

        if (type === 3) {
            setFormData({ ...formData, common_chart_id: parseFloat(value) });
            setErrors({ ...errors, common_chart_id: "" });
        }

    }

    const handleStateChange = (event) => {
        const selectedState = event.target.value;
        // If you wish to update state with this value:
        setFormData({ ...formData, state: selectedState });
    };

    const handleDistrictChange = (event) => {
        const selectedDistrict = event.target.value;
        setFormData((prevData) => ({
            ...prevData,
            city: selectedDistrict
        }));
    };

    const states = [

        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamilnadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
    ];

    //District
    const districts = [

        "Ariyalur",
        "Chennai",
        "Coimbatore",
        "Cuddalore",
        "Dharmapuri",
        "Dindigul",
        "Erode",
        "Kanchipuram",
        "Kanyakumari",
        "Karur",
        "Krishnagiri",
        "Madurai",
        "Nagapattinam",
        "Namakkal",
        "Nilgiris",
        "Perambalur",
        "Pudukkottai",
        "Ramanathapuram",
        "Salem",
        "Sivaganga",
        "Thanjavur",
        "Theni",
        "Thoothukudi (Tuticorin)",
        "Tiruchirappalli",
        "Tirunelveli",
        "Tirupur",
        "Tiruvallur",
        "Tiruvannamalai",
        "Tiruvarur",
        "Vellore",
        "Viluppuram",
        "Virudhunagar",
        // Add more districts as needed
    ];


    return (
        <Layout title="Form controls" content="container">
            <Loader loading={loading} />

            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Collection Center</Block.Title>
                        <nav>
                            <ol className="breadcrumb breadcrumb-arrow mb-0">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Masters
                                </li>
                            </ol>
                        </nav>
                    </Block.HeadContent>
                    <Block.HeadContent>
                        <ul className="d-flex">

                            <li>
                                <Link
                                    to="/master-view"
                                    className="btn btn-primary d-none d-md-inline-flex"
                                >
                                    <Icon name="eye" />
                                    <span>View List</span>
                                </Link>
                            </li>
                        </ul>

                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>
            <Block.Head page>
                <Block.HeadContent>


                    <Card>
                        <Card.Body>

                            <Row className="g-3">
                                <Col xl={8}>

                                    <Row className="g-3">
                                        <Card className="card-gutter-md">
                                            <Card.Body>
                                                <Row className="g-3">
                                                    <Col md="4">
                                                        <SearchDown
                                                            data={routeData}
                                                            placeholder="Route Name"
                                                            name="Route Name"
                                                            errors="Route name is required"
                                                            error={errors.routeName}
                                                            value={formData.routeName}
                                                            nextInputRef={routeNameRef}
                                                            onValidate={validateName}
                                                            onSelect={(selectedItem, id, code) => {
                                                                handleRoute(selectedItem, id, code)
                                                            }}
                                                            func={handleName}
                                                        />
                                                    </Col>
                                                    <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="validationCustom01">
                                                                Center Code
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">
                                                                        *
                                                                    </i>
                                                                </span>
                                                            </Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control
                                                                    type="text"
                                                                    name="centerCode"
                                                                    id="centerCode"
                                                                    placeholder="Enter Center Code"
                                                                    onChange={handleInput}
                                                                    value={formData.centerCode}
                                                                    isInvalid={!!errors.centerCode}
                                                                    onKeyUp={numOnly}
                                                                    required
                                                                />
                                                            </div>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.centerCode}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                    </Col>
                                                    <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="validationCustom01">
                                                                Center name
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">
                                                                        *
                                                                    </i>
                                                                </span>
                                                            </Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control
                                                                    type="text"
                                                                    name="centerName"
                                                                    id="centerName"
                                                                    placeholder="Enter Center Name"
                                                                    onChange={handleInput}
                                                                    value={formData.centerName}
                                                                    isInvalid={!!errors.centerName}
                                                                    required
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.centerName}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="validationCustom04">
                                                                State
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">
                                                                        *
                                                                    </i>
                                                                </span>
                                                            </Form.Label>
                                                            <div className="form-control-wrap">
                                                                <select className="form-select" id="exampleInputState" onChange={handleStateChange} value={formData.state}>
                                                                    {/* <option value="">Select State</option> */}
                                                                    {states.map((state, indexs) => (

                                                                        <option key={indexs} value={state}>
                                                                            {state}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please select a valid state.
                                                                </Form.Control.Feedback>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="validationCustom04">
                                                                District
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">
                                                                        *
                                                                    </i>
                                                                </span>

                                                            </Form.Label>
                                                            <div className="form-control-wrap">
                                                                <select
                                                                    className="form-select"
                                                                    id="exampleInputDistrict"
                                                                    onChange={handleDistrictChange}
                                                                    value={formData.city}  // Bind the value
                                                                >
                                                                    {districts.map((district, index) => (
                                                                        <option key={index} value={district}>
                                                                            {district}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please select a valid state.
                                                                </Form.Control.Feedback>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="centerNo">Taulk name
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">
                                                                        *
                                                                    </i>
                                                                </span>
                                                            </Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control
                                                                    placeholder="Enter Taulk name"
                                                                    type="numeric"
                                                                    id="centerNo"
                                                                    name="taulkName"
                                                                    onChange={handleInput}
                                                                    value={formData.taulkName}
                                                                    isInvalid={!!errors.taulkName}
                                                                />
                                                            </div>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.taulkName}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="ebCollectionNo">
                                                                Address
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">
                                                                        *
                                                                    </i>
                                                                </span>
                                                            </Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control
                                                                    placeholder="Enter The Address"
                                                                    as="textarea" // Use "textarea" instead of "textArea"
                                                                    name="address"
                                                                    id="ebCollectionNo"
                                                                    onChange={handleInput}
                                                                    value={formData.address}
                                                                    isInvalid={!!errors.address}
                                                                />
                                                            </div>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.address}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="userName">Pincode
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">
                                                                        *
                                                                    </i>
                                                                </span>
                                                            </Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control
                                                                    placeholder="Pincode"
                                                                    type="text"
                                                                    id="pincode"
                                                                    name="pincode"
                                                                    maxLength={6}
                                                                    onChange={handleInput}
                                                                    value={formData.pincode}
                                                                    isInvalid={!!errors.pincode}
                                                                />
                                                            </div>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.pincode}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="userName">Lat,Lang</Form.Label>
                                                            <div className="form-control-wrap">
                                                                <Form.Control
                                                                    placeholder="lat&lang"
                                                                    type="text"
                                                                    id="userName"
                                                                    name="latLang"
                                                                    disabled

                                                                    onChange={handleInput}
                                                                    value={formData.latLang}
                                                                    isInvalid={!!errors.latLang}
                                                                />
                                                            </div>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.latLang}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="validationCustomVillageName">
                                                                Village Name
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">
                                                                        *
                                                                    </i>
                                                                </span>
                                                            </Form.Label>
                                                            <div className="form-control-wrap">

                                                                <Form.Control
                                                                    placeholder="Enter The Village Name"
                                                                    type="text"
                                                                    id="validationCustomVillageName"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    name="villageName"
                                                                    onChange={handleInput}
                                                                    value={formData.villageName}
                                                                    isInvalid={!!errors.villageName}

                                                                />
                                                            </div>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.villageName}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>


                                                </Row>
                                            </Card.Body>
                                        </Card>

                                        <Card className="card-gutter-md">
                                            <Card.Body>
                                                <Row className="g-3">

                                                    <Col lg="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="share_mode">
                                                                Milktype base rate chart
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">
                                                                        *
                                                                    </i>
                                                                </span>
                                                            </Form.Label>
                                                            <br />
                                                            <ButtonGroup aria-label="Basic radio toggle button group">
                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="milkTypeChart"
                                                                    id="cowBuffaloYes"
                                                                    autoComplete="off"
                                                                    value='0'
                                                                    checked={formData.milkTypeChart === 0}
                                                                    onChange={handleRatetype} />
                                                                <Button
                                                                    as="label"
                                                                    variant="outline-primary"
                                                                    htmlFor="cowBuffaloYes"
                                                                    style={{ width: "90px", textAlign: "center" }}
                                                                >
                                                                    Cow/Buffalo
                                                                </Button>

                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="milkTypeChart"
                                                                    id="common"
                                                                    autoComplete="off"
                                                                    value="1"
                                                                    checked={formData.milkTypeChart === 1}
                                                                    onChange={handleRatetype} />
                                                                <Button
                                                                    as="label"
                                                                    variant="outline-primary"
                                                                    htmlFor="common"
                                                                    style={{ width: "90px", textAlign: "center" }}
                                                                >
                                                                    Common
                                                                </Button>
                                                            </ButtonGroup>
                                                        </Form.Group>
                                                        {errors.milkTypeChart && (
                                                            <div className="text-danger">{errors.milkTypeChart}</div>
                                                        )}
                                                    </Col>

                                                    {formData.milkTypeChart === 1 && <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="validationCustom04">
                                                                Rate chart type (Common)
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">*</i>
                                                                </span>

                                                            </Form.Label>
                                                            <div className="form-control-wrap">
                                                                <select className="form-select" id="exampleInputState" value={formData.commonChart}
                                                                    onChange={(e) => handleChart(e.target.value, 3)}>
                                                                    <option value="">Select Rate Type</option> {/* This is the default option */}
                                                                    {RateChart.map((type, index) => (
                                                                        <option key={index} value={type.value}>
                                                                            {type.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </Form.Group>
                                                        {errors.commonChart && (
                                                            <div className="text-danger">{errors.commonChart}</div>
                                                        )}
                                                    </Col>}

                                                    {(formData.milkTypeChart === 1 && selectedRate !== 0) && <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="validationCustom04">
                                                                Chart name (Common)
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">*</i>
                                                                </span>

                                                            </Form.Label>
                                                            <div className="form-control-wrap">
                                                                <select className="form-select" id="exampleInputState"
                                                                    onChange={(e) => handleChartType(e.target.value, 3)} value={formData.common_chart_id} >
                                                                    <option value="">Select Rate chartname</option>
                                                                    {commonChartName.map((type, index) => (
                                                                        <option key={index} value={type.id}>
                                                                            {type.rate_chart_name}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                            </div>
                                                            {errors.common_chart_id && (
                                                                <div className="text-danger">{errors.common_chart_id}</div>
                                                            )}
                                                        </Form.Group>
                                                    </Col>}

                                                    {(formData.milkTypeChart === 0) && <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="validationCustom04">
                                                                Rate chart type (Cow)
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">*</i>
                                                                </span>

                                                            </Form.Label>
                                                            <div className="form-control-wrap">
                                                                <select className="form-select" id="exampleInputState" value={formData.rateChart}
                                                                    onChange={(e) => handleChart(e.target.value, 1)}>
                                                                    <option value="">Select Rate Type</option> {/* This is the default option */}
                                                                    {RateChart.map((type, index) => (
                                                                        <option key={index} value={type.value}>
                                                                            {type.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </Form.Group>
                                                        {errors.rateChart && (
                                                            <div className="text-danger">{errors.rateChart}</div>
                                                        )}
                                                    </Col>}

                                                    {(formData.milkTypeChart === 0) && <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="validationCustom04">
                                                                Chart name (Cow)
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">*</i>
                                                                </span>
                                                            </Form.Label>
                                                            <div className="form-control-wrap">
                                                                <select className="form-select" id="exampleInputState"
                                                                    onChange={(e) => handleChartType(e.target.value, 1)} value={formData.cow_chart_id} >
                                                                    <option value="">Select Rate chartname</option>
                                                                    {chartName.map((type, index) => (
                                                                        <option key={index} value={type.id}>
                                                                            {type.rate_chart_name}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                            </div>
                                                            {errors.cow_chart_id && (
                                                                <div className="text-danger">{errors.cow_chart_id}</div>
                                                            )}
                                                        </Form.Group>
                                                    </Col>}
                                                    <Col md="4">

                                                    </Col>
                                                    {(formData.milkTypeChart === 0) && <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="validationCustom04">
                                                                Rate chart type (Buffalo)
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">*</i>
                                                                </span>
                                                            </Form.Label>
                                                            <div className="form-control-wrap">
                                                                <select className="form-select" id="exampleInputState" value={formData.buffaloChart}
                                                                    onChange={(e) => handleChart(e.target.value, 2)}>
                                                                    <option value="">Select Rate Type</option> {/* This is the default option */}
                                                                    {RateChart.map((type, index) => (
                                                                        <option key={index} value={type.value}>
                                                                            {type.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </Form.Group>
                                                        {errors.buffaloChart && (
                                                            <div className="text-danger">{errors.buffaloChart}</div>
                                                        )}
                                                    </Col>}

                                                    {(formData.milkTypeChart === 0) && <Col md="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="validationCustom04">
                                                                Chart name (Buffalo)
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">*</i>
                                                                </span>
                                                            </Form.Label>
                                                            <div className="form-control-wrap">
                                                                <select className="form-select" id="exampleInputState"
                                                                    onChange={(e) => handleChartType(e.target.value, 2)} value={formData.buffalo_chart_id} >
                                                                    <option value="">Select Rate chartname</option>
                                                                    {buffaloChartName.map((type, index) => (
                                                                        <option key={index} value={type.id}>
                                                                            {type.rate_chart_name}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                            </div>
                                                            {errors.buffalo_chart_id && (
                                                                <div className="text-danger">{errors.buffalo_chart_id}</div>
                                                            )}
                                                        </Form.Group>
                                                    </Col>}

                                                </Row>
                                            </Card.Body>
                                        </Card>

                                        <Card className="card-gutter-md">
                                            <Card.Body>
                                                <Row className="g-3">
                                                    <Col lg="4">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="share_mode">
                                                                Incentive Available Type
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">
                                                                        *
                                                                    </i>
                                                                </span>
                                                            </Form.Label>
                                                            <br />
                                                            <ButtonGroup aria-label="Basic radio toggle button group">
                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="incentive"
                                                                    id="incentive"
                                                                    autoComplete="off"
                                                                    value="yes"
                                                                    checked={formData.incentive === "yes"}
                                                                    onChange={handleInput}

                                                                />
                                                                <Button
                                                                    as="label"
                                                                    variant="outline-primary"
                                                                    htmlFor="incentive"
                                                                    style={{ width: "90px", textAlign: "center" }}
                                                                >
                                                                    Yes
                                                                </Button>

                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="incentive"
                                                                    id="IncentiveNO"
                                                                    autoComplete="off"
                                                                    value="no"
                                                                    checked={formData.incentive === "no"}
                                                                    onChange={handleInput}
                                                                />
                                                                <Button
                                                                    as="label"
                                                                    variant="outline-primary"
                                                                    htmlFor="IncentiveNO"
                                                                    style={{ width: "90px", textAlign: "center" }}
                                                                >
                                                                    No
                                                                </Button>
                                                            </ButtonGroup>
                                                        </Form.Group>
                                                    </Col>
                                                    {formData.incentive === "yes" &&
                                                        (<Col lg="6">
                                                            <Row>
                                                                <Col sm={6}>
                                                                    <Form.Group >
                                                                        <Form.Label>Cow (Amt/Lit)
                                                                            <span className="me-2">
                                                                                <i className="fa fa-exclamation-triangle text-danger">*</i>
                                                                            </span>
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type="text" // Use type="text" instead of type="numeric"
                                                                            placeholder="Enter Cow"
                                                                            name="cowIncentive"
                                                                            id="cowIncentive"
                                                                            autoComplete="off"
                                                                            value={formData.cowIncentive}
                                                                            onChange={handleInput}


                                                                            onInput={(e) => {
                                                                                e.target.value = e.target.value.replace(
                                                                                    /[^0-9.]/g,
                                                                                    ""
                                                                                );
                                                                            }}
                                                                            onKeyUp={(e) => {
                                                                                const validCharacters = /^[0-9.]$/;
                                                                                if (!validCharacters.test(e.key)) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                        />

                                                                        {errors.cowIncentive && (
                                                                            <div className="text-danger">
                                                                                Cow Is Required
                                                                            </div>
                                                                        )}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Group >
                                                                        <Form.Label>Buffalo(Amt/Lit)
                                                                            <span className="me-2">
                                                                                <i className="fa fa-exclamation-triangle text-danger">*</i>
                                                                            </span>
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type="text" // Use type="text" instead of type="numeric"
                                                                            placeholder="Enter Buffalo"
                                                                            name="buffaloIncentive"
                                                                            id="buffaloIncentive"
                                                                            autoComplete="off"
                                                                            value={formData.buffaloIncentive}
                                                                            onChange={handleInput}

                                                                            onInput={(e) => {
                                                                                e.target.value = e.target.value.replace(
                                                                                    /[^0-9.]/g,
                                                                                    ""
                                                                                );
                                                                            }}
                                                                            onKeyUp={(e) => {
                                                                                const validCharacters = /^[0-9.]$/;
                                                                                if (!validCharacters.test(e.key)) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                        />
                                                                        {errors.buffaloIncentive && (
                                                                            <div className="text-danger">
                                                                                Buffalo Is Required
                                                                            </div>
                                                                        )}
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        )}
                                                </Row>
                                            </Card.Body>
                                        </Card>


                                        <Card className="card-gutter-md">
                                            <Card.Body>
                                                <Row className="g-3">
                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="validationCustom03">
                                                                Payment Cycle
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">
                                                                        *
                                                                    </i>
                                                                </span>
                                                            </Form.Label>
                                                            <div className="form-control-wrap">
                                                                <ButtonGroup aria-label="Basic radio toggle button group">
                                                                    <input
                                                                        type="radio"
                                                                        className="btn-check"
                                                                        name="Daily"
                                                                        id="dailyRadio"
                                                                        value="daily"
                                                                        autoComplete="off"
                                                                        checked={formData.paymentCycle === "daily"}
                                                                        onClick={handlepaymentCycleTypeChanges}

                                                                    />
                                                                    <Button
                                                                        as="label"
                                                                        variant="outline-primary"
                                                                        htmlFor="dailyRadio"
                                                                    >
                                                                        Daily
                                                                    </Button>

                                                                    <input
                                                                        type="radio"
                                                                        className="btn-check"
                                                                        name="Weekly"
                                                                        id="weeklyRadio"
                                                                        value="weekly"
                                                                        autoComplete="off"
                                                                        checked={formData.paymentCycle === "weekly"}
                                                                        onChange={handlepaymentCycleTypeChanges}

                                                                    />
                                                                    <Button
                                                                        as="label"
                                                                        variant="outline-primary"
                                                                        htmlFor="weeklyRadio"
                                                                    >
                                                                        Weekly
                                                                    </Button>

                                                                    <input
                                                                        type="radio"
                                                                        className="btn-check"
                                                                        name="Monthly"
                                                                        id="monthlyRadio"
                                                                        value="monthly"
                                                                        autoComplete="off"
                                                                        checked={formData.paymentCycle === "monthly"}
                                                                        onChange={handlepaymentCycleTypeChanges}

                                                                    />
                                                                    <Button
                                                                        as="label"
                                                                        variant="outline-primary"
                                                                        htmlFor="monthlyRadio"
                                                                    >
                                                                        Monthly
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </div>
                                                            {errors.paymentCycle && <div className="text-danger">{errors.paymentCycle}</div>}

                                                        </Form.Group>
                                                    </Col>


                                                    {(formData.paymentCycle === "daily") && (
                                                        <Col lg="5">
                                                            <Form.Group >
                                                                <Form.Label htmlFor="WhatsAppField">
                                                                    Daily Session

                                                                    <span className="me-2">
                                                                        <i className="fa fa-exclamation-triangle text-danger">
                                                                            *
                                                                        </i>
                                                                    </span>
                                                                </Form.Label>
                                                                <br />
                                                                <ButtonGroup aria-label="Basic radio toggle button group">
                                                                    <input
                                                                        type="radio"
                                                                        className="btn-check"
                                                                        value="0"
                                                                        name="sessionTime"  // Same name for all radio buttons
                                                                        onClick={handleSession}
                                                                        checked={formData.session === 0}
                                                                        id="mrngRadio"
                                                                        autoComplete="off"
                                                                    />
                                                                    <Button
                                                                        as="label"
                                                                        variant="outline-primary"
                                                                        htmlFor="mrngRadio"
                                                                    >
                                                                        Morning
                                                                    </Button>

                                                                    <input
                                                                        type="radio"
                                                                        className="btn-check"
                                                                        value="1"
                                                                        checked={formData.session === 1}
                                                                        name="sessionTime"  // Same name for all radio buttons
                                                                        id="eveningRadio"
                                                                        onClick={handleSession}
                                                                        autoComplete="off"
                                                                    />
                                                                    <Button
                                                                        as="label"
                                                                        variant="outline-primary"
                                                                        htmlFor="eveningRadio"
                                                                    >
                                                                        Evening
                                                                    </Button>

                                                                    <input
                                                                        type="radio"
                                                                        className="btn-check"
                                                                        value="2"
                                                                        checked={formData.session === 2}
                                                                        name="sessionTime"  // Same name for all radio buttons
                                                                        id="bothRadio"
                                                                        onClick={handleSession}
                                                                        autoComplete="off"
                                                                    />
                                                                    <Button
                                                                        as="label"
                                                                        variant="outline-primary"
                                                                        htmlFor="bothRadio"
                                                                    >
                                                                        Both
                                                                    </Button>
                                                                </ButtonGroup>

                                                            </Form.Group>
                                                        </Col>
                                                    )}

                                                    {formData.paymentCycle === "weekly" && (
                                                        <Col lg="5">
                                                            <Form.Group >
                                                                <Form.Label htmlFor="WeeklySession">
                                                                    Weekly Days :
                                                                </Form.Label>
                                                                <br />

                                                                <Container className="Container">
                                                                    <Row className="button-row">
                                                                        <Col md={4} sm={12}>
                                                                            <ButtonGroup aria-label="Basic radio toggle button group">
                                                                                <input
                                                                                    type="radio"
                                                                                    className="btn-check"
                                                                                    name="Weeklyradio"
                                                                                    id="sunRadio"
                                                                                    autoComplete="off"
                                                                                    value={formData.session}
                                                                                    checked={formData.session === "Sunday"}
                                                                                    onClick={() => handleButtonClick("Sunday")}
                                                                                />
                                                                                <Button
                                                                                    as="label"
                                                                                    variant="outline-primary"
                                                                                    htmlFor="sunRadio"

                                                                                    style={{ minWidth: "80px" }} // Set a minimum width for the buttons
                                                                                >
                                                                                    S
                                                                                </Button>

                                                                                <input
                                                                                    type="radio"
                                                                                    className="btn-check"
                                                                                    name="Weeklyradio"
                                                                                    id="monRadio"
                                                                                    autoComplete="off"
                                                                                    value={formData.session}
                                                                                    onClick={() => handleButtonClick("Monday")}
                                                                                />
                                                                                <Button
                                                                                    as="label"
                                                                                    variant="outline-primary"
                                                                                    htmlFor="monRadio"
                                                                                    checked={formData.session === "Monday"}
                                                                                    style={{ minWidth: "80px" }} // Set a minimum width for the buttons
                                                                                >
                                                                                    M
                                                                                </Button>

                                                                                <input
                                                                                    type="radio"
                                                                                    className="btn-check"
                                                                                    name="Weeklyradio"
                                                                                    id="tueRadio"
                                                                                    autoComplete="off"
                                                                                    value={formData.session}
                                                                                    onClick={() => handleButtonClick("Tuesday")}
                                                                                />
                                                                                <Button
                                                                                    as="label"
                                                                                    variant="outline-primary"
                                                                                    htmlFor="tueRadio"
                                                                                    checked={formData.session === "Tuesday"}
                                                                                    style={{ minWidth: "80px" }} // Set a minimum width for the buttons
                                                                                >
                                                                                    T
                                                                                </Button>
                                                                            </ButtonGroup>
                                                                        </Col>

                                                                        <Col md={4} sm={12}>
                                                                            <ButtonGroup aria-label="Basic radio toggle button group">
                                                                                <input
                                                                                    type="radio"
                                                                                    className="btn-check"
                                                                                    name="Weeklyradio"
                                                                                    id="wedRadio"
                                                                                    autoComplete="off"
                                                                                    value={formData.session}
                                                                                    checked={formData.session === "Wednesday"}
                                                                                    onClick={() =>
                                                                                        handleButtonClick("Wednesday")
                                                                                    }
                                                                                />
                                                                                <Button
                                                                                    as="label"
                                                                                    variant="outline-primary"
                                                                                    htmlFor="wedRadio"
                                                                                    style={{ minWidth: "80px" }} // Set a minimum width for the buttons
                                                                                >
                                                                                    W
                                                                                </Button>
                                                                                <input
                                                                                    type="radio"
                                                                                    className="btn-check"
                                                                                    name="Weeklyradio"
                                                                                    id="thursRadio"
                                                                                    autoComplete="off"

                                                                                    onClick={() =>
                                                                                        handleButtonClick("Thursday")
                                                                                    }
                                                                                />
                                                                                <Button
                                                                                    as="label"
                                                                                    variant="outline-primary"
                                                                                    htmlFor="thursRadio"
                                                                                    value={formData.session}
                                                                                    checked={formData.session === "Thursday"}
                                                                                    style={{ minWidth: "80px" }} // Set a minimum width for the buttons
                                                                                >
                                                                                    T
                                                                                </Button>

                                                                                <input
                                                                                    type="radio"
                                                                                    className="btn-check"
                                                                                    name="Weeklyradio"
                                                                                    id="friRadio"
                                                                                    autoComplete="off"
                                                                                    onClick={() => handleButtonClick("Friday")}
                                                                                />
                                                                                <Button
                                                                                    as="label"
                                                                                    variant="outline-primary"
                                                                                    htmlFor="friRadio"
                                                                                    style={{ minWidth: "80px" }} // Set a minimum width for the buttons
                                                                                >
                                                                                    F
                                                                                </Button>
                                                                            </ButtonGroup>
                                                                        </Col>

                                                                        <Col md={4} sm={12}>
                                                                            <ButtonGroup aria-label="Basic radio toggle button group">
                                                                                <input
                                                                                    type="radio"
                                                                                    className="btn-check"
                                                                                    name="Weeklyradio"
                                                                                    id="satRadio"
                                                                                    autoComplete="off"
                                                                                    onClick={() =>
                                                                                        handleButtonClick("Saturday")
                                                                                    }
                                                                                />
                                                                                <Button
                                                                                    as="label"
                                                                                    variant="outline-primary"
                                                                                    htmlFor="satRadio"
                                                                                    style={{ minWidth: "80px" }} // Set a minimum width for the buttons
                                                                                >
                                                                                    S
                                                                                </Button>
                                                                            </ButtonGroup>

                                                                        </Col>

                                                                    </Row>
                                                                    {errors.session && (
                                                                        <div className="text-danger">{errors.session}</div>
                                                                    )}
                                                                </Container>
                                                                {/* //WeB View */}

                                                                <Container className="WebContainer" >
                                                                    <Col md={6}>
                                                                        <ButtonGroup aria-label="Basic radio toggle button group">
                                                                            <input
                                                                                type="radio"
                                                                                className="btn-check"
                                                                                name="Weeklyradio"
                                                                                id="SundayRadio"
                                                                                autoComplete="off"
                                                                                value={formData.session}
                                                                                checked={formData.session === "6"}
                                                                                onClick={() => handleButtonClick("6")}
                                                                            />
                                                                            <Button
                                                                                as="label"
                                                                                variant="outline-primary"
                                                                                htmlFor="SundayRadio"
                                                                            >
                                                                                S
                                                                            </Button>
                                                                            <input
                                                                                type="radio"
                                                                                className="btn-check"
                                                                                name="Weeklyradio"
                                                                                id="MondayRadio"
                                                                                autoComplete="off"
                                                                                onClick={() => handleButtonClick("0")}
                                                                                value={formData.session}
                                                                                checked={formData.session === "0"}
                                                                            />
                                                                            <Button
                                                                                as="label"
                                                                                variant="outline-primary"
                                                                                htmlFor="MondayRadio"
                                                                            >
                                                                                M
                                                                            </Button>
                                                                            <input
                                                                                type="radio"
                                                                                className="btn-check"
                                                                                name="Weeklyradio"
                                                                                id="TuesdayRadio"
                                                                                autoComplete="off"
                                                                                onClick={() => handleButtonClick("1")}
                                                                                value={formData.session}
                                                                                checked={formData.session === "1"}
                                                                            />
                                                                            <Button
                                                                                as="label"
                                                                                variant="outline-primary"
                                                                                htmlFor="TuesdayRadio"
                                                                            >
                                                                                T
                                                                            </Button>
                                                                            <input
                                                                                type="radio"
                                                                                className="btn-check"
                                                                                name="Weeklyradio"
                                                                                id="WednesdayRadio"
                                                                                autoComplete="off"
                                                                                value={formData.session}
                                                                                checked={formData.session === "2"}
                                                                                onClick={() => handleButtonClick("2")}
                                                                            />
                                                                            <Button
                                                                                as="label"
                                                                                variant="outline-primary"
                                                                                htmlFor="WednesdayRadio"
                                                                            >
                                                                                W
                                                                            </Button>
                                                                            <input
                                                                                type="radio"
                                                                                className="btn-check"
                                                                                name="Weeklyradio"
                                                                                id="ThursdayRadio"
                                                                                autoComplete="off"
                                                                                onClick={() => handleButtonClick("3")}
                                                                                value={formData.session}
                                                                                checked={formData.session === "3"}
                                                                            />
                                                                            <Button
                                                                                as="label"
                                                                                variant="outline-primary"
                                                                                htmlFor="ThursdayRadio"
                                                                            >
                                                                                T
                                                                            </Button>
                                                                            <input
                                                                                type="radio"
                                                                                className="btn-check"
                                                                                name="Weeklyradio"
                                                                                id="FridayRadio"
                                                                                autoComplete="off"
                                                                                onClick={() => handleButtonClick("4")}
                                                                                value={formData.session}
                                                                                checked={formData.session === "4"}
                                                                            />
                                                                            <Button
                                                                                as="label"
                                                                                variant="outline-primary"
                                                                                htmlFor="FridayRadio"
                                                                            >
                                                                                F
                                                                            </Button>
                                                                            <input
                                                                                type="radio"
                                                                                className="btn-check"
                                                                                name="Weeklyradio"
                                                                                id="SaturdayRadio"
                                                                                autoComplete="off"
                                                                                onClick={() => handleButtonClick("5")}
                                                                                value={formData.session}
                                                                                checked={formData.session === "5"}
                                                                            />
                                                                            <Button
                                                                                as="label"
                                                                                variant="outline-primary"
                                                                                htmlFor="SaturdayRadio"
                                                                            >
                                                                                S
                                                                            </Button>
                                                                        </ButtonGroup>
                                                                    </Col>
                                                                </Container>

                                                            </Form.Group>
                                                        </Col>
                                                    )}

                                                    {errors.session && (
                                                        <div className="text-danger">{errors.session}</div>
                                                    )}

                                                    {formData.paymentCycle === "monthly" && (
                                                        <Col lg="5">
                                                            <Form.Group >
                                                                <Form.Label htmlFor="EMailField">
                                                                    Monthly Payment Cycle:
                                                                </Form.Label>
                                                                <ButtonGroup aria-label="Basic radio toggle button group">
                                                                    <Button
                                                                        as="label"
                                                                        checked={formData.custom === "MonthEnd"}
                                                                        value={formData.custom}

                                                                    >
                                                                        Month End
                                                                    </Button>

                                                                    <DropdownButton
                                                                        as={ButtonGroup}
                                                                        title={`Custom${formData.value ? `: ${formData.value}` : ""
                                                                            }`}
                                                                        value={formData.custom}
                                                                        variant={
                                                                            formData.custom === "Custom"
                                                                                ? "primary"
                                                                                : "outline-primary"
                                                                        }
                                                                        id="bg-nested-dropdown"
                                                                    >
                                                                        <Dropdown.Menu className="scrollable-menu"  >
                                                                            {[...Array(27)].map((_, i) => (
                                                                                <Dropdown.Item
                                                                                    key={i + 1}
                                                                                    eventKey={(i + 1).toString()}
                                                                                    onClick={() =>
                                                                                        handleDropdownItemClick(i + 1, "Custom")
                                                                                    }
                                                                                >
                                                                                    {i + 1}
                                                                                </Dropdown.Item>
                                                                            ))}
                                                                        </Dropdown.Menu>
                                                                    </DropdownButton>
                                                                </ButtonGroup>
                                                            </Form.Group>
                                                        </Col>
                                                    )}

                                                    <Col md="6">
                                                        <Form.Group className="form-group">
                                                            <Form.Label htmlFor="start">Communication
                                                                <span className="me-2">
                                                                    <i className="fa fa-exclamation-triangle text-danger">
                                                                        *
                                                                    </i>
                                                                </span></Form.Label>
                                                            <div className="form-control-wrap">
                                                                <ButtonGroup aria-label="Basic checkbox toggle button group">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="btn-check"
                                                                        id="Bill"
                                                                        autoComplete="off"
                                                                        checked={formData.communication[0] === 1}
                                                                        onChange={handlecommunicationBillChange}
                                                                    />
                                                                    <Button
                                                                        as="label"
                                                                        variant="outline-primary"
                                                                        htmlFor="Bill"
                                                                    >
                                                                        <Icon name="printer-fill"></Icon>
                                                                    </Button>

                                                                    <input
                                                                        type="checkbox"
                                                                        className="btn-check"
                                                                        id="Whatsapp"
                                                                        value="Whatsapp"
                                                                        autoComplete="off"
                                                                        checked={formData.communication[1] === 1}
                                                                        onChange={handlecommunicationWhatsAppChange}
                                                                    />
                                                                    <Button
                                                                        as="label"
                                                                        variant="outline-primary"
                                                                        htmlFor="Whatsapp"
                                                                    >
                                                                        <Icon name="whatsapp"></Icon>
                                                                    </Button>

                                                                    <input
                                                                        type="checkbox"
                                                                        className="btn-check"
                                                                        id="SMS"
                                                                        value="SMS"
                                                                        autoComplete="off"
                                                                        checked={formData.communication[2] === 1}
                                                                        onChange={handlecommunicationSMSChange}
                                                                    />
                                                                    <Button
                                                                        as="label"
                                                                        variant="outline-primary"
                                                                        htmlFor="SMS"
                                                                    >
                                                                        <Icon name="chat"></Icon>
                                                                    </Button>

                                                                    <input
                                                                        type="checkbox"
                                                                        className="btn-check"
                                                                        id="Gmail"
                                                                        value="Email"
                                                                        autoComplete="off"
                                                                        checked={formData.communication[3] === 1}
                                                                        onChange={handlecommunicationGmailChange}
                                                                    />
                                                                    <Button
                                                                        as="label"
                                                                        variant="outline-primary"
                                                                        htmlFor="Gmail"
                                                                    >
                                                                        <Icon name="mail"></Icon>
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </div>
                                                            {!formData.communication.includes(1) && (
                                                                <div className="text-danger">Communication is Required</div>
                                                            )}
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg="3">
                                                        <Row >
                                                            <Form.Group className="form-group">
                                                                <Form.Label>
                                                                    Mode of Payment
                                                                    <span className="me-2">
                                                                        <i className="fa fa-exclamation-triangle text-danger">
                                                                            *
                                                                        </i>
                                                                    </span>
                                                                </Form.Label>

                                                                <ButtonGroup aria-label="Basic radio toggle button group">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="btn-check"
                                                                        name="paymentMethodcash"
                                                                        id="cash"
                                                                        autoComplete="off"
                                                                        value="cash"
                                                                        onClick={handleCash}
                                                                        checked={formData.paymentMethod[0] === 1}

                                                                    />
                                                                    <Button
                                                                        as="label"
                                                                        variant="outline-primary"
                                                                        htmlFor="cash"
                                                                    >
                                                                        Cash
                                                                    </Button>

                                                                    <input
                                                                        type="checkbox"
                                                                        className="btn-check"
                                                                        name="paymentMethodBank"
                                                                        id="bank"
                                                                        autoComplete="off"
                                                                        value="bank"
                                                                        onClick={handleBank}
                                                                        checked={formData.paymentMethod[1] === 1}


                                                                    />
                                                                    <Button
                                                                        as="label"
                                                                        variant="outline-primary"
                                                                        htmlFor="bank"
                                                                    >
                                                                        Bank
                                                                    </Button>

                                                                    <input
                                                                        type="checkbox"
                                                                        className="btn-check"
                                                                        name="paymentMethod"
                                                                        id="upi"
                                                                        autoComplete="off"
                                                                        value="upi"
                                                                        onClick={handleUpi}
                                                                        checked={formData.paymentMethod[2] === 1}


                                                                    />
                                                                    <Button
                                                                        as="label"
                                                                        variant="outline-primary"
                                                                        htmlFor="upi"
                                                                    >
                                                                        UPI
                                                                    </Button>
                                                                </ButtonGroup>

                                                            </Form.Group>
                                                            {!formData.paymentMethod.includes(1) && (
                                                                <div className="text-danger">mode of payment is Required</div>
                                                            )}

                                                        </Row>
                                                    </Col>


                                                </Row>
                                            </Card.Body>
                                        </Card>








                                    </Row>
                                </Col>
                                <Col lg={4}>
                                    <Card className="card-gutter-md card-center-content">
                                        <Card.Body>

                                            <Row className="mt-3 mx-5">
                                                <Col sm={12}>
                                                    <Form.Group className="form-group">
                                                        <Form.Label
                                                            htmlFor="validationCustom03"
                                                            className="single-line-label"
                                                        >
                                                            Farmer Setting
                                                            <span className="me-2">
                                                                <i className="fa fa-exclamation-triangle text-danger">
                                                                    *
                                                                </i>
                                                            </span>
                                                        </Form.Label>
                                                        <div className="form-control-wrap">
                                                            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="farmerSetting"
                                                                    id="FarmerSettingYes"
                                                                    autoComplete="off"
                                                                    value="yes"
                                                                    checked={formData.farmerSetting === "yes"}
                                                                    onChange={handleInput}
                                                                />
                                                                <Button as="label" variant="outline-primary" htmlFor="FarmerSettingYes">
                                                                    Yes
                                                                </Button>

                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="farmerSetting"
                                                                    id="FarmerSettingNo"
                                                                    value="no"
                                                                    checked={formData.farmerSetting === "no"}
                                                                    onChange={handleInput}
                                                                />
                                                                <Button as="label" variant="outline-primary" htmlFor="FarmerSettingNo">
                                                                    No
                                                                </Button>
                                                            </div>
                                                        </div>


                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className="mt-3 mx-5">
                                                <Col sm={12}>
                                                    <Form.Group className="form-group">
                                                        <Form.Label
                                                            htmlFor="validationCustom03"
                                                            className="single-line-label"
                                                        >
                                                            Rate Setting
                                                            <span className="me-2">
                                                                <i className="fa fa-exclamation-triangle text-danger">
                                                                    *
                                                                </i>
                                                            </span>
                                                        </Form.Label>
                                                        <div className="form-control-wrap">
                                                            <div
                                                                className="btn-group"
                                                                role="group"
                                                                aria-label="Basic radio toggle button group"
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="rateSetting"
                                                                    id="RateSettingYes"
                                                                    autoComplete="off"
                                                                    value="yes"
                                                                    checked={formData.rateSetting === "yes"}
                                                                    onChange={handleInput}
                                                                />
                                                                <Button
                                                                    as="label"
                                                                    variant="outline-primary"
                                                                    htmlFor="RateSettingYes"
                                                                >
                                                                    Yes
                                                                </Button>

                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="rateSetting"
                                                                    id="RateSettingNo"
                                                                    autoComplete="off"
                                                                    value="no"
                                                                    checked={formData.rateSetting === "no"}
                                                                    onChange={handleInput}
                                                                />
                                                                <Button
                                                                    as="label"
                                                                    variant="outline-primary"
                                                                    htmlFor="RateSettingNo"
                                                                >
                                                                    No
                                                                </Button>
                                                            </div>
                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mt-3 mx-5">
                                                <Col sm={12}>
                                                    <Form.Group className="form-group">
                                                        <Form.Label
                                                            htmlFor="validationCustom03"
                                                            className="single-line-label"
                                                        >
                                                            Equipment Setting
                                                            <span className="me-2">
                                                                <i className="fa fa-exclamation-triangle text-danger">
                                                                    *
                                                                </i>
                                                            </span>
                                                        </Form.Label>
                                                        <div className="form-control-wrap">
                                                            <div
                                                                className="btn-group"
                                                                role="group"
                                                                aria-label="Basic radio toggle button group"
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="equipmentSetting"
                                                                    id="equipmentSettingYes"
                                                                    autoComplete="off"
                                                                    value="yes"
                                                                    checked={formData.equipmentSetting === "yes"}
                                                                    onChange={handleInput}
                                                                />
                                                                <Button
                                                                    as="label"
                                                                    variant="outline-primary"
                                                                    htmlFor="equipmentSettingYes"
                                                                >
                                                                    Yes
                                                                </Button>

                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="equipmentSetting"
                                                                    id="equipmentSettingNo"
                                                                    autoComplete="off"
                                                                    value="no"
                                                                    checked={formData.equipmentSetting === "no"}
                                                                    onChange={handleInput}
                                                                />
                                                                <Button
                                                                    as="label"
                                                                    variant="outline-primary"
                                                                    htmlFor="equipmentSettingNo"
                                                                >
                                                                    No
                                                                </Button>
                                                            </div>
                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className="mt-3 mx-5">
                                                <Col sm={12}>
                                                    <Form.Group className="form-group">
                                                        <Form.Label
                                                            htmlFor="validationCustom03"
                                                            className="single-line-label"
                                                        >
                                                            Collection Setting
                                                            <span className="me-2">
                                                                <i className="fa fa-exclamation-triangle text-danger">
                                                                    *
                                                                </i>
                                                            </span>
                                                        </Form.Label>
                                                        <div className="form-control-wrap">
                                                            <div
                                                                className="btn-group"
                                                                role="group"
                                                                aria-label="Basic radio toggle button group"
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="collectionSetting"
                                                                    id="CollectionSettingYes"
                                                                    autoComplete="off"
                                                                    value="yes"
                                                                    checked={formData.collectionSetting === "yes"}
                                                                    onChange={handleInput}
                                                                />
                                                                <Button
                                                                    as="label"
                                                                    variant="outline-primary"
                                                                    htmlFor="CollectionSettingYes"
                                                                >
                                                                    Yes
                                                                </Button>

                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="collectionSetting"
                                                                    id="CollectionSettingNo"
                                                                    autoComplete="off"
                                                                    value="no"
                                                                    checked={formData.collectionSetting === "no"}
                                                                    onChange={handleInput}
                                                                />
                                                                <Button
                                                                    as="label"
                                                                    variant="outline-primary"
                                                                    htmlFor="CollectionSettingNo"
                                                                >
                                                                    No
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        {errors.CollectionSetting && (
                                                            <div className="text-danger">
                                                                {errors.CollectionSetting}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className="mt-3 mx-5">
                                                <Col sm={12}>
                                                    <Form.Group className="form-group">
                                                        <Form.Label
                                                            htmlFor="validationCustom03"
                                                            className="single-line-label"
                                                        >
                                                            Printer Setting
                                                            <span className="me-2">
                                                                <i className="fa fa-exclamation-triangle text-danger">
                                                                    *
                                                                </i>
                                                            </span>
                                                        </Form.Label>
                                                        <div className="form-control-wrap">
                                                            <div
                                                                className="btn-group"
                                                                role="group"
                                                                aria-label="Basic radio toggle button group"
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="printerSetting"
                                                                    id="printerSettingyes"
                                                                    autoComplete="off"
                                                                    value="yes"
                                                                    checked={formData.printerSetting === "yes"}
                                                                    onChange={handleInput}
                                                                />
                                                                <Button
                                                                    as="label"
                                                                    variant="outline-primary"
                                                                    htmlFor="printerSettingyes"
                                                                >
                                                                    Yes
                                                                </Button>

                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="printerSetting"
                                                                    id="printerSetting"
                                                                    autoComplete="off"
                                                                    value="no"
                                                                    checked={formData.printerSetting === "no"}
                                                                    onChange={handleInput}
                                                                />
                                                                <Button
                                                                    as="label"
                                                                    variant="outline-primary"
                                                                    htmlFor="printerSetting"
                                                                >
                                                                    No
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        {errors.printerSetting && (
                                                            <div className="text-danger">
                                                                {errors.printerSetting}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className="mt-3 mx-5">
                                                <Col sm={12}>
                                                    <Form.Group className="form-group">
                                                        <Form.Label
                                                            htmlFor="validationCustom03"
                                                            className="single-line-label"
                                                        >
                                                            Payment Cycle Edit
                                                            <span className="me-2">
                                                                <i className="fa fa-exclamation-triangle text-danger">
                                                                    *
                                                                </i>
                                                            </span>
                                                        </Form.Label>
                                                        <div className="form-control-wrap">
                                                            <div
                                                                className="btn-group"
                                                                role="group"
                                                                aria-label="Basic radio toggle button group"
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="paymentCycleEdit"
                                                                    id="paymentCycleEditYes"
                                                                    autoComplete="off"
                                                                    value="yes"
                                                                    checked={formData.paymentCycleEdit === "yes"}
                                                                    onChange={handleInput}
                                                                />
                                                                <Button
                                                                    as="label"
                                                                    variant="outline-primary"
                                                                    htmlFor="paymentCycleEditYes"
                                                                >
                                                                    Yes
                                                                </Button>

                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="paymentCycleEdit"
                                                                    id="paymentCycleEditNo"
                                                                    autoComplete="off"
                                                                    value="no"
                                                                    checked={formData.paymentCycleEdit === "no"}
                                                                    onChange={handleInput}
                                                                />
                                                                <Button
                                                                    as="label"
                                                                    variant="outline-primary"
                                                                    htmlFor="paymentCycleEditNo"
                                                                >
                                                                    No
                                                                </Button>
                                                            </div>
                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            {/* Incentive Edit */}
                                            <Row className="mt-3 mx-5">
                                                <Col sm={12}>
                                                    <Form.Group className="form-group">
                                                        <Form.Label
                                                            htmlFor="validationCustom03"
                                                            className="single-line-label"
                                                        >
                                                            Incentive Edit
                                                            <span className="me-2">
                                                                <i className="fa fa-exclamation-triangle text-danger">
                                                                    *
                                                                </i>
                                                            </span>
                                                        </Form.Label>
                                                        <div className="form-control-wrap">
                                                            <ButtonGroup
                                                                className="btn-group"
                                                                role="group"
                                                                aria-label="Basic radio toggle button group"
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="incentiveEdit"
                                                                    id="incentiveEditYes"
                                                                    autoComplete="off"
                                                                    value="yes"
                                                                    checked={formData.incentiveEdit === "yes"}
                                                                    onChange={handleInput}
                                                                />

                                                                <Button
                                                                    as="label"
                                                                    variant="outline-primary"
                                                                    htmlFor="incentiveEditYes"
                                                                >
                                                                    Yes
                                                                </Button>

                                                                <input
                                                                    type="radio"
                                                                    className="btn-check"
                                                                    name="incentiveEdit"
                                                                    id="incentiveEditNo"
                                                                    autoComplete="off"
                                                                    value="no"
                                                                    checked={formData.incentiveEdit === "no"}
                                                                    onChange={handleInput}
                                                                />

                                                                <Button
                                                                    as="label"
                                                                    variant="outline-primary"
                                                                    htmlFor="incentiveEditNo"
                                                                >
                                                                    No
                                                                </Button>
                                                            </ButtonGroup>
                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <div
                                        className="gap-col"
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <ul className="d-flex align-items-center gap g-3">

                                            <li>
                                                <Col className="col-12">
                                                    <Button
                                                        className="mt-2"
                                                        variant="success"
                                                        type="submit"
                                                        onClick={handleValidate}
                                                        disabled={isButtonDisabled}
                                                    >
                                                        Submit
                                                    </Button>
                                                </Col>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>


                    {/* OLD CODE */}
                </Block.HeadContent>
            </Block.Head>
        </Layout>
    );
}

export default CollectionCenter;
