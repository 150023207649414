import { createAsyncThunk } from "@reduxjs/toolkit";
import { post, put } from "../../../api/Api";



// Create Direct Collection (POST)
export const createDirectCollection = createAsyncThunk('directCollection/create', async (collection) => {
    try {
        const endpoint = "/api/direct-collection/create";

        console.log("Creating farmer with data:", collection);

        const response = await post(endpoint, collection);
        console.log("API response:", response);

        return response.data; // Success case

    } catch (error) {
        console.error("API error:", error);
        return error;
    }
});


// Update Farmer (PUT)
export const updateDirectCollection = createAsyncThunk('directFarmerList/update', async (body) => {
    try {
        const endpoint = `/api/direct-collection/${body.id}`;

        console.log("Updating farmer with data:", body);

        const response = await put(endpoint, body);
        console.log("API response:", response);

        return response.data;
    } catch (error) {
        console.error("API error:", error);
        return error;
    }
});

