import { createSlice } from "@reduxjs/toolkit";
import { FarmerDetails } from "../../thunks/direct-thunk/FarmerDetailsThunk";



const FarmerDetailSlice = createSlice({
    name: "FarmerDetails",
    initialState: {
        details: [],
        isLoading: false,
        error: null

    },
    extraReducers(builder) {
        builder.addCase(FarmerDetails.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(FarmerDetails.fulfilled, (state, action) => {
            state.isLoading = false
            state.details = action.payload
            console.log("Data received:", state.details);
        })
        builder.addCase(FarmerDetails.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })
    }
})

export const FarmerDetailsReducer = FarmerDetailSlice.reducer
