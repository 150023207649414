export const generateRandomId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < 16; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }
    return result;
};

export function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
}

export const calculateSerialNumber = (index, currentPage, rowsPerPage) => {
    const page = currentPage ? currentPage : 1
    const itemPerPage = rowsPerPage ? rowsPerPage : 25
    return index + 1 + (page - 1) * (itemPerPage);
};

export function getColumnDisplayName(columnName) {
    switch (columnName) {
        case "Routename":
            return 'route_name';
        case 'M/E':
            return 'session';
        case 'Bill':
            return 'bill_no';
        case 'date':
            return 'bill_date';
        case 'Code':
            return 'farmer_code';
        case 'farmerName':
            return 'farmer_name';
        case 'fat':
            return 'tot_fat';
        case 'snf':
            return 'tot_snf';
        case 'Kg':
            return 'tot_qty_kg';
        case 'litre':
            return 'tot_qty_litre';
        case 'rate':
            return 'tot_rate';
        case 'Amount':
            return 'tot_amount';
        default:
            return columnName;
    }
}

export function getVlccColumnDisplayName(columnName) {
    switch (columnName) {
        case "Routename":
            return 'vc.route_id';
        case 'M/E':
            return 'session';
        case 'Bill':
            return 'bill_no';
        case 'date':
            return 'bill_date';
        case 'farmerCode':
            return 'farmer_code';
        case 'farmerName':
            return 'farmer_name';
        case 'fat':
            return 'tot_fat';
        case 'snf':
            return 'tot_snf';
        case 'KG':
            return 'tot_qty_kg';
        case 'Lit':
            return 'tot_qty_litre';
        case 'rate':
            return 'tot_rate';
        case 'Total':
            return 'tot_amount';
        case 'Center':
            return 'df.code';
        default:
            return columnName;
    }
}

export function getColumnAnalyzer(columnName) {
    switch (columnName) {
        case "Route code":
            return 'routename';
        case 'Code':
            return 'farmercode';
        case 'Farmer Name':
            return 'farmername';
        case 'Date':
            return 'date';
        case 'M/E':
            return 'session';
        case 'Sample No':
            return 'sample_number';
        case 'QTY(Kg)':
            return 'tot_qty_kg';
        case 'FAT':
            return 'fat';
        case 'SNF':
            return 'snf';

        default:
            return columnName;
    }
}

export function getColumnDirect(columnName) {
    switch (columnName) {
        case "Route":
            return 'name';
        case 'R.Code':
            return 'route_id';
        case 'Code':
            return 'code';
        case 'Farmer Name':
            return 'name';
        case 'Mobile':
            return 'mobile_no';

        default:
            return columnName;
    }
}

export function getColumnVlcc(columnName) {
    switch (columnName) {
        case "Center Name":
            return 'name';
        case 'Code':
            return 'code';
        case 'Farmer Name':
            return 'name';
        case 'Mobile':
            return 'mobile_no';

        default:
            return columnName;
    }
}


export function getColumnCenter(columnName) {
    switch (columnName) {
        case "Center Name":
            return 'name';
        case 'Code':
            return 'code';
        case 'Center Name':
            return 'name';
        case "Route":
            return 'name';
        default:
            return columnName;
    }
}

export function removeEmptyKeys(obj) {
    for (const key in obj) {
        if (obj[key] === null || obj[key] === '' || obj[key] === "null") {
            delete obj[key];
        }
    }
    return obj;
}

export function createFormattedJSON(dataArray, params) {
    return dataArray.map(data => ({
        avg_fat: data.avg_fat,
        avg_snf: data.avg_snf,
        code: data.code,
        count: data.count,
        incentive_amount: data.incentive_amount,
        name: data.name,
        route_id: data.route_id,
        tot_amount: data.tot_amount,
        tot_qty_kg: data.tot_qty_kg,
        tot_qty_litre: data.tot_qty_litre,
        tot_rate: data.tot_rate,
        params: {
            from_date: params.from_date,
            from_session: params.from_session,
            route_id: params.route_id,
            to_date: params.to_date,
            to_session: params.to_session
        }
    }));
}
