import React from "react";
import { Card, Form, Col, Row, Container, DropdownButton, Dropdown } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { ButtonGroup, Button } from "react-bootstrap";
import { useState } from "react";
import Swal from "sweetalert2";
import "../../pages/masterCSS/CenterSettingMaster.css";
import { Icon } from "../../components";
import { useEffect } from "react";
import { get } from "./api/Api";


function CenterSetting() {





  // State to hold form data and errors
  const [formData, setFormData] = useState({
    CenterID: "",
    CenterName: "",
    Taluk: "",
    Address: "",
    Pincode: "",
    Location: "",
    Village: "",
    UserID: "",
    BankName: "",
    BranchName: "",
    HolderName: "",
    AccNo: "",
    AccType: "",
    UPIiD: "",
    PaymentCycleEdit: "",
    IncentiveEdit: "",
    PrinterSetting: "",
    CollectionSetting: "",

    Email: "",
    CowRateType: "",
    buffaloRateType: "",
    BothRateType: "",
  });

  const [errors, setErrors] = useState({
    CenterID: "",
    CenterName: "",
    Taluk: "",
    Address: "",
    Pincode: "",
    Location: "",
    Village: "",
    UserID: "",
    BankName: "",
    BranchName: "",
    HolderName: "",
    AccNo: "",
    AccType: "",
    UPIiD: "",
    PaymentCycleEdit: "",
    IncentiveEdit: "",
    PrinterSetting: "",
    CollectionSetting: "",
    RateSetting: "",
    FarmerSetting: "",
    Buffalo: "",
    Cow: "",
    whatsAppField: "",
    SMS: "",
    Email: "",
    CowRateType: "",
    buffaloRateType: "",
    BothRateType: "",
  });

  const handleRadioChange = (event) => {
    const { name, value } = event.target;

    // Ignore leading spaces for all fields
    if (value.length === 1 && value.trim() === "") {
      return;
    }

    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error when user interacts
  };

  //Dropdown Validation Start
  const validateDropdown = (fieldName, selectedValue) => {
    if (selectedValue === "0") {
      return `${fieldName} is required.`;
    }
    return "";
  };

  // In your event handler for dropdown changes
  const handleDropdownChange = (event) => {
    const { name, value } = event.target;
    const error = validateDropdown(name, value);

    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: error });
  };
  //Dropdown Validation End

  // Event handler for form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    const fieldsToValidate = [
      "CenterID",
      "CenterName",
      "Taluk",
      "Address",
      "Pincode",
      "Location",
      "Village",
      "UserID",
      "BankName",
      "BranchName",
      "HolderName",
      "AccNo",
      "AccType",
      "UPIiD",
      "PaymentCycleEdit",
      "IncentiveEdit",
      "PrinterSetting",
      "CollectionSetting",
      "RateSetting",
      "FarmerSetting",
      "Buffalo",
      "Cow",
      "whatsAppField",
      "SMS",
      "Email",
      "CowRateType",
      "buffaloRateType",
      "BothRateType",
    ];

    if (validateEmail(email)) {
      // Valid email, you can proceed with form submission
      // Add your form submission logic here
    } else {
      setEmailError("Invalid email address");
    }

    const isValid = fieldsToValidate.every((field) => !!formData[field]);

    if (!isValid) {
      fieldsToValidate.forEach((field) => {
        if (!formData[field]) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: `${field} is required.`,
          }));
        }
      });
      return;
    }

    // Continue with your form submission logic here
  };

  //Payment Cycle
  const [paymentCycleType, setpaymentCycleType] = useState("BILL"); // Default to 'Own', change this if needed


  const handlepaymentCycleTypeChanges = (event) => {
    setpaymentCycleType(event.target.value);
  };



  //Payment Cycle Weekly
  const handleButtonClick = (day) => {
    // Add your logic here for handling button clicks
  };

  //Payment Cycle Monthly
  const [selectMonthEnd, setselectMonthEnd] = useState("MonthEnd");
  const [customValue, setCustomValue] = useState("");

  const handleOptionChange = (option) => {
    setselectMonthEnd(option);

    if (option === "MonthEnd") {
      setCustomValue("");
    }
  };

  const handleDropdownItemClick = (value) => {
    setCustomValue(value);
    setselectMonthEnd("Custom");
  };


  //Communication Start
  const [showWhatsAppField, setShowWhatsAppField] = useState(false);
  const [showSMSField, setShowSMSField] = useState(false);
  const [showEmailField, setShowEmailField] = useState(false);

  const handleCommunicationCheckBox = (e) => {
    const selectedCheckbox = e.target.id;

    if (selectedCheckbox === "WhatsApp") {
      setShowWhatsAppField(!showWhatsAppField);
    } else if (selectedCheckbox === "SMS") {
      setShowSMSField(!showSMSField);
    } else if (selectedCheckbox === "Email") {
      setShowEmailField(!showEmailField);
    }
  };

  //Communication Email
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  //Communication End

  //Incentive Available Type Start
  const [ShowIncentiveAvailableType, setShowIncentiveAvailableType] =
    useState(false);

  const handleIncentive = (e) => {
    setShowIncentiveAvailableType(e.target.value === "Yes");
  };
  //Incentive Available Type End

  //Mode OF Payment Start
  const [selectedModeOfPayment, setselectedModeOfPayment] = useState("Cash");

  const HandleModeOfPayment = (e) => {
    setselectedModeOfPayment(e.target.value);
  };
  //Mode OF Payment End

  //RateType Buttons
  const [selectedOption, setSelectedOption] = useState(null);

  const handleRadioChanges = (event) => {
    setSelectedOption(event.target.value);
  };

  //Cancel Button

  const alertConfirm = () => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes ",
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          "Deleted",
          "You successfully deleted this file",
          "success"
        ).then(() => {
          setFormData({
            CenterID: "",
            CenterName: "",
            Taluk: "",
            Address: "",
            Pincode: "",
            Location: "",
            Village: "",
            UserID: "",
            BankName: "",
            BranchName: "",
            HolderName: "",
            AccNo: "",
            AccType: "",
            UPIiD: "",
            PaymentCycleEdit: "",
            IncentiveEdit: "",
            PrinterSetting: "",
            CollectionSetting: "",
            RateSetting: "",
            FarmerSetting: "",
            Buffalo: "",
            Cow: "",
            whatsAppField: "",
            SMS: "",
            Email: "",
          });
        });
      }
    });
  };

  return (
    <Layout title="Center Settings" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Center Settings</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Center Settings
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">

              <li>
                <Link
                  to="/center-view"
                  className="btn btn-primary d-none d-md-inline-flex"
                >
                  <Icon name="eye" />
                  <span>View List</span>
                </Link>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block.Head page>
        <Block.HeadContent></Block.HeadContent>
      </Block.Head>

      <Block>
        <Block.Head className="wide-md"></Block.Head>

        <Card>
          {/* <div style={{ marginBottom: "1rem" }}></div>
          <Block.Title tag="h4"> User List</Block.Title> */}
          <Card.Body>
            <Form onSubmit={handleSubmit}>

              <Row>
                <Col lg={8}>
                  <Card>
                    <Card.Body>
                      <Row className="mb-3">
                        <Col sm={4}>
                          <Form.Group>
                            <Form.Label>
                              Center ID
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Center ID"
                              name="CenterID"
                              id="CenterID"
                              value={formData.CenterID}
                              onChange={handleRadioChange}
                            />
                            {errors.CenterID && (
                              <div className="text-danger">{errors.CenterID}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group>
                            <Form.Label>
                              Center Name
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Center Name"
                              name="CenterName"
                              id="CenterName"
                              autoComplete="off"
                              value={formData.CenterName}
                              onChange={handleRadioChange}
                            />
                            {errors.CenterName && (
                              <div className="text-danger">
                                {errors.CenterName}
                              </div>
                            )}
                          </Form.Group>
                        </Col>

                        <Col sm={4}>
                          <Form.Group>
                            <Form.Label>
                              Taluk
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Taluk"
                              name="Taluk"
                              id="Taluk"
                              autoComplete="off"
                              value={formData.Taluk}
                              onChange={handleRadioChange}
                            />
                            {errors.Taluk && (
                              <div className="text-danger">{errors.Taluk}</div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col sm={4}>
                          <Form.Group>
                            <Form.Label>
                              Address
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Address"
                              name="Address"
                              id="Address"
                              autoComplete="off"
                              value={formData.Address}
                              onChange={handleRadioChange}
                            />
                            {errors.Address && (
                              <div className="text-danger">{errors.Address}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group>
                            <Form.Label>
                              Pincode
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>
                            <Form.Control
                              placeholder="Enter Pincode"
                              name="Pincode"
                              id="Pincode"
                              autoComplete="off"
                              value={formData.Pincode}
                              onChange={handleRadioChange}
                              type="numeric"
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              maxLength={6}
                            />
                            {errors.Pincode && (
                              <div className="text-danger">{errors.Pincode}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group>
                            <Form.Label>
                              Location
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Location"
                              name="Location"
                              id="Location"
                              autoComplete="off"
                              value={formData.Location}
                              onChange={handleRadioChange}
                            />
                            {errors.Location && (
                              <div className="text-danger">{errors.Location}</div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>
                              Village
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Village Name"
                              name="Village"
                              id="Village"
                              value={formData.Village}
                              onChange={handleRadioChange}
                            />
                            {errors.Village && (
                              <div className="text-danger">{errors.Village}</div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={4}>
                          <Form.Group>
                            <Form.Label>
                              User ID
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter User ID"
                              name="UserID"
                              id="UserID"
                              value={formData.UserID}
                              onChange={handleRadioChange}
                            />
                            {errors.UserID && (
                              <div className="text-danger">{errors.UserID}</div>
                            )}
                          </Form.Group>
                        </Col>


                      </Row>
                    </Card.Body>
                  </Card>

                  <Card className="card-gutter-md mt-2">
                    <Card.Body>
                      <Row className="g-3 align-items-center">
                        <Col lg={4}>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="share_mode">
                              Incentive Available Type
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>

                            <ButtonGroup aria-label="Basic radio toggle button group">
                              <input
                                type="radio"
                                className="btn-check"
                                name="IncentiveAvailable"
                                id="IncentiveYES"
                                autoComplete="off"
                                value="Yes"
                                onChange={handleIncentive}
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor="IncentiveYES"
                              >
                                Yes
                              </Button>

                              <input
                                type="radio"
                                className="btn-check"
                                name="IncentiveAvailable"
                                id="IncentiveNO"
                                autoComplete="off"
                                value="No"
                                onChange={handleIncentive}
                                defaultChecked
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor="IncentiveNO"
                              >
                                No
                              </Button>
                            </ButtonGroup>
                          </Form.Group>

                        </Col>
                        {ShowIncentiveAvailableType && (
                          <Col lg="6">

                            <Row>
                              <Col sm={6}>
                                <Form.Group controlId="Cow">
                                  <Form.Label>Cow</Form.Label>
                                  <Form.Control
                                    type="text" // Use type="text" instead of type="numeric"
                                    placeholder="Enter Cow"
                                    name="Cow"
                                    id="Cow"
                                    autoComplete="off"
                                    value={formData.Cow}
                                    onChange={handleRadioChange}
                                    onKeyUp={(e) => {
                                      const validCharacters = /^[0-9.]$/;
                                      if (!validCharacters.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9.]/g,
                                        ""
                                      );
                                    }}

                                  />
                                  {errors.Cow && (
                                    <div className="text-danger">
                                      {errors.Cow}
                                    </div>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col sm={6}>
                                <Form.Group controlId="Buffalo">
                                  <Form.Label>Buffalo</Form.Label>
                                  <Form.Control
                                    type="text" // Use type="text" instead of type="numeric"
                                    placeholder="Enter Buffalo"
                                    name="Buffalo"
                                    id="Buffalo"
                                    autoComplete="off"
                                    value={formData.Buffalo}
                                    onChange={handleRadioChange}
                                    onKeyUp={(e) => {
                                      const validCharacters = /^[0-9.]$/;
                                      if (!validCharacters.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9.]/g,
                                        ""
                                      );
                                    }}

                                  />
                                  {errors.Buffalo && (
                                    <div className="text-danger">
                                      {errors.Buffalo}
                                    </div>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                        )}

                        <Col sm={6}>
                          <Card>
                            <Form.Group>
                              <div style={{ marginBottom: "1rem" }}></div>
                              <Form.Label className="mb-4">
                                &nbsp; &nbsp;&nbsp;RateType
                                <span className="me-2">
                                  <i className="fa fa-exclamation-triangle text-danger">
                                    *
                                  </i>
                                </span>
                              </Form.Label>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <ButtonGroup aria-label="Basic radio toggle button group">
                                <input
                                  type="radio"
                                  className="btn-check "
                                  name="btnradio"
                                  id="Cow/Buffalo"
                                  autoComplete="off"
                                  value="cowBuffalo"
                                  onChange={handleRadioChanges}
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="Cow/Buffalo"
                                  className="mb-0"
                                >
                                  Cow/Buffalo
                                </Button>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio"
                                  id="Both"
                                  autoComplete="off"
                                  value="both"
                                  onChange={handleRadioChanges}
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="Both"
                                  className="mb-0"
                                >
                                  Both
                                </Button>
                              </ButtonGroup>
                            </Form.Group>

                            {selectedOption === "cowBuffalo" && (
                              <Row>
                                <Col md="5" className="text-center">
                                  <Form.Group controlId="cowBuffaloField">
                                    <Form.Select
                                      name="CowRateType"
                                      className="md-2 mx-4"
                                      value={formData.CowRateType}
                                      onChange={handleDropdownChange}
                                    >
                                      <option value="0">Choose Cow</option>
                                      <option value="1">FLAT RATE</option>
                                      <option value="2">FAT & SNF</option>
                                      <option value="3">FAT ONLY</option>
                                      <option value="4">SNF ONLY</option>
                                      <option value="5">TS ONLY</option>
                                      <option value="6">FLAT(SNF & FAT)</option>
                                    </Form.Select>
                                  </Form.Group>
                                  {errors.CowRateType && (
                                    <div className="text-danger">
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      {"Cow Is Required"}
                                    </div>
                                  )}
                                </Col>

                                <Col md="5" className="text-center">
                                  <Form.Group controlId="cowBuffaloField">
                                    <Form.Select
                                      name="buffaloRateType"
                                      className="md-6 mx-4"
                                      value={formData.buffaloRateType}
                                      onChange={handleDropdownChange}
                                    >
                                      <option value="0">Choose Buffalo</option>
                                      <option value="1">FLAT RATE</option>
                                      <option value="2">FAT & SNF</option>
                                      <option value="3">FAT ONLY</option>
                                      <option value="4">SNF ONLY</option>
                                      <option value="5">TS ONLY</option>
                                      <option value="6">FLAT(SNF & FAT)</option>
                                    </Form.Select>
                                  </Form.Group>
                                  {errors.buffaloRateType && (
                                    <div className="text-danger">
                                      {" Buffalo Is Required"}
                                    </div>
                                  )}
                                  <div style={{ marginBottom: "1rem" }}></div>
                                </Col>
                              </Row>
                            )}
                            {selectedOption === "both" && (
                              <Col md="10" className="text-center">
                                <Form.Group controlId="bothField">
                                  <Form.Select
                                    name="BothRateType"
                                    className="mx-4"
                                    value={formData.BothRateType}
                                    onChange={handleDropdownChange}
                                  >
                                    <option value="0">Choose</option>
                                    <option value="1">FLAT RATE</option>
                                    <option value="2">FAT & SNF</option>
                                    <option value="3">FAT ONLY</option>
                                    <option value="4">SNF ONLY</option>
                                    <option value="5">TS ONLY</option>
                                    <option value="6">FLAT(SNF & FAT)</option>
                                  </Form.Select>
                                  {errors.BothRateType && (
                                    <div className="text-danger">
                                      {errors.BothRateType}
                                    </div>
                                  )}
                                </Form.Group>

                                <div style={{ marginBottom: "1rem" }}></div>
                              </Col>
                            )}
                          </Card>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className="form-group">
                            <Form.Label>
                              Mode Of Payment
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>
                            <br />
                            <ButtonGroup aria-label="Basic radio toggle button group">
                              <input
                                type="radio"
                                className="btn-check"
                                name="paymentMethod"
                                id="cash"
                                autoComplete="off"
                                value="Cash"
                                onChange={HandleModeOfPayment}
                                defaultChecked
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor="cash"
                              >
                                Cash
                              </Button>

                              <input
                                type="radio"
                                className="btn-check"
                                name="paymentMethod"
                                id="bank"
                                autoComplete="off"
                                value="Bank"
                                onChange={HandleModeOfPayment}
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor="bank"
                              >
                                Bank
                              </Button>

                              <input
                                type="radio"
                                className="btn-check"
                                name="paymentMethod"
                                id="upi"
                                autoComplete="off"
                                value="UPI"
                                onChange={HandleModeOfPayment}
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor="upi"
                              >
                                UPI
                              </Button>
                            </ButtonGroup>
                          </Form.Group>

                        </Col>
                        {selectedModeOfPayment === "Cash" && (<Col lg="6">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="validationCustom03">
                              Payment Cycle
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>
                            <div className="form-control-wrap">
                              <ButtonGroup aria-label="Basic radio toggle button group">
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="paymentCycleType"
                                  id="dailyRadio"
                                  value="daily"
                                  autoComplete="off"
                                  checked={paymentCycleType === "daily"}
                                  onChange={handlepaymentCycleTypeChanges}
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="dailyRadio"
                                >
                                  Daily
                                </Button>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="paymentCycleType"
                                  id="weeklyRadio"
                                  value="weekly"
                                  autoComplete="off"
                                  checked={paymentCycleType === "weekly"}
                                  onChange={handlepaymentCycleTypeChanges}
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="weeklyRadio"
                                >
                                  Weekly
                                </Button>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="paymentCycleType"
                                  id="monthlyRadio"
                                  value="monthly"
                                  autoComplete="off"
                                  checked={paymentCycleType === "monthly"}
                                  onChange={handlepaymentCycleTypeChanges}
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="monthlyRadio"
                                >
                                  Monthly
                                </Button>
                              </ButtonGroup>
                            </div>
                          </Form.Group>


                        </Col>)}
                        {selectedModeOfPayment === "Bank" && (
                          <>

                            <Col md={6}>
                              <Form.Group>
                                <Form.Label>Bank Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="BankName"
                                  id="BankName"
                                  value={formData.BankName}
                                  onChange={handleRadioChange}
                                />
                                {errors.BankName && (
                                  <div className="text-danger">
                                    {errors.BankName}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group>
                                <Form.Label>Branch Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="BranchName"
                                  id="BranchName"
                                  value={formData.BranchName}
                                  onChange={handleRadioChange}
                                />
                                {errors.BranchName && (
                                  <div className="text-danger">
                                    {errors.BranchName}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>

                            <Col md={6}>
                              <Form.Group>
                                <Form.Label>Holder Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="HolderName"
                                  id="HolderName"
                                  value={formData.HolderName}
                                  onChange={handleRadioChange}
                                />
                                {errors.HolderName && (
                                  <div className="text-danger">
                                    {errors.HolderName}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group>
                                <Form.Label>Account No</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="AccNo"
                                  id="AccNo"
                                  value={formData.AccNo}
                                  onChange={handleRadioChange}
                                />
                                {errors.AccNo && (
                                  <div className="text-danger">
                                    {errors.AccNo}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>


                            <Col md={6}>
                              <Form.Group>
                                <Form.Label>Account Type</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="AccType"
                                  id="AccType"
                                  value={formData.AccType}
                                  onChange={handleRadioChange}
                                />
                                {errors.AccType && (
                                  <div className="text-danger">
                                    {errors.AccType}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>

                          </>
                        )}
                        {selectedModeOfPayment === "UPI" && (
                          <Col lg="6">
                            <div>
                              <Form.Group>
                                <Form.Label>UPI ID</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="UPIiD"
                                  id="UPIiD"
                                  value={formData.UPIiD}
                                  onChange={handleRadioChange}
                                />
                                {errors.UPIiD && (
                                  <div className="text-danger">
                                    {errors.UPIiD}
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                          </Col>
                        )}

                        {paymentCycleType === "daily" && (
                          <Col lg="6">
                            <Form.Group>
                              <Form.Label htmlFor="WhatsAppField">
                                Daily Session :
                              </Form.Label>
                              <br />
                              <ButtonGroup aria-label="Basic radio toggle button group">
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="DailyRadio"
                                  id="mrngRadio"
                                  autoComplete="off"
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="mrngRadio"
                                >
                                  Morning
                                </Button>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="DailyRadio"
                                  id="eveningRadio"
                                  autoComplete="off"
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="eveningRadio"
                                >
                                  Evening
                                </Button>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="DailyRadio"
                                  id="bothRadio"
                                  autoComplete="off"
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="bothRadio"
                                >
                                  Both
                                </Button>
                              </ButtonGroup>
                            </Form.Group>
                          </Col>
                        )}

                        {paymentCycleType === "weekly" && (
                          <Col lg="6">
                            <Form.Group>
                              <Form.Label htmlFor="WeeklySession">
                                Weekly Session :
                              </Form.Label>
                              <br />

                              <Container className="Container">
                                <Row className="button-row">
                                  <Col md={4} sm={12}>
                                    <ButtonGroup aria-label="Basic radio toggle button group">
                                      <input
                                        type="radio"
                                        className="btn-check"
                                        name="Weeklyradio"
                                        id="sunRadio"
                                        autoComplete="off"
                                        onClick={() =>
                                          handleButtonClick("Sunday")
                                        }
                                      />
                                      <Button
                                        as="label"
                                        variant="outline-primary"
                                        htmlFor="sunRadio"
                                        style={{ minWidth: "80px" }} // Set a minimum width for the buttons
                                      >
                                        S
                                      </Button>

                                      <input
                                        type="radio"
                                        className="btn-check"
                                        name="Weeklyradio"
                                        id="monRadio"
                                        autoComplete="off"
                                        onClick={() =>
                                          handleButtonClick("Monday")
                                        }
                                      />
                                      <Button
                                        as="label"
                                        variant="outline-primary"
                                        htmlFor="monRadio"
                                        style={{ minWidth: "80px" }} // Set a minimum width for the buttons
                                      >
                                        M
                                      </Button>

                                      <input
                                        type="radio"
                                        className="btn-check"
                                        name="Weeklyradio"
                                        id="tueRadio"
                                        autoComplete="off"
                                        onClick={() =>
                                          handleButtonClick("Tuesday")
                                        }
                                      />
                                      <Button
                                        as="label"
                                        variant="outline-primary"
                                        htmlFor="tueRadio"
                                        style={{ minWidth: "80px" }} // Set a minimum width for the buttons
                                      >
                                        T
                                      </Button>
                                    </ButtonGroup>
                                  </Col>

                                  <Col md={4} sm={12}>
                                    <ButtonGroup aria-label="Basic radio toggle button group">
                                      <input
                                        type="radio"
                                        className="btn-check"
                                        name="Weeklyradio"
                                        id="wedRadio"
                                        autoComplete="off"
                                        onClick={() =>
                                          handleButtonClick("Wednesday")
                                        }
                                      />
                                      <Button
                                        as="label"
                                        variant="outline-primary"
                                        htmlFor="wedRadio"
                                        style={{ minWidth: "80px" }} // Set a minimum width for the buttons
                                      >
                                        W
                                      </Button>
                                      <input
                                        type="radio"
                                        className="btn-check"
                                        name="Weeklyradio"
                                        id="thursRadio"
                                        autoComplete="off"
                                        onClick={() =>
                                          handleButtonClick("Thursday")
                                        }
                                      />
                                      <Button
                                        as="label"
                                        variant="outline-primary"
                                        htmlFor="thursRadio"
                                        style={{ minWidth: "80px" }} // Set a minimum width for the buttons
                                      >
                                        T
                                      </Button>

                                      <input
                                        type="radio"
                                        className="btn-check"
                                        name="Weeklyradio"
                                        id="friRadio"
                                        autoComplete="off"
                                        onClick={() =>
                                          handleButtonClick("Friday")
                                        }
                                      />
                                      <Button
                                        as="label"
                                        variant="outline-primary"
                                        htmlFor="friRadio"
                                        style={{ minWidth: "80px" }} // Set a minimum width for the buttons
                                      >
                                        F
                                      </Button>
                                    </ButtonGroup>
                                  </Col>

                                  <Col md={4} sm={12}>
                                    <ButtonGroup aria-label="Basic radio toggle button group">
                                      <input
                                        type="radio"
                                        className="btn-check"
                                        name="Weeklyradio"
                                        id="satRadio"
                                        autoComplete="off"
                                        onClick={() =>
                                          handleButtonClick("Saturday")
                                        }
                                      />
                                      <Button
                                        as="label"
                                        variant="outline-primary"
                                        htmlFor="satRadio"
                                        style={{ minWidth: "80px" }} // Set a minimum width for the buttons
                                      >
                                        S
                                      </Button>
                                    </ButtonGroup>
                                  </Col>
                                </Row>
                              </Container>
                              {/* //WeB View */}
                              <Container className="WebContainer">
                                <Col md={6}>
                                  <ButtonGroup aria-label="Basic radio toggle button group">
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="Weeklyradio"
                                      id="SundayRadio"
                                      autoComplete="off"
                                      onClick={() => handleButtonClick("Sunday")}
                                    />
                                    <Button
                                      as="label"
                                      variant="outline-primary"
                                      htmlFor="SundayRadio"
                                    >
                                      S
                                    </Button>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="Weeklyradio"
                                      id="MondayRadio"
                                      autoComplete="off"
                                      onClick={() => handleButtonClick("Monday")}
                                    />
                                    <Button
                                      as="label"
                                      variant="outline-primary"
                                      htmlFor="MondayRadio"
                                    >
                                      M
                                    </Button>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="Weeklyradio"
                                      id="TuesdayRadio"
                                      autoComplete="off"
                                      onClick={() => handleButtonClick("Tuesday")}
                                    />
                                    <Button
                                      as="label"
                                      variant="outline-primary"
                                      htmlFor="TuesdayRadio"
                                    >
                                      T
                                    </Button>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="Weeklyradio"
                                      id="WednesdayRadio"
                                      autoComplete="off"
                                      onClick={() =>
                                        handleButtonClick("Wednesday")
                                      }
                                    />
                                    <Button
                                      as="label"
                                      variant="outline-primary"
                                      htmlFor="WednesdayRadio"
                                    >
                                      W
                                    </Button>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="Weeklyradio"
                                      id="ThursdayRadio"
                                      autoComplete="off"
                                      onClick={() =>
                                        handleButtonClick("Thursday")
                                      }
                                    />
                                    <Button
                                      as="label"
                                      variant="outline-primary"
                                      htmlFor="ThursdayRadio"
                                    >
                                      T
                                    </Button>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="Weeklyradio"
                                      id="FridayRadio"
                                      autoComplete="off"
                                      onClick={() => handleButtonClick("Friday")}
                                    />
                                    <Button
                                      as="label"
                                      variant="outline-primary"
                                      htmlFor="FridayRadio"
                                    >
                                      F
                                    </Button>
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="Weeklyradio"
                                      id="SaturdayRadio"
                                      autoComplete="off"
                                      onClick={() =>
                                        handleButtonClick("Saturday")
                                      }
                                    />
                                    <Button
                                      as="label"
                                      variant="outline-primary"
                                      htmlFor="SaturdayRadio"
                                    >
                                      S
                                    </Button>
                                  </ButtonGroup>
                                </Col>
                              </Container>
                            </Form.Group>
                          </Col>
                        )}

                        {paymentCycleType === "monthly" && (
                          <Col lg="6">
                            <Form.Group>
                              <Form.Label htmlFor="EMailField">
                                Monthly Payment Cycle:
                              </Form.Label>
                              <ButtonGroup aria-label="Basic radio toggle button group">
                                <Button
                                  as="label"
                                  variant={
                                    selectMonthEnd === "MonthEnd"
                                      ? "primary"
                                      : "outline-primary"
                                  }
                                  className={`${selectMonthEnd === "MonthEnd" ? "active" : ""
                                    }`}
                                  onClick={() => handleOptionChange("MonthEnd")}
                                >
                                  Month End
                                </Button>

                                <DropdownButton
                                  as={ButtonGroup}
                                  title={`Custom${customValue ? `: ${customValue}` : ""
                                    }`}
                                  variant={
                                    selectMonthEnd === "Custom"
                                      ? "primary"
                                      : "outline-primary"
                                  }
                                  id="bg-nested-dropdown"
                                >
                                  <Dropdown.Menu className="scrollable-menu">
                                    {[...Array(27)].map((_, i) => (
                                      <Dropdown.Item
                                        key={i + 1}
                                        eventKey={(i + 1).toString()}
                                        onClick={() =>
                                          handleDropdownItemClick(i + 1)
                                        }
                                      >
                                        {i + 1}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </DropdownButton>
                              </ButtonGroup>
                            </Form.Group>
                          </Col>
                        )}
                        <Col sm={6}>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="validationCustom03">
                              Communication
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>

                            <ButtonGroup aria-label="Basic checkbox toggle button group">
                              <input
                                type="checkbox"
                                className="btn-check"
                                id="Bill"
                                autoComplete="off"
                                onChange={handleCommunicationCheckBox}
                                defaultChecked
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor="Bill"
                                style={{ fontSize: "20px" }}
                              >
                                <Icon name="printer-fill"></Icon>
                              </Button>

                              <input
                                type="checkbox"
                                className="btn-check"
                                id="WhatsApp"
                                autoComplete="off"
                                onChange={handleCommunicationCheckBox}
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor="WhatsApp"
                                style={{ fontSize: "20px" }}
                              >
                                <Icon name="whatsapp"></Icon>
                              </Button>

                              <input
                                type="checkbox"
                                className="btn-check"
                                id="SMS"
                                autoComplete="off"
                                onChange={handleCommunicationCheckBox}
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor="SMS"
                                style={{ fontSize: "20px" }}
                              >
                                <Icon name="chat-fill" />
                              </Button>

                              <input
                                type="checkbox"
                                className="btn-check"
                                id="Email"
                                autoComplete="off"
                                onChange={handleCommunicationCheckBox}
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor="Email"
                                style={{ fontSize: "20px" }}
                              >
                                <Icon name="mail"></Icon>
                              </Button>
                            </ButtonGroup>
                          </Form.Group>


                        </Col>
                        {showWhatsAppField && (
                          <Col md={6}>
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="whatsAppField">
                                WhatsApp No
                              </Form.Label>
                              <Form.Control
                                placeholder="Enter whatsAppField"
                                name="whatsAppField"
                                id="whatsAppField"
                                autoComplete="off"
                                value={formData.whatsAppField}
                                onChange={handleRadioChange}
                                type="numeric"
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                maxLength={10}
                              />
                              {errors.whatsAppField && (
                                <div className="text-danger">
                                  {errors.whatsAppField}
                                </div>
                              )}
                            </Form.Group>
                          </Col>
                        )}

                        {showSMSField && (
                          <Col md={6}>
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="SMS">SMS No</Form.Label>
                              <Form.Control
                                placeholder="Enter SMS"
                                name="SMS"
                                id="SMS"
                                autoComplete="off"
                                value={formData.SMS}
                                onChange={handleRadioChange}
                                type="numeric"
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                maxLength={10}
                              />
                              {errors.SMS && (
                                <div className="text-danger">
                                  {errors.SMS}
                                </div>
                              )}
                            </Form.Group>
                          </Col>
                        )}

                        {showEmailField && (
                          <Col md={6}>
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="Email">Email ID</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Email"
                                name="Email"
                                id="Email"
                                value={formData.Email}
                                onChange={handleRadioChange}
                              />
                              {errors.Email && (
                                <div className="text-danger">
                                  {errors.Email}
                                </div>
                              )}
                            </Form.Group>
                          </Col>
                        )}
                      </Row>

                    </Card.Body>
                  </Card>
                </Col>

                <Col lg={4}>
                  <Card className="card-gutter-md card-center-content">
                    <Card.Body>

                      <Row className="mt-3 mx-5">
                        <Col sm={12}>
                          <Form.Group className="form-group">
                            <Form.Label
                              htmlFor="validationCustom03"
                              className="single-line-label"
                            >
                              Farmer Setting
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>
                            <div className="form-control-wrap">
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Basic radio toggle button group"
                              >
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="FarmerSetting"
                                  id="FarmerSettingYes"
                                  autoComplete="off"
                                  value="Yes"
                                  checked={formData.FarmerSetting === "Yes"}
                                  onChange={handleRadioChange}
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="FarmerSettingYes"
                                >
                                  Yes
                                </Button>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="FarmerSetting"
                                  id="FarmerSettingNo"
                                  autoComplete="off"
                                  value="No"
                                  checked={formData.FarmerSetting === "No"}
                                  onChange={handleRadioChange}
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="FarmerSettingNo"
                                >
                                  No
                                </Button>
                              </div>
                            </div>
                            {errors.FarmerSetting && (
                              <div className="text-danger">
                                {errors.FarmerSetting}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="mt-3 mx-5">
                        <Col sm={12}>
                          <Form.Group className="form-group">
                            <Form.Label
                              htmlFor="validationCustom03"
                              className="single-line-label"
                            >
                              Rate Setting
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>
                            <div className="form-control-wrap">
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Basic radio toggle button group"
                              >
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="RateSetting"
                                  id="RateSettingYes"
                                  autoComplete="off"
                                  value="Yes"
                                  checked={formData.RateSetting === "Yes"}
                                  onChange={handleRadioChange}
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="RateSettingYes"
                                >
                                  Yes
                                </Button>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="RateSetting"
                                  id="RateSettingNo"
                                  autoComplete="off"
                                  value="No"
                                  checked={formData.RateSetting === "No"}
                                  onChange={handleRadioChange}
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="RateSettingNo"
                                >
                                  No
                                </Button>
                              </div>
                            </div>
                            {errors.RateSetting && (
                              <div className="text-danger">
                                {errors.RateSetting}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="mt-3 mx-5">
                        <Col sm={12}>
                          <Form.Group className="form-group">
                            <Form.Label
                              htmlFor="validationCustom03"
                              className="single-line-label"
                            >
                              Collection Setting
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>
                            <div className="form-control-wrap">
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Basic radio toggle button group"
                              >
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="CollectionSetting"
                                  id="CollectionSettingYes"
                                  autoComplete="off"
                                  value="Yes"
                                  checked={formData.CollectionSetting === "Yes"}
                                  onChange={handleRadioChange}
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="CollectionSettingYes"
                                >
                                  Yes
                                </Button>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="CollectionSetting"
                                  id="CollectionSettingNo"
                                  autoComplete="off"
                                  value="No"
                                  checked={formData.CollectionSetting === "No"}
                                  onChange={handleRadioChange}
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="CollectionSettingNo"
                                >
                                  No
                                </Button>
                              </div>
                            </div>
                            {errors.CollectionSetting && (
                              <div className="text-danger">
                                {errors.CollectionSetting}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="mt-3 mx-5">
                        <Col sm={12}>
                          <Form.Group className="form-group">
                            <Form.Label
                              htmlFor="validationCustom03"
                              className="single-line-label"
                            >
                              Printer Setting
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>
                            <div className="form-control-wrap">
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Basic radio toggle button group"
                              >
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="PrinterSetting"
                                  id="PrinterSettingYes"
                                  autoComplete="off"
                                  value="Yes"
                                  checked={formData.PrinterSetting === "Yes"}
                                  onChange={handleRadioChange}
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="PrinterSettingYes"
                                >
                                  Yes
                                </Button>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="PrinterSetting"
                                  id="PrinterSettingNo"
                                  autoComplete="off"
                                  value="No"
                                  checked={formData.PrinterSetting === "No"}
                                  onChange={handleRadioChange}
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="PrinterSettingNo"
                                >
                                  No
                                </Button>
                              </div>
                            </div>
                            {errors.PrinterSetting && (
                              <div className="text-danger">
                                {errors.PrinterSetting}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="mt-3 mx-5">
                        <Col sm={12}>
                          <Form.Group className="form-group">
                            <Form.Label
                              htmlFor="validationCustom03"
                              className="single-line-label"
                            >
                              Payment Cycle Edit
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>
                            <div className="form-control-wrap">
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Basic radio toggle button group"
                              >
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="PaymentCycleEdit"
                                  id="PaymentCycleEditYes"
                                  autoComplete="off"
                                  value="Yes"
                                  checked={formData.PaymentCycleEdit === "Yes"}
                                  onChange={handleRadioChange}
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="PaymentCycleEditYes"
                                >
                                  Yes
                                </Button>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="PaymentCycleEdit"
                                  id="PaymentCycleEditNo"
                                  autoComplete="off"
                                  value="No"
                                  checked={formData.PaymentCycleEdit === "No"}
                                  onChange={handleRadioChange}
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="PaymentCycleEditNo"
                                >
                                  No
                                </Button>
                              </div>
                            </div>
                            {errors.PaymentCycleEdit && (
                              <div className="text-danger">
                                {errors.PaymentCycleEdit}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* Incentive Edit */}
                      <Row className="mt-3 mx-5">
                        <Col sm={12}>
                          <Form.Group className="form-group">
                            <Form.Label
                              htmlFor="validationCustom03"
                              className="single-line-label"
                            >
                              Incentive Edit
                              <span className="me-2">
                                <i className="fa fa-exclamation-triangle text-danger">
                                  *
                                </i>
                              </span>
                            </Form.Label>
                            <div className="form-control-wrap">
                              <ButtonGroup
                                className="btn-group"
                                role="group"
                                aria-label="Basic radio toggle button group"
                              >
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="IncentiveEdit"
                                  id="IncentiveEditYes"
                                  autoComplete="off"
                                  value="Yes"
                                  checked={formData.IncentiveEdit === "Yes"}
                                  onChange={handleRadioChange}
                                />

                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="IncentiveEditYes"
                                >
                                  Yes
                                </Button>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="IncentiveEdit"
                                  id="IncentiveEditNo"
                                  autoComplete="off"
                                  value="No"
                                  checked={formData.IncentiveEdit === "No"}
                                  onChange={handleRadioChange}
                                />

                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="IncentiveEditNo"
                                >
                                  No
                                </Button>
                              </ButtonGroup>
                            </div>
                            {errors.IncentiveEdit && (
                              <Col className="d-flex align-items-center text-danger">
                                {errors.IncentiveEdit}
                              </Col>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <br />
              <Row>
                <Col className="col-12">
                  <div
                    className="gap-col"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <ul className="d-flex align-items-center gap g-3">
                      <li>
                        <Button
                          className="mt-2"
                          variant="danger"
                          onClick={() => alertConfirm()}
                        >
                          Cancel
                        </Button>
                      </li>
                      <li>
                        <Button
                          type="submit"
                          variant="success"
                          className="mt-2"
                        >
                          Submit
                        </Button>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  );
}
export default CenterSetting;
