import React from 'react';
import { Modal, Button, Col, Row, Form } from 'react-bootstrap';

function FarmerDetailsModal({
    show,
    farmerDetails,
    onClose,
    closeLabel = "Close",
}) {

    const fetchData = farmerDetails
    console.log(farmerDetails, "farmerDetails");



    return (
        <Modal show={show} onHide={onClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Farmer Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3">
                    <Col xl="3">

                        <Form.Group className="form-group">
                            <Form.Label htmlFor="validationCustom04">
                                <strong>Farmer </strong>
                            </Form.Label>
                            <div className="form-control-wrap">
                                {fetchData?.code} - {fetchData?.name}
                            </div>

                        </Form.Group>

                    </Col>

                    <Col xl="3">
                        <Form.Group className="form-group">
                            <Form.Label htmlFor="validationCustom04">
                                <strong>Route </strong>
                            </Form.Label>
                            <div className="form-control-wrap">
                                {fetchData?.route?.code} - {fetchData?.route?.name}
                            </div>

                        </Form.Group>
                    </Col>

                    <Col xl="3">
                        <Form.Group className="form-group">
                            <Form.Label htmlFor="validationCustom04">
                                <strong>Mobile Number</strong>
                            </Form.Label>
                            <div className="form-control-wrap">
                                {fetchData?.mobile_no}
                            </div>

                        </Form.Group>

                    </Col>
                    <Col xl="3">
                        <Form.Group className="form-group">
                            <Form.Label htmlFor="validationCustom04">
                                <strong>Milk Type</strong>
                            </Form.Label>
                            <div className="form-control-wrap">
                                {fetchData?.milk_info.milk_type[0]}
                            </div>

                        </Form.Group>
                    </Col>

                    {fetchData?.incentive_info.incentive === "Yes" && <Col xl="3">
                        <Form.Group className="form-group">
                            <Form.Label htmlFor="validationCustom04">
                                <strong>Communication:</strong>
                            </Form.Label>
                            <div className="form-control-wrap">
                                COW: {fetchData?.incentive_info.incentive_info.cow} BUFFALO: {fetchData?.incentive_info.incentive_info.buffalo}
                            </div>

                        </Form.Group>

                    </Col>}

                    <Col xl="3">
                        <Form.Group className="form-group">
                            <Form.Label htmlFor="validationCustom04">
                                <strong>Mode of Payment:</strong>
                            </Form.Label>
                            <div className="form-control-wrap">
                                {fetchData?.mode_payment_info?.[0].mode_of_payment === 0 ? "CASH" :
                                    fetchData?.mode_payment_info?.[0].mode_of_payment === 1 ? "BANK" :
                                        fetchData?.mode_payment_info?.[0].mode_of_payment === 2 ? "UPI" : ""}
                            </div>

                        </Form.Group>
                    </Col>

                    <Col xl="3">
                        <Form.Group className="form-group">
                            <Form.Label htmlFor="validationCustom04">
                                <strong>Payment Cycle:</strong>
                            </Form.Label>
                            <div className="form-control-wrap">
                                {fetchData?.payment_cycle?.[0].payment_cycle}
                            </div>
                        </Form.Group>
                    </Col>

                    <Col xl="3">
                        <Form.Group className="form-group">
                            <Form.Label htmlFor="validationCustom04">
                                <strong>Session:</strong>
                            </Form.Label>
                            <div className="form-control-wrap">
                                {fetchData?.payment_cycle?.[0].session}
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>{closeLabel}</Button>
            </Modal.Footer>
        </Modal >
    );
}

export default FarmerDetailsModal;
