import { createSlice } from "@reduxjs/toolkit";
import { VlccRouteInCenter } from "../../thunks/vlcc-thunk/VlccCenterRoute";



const vlccRouteCenterSlice = createSlice({
    name: "vlccCenter",
    initialState: {
        centerList: [],
        isLoading: false,
        error: null

    },
    extraReducers(builder) {
        builder.addCase(VlccRouteInCenter.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(VlccRouteInCenter.fulfilled, (state, action) => {
            state.isLoading = false
            state.centerList = action.payload
        })
        builder.addCase(VlccRouteInCenter.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })


    }
})

export const VlccRouteCenterReducer = vlccRouteCenterSlice.reducer
