import { useLayoutEffect, useRef } from 'react';
import { Datepicker } from 'vanillajs-datepicker';
import { Form } from 'react-bootstrap';

function DatePicker({ maxDate, onDateChange, className, value, minDate, id, ...props }) {
  const { autoHide, clearBtn, format, maxView, pickLevel, startView, title, todayBtn, weekStart } = props;
  const dateInput = useRef(null);

  console.log(typeof value, value, "value ==>");

  useLayoutEffect(() => {
    let opt = {
      title: title || '',
      buttonClass: 'btn btn-md',
      autohide: autoHide || true,
      clearBtn: clearBtn || false,
      todayBtn: todayBtn || false,
      format: format || 'yyyy-mm-dd',
      maxView: maxView || 3,
      pickLevel: pickLevel || 0,
      startView: startView || 0,
      weekStart: weekStart || 0,
      minDate: minDate,
      maxDate: maxDate,

    };

    new Datepicker(dateInput.current, opt);
    const startInput = dateInput.current;

    const handleChange = () => {
      if (onDateChange) {
        const startDate = startInput.value;
        console.log(startDate, "startDate");
        onDateChange(startDate);
      }
    };

    startInput.addEventListener('changeDate', handleChange);

  }, [onDateChange, value]);

  return (
    <>
      <Form.Control
        className={className}
        readOnly
        defaultValue={value}
        ref={dateInput}
        id={id}
        placeholder="dd-mm-yyyy"
        autoComplete="off"
        name="start"
        type="text"
      />
    </>
  );
}

export default DatePicker;
