import { createSlice } from "@reduxjs/toolkit";
import { SettingsDetails } from "../../thunks/settings-thunk/SettingsThunk";

const SettingsDetailsSlice = createSlice({
    name: "Settings",
    initialState: {
        settingsDetails: [],
        isLoading: false,
        error: null

    },
    extraReducers(builder) {
        builder.addCase(SettingsDetails.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(SettingsDetails.fulfilled, (state, action) => {
            state.isLoading = false
            state.settingsDetails = action.payload
            console.log("Data received:", action.payload);
        })
        builder.addCase(SettingsDetails.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })

    }
})


export const SettingsDetailsReducer = SettingsDetailsSlice.reducer