import { createSlice } from "@reduxjs/toolkit";
import { rateChartList, rateChartStatus } from "../../thunks/ratechart-thunk/RateChartThunk";



const RateChartSlice = createSlice({
    name: "rateChart",
    initialState: {
        data: [],
        isLoading: false,
        error: null

    },
    extraReducers(builder) {
        builder.addCase(rateChartList.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(rateChartList.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            console.log("Data received:", action.payload);
        })
        builder.addCase(rateChartList.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })

        // Rate chart status

        builder.addCase(rateChartStatus.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(rateChartStatus.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            console.log("Data received:", action.payload);
        })
        builder.addCase(rateChartStatus.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })

    }
})

export const RateChartReducer = RateChartSlice.reducer