import { createSlice } from "@reduxjs/toolkit";
import { updateDirectCollection } from "../../thunks/direct-collection-thunk/DirectCollection";



const CollectionUpdateSlice = createSlice({
    name: "Direct Collection Update",
    initialState: {
        collection: [],
        isLoading: false,
        error: null

    },
    extraReducers(builder) {
        builder.addCase(updateDirectCollection.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(updateDirectCollection.fulfilled, (state, action) => {
            state.isLoading = false
            state.collection = action.payload

        })
        builder.addCase(updateDirectCollection.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })
    }
})

export const updateDirectCollectionReducer = CollectionUpdateSlice.reducer