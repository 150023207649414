import { createSlice } from "@reduxjs/toolkit";
import { rateChartDetails } from "../../thunks/ratechart-thunk/RateChartThunk";



const RateChartDetailsSlice = createSlice({
    name: "rateChart",
    initialState: {
        details: [],
        isLoading: false,
        error: null

    },
    extraReducers(builder) {
        builder.addCase(rateChartDetails.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(rateChartDetails.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            console.log("Data received:", action.payload);
        })
        builder.addCase(rateChartDetails.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })
    }
})

export const RateChartDetailsReducer = RateChartDetailsSlice.reducer