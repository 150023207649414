import { createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../../api/Api";


export const fetchEntryList = createAsyncThunk('rateChart/fetch', async ({ data }) => {
    try {
        const endpoint = `/api/rate-chart/list`;

        const response = await post(endpoint, data);
        console.log("API response:", response);

        return response.data;
    } catch (error) {
        console.error("API error:", error);
        throw error; // Rethrow the error to be caught in the rejected action
    }
});
