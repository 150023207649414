import { createSlice } from "@reduxjs/toolkit";
import { ProfileDetails } from "../../thunks/profile-thunk/Profile";

const ProfileSlice = createSlice({
    name: "profile",
    initialState: {
        data: [],
        isLoading: false,
        error: null

    },
    extraReducers(builder) {
        builder.addCase(ProfileDetails.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(ProfileDetails.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload

        })
        builder.addCase(ProfileDetails.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })
    }
})

export const ProfileReducer = ProfileSlice.reducer