import Layout from "../../../layout/default";
import Block from "../../../components/Block/Block";
import { Card, Form, Col, Row, ButtonGroup, Table, Button, Modal, } from "react-bootstrap";
// import { Link, useNavigate } from "react-router-dom";
import { Icon } from "../../../components";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Swal from "sweetalert2";
import { CodeDropDown } from "../shared/dropDown/CodeDropDown";
import { useDispatch, useSelector } from "react-redux";
import { fetchFarmerList } from "../store/store";
import { Settings, SettingsDetails } from "../store/thunks/settings-thunk/SettingsThunk";
import { createBody } from "../shared/api-structure/CollectionSettingStructure";
import ConfirmationModal from "../modal/ConfirmationModal";

const EquipmentSetting = () => {
  //Table Action Start
  const buttonStyle = {
    padding: "0.7px 7px ",
    fontSize: "10px",
    lineHeight: "1",
  };
  //Table Action End
  const handleDelete = (index) => {
    // Copy the current tableData array
    const updatedTableData = [...dataTable];

    // Remove the item at the specified index
    updatedTableData.splice(index, 1);

    // Update the state with the new array
    setDataTable(updatedTableData);
  };

  const [showModal, setShowModal] = useState(false);
  const [inputKey, setInputKey] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputFunction, setInputFunction] = useState("");
  const [dataTable, setDataTable] = useState([]);
  const farmerRef = useRef(null);
  const [centerId, setCenterId] = useState(null);
  const [centerCode, setCenterCode] = useState("");
  const centerRef = useRef(null);
  const { id } = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);


  useEffect(() => {
    dispatch(fetchFarmerList());
  }, [dispatch]);

  const collectionCenter = useSelector((state) => state.directFarmerList);
  const filterCollection = collectionCenter.data?.filter(
    (el) => el.type === "1"
  );

  useEffect(() => {
    if (parseFloat(id) !== 0) dispatch(SettingsDetails(id));
  }, [dispatch, id]);

  const { settingsDetails } = useSelector((state) => state.SettingsDetails);

  console.log(settingsDetails, "settingsDetails");

  const centerData = (details) => {
    return `${details.code}-${details.name}`
  }



  useEffect(() => {
    if (settingsDetails?.length !== 0 && parseFloat(id) !== 0) {
      const data = settingsDetails?.settings?.equipment_settings;
      const details = settingsDetails?.center_details


      console.log(data, "details==?");
      const analyserName = data?.analyser_name;
      const weightscale_name = data?.weightscale_name;
      const stirrer_name = data?.stirrer_name;
      const analyser_baud_rate = data?.analyser_baud_rate;
      const weightscale_baud_rate = data?.weightscale_baud_rate;
      const Stirrer_baud_rate = data?.Stirrer_baud_rate;
      const send_command = data?.send_command;
      const analyser_mode = data?.analyser_mode;
      const stirrer_mode = data?.stirrer_mode;
      const clean_mode = data?.clean_mode;
      const kg_to_litre = data?.kg_to_litre;
      const tare_value = data?.tare_value;
      const mode_value = data?.mode_value;
      const is_tare_option = data?.is_tare_option;
      const is_mode_option = data?.is_mode_option;

      setFormData({
        AnalyserName: analyserName ? analyserName : "",
        baudRate: analyser_baud_rate ? analyser_baud_rate : '',
        SendCommand: send_command ? send_command : "",
        AnalyseButton: analyser_mode ? analyser_mode : "",
        Clean: clean_mode ? clean_mode : '',
        BaudRateWS: weightscale_name ? weightscale_name : '',
        baudRateWS1: weightscale_baud_rate ? weightscale_baud_rate : "",
        KgToLitre: kg_to_litre ? kg_to_litre : '',
        ModeWS: mode_value ? mode_value : '',
        Mode: is_mode_option ? is_mode_option : '',
        TareWS: tare_value ? tare_value : '',
        Tare: is_tare_option ? is_tare_option : "",
        Stirrer: stirrer_name ? stirrer_name : "", // Default value, change based on your initial state
        StirrerButton: stirrer_mode ? stirrer_mode : '', // Default value, change based on your initial state
        analyser_cmd: [],
      });





      if (details) {
        const centerDetails = centerData(details)
        console.log(data, "data--->");
        if (data)
          setCenterCode(centerDetails)
      }

      if (id)
        setCenterId(parseInt(id))


    }
  }, [id, settingsDetails]);


  const handlePlusButtonClick = () => {
    setShowModal(true);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleAddRow = () => {
    if (inputKey && inputValue && inputFunction) {
      setDataTable([
        ...dataTable,
        { Key: inputKey, Value: inputValue, function: inputFunction },
      ]);
      setInputKey("");
      setInputValue("");
      setInputFunction("");
    }
  };
  const handleSaveForm = () => {

    // Save the dataTable array in formData under the key "analyser_cmd"
    setFormData((prevData) => ({
      ...prevData,
      analyser_cmd: dataTable,
    }));

    // Close the modal after saving data
    handleCloseModal();
  };

  //Plus Button End
  const [formData, setFormData] = useState({
    AnalyserName: "0",
    baudRate: "",
    SendCommand: "NO",
    AnalyseButton: "Enable",
    Clean: "Enable",
    BaudRateWS: "0",
    baudRateWS1: "",
    KgToLitre: "NO",
    ModeWS: "",
    Mode: "Automatic",
    TareWS: "",
    Tare: "Automatic",
    Stirrer: "0", // Default value, change based on your initial state
    StirrerButton: "Enable", // Default value, change based on your initial state
    analyser_cmd: [],
  });
  console.log("Form Values Of Fields ======>", formData);

  //Fields
  const [isAnalyserNameValid, setIsAnalyserNameValid] = useState(true); //Analyser Name
  const [isBaudRateValid, setIsBaudRateValid] = useState(true); //Analuser Baud Rate
  const [isBaudRateWSValid, setIsBaudRateWSValid] = useState(true); //Weight Scale Baud Rate Dropdown
  const [isBaudRateWS1Valid, setIsBaudRatewS1Valid] = useState(true); //Weight Scale Baud Rate
  const [isModeWSValid, setIsModeWSValid] = useState(true); //Weight Scale Mode
  const [isTareWSValid, setIsTareWSValid] = useState(true); //Weight Scale Tare
  const [StirrerValid, setStirrerValid] = useState(true); //Sterrier

  const handleSaveButtonClick = (e) => {
    e.preventDefault();

    // Perform form validation and log the field values
    const isFormValid = validateForm();

    if (isFormValid) {
      // If the form is valid, log the field values and proceed with saving the data
      console.log("Form is valid. Saving data...");

      console.log(formData, "formData===>");
      const body = createBody(formData, "equipment", centerId)
      dispatch(Settings(body))
        .then((action) => {
          // Handle success here
          if (action.payload) {
            navigate(`/settings-view/printer-settings/${id}`)
            // setIsButtonDisabled(false);
          }

        })
        .catch((error) => {
          // Handle failure here
          console.error("Farmer creation failed:", error);
          // setIsButtonDisabled(false);
          // scrollToTop()
        })
        .finally(() => {
          // This block will be executed regardless of success or failure
          // setIsButtonDisabled(false);
          // scrollToTop();
        });


      // You can add your save logic here
    } else {
      // If the form is not valid, display an error message or take appropriate action
      console.log("Form is not valid. Please check your inputs.");
    }
    console.log("Saved Data:", formData);
  };

  const validateForm = () => {
    // Add your validation logic here
    // Check if AnalyserName and baudRate are valid based on your requirements

    let isFormValid = true;

    /* Analyser Validate Start */

    //Anayser Name
    if (formData.AnalyserName === "0") {
      setIsAnalyserNameValid(false);
      isFormValid = false;
    } else {
      setIsAnalyserNameValid(true);
    }

    //Baud Rate
    if (formData.baudRate.trim() === "") {
      setIsBaudRateValid(false);
      isFormValid = false;
    } else {
      setIsBaudRateValid(true);
    }
    /* Analyser Validate End */

    // Weight Scale Validate Start
    //BaudRate Dropdown
    if (formData.BaudRateWS === "0") {
      setIsBaudRateWSValid(false);
      isFormValid = false;
    } else {
      setIsBaudRateWSValid(true);
    }

    //BaudRate Field
    if (formData.baudRateWS1.trim() === "") {
      setIsBaudRatewS1Valid(false);
      isFormValid = false;
    } else {
      setIsBaudRatewS1Valid(true);
    }

    //Mode
    if (formData.ModeWS.trim() === "") {
      setIsModeWSValid(false);
      isFormValid = false;
    } else {
      setIsModeWSValid(true);
    }

    //Tare
    if (formData.TareWS.trim() === "") {
      setIsTareWSValid(false);
      isFormValid = false;
    } else {
      setIsTareWSValid(true);
    }
    // Weight Scale Validate End

    /* Stirrer Validatio Start */
    //Stirrer Dropdown
    if (formData.Stirrer === "0") {
      setStirrerValid(false);
      isFormValid = false;
    } else {
      setStirrerValid(true);
    }
    /* Stirrer Validatio End */

    return isFormValid;
  };

  //Analyuser On Change Function Start

  const handleAnalyserNameChange = (e) => {
    setFormData({
      ...formData,
      AnalyserName: e.target.value,
    });
    setIsAnalyserNameValid(true); // Reset the validation state when the field is changed
  };

  const handleBaudRateChange = (e) => {
    setFormData({
      ...formData,
      baudRate: e.target.value,
    });
    setIsBaudRateValid(true); // Reset the validation state when the field is changed
  };
  const handleSendCommandChange = (e) => {
    setFormData({
      ...formData,
      SendCommand: e.target.id.endsWith("NO") ? "YES" : "NO",
    });
  };

  const handleAnalyserButtonChange = (e) => {
    setFormData({
      ...formData,
      AnalyseButton: e.target.id.endsWith("Enable") ? "Enable" : "Disable",
    });
  };
  const handleCleanChange = (e) => {
    setFormData({
      ...formData,
      handleCleanChange: e.target.id.endsWith("Enable") ? "Enable" : "Disable",
    });
  };


  //Weight Scale ONChange Start
  const handleBaudRateWS1Change = (e) => {
    setFormData({
      ...formData,
      baudRateWS1: e.target.value,
    });
    setIsBaudRatewS1Valid(true); // Reset the validation state when the field is changed
  };

  const handleBaudRateWSChange = (e) => {
    setFormData({
      ...formData,
      BaudRateWS: e.target.value,
    });
    setIsBaudRateWSValid(true); // Reset the validation state when the field is changed
  };
  const handleKgToLitreChange = (e) => {
    setFormData({
      ...formData,
      KgToLitre: e.target.id.endsWith("YES") ? "YES" : "NO",
    });
  };

  //Mode Field And Button Start

  const handleModeWSChange = (e) => {
    setFormData({
      ...formData,
      ModeWS: e.target.value,
    });
    setIsModeWSValid(true); // Reset the validation state when the field is changed
  };
  const handleModeButtonChange = (event) => {
    const value = event.target.id === "ModeAuto" ? "Automatic" : "Manual";

    setFormData({
      ...formData,
      Mode: value,
    });
  };

  //Mode Field And Button END

  //Tare Button And Field Start
  const handleTareWSChange = (e) => {
    setFormData({
      ...formData,
      TareWS: e.target.value,
    });
    setIsTareWSValid(true); // Reset the validation state when the field is changed
  };

  const handleTareButtonChange = (event) => {
    const value = event.target.id === "TareAuto" ? "Automatic" : "Manual";

    setFormData({
      ...formData,
      Tare: value,
    });
  };

  const handleFarmerCode = (value) => {
    // Simple validation check
    if (!value || value.trim() === "") {
      return "Farmer name with Code is required.";
    }
    return ""; // No error
  };

  //Tare Button And Field End

  //Weight Scale ONChange End

  //Sterrrier OnChange Start
  const handleStirrerChange = (e) => {
    setFormData({
      ...formData,
      Stirrer: e.target.value,
    });
    setStirrerValid(true); // Reset the validation state when the field is changed
  };
  // Get Center Name and Center Code
  const handleInputCenter = (value, id, data) => {
    console.log(data, "data");
    setCenterCode(value);
    setCenterId(data.id);
  };

  const handleStirrerButtonChange = (e) => {
    setFormData({
      ...formData,
      StirrerButton: e.target.id.endsWith("Enable") ? "Enable" : "Disable",
    });
  };
  //Sterrrier OnChange End
  const handleKeyDown = (e) => {
    // If the Enter key is pressed, prevent default form submission
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  const handleCenter = (name) => {
    // setInputValue(name)
  };

  const handleYesClick = () => {
    navigate(`/settings-view/printer-settings/${id}`)
    setShowConfirmationModal(false); // Close the confirmation modal
  };

  const askModal = () => {
    setShowConfirmationModal(true)
  }

  return (

    <Form>
      <Card>
        <Card.Body>

          <ConfirmationModal
            show={showConfirmationModal}
            title="Closing Confirmation"
            body="Are you sure?"
            onCancel={() => {
              setShowConfirmationModal(false)
            }}
            onConfirm={handleYesClick}
          />

          <Block>
            <Card className="card-gutter-md">
              <Card.Body>
                <Block.Title tag="h4" className="mb-3">
                  ANALYSER :
                </Block.Title>
                <Row className="g-4">
                  <Row className="justify-content-end">
                    <Button
                      variant="primary"
                      className="rounded-circle"
                      style={{
                        fontSize: "1rem",
                        width: "40px",
                        height: "40px",
                      }}
                      onClick={handlePlusButtonClick}
                    >
                      +
                    </Button>
                  </Row>

                  <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Analyser:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {/* Your Form 1 */}
                      <Col md="12">
                        <Row>
                          <Col md="3">
                            <Form.Group className="form-group">
                              <Form.Label>Key</Form.Label>
                              <div className="form-control-wrap">
                                <Form.Control
                                  type="text"
                                  name="Key"
                                  placeholder="Enter The Key"
                                  value={inputKey}
                                  onChange={(e) =>
                                    setInputKey(e.target.value)
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  Key Is Required.
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </Col>

                          <Col md="3">
                            <Form.Group className="form-group">
                              <Form.Label>Value</Form.Label>
                              <div className="form-control-wrap">
                                <Form.Control
                                  type="text"
                                  name="Value"
                                  placeholder="Enter The Value"
                                  value={inputValue}
                                  onChange={(e) =>
                                    setInputValue(e.target.value)
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  Value Is Required.
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </Col>

                          <Col md="4">
                            <Form.Group className="form-group">
                              <Form.Label>Function</Form.Label>
                              <div className="form-control-wrap">
                                <Form.Control
                                  type="text"
                                  name="Function"
                                  placeholder="Enter The Function"
                                  value={inputFunction}
                                  onChange={(e) =>
                                    setInputFunction(e.target.value)
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  Function Is Required.
                                </Form.Control.Feedback>
                              </div>
                            </Form.Group>
                          </Col>

                          <Col md="2">
                            <Button
                              variant="primary"
                              className="rounded-circle"
                              style={{
                                fontSize: "1rem",
                                width: "40px",
                                height: "40px",
                                marginTop: "32px",
                              }}
                              onClick={handleAddRow}
                            >
                              +
                            </Button>
                          </Col>
                        </Row>
                      </Col>

                      {/* Display DataTable */}
                      <br />
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Key</th>
                            <th>Value</th>
                            <th>Function</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataTable.map((row, index) => (
                            <tr key={index}>
                              <td>{row.Key}</td>
                              <td>{row.Value}</td>
                              <td>{row.function}</td>
                              <td>
                                {/* Add your Action buttons here */}
                                <Button
                                  className="mx-1"
                                  style={buttonStyle}
                                  variant="primary"
                                // onClick={() => handleEdit(row.id, row)}
                                >
                                  <Icon name="edit"></Icon>
                                </Button>

                                <Button
                                  className="mx-1"
                                  style={buttonStyle}
                                  variant="danger"
                                  onClick={() => handleDelete(index)}
                                >
                                  <Icon name="trash"></Icon>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Modal.Body>
                    <Modal.Footer style={{ padding: "5px" }}>
                      <Button variant="primary" onClick={handleSaveForm}>
                        Save All
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/* Name Start */}
                  {parseFloat(id) === 0 && <Col sm="3">
                    <Form.Group className="form-group">
                      <CodeDropDown
                        data={filterCollection}
                        placeholder="Center Code"
                        name="Center Code"
                        nextInputRef={farmerRef}
                        onValidate={handleFarmerCode}
                        onSelect={(selectedItem, type, data, error) => {
                          handleInputCenter(selectedItem, type, data, error);
                        }}
                        // value={center}
                        func={handleCenter}
                        inputRef={centerRef}
                        style={{ width: "155px" }}
                        value={centerCode}

                      />


                    </Form.Group>
                  </Col>}

                  <Col md="3">
                    <Form.Group className="form-group">
                      <Form.Label>
                        Choose Analyser
                        <span className="me-2">
                          <i className="fa fa-exclamation-triangle text-danger">
                            *
                          </i>
                        </span>
                      </Form.Label>
                      <Form.Select
                        name="AnalyserName"
                        onChange={handleAnalyserNameChange}
                        value={formData.AnalyserName}
                        isInvalid={!isAnalyserNameValid}
                      >
                        <option value="0">Select Analyser</option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Name Is Required.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  {/* Baud Rate Start*/}
                  <Col md="2">
                    <Form.Group className="form-group">
                      <Form.Label>
                        Baud Rate
                        <span className="me-2">
                          <i className="fa fa-exclamation-triangle text-danger">
                            *
                          </i>
                        </span>
                      </Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="numeric"
                          name="baudRate"
                          placeholder="Baud Rate"
                          onChange={handleBaudRateChange}
                          value={formData.baudRate}
                          isInvalid={!isBaudRateValid}
                          onKeyDown={handleKeyDown}
                        />
                        <Form.Control.Feedback type="invalid">
                          Baud Rate is required.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  </Col>

                  {/* Send Command Start */}
                  <Col md="2">
                    <Form.Group className="form-group">
                      <Form.Label>
                        Send Command
                        <span className="me-2">
                          <i className="fa fa-exclamation-triangle text-danger">
                            *
                          </i>
                        </span>
                      </Form.Label>
                      <div className="form-control-wrap">
                        <ButtonGroup aria-label="Basic radio toggle button group">
                          <input
                            type="radio"
                            className="btn-check"
                            name="SendCommand"
                            id="SendCommandYES"
                            autoComplete="off"
                            checked={formData.SendCommand === "Yes"}
                            onChange={handleSendCommandChange}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="SendCommandYES"
                            onClick={() =>
                              setFormData({
                                ...formData,
                                SendCommand: "Yes",
                              })
                            }
                          >
                            YES
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            name="SendCommand"
                            id="SendCommandNO"
                            autoComplete="off"
                            checked={formData.SendCommand === "No"}
                            onChange={handleSendCommandChange}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="SendCommandNO"
                            onClick={() =>
                              setFormData({
                                ...formData,
                                SendCommand: "No",
                              })
                            }
                          >
                            NO
                          </Button>
                        </ButtonGroup>
                      </div>
                    </Form.Group>
                  </Col>

                  {/* Analyse Start */}

                  <Col md="2">
                    <Form.Group className="form-group">
                      <Form.Label>
                        Analyse
                        <span className="me-2">
                          <i className="fa fa-exclamation-triangle text-danger">
                            *
                          </i>
                        </span>
                      </Form.Label>
                      <div className="form-control-wrap">
                        <ButtonGroup aria-label="Basic radio toggle button group">
                          <input
                            type="radio"
                            className="btn-check"
                            name="Analyse"
                            id="AnalyseENABLE"
                            autoComplete="off"
                            checked={formData.AnalyseButton === "Enable"}
                            onChange={handleAnalyserButtonChange}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="AnalyseENABLE"
                            onClick={() =>
                              setFormData({
                                ...formData,
                                AnalyseButton: "Enable",
                              })
                            }
                          >
                            Enable
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            name="Analyse"
                            id="AnalyseDISABLE"
                            autoComplete="off"
                            checked={formData.AnalyseButton === "Disable"}
                            onChange={handleAnalyserButtonChange}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="AnalyseDISABLE"
                            onClick={() =>
                              setFormData({
                                ...formData,
                                AnalyseButton: "Disable",
                              })
                            }
                          >
                            Disable
                          </Button>
                        </ButtonGroup>
                      </div>
                    </Form.Group>
                  </Col>

                  {/* Clean */}
                  <Col md="2">
                    <Form.Group className="form-group">
                      <Form.Label>
                        Clean
                        <span className="me-2">
                          <i className="fa fa-exclamation-triangle text-danger">
                            *
                          </i>
                        </span>
                      </Form.Label>
                      <div className="form-control-wrap">
                        <ButtonGroup aria-label="Basic radio toggle button group">
                          <input
                            type="radio"
                            className="btn-check"
                            name="Clean"
                            id="CleanENABLE"
                            autoComplete="off"
                            checked={formData.Clean === "Enable"}
                            onChange={handleCleanChange}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="CleanENABLE"
                            onClick={() =>
                              setFormData({
                                ...formData,
                                Clean: "Enable",
                              })
                            }
                          >
                            Enable
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            name="Clean"
                            id="CleanDISABLE"
                            autoComplete="off"
                            checked={formData.Clean === "Disable"}
                            onChange={handleCleanChange}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="CleanDISABLE"
                            onClick={() =>
                              setFormData({
                                ...formData,
                                Clean: "Disable",
                              })
                            }
                          >
                            Disable
                          </Button>
                        </ButtonGroup>
                      </div>
                    </Form.Group>
                  </Col>
                  {/* Add two more similar sections for year and   Category */}
                </Row>
              </Card.Body>
            </Card>
            <br />
            {/* <hr /> */}

            <Card className="card-gutter-md">
              <Card.Body>
                <Block.Title tag="h4" className="mb-3">
                  WEIGHT SCALE :
                </Block.Title>
                <Row className="g-3">
                  <Col xl="12">

                    <Row className="g-4">
                      {/* Weight Scale Baud Rate */}
                      <Col md="3">
                        <Form.Group className="form-group">
                          <Form.Label>
                            Weight Scale name
                            <span className="me-2">
                              <i className="fa fa-exclamation-triangle text-danger">
                                *
                              </i>
                            </span>
                          </Form.Label>
                          <Form.Select
                            name="BaudRateWS"
                            onChange={handleBaudRateWSChange}
                            value={formData.BaudRateWS}
                            isInvalid={!isBaudRateWSValid}
                          >
                            <option value="0">Select Scale</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Weight Scale BaudRate Is Required.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      {/* Baud Rate */}
                      <Col md="2">
                        <Form.Group className="form-group">
                          <Form.Label>
                            Baud Rate
                            <span className="me-2">
                              <i className="fa fa-exclamation-triangle text-danger">
                                *
                              </i>
                            </span>
                          </Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              type="numeric"
                              name="baudRate"
                              placeholder="Baud Rate"
                              onChange={handleBaudRateWS1Change}
                              value={formData.baudRateWS1}
                              isInvalid={!isBaudRateWS1Valid}
                              onKeyDown={handleKeyDown}
                            />
                            <Form.Control.Feedback type="invalid">
                              Baud Rate is required.
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>

                      {/* KG TO Litre Conversion */}
                      <Col md="2">
                        <Form.Group className="form-group">
                          <Form.Label>
                            Kg/Lit Conversion
                            <span className="me-2">
                              <i className="fa fa-exclamation-triangle text-danger">
                                *
                              </i>
                            </span>
                          </Form.Label>
                          <div className="form-control-wrap">
                            <ButtonGroup aria-label="Basic radio toggle button group">
                              <input
                                type="radio"
                                className="btn-check"
                                name="KgToLitre"
                                id="KgToLitreYES"
                                autoComplete="off"
                                checked={formData.KgToLitre === "Yes"}
                                onChange={handleKgToLitreChange}
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor="KgToLitreYES"
                                onClick={() =>
                                  setFormData({
                                    ...formData,
                                    KgToLitre: "Yes",
                                  })
                                }
                              >
                                YES
                              </Button>

                              <input
                                type="radio"
                                className="btn-check"
                                name="KgToLitre"
                                id="KgToLitreNO"
                                autoComplete="off"
                                checked={formData.KgToLitre === "No"}
                                onChange={handleKgToLitreChange}
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor="KgToLitreNO"
                                onClick={() =>
                                  setFormData({
                                    ...formData,
                                    KgToLitre: "No",
                                  })
                                }
                              >
                                NO
                              </Button>
                            </ButtonGroup>
                          </div>
                        </Form.Group>
                      </Col>

                      {/* Mode */}
                      <Col md="5">
                        <Form.Group className="form-group">
                          <Form.Label>
                            Mode
                            <span className="me-2">
                              <i className="fa fa-exclamation-triangle text-danger">
                                *
                              </i>
                            </span>
                          </Form.Label>
                          <div className="form-control-wrap d-flex align-items-center">
                            <Form.Control
                              type="text"
                              id="exampleInputColumn1"
                              name="Mode"
                              placeholder="Enter The Mode"
                              onKeyDown={handleKeyDown}
                              autoComplete="off"
                              onChange={handleModeWSChange}
                              value={formData.ModeWS}
                              isInvalid={!isModeWSValid}
                            />
                            <div className="ms-4"></div>

                            <ButtonGroup aria-label="Basic radio toggle button group">
                              <input
                                type="radio"
                                className="btn-check"
                                name="Mode"
                                id="ModeAuto"
                                autoComplete="off"
                                onChange={handleModeButtonChange}
                                checked={formData.Mode === "Automatic"}
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor="ModeAuto"
                              >
                                Automatic
                              </Button>

                              <input
                                type="radio"
                                className="btn-check"
                                name="Mode"
                                id="ModeMANUAL"
                                autoComplete="off"
                                onChange={handleModeButtonChange}
                                checked={formData.Mode === "Manual"}
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor="ModeMANUAL"
                              >
                                Manual
                              </Button>
                            </ButtonGroup>
                            <Form.Control.Feedback
                              type="invalid"
                              className="ms-2"
                            >
                              Mode Is Required.
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>

                      {/*Tare Field With Button*/}
                      <Col md="5">
                        <Form.Group className="form-group">
                          <Form.Label>
                            Tare
                            <span className="me-2">
                              <i className="fa fa-exclamation-triangle text-danger">
                                *
                              </i>
                            </span>
                          </Form.Label>
                          <div className="form-control-wrap d-flex align-items-center">
                            <Form.Control
                              type="text"
                              id="exampleInputColumn1"
                              name="Tare"
                              placeholder="Enter The Tare"
                              onKeyDown={handleKeyDown}
                              autoComplete="off"
                              onChange={handleTareWSChange}
                              value={formData.TareWS}
                              isInvalid={!isTareWSValid}
                            />
                            <div className="ms-4"></div>

                            <ButtonGroup aria-label="Basic radio toggle button group">
                              <input
                                type="radio"
                                className="btn-check"
                                name="Tare"
                                id="TareAuto"
                                autoComplete="off"
                                onChange={handleTareButtonChange}
                                checked={formData.Tare === "Automatic"}
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor="TareAuto"
                              >
                                Automatic
                              </Button>

                              <input
                                type="radio"
                                className="btn-check"
                                name="Tare"
                                id="TareMANUAL"
                                autoComplete="off"
                                onChange={handleTareButtonChange}
                                checked={formData.Tare === "Manual"}
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor="TareMANUAL"
                              >
                                Manual
                              </Button>
                            </ButtonGroup>
                            <Form.Control.Feedback
                              type="invalid"
                              className="ms-2"
                            >
                              Mode Is Required.
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md="auto" className="d-flex align-items-end"></Col>
                    </Row>
                  </Col>
                </Row>

              </Card.Body>
            </Card>
            <br />
            {/* <hr /> */}
            <Card>
              <Card.Body>
                <Block.Title tag="h4" className="mb-3">
                  Stirrer :
                </Block.Title>
                <Row className="g-4">
                  {/* Stirrer */}
                  <Col md="3">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Stirrer
                        <span className="me-2">
                          <i className="fa fa-exclamation-triangle text-danger">
                            *
                          </i>
                        </span>
                      </Form.Label>
                      <Form.Select
                        name="Stirrer"
                        onChange={handleStirrerChange}
                        value={formData.Stirrer}
                        isInvalid={!StirrerValid}
                      >
                        <option value="0">Select Stirrer</option>
                        <option value="A">Automatic</option>
                        <option value="B">Manual</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Stirrer Is Required.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  {/* Stirrer Enable And Disable */}
                  <Col md="2">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Stirrer
                        <span className="me-2">
                          <i className="fa fa-exclamation-triangle text-danger">
                            *
                          </i>
                        </span>
                      </Form.Label>
                      <ButtonGroup aria-label="Basic radio toggle button group">
                        <input
                          type="radio"
                          className="btn-check"
                          name="StirrerButton"
                          id="StirrerENABLE"
                          autoComplete="off"
                          checked={formData.StirrerButton === "Enable"}
                          onChange={handleStirrerButtonChange}
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="StirrerENABLE"
                          onClick={() =>
                            setFormData({
                              ...formData,
                              StirrerButton: "Enable",
                            })
                          }
                        >
                          Enable
                        </Button>

                        <input
                          type="radio"
                          className="btn-check"
                          name="StirrerButton"
                          id="StirrerDISABLE"
                          autoComplete="off"
                          checked={formData.StirrerButton === "Disable"}
                          onChange={handleStirrerButtonChange}
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="StirrerDISABLE"
                          onClick={() =>
                            setFormData({
                              ...formData,
                              StirrerButton: "Disable",
                            })
                          }
                        >
                          Disable
                        </Button>
                      </ButtonGroup>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Block>

          <br />
          <Row>
            <Col className="col-12">
              <div
                className="gap-col"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ul className="d-flex align-items-center gap g-3">

                  <li>
                    <Button

                      variant="danger"
                      onClick={askModal}
                    >
                      Cancel
                    </Button>
                  </li>

                  <li>
                    <Button
                      type="submit"
                      onClick={handleSaveButtonClick}
                      variant="success"
                    >
                      Save
                    </Button>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Form>

  );
};

export default EquipmentSetting;
