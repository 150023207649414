import React, { useEffect, useRef, useState } from 'react'
import Layout from "../../../../layout/default";
import { Block, DatePicker, Icon, Tags } from '../../../../components';
import { Button, ButtonGroup, Card, Col, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../../../components/DataTable/DataTable';
import ConfirmationModal from '../../modal/ConfirmationModal';
import '../Procurement.css'
import { get, post, postToast, put } from '../../api/Api';
import { SearchDown } from '../../shared/dropDown/SearchDown';
import { useReactToPrint } from 'react-to-print';
import MyComponent from '../../shared/printer/Printer';
import { CodeDropDown } from '../../shared/dropDown/CodeDropDown';
import { generateRandomId } from '../../shared/Service';
import { extractDateFromTimestamp } from '../../shared/Date';
import { SearchDownCode } from '../../shared/dropDown/SearchCode';

const VlccForm = () => {
    const [storedData, setStoredData] = useState([])
    const [fat, setFat] = useState('')
    const [snf, setSnf] = useState('')
    const [quantity, setQuantity] = useState(null)
    const [kg, setKg] = useState('')
    const [fatError, setFatError] = useState('')
    const [snfError, setSnfError] = useState('')
    const [quantityError, setQuantityError] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [formData, setFormData] = useState([])
    const [rate, setRate] = useState('');
    const [tableData, setTableData] = useState([]);
    const [fatData, setFatData] = useState([])
    const [snfData, setSnfData] = useState([])
    const [tsData, setTsData] = useState([])
    const [flatData, setFlatData] = useState([])
    const [flatOnlyData, setFlatOnlyData] = useState([])
    const [codeError, setCodeError] = useState('')
    const idRef = useRef(1);
    const [isEditMode, setIsEditMode] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const fatRef = useRef(null);
    const snfRef = useRef(null);
    const farmerRef = useRef(null);
    const centerRef = useRef(null);
    const quantityRef = useRef(null);
    const buttonRef = useRef(null);
    const codeRef = useRef(null);
    const [milkType, setMilkType] = useState(null)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [pendingRow, setPendingRow] = useState(null);
    const [fieldType, setFieldType] = useState('');
    const [ratePerLtr, setRatePerLtr] = useState(0)
    const [abnormal, setAbnormal] = useState([])
    const [rateChartType, setRateChartType] = useState('')
    const [rateChartData, setRateChartData] = useState([])
    const [farmerId, setFarmerId] = useState(null)
    const printRef = useRef();
    const [show, setShow] = useState(false)
    const [bill_no, setBill_no] = useState(null)
    const [numericValue, setNumericValue] = useState([]);
    const [loading, setLoading] = useState(true)
    const [inputValue, setInputValue] = useState('');
    const searchDownRef = useRef();
    const tagsRef = useRef(null);
    const routeRef = useRef(null);
    const [selectedDate, setSelectedDate] = useState(extractDateFromTimestamp(new Date()));
    const [sessionDay, setSessionDay] = useState("M");
    const [rowId, setRowId] = useState(null)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [typeValue, setTypeValue] = useState(null)
    const [rateChartId, setRateChartId] = useState(null)
    const [chartID, setChartID] = useState(null)
    const [listData, setListData] = useState([])
    const [editData, setEditData] = useState([])
    const [centerName, setCenterName] = useState(null)
    const [centerList, setCenterList] = useState([])
    const [routeList, setRouteList] = useState([])
    const [routeName, setRouteName] = useState('')
    const [centerId, setCenterId] = useState(null)
    const [printer, setPrinter] = useState(false)
    const [dummyCow, setDummyCow] = useState('C')
    const [dummyBuffalo, setDummyBuffalo] = useState('B')
    const [lastData, setLastData] = useState([])
    const [findLast, setFindLast] = useState(false)
    const [printFrName, setPrintFrName] = useState('')
    const [pagination, setPagination] = useState({
        page: 1,
        page_count: 25,
        search: '',
        total_count: null
    })
    const updatePagination = ({ current_page, per_page, total }) => {
        setPagination({
            ...pagination,
            page: current_page,
            page_count: per_page,
            total_count: total
        });
    };


    const clearSearchInput = () => {
        if (searchDownRef.current) {
            searchDownRef.current.clearInputValue();
        }
    }

    // Get Route List

    const fetchRouteList = async () => {
        const url = `/api/route/list`;
        try {
            const response = await get(url);
            if (response.status) {
                const data = response.data
                setRouteList(data ? data : [])
            }

        } catch (error) {
            console.error("Error while posting data:", error);
        }
    };


    useEffect(() => {
        fetchRouteList()
    }, [])

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        onBeforePrint: () => {
            setShow(true);
        },
        onAfterPrint: () => {
            setShow(false);
            setTimeout(() => {
                searchDownRef.current.setAutofocus(true);
            }, 500);


        },

    });

    const location = useLocation();
    const { id, action } = useParams()
    const navigate = useNavigate()

    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get('mode');
    const type = searchParams.get('type');


    // Collection entry list

    const fetchList = async () => {
        const url = `/api/procurement-access/list`;
        const body = {}
        try {
            const response = await postToast(url, body);
            if (response.status) {

                const data = response.data.data
                const filterData = data.filter((el) => el.id === parseFloat(action))
                setStoredData(filterData ? filterData[0] : [])
                setLoading(false)
            }
        } catch (error) {
            console.error("Error while posting data:", error);
            setLoading(false)
        }
    }

    const handleRemove = () => {
        setFat('')
        setSnf("")
        setQuantity('')
        setKg('')
        // clearSearchInput();

        setRowId('')
        // if (milkType.includes('cow')) {
        //     setDummyCow('')
        // } else if (milkType.includes('buffalo')) {
        //     setDummyBuffalo('')
        // }

        if (selectedId) {
            setSelectedId(null)
            setIsEditMode(false)
        }

    }



    // Edit function for update the data
    const editFunc = (data, clickId) => {


        // Check if the edited row is the last row in the data array
        const isFirstRow = listData.indexOf(data) === 0;


        // Now you can use the isLastRow variable in your logic
        if (isFirstRow) {
            setFindLast(true)
            setLastData(data)
        }


        // fetchFarmerData(data.direct_farmer_id)
        setRowId(clickId)
        setSelectedId(data.id)
        setFat(data.tot_fat)
        setSnf(data.tot_snf)
        setQuantity(data.tot_qty_litre)
        setKg(data.tot_qty_kg)
        setIsEditMode(true)
        setCenterId(data.vcc_farmer_id)
        // const value = `${data.farmer_code}-${data.name}`
        // if (value) {
        setInputValue(data.farmer_code)
        setMilkType(data.milk_type)
        // }
        setFarmerId(data.direct_farmer_id)
        setFarmerName(data.name)
        setPrintFrName(data.name)
        setCode(data.farmer_code)
        setDummyBuffalo(data.milk_type === "1" ? "B" : "")
        setDummyCow(data.milk_type === "0" ? "C" : "")
        setTypeValue(data.milk_type === "0" ? "C" : data.milk_type === "1" ? "B" : "")

        if (clickId) {
            const fetchList = async () => {
                const url = `/api/vcc-collection/${data.id}/details`;

                try {
                    const response = await get(url);
                    if (response.status) {
                        const data = response.data
                        setEditData(data[0])
                        setFat(data[0].tot_fat)
                        setSnf(data[0].tot_snf)
                        setQuantity(data[0].tot_qty_litre)
                        setKg(data[0].tot_qty_kg)
                        setIsEditMode(true)
                        // setInputValue(`${data[0].farmer_code}-${data[0].name}`)
                        const name = `${data[0].farmer_code}-${data[0].name}`
                        if (name) {
                            handleName(name)
                        }
                    }

                } catch (error) {
                    console.error("Error while posting data:", error);
                    setLoading(false)
                }
            }
            fetchList()
        }
    };

    const [code, setCode] = useState(null)
    const [farmerTotalData, setFarmerTotalData] = useState([])
    const [kgFactor, setKgFactor] = useState(null)
    const [farmerName, setFarmerName] = useState('')
    const [routeId, setRouteId] = useState(null)
    const [farmerData, setFarmerData] = useState([])



    // Get Center Name and Center Code
    const handleInputCenter = (value, id, data) => {
        const body = {
            route_id: routeId,
            from_date: selectedDate,
            to_date: selectedDate,
            from_session: sessionDay,
            to_session: sessionDay,
            center_id: id,
            farmer_id: farmerId
        }

        if (body) {
            // fetchListData(body)
        }

        setCenterName(value)
        setCenterId(data.id)
        const fetchFarmerData = async () => {
            const url = `/api/vcc-farmer/center/${data.id}`;
            try {
                const response = await get(url);
                if (response.status) {
                    const responseData = response.data
                    setFarmerData(responseData)
                    // setFarmerData(responseData)
                    // setMilkType(responseData[0].milk_info.milk_type)
                    // setKgFactor(responseData[0].kg_ltr_factor)
                }
            } catch (error) {
                console.error("Error while posting data:", error);
            }
        }
        fetchFarmerData()

        farmerRef.current?.focus();

    }




    // Get Rate chart Data
    const rateChartDetails = async (id, name) => {

        if (id) {
            const url = `/api/rate-chart/${id}/details`;
            try {
                const response = await get(url);
                if (response.status) {
                    const data = response.data;
                    setRateChartData(data.range_jsonmap);
                    setFlatOnlyData(data.range_jsonmap);

                    switch (data.rate_type) {
                        case 1:
                            setRateChartType('FLATONLY');
                            setFlatData(data.range_jsonmap);
                            break;
                        case 2:
                            setRateChartType('FATSNF');
                            break;
                        case 3:
                            setRateChartType('FAT');
                            setFatData(data.range_jsonmap);
                            break;
                        case 4:
                            setRateChartType('SNF');
                            setSnfData(data.range_jsonmap);
                            break;
                        case 5:
                            setRateChartType('TS');
                            setTsData(data.range_jsonmap);
                            break;
                        default:
                            setRateChartType('FLAT');
                            setFlatData(data.range_jsonmap);
                            break;
                    }
                }
            } catch (error) {
                console.error('Error while fetching rate chart details:', error);
            }
        }
    };
    const handleKeyPress = () => {
        setFat('')
        setSnf("")
        setQuantity('')
        setKg('')
        setInputValue("")
        setDummyCow('')
        setDummyBuffalo('')
        setFarmerName('')
        setMilkType('')
    }

    const handleInput = (value, id, data, error) => {
        const body = {
            route_id: routeId,
            from_date: selectedDate,
            to_date: selectedDate,
            from_session: sessionDay,
            to_session: sessionDay,
            center_id: centerId,
            farmer_id: id
        }

        if (body) {
            // fetchListData(body)
        }

        setFarmerName(data.name)
        setCode(data.code)
        setInputValue(data.code)
        setFarmerId(id)
        setDummyBuffalo("B")
        setDummyCow("C")
        // fetchFarmerData(id)
        setPrintFrName(data.name)
        const milk_type = data.milk_info?.[0]?.milk_type

        if (milk_type)
            setMilkType(milk_type)
        // handleRemove()
    }


    const handleInputRoute = (value, id, data, error) => {
        const body = {
            route_id: id,
            from_date: selectedDate,
            to_date: selectedDate,
            from_session: sessionDay,
            to_session: sessionDay,
            center_id: centerId,
            farmer_id: farmerId
        }

        if (body) {
            // fetchListData(body)
        }

        setRouteName(value)

        setRouteId(id)
        const fetchCenterList = async () => {
            const url = `/api/vcc-farmer/centersinroute/${id}`;

            try {
                const response = await get(url);
                if (response.status) {
                    const responseData = response.data
                    setCenterList(responseData ? responseData : [])
                    const milk_type = JSON.parse(responseData[0].milk_info)
                    setRateChartId(JSON.parse(responseData[0].ratechart_id)?.[0])
                    setFarmerTotalData(JSON.parse(responseData[0].incentive_info))
                    // setMilkType(milk_type.milk_type)


                    setKgFactor(1.04)
                    const data = JSON.parse(responseData[0].ratechart_id)?.[0]

                    let chartType = '';
                    if (milk_type.milk_type.includes('cow')) {
                        if (data?.cow) {
                            setChartID(data?.cow);
                            chartType = data?.cow;
                        } else {
                            setChartID(data?.common);
                            chartType = data?.common;
                        }

                    } else if (milk_type.milk_type.includes('buffalo')) {
                        if (data?.buffalo) {
                            setChartID(data?.buffalo);
                            chartType = data?.buffalo;
                        } else {
                            setChartID(data?.common);
                            chartType = data?.common;
                        }
                    } else {
                        setChartID(data?.common);
                        chartType = data?.common;
                    }

                    if (!(milk_type.milk_type.includes('cow') && milk_type.milk_type.includes('buffalo'))) {
                        rateChartDetails(chartType);
                    }

                }

            } catch (error) {
                console.error("Error while posting data:", error);
            }
        };
        if (id) {
            fetchCenterList()
        }


    }


    const confirmDeleteForFatRate = (reason) => {
        // 1. Update the state by filtering out the data with the provided idToDelete
        if (mode === "add") {
            setTableData(prevData => {
                const updatedData = prevData.filter(data => data.id !== idToDelete);
                localStorage.setItem('procurementData', JSON.stringify(updatedData));
                return updatedData;
            });
        } else {
            setAbnormal(prevData => {
                const updatedData = prevData.filter(data => data.id !== idToDelete);

                const data = localStorage.getItem('procurementData');
                // data.tableData: updatedData
                const initialData = [{
                    centerName: data[0].centerName,
                    id: data[0].id,
                    code: data[0].code,
                    tableData: updatedData
                }]

                localStorage.setItem('procurementData', JSON.stringify(initialData));
                return updatedData;
            });
        }

        setShowDeleteModal(false);
        setIdToDelete(null);
        setIsEditMode(false)
        setSelectedId(null);

    };
    const cancelDelete = () => {
        setShowDeleteModal(false);
        setIdToDelete(null);
    };

    const handleDelete = (row) => {
        setShowDeleteModal(true);
        setIdToDelete(row.id);  // Assuming 'start' is unique; adjust as needed

    };


    useEffect(() => {


        if (type === "vlcc") {
            const storedData = JSON.parse(localStorage.getItem('formData'));
            const filteredData = storedData?.filter((el) => el.id === parseFloat(id))
            setStoredData(filteredData ? filteredData[0] : [])
        } else if (type === "bulk") {
            const storedData = JSON.parse(localStorage.getItem('bulk'));
            const filteredData = storedData?.filter((el) => el.id === parseFloat(id))
            setStoredData(filteredData ? filteredData[0] : [])
        } else if (type === "direct") {
            // const storedData = JSON.parse(localStorage.getItem('direct'));
            // const filteredData = storedData?.filter((el) => el.id === parseFloat(id))
            // setStoredData(filteredData ? filteredData[0] : [])
            fetchList()
        } else {
            const storedData = JSON.parse(localStorage.getItem('vlcc-entry'));
            const filteredData = storedData?.filter((el) => el.id === parseFloat(id))
            setStoredData(filteredData ? filteredData[0] : [])
        }
    }, [type, id])




    const rateType = rateChartType

    useEffect(() => {
        if (fat && snf) {
            let computedRate = 0;
            let tsFat = parseFloat(fat)
            let tsSnf = parseFloat(snf);

            switch (rateChartType) {
                case 'FAT':
                    const fatRate = findRateForFatOnly(fat, fatData); // assuming fatData is the data for fat ranges from localStorage
                    computedRate = fatRate ? computeRateForFatOnly(fat, fatRate) : 0;
                    break;

                case 'SNF':
                    const snfRate = findRateForSnfOnly(snf, snfData); // assuming snfData is the data for snf ranges from localStorage
                    computedRate = snfRate ? computeRateForSnfOnly(snf, snfRate) : 0;
                    break;

                case 'TS':
                    const tsRate = findRateForTS(tsFat, tsSnf, tsData);  // Find the rate for this TS value

                    const tsValue = tsFat + tsSnf
                    computedRate = tsRate ? tsValue * tsRate : 0;  // If rate is found, compute the rate
                    break;

                case 'FATSNF':
                    const combinedRate = findPrice(fat, snf); // this will get combined rate based on FAT & SNF range
                    computedRate = combinedRate ? computeRateForFatAndSnf(fat, snf, combinedRate) : 0;
                    break;

                case 'FLAT':
                    const fatRateFlat = findRateForFatOnly(fat, flatData?.formData); // fatDataFlat is the fat ranges data for flat method
                    const snfRateFlat = findRateForSnfOnly(snf, flatData?.formSnf); // snfDataFlat is the snf ranges data for flat method

                    if (fatRateFlat && snfRateFlat) {
                        computedRate = computeRateForFlat(fat, snf, fatRateFlat, snfRateFlat, flatData.litreRate);
                    } else {
                        computedRate = 0;
                    }
                    break;

                default:
                    computedRate = 0;
            }

            setRate(computedRate);
        }

    }, [fat, snf, quantity]);


    const findRateForFatOnly = (fatValue, data) => {

        const fatFloat = parseFloat(fatValue);
        if (data)
            for (const item of data) {
                if (fatFloat >= item.start && fatFloat <= item.end) {
                    return item.price;
                }
            }
        return false;
    }

    const findRateForSnfOnly = (snfValue, data) => {
        const snfFloat = parseFloat(snfValue);
        if (data)
            for (const item of data) {
                if (snfFloat >= item.start && snfFloat <= item.end) {
                    return item.price;
                }
            }
        return null;
    }
    const findRateForTS = (tsFat, tsSnf, data) => {
        if (data)
            for (const item of data) {
                if (tsFat >= item.start && tsSnf <= item.end) {
                    return item.price;
                }
            }
        return null;
    }


    const computeRateForFatOnly = (fat, rate) => {
        return parseFloat(fat) * rate;
    }

    const computeRateForSnfOnly = (snf, rate) => {
        return parseFloat(snf) * rate;
    }

    const computeRateForFatAndSnf = (fat, snf, rate) => {
        return (parseFloat(fat) + parseFloat(snf)) * rate;
    }

    const computeRateForFlat = (fat, snf, fatRate, snfRate, litreRate) => {
        return ((parseFloat(fat) * fatRate) + (parseFloat(snf) * snfRate) + parseFloat(litreRate)).toFixed(2);
    }

    const priceRangeData = formData[0]?.formData

    function findPrice(fatValue, snfValue) {
        // Convert to float for accurate comparisons
        const fatFloat = parseFloat(fatValue);
        const snfFloat = parseFloat(snfValue);

        // Loop through the price data
        if (rateChartData) {
            for (const item of rateChartData) {
                // Check if the fatValue is within the range
                if (fatFloat >= item.fat_start && fatFloat <= item.fat_end) {
                    // Check if the snfValue is within the range
                    if (snfFloat >= item.snf_start && snfFloat <= item.snf_end) {
                        const priceData = {
                            fat: {
                                start: item.fat_start,
                                end: item.fat_end
                            },
                            snf: {
                                start: item.snf_start,
                                end: item.snf_end
                            },
                            price: item.price
                        };

                        setRatePerLtr(item.price);
                        return item.price; // Return the found price
                    }
                }
            }
        }
        return null; // Return null if no matching price was found
    }


    const handleBlur = (e, setValue) => {
        let { value } = e.target;

        // Format to two decimal places
        value = formatToTwoDecimalPlaces(value);
        setValue(value);
        // Shift focus to the next input if value is valid (i.e., not empty)

    }

    function formatToTwoDecimalPlaces(value) {
        // Parse the value to float, then format it to two decimal places
        let formattedValue = parseFloat(value).toFixed(2);
        // Handle NaN cases (when value cannot be parsed to a number)
        return isNaN(formattedValue) ? "" : formattedValue;
    }

    // Helper function to validate value range
    const isValueInRange = (value, type) => {
        if (!rateChartData) return false;

        for (const item of rateChartData) {

            if (type === "fat" && value >= item.fat_start && value <= item.fat_end) {
                return true;
            } else if (type === "snf" && value >= item.snf_start && value <= item.snf_end) {
                return true;
            }
        }
        return false;
    };

    function handleKeyUp(e) {
        const input = e.target;
        const value = input.value;
        e.preventDefault();
        const fieldType = e.target.getAttribute("data-field");
        setFieldType(fieldType)

        if (fieldType === "fat" || fieldType === "quantity") {
            // Check if the input value is a number
            if (isNaN(value)) {
                e.target.value = "";  // Clear the input field
                return;
            }

            // Validate "fat" value based on milk type
            if (fieldType === "fat") {
                if (milkType === "0" && (parseFloat(value) > 9 || parseFloat(value) < 0)) {
                    // Invalid "fat" value for cow milk
                    setFatError("Fat value must be between 0 and 9 for cow milk");
                } else if (milkType === "1" && (parseFloat(value) > 14 || parseFloat(value) < 0)) {
                    // Invalid "fat" value for buffalo milk
                    setFatError("Fat value must be between 0 and 14 for buffalo milk");
                } else {
                    // Valid "fat" value, clear the error
                    setFatError("");
                }
            }

            // Validate "quantity" to ensure it's less than 200
            if (fieldType === "quantity" && parseFloat(value) > 200) {
                setQuantityError("Quantity must not exceed 200");
            } else {
                setQuantityError("");
            }
        }

        if (!value) return;

        switch (rateType) {
            case 'FAT':
                validateFat(value, fatData);
                break;
            case 'SNF':
                validateSnf(value, snfData);
                break;
            case 'TS':
                validateTS(fat, value, tsData);
                break;
            case "FATSNF":
                if (fieldType === "fat") {
                    if (!isValueInRange(parseFloat(value), "fat")) {
                        setFatError("Entered FAT value is out of range!")
                        // toast.error("Entered FAT value is out of range!");
                    }
                } else if (fieldType === "snf") {
                    if (!isValueInRange(parseFloat(value), "snf")) {
                        setSnfError("Entered SNF value is out of range!")
                    }
                }
                break
            case 'FLAT':
                validateFat(value, flatData.formData)
                validateSnf(value, snfData.formSnf);
                break;

            default:
                break;
        }






        const regex = /^\d{0,2}(\.\d{0,2})?$/;

        if (fieldType === "fat" && fieldType === "snf")
            if (!regex.test(value) && value !== ".") {
                // If it doesn't match the pattern, revert to the last valid value
                input.value = input._lastValidValue || "";
            } else {
                // If it matches, update the last known valid value
                input._lastValidValue = value;
            }
    }

    const validateFat = (value, data) => {
        if (fieldType === "fat") {
            const fatFloat = parseFloat(value);

            // Check if fat value is within any range in the data
            const isInValidRange = data.some(item => fatFloat >= item.start && fatFloat <= item.end);

            if (!isInValidRange) {
                setFatError("Value is not within the valid range");
            } else {
                setFatError(""); // Clear error if the value is valid
            }
        }
    }
    const validateSnf = (value, data) => {
        if (fieldType === "snf") {
            const snfFloat = parseFloat(value);

            // Check if snf value is within any range in the data
            const isInValidRange = data.some(item => snfFloat >= item.start && snfFloat <= item.end);

            if (!isInValidRange) {
                setSnfError("Value is not within the valid range");
            } else {
                setSnfError(""); // Clear error if the value is valid
            }
        }
    }
    const validateTS = (tsFatValue, tsSnfValue, data) => {
        const tsFatFloat = parseFloat(tsFatValue);
        const tsSnfFloat = parseFloat(tsSnfValue);

        // Check if tsFat and tsSnf values are within any range in the data
        const isInValidRange = data.some(item => tsFatFloat >= item.start && tsSnfFloat <= item.end);

        if (!isInValidRange) {

        } else {
        }
    }


    const handleKeyDown = (e) => {
        const { value, selectionStart } = e.target;
        // Check if Enter is pressed
        if (e.key === 'Enter') {
            if (value === '') {
                e.preventDefault();
                return;
            }

            switch (e.target.name) {
                case 'Center Code':
                    if (!centerName) {
                        searchDownRef.current?.focus();
                        e.preventDefault();
                    }
                    return;
                case 'Farmer Code':
                    if (!inputValue) {
                        fatRef.current?.focus();
                        e.preventDefault();
                    }
                    return;

                case 'start': // This should be the name of the FAT input
                    if (!fatError) {
                        snfRef.current?.focus();
                        e.preventDefault();
                    }
                    return;

                case 'end': // This should be the name of the SNF input
                    if (!snfError) {
                        quantityRef.current?.focus();
                        e.preventDefault();
                    }
                    return;

                case 'start1':
                    if (!quantityError) {  // Assume start1Error for demonstration purposes
                        buttonRef.current?.focus();
                        e.preventDefault();
                    }
                    return;

                default:
                    break;
            }
        }

        // Check if Tab is pressed
        if (e.key === 'Tab' && !e.shiftKey) {
            if (value === '' || (e.target.name === 'FarmerCode' && inputValue) ||
                (e.target.name === 'start' && fatError) ||
                (e.target.name === 'end' && snfError) ||
                (e.target.name === 'start1' && quantityError)) {
                e.preventDefault();
            }
            return;
        }
        // Allow navigation & control keys
        const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab", "."];
        if (allowedKeys.includes(e.key)) {
            // Allow only one dot
            if (e.key === "." && value.includes('.')) {
                e.preventDefault();
            }
            return;
        }
        // If key is not a number or alphabet, prevent input
        if (!/^[a-zA-Z0-9]+$/.test(e.key)) {
            e.preventDefault();
            return;
        }

        // If there's a dot in the value, ensure up to two numbers after it
        const dotIndex = value.indexOf('.');
        if (dotIndex !== -1 && value.split('.')[1].length >= 2 && selectionStart > dotIndex) {
            e.preventDefault();
        }
    }



    const ABNORMAL_MULTIPLIER = 1.1; // Adjust this based on what you consider to be "abnormal"
    const [printerData, setPrinterData] = useState({})

    const handleSubmit = () => {
        let isValid = true
        setIsButtonDisabled(true);
        if (!fat) {
            setFatError('FAT is required.');
            isValid = false;

        } else {
            setFatError('');
        }
        if (!snf) {
            setSnfError('SNF is required.');
            isValid = false;

        } else {
            setSnfError('');
        }
        // if (!kg) {
        //     setQuantityError('Quantity is required.');
        //     isValid = false;

        // } else {
        //     setQuantityError('');
        // }


        if (!inputValue) {
            setCodeError('Farmer Code is required.');
            isValid = false;

        } else {
            setCodeError('');
        }
        if (!rate) {
            toast.error("Rate must be higher than 0")
            isValid = false;
        }

        if (isValid) {
            // Find the farmer using the inputValue (farmer code)
            const farmer = farmerTotalData.incentive_info.incentive === "yes" ? farmerTotalData.incentive_info.incentive_info : null;

            let incentiveRate = 0;
            // If the farmer exists and has an incentive, fetch the incentiveRate
            if (farmer) {
                const incentive = milkType === "0" ? farmer.cow : milkType === "1" ? farmer.buffalo : 0
                incentiveRate = incentive || 0;
            }
            const basicAmount = rate * parseFloat(quantity) || 0;

            // Calculate the incentive for each litre
            const incentiveAmount = parseFloat(quantity) * incentiveRate;

            // Sum basicAmount and incentiveAmount to get the final amount
            const finalAmount = basicAmount + incentiveAmount;



            let isAbnormal = false
            let symbol = null


            if (abnormal) {
                // Find the specific data for the farmer in question
                const farmerSpecificData = abnormal?.find(row => row.farmerName === inputValue && !row.isAbnormal);


                if (farmerSpecificData) {
                    // If the farmer is found in the previous data, compute average and check for abnormality
                    const totalQuantity = abnormal.reduce((sum, row) => sum + parseFloat(row.quantity), 0);
                    const totalFat = abnormal.reduce((sum, row) => sum + parseFloat(row.fat), 0);
                    const averageQuantity = totalQuantity / abnormal.length;

                    isAbnormal = quantity >= averageQuantity * ABNORMAL_MULTIPLIER || quantity <= averageQuantity * ABNORMAL_MULTIPLIER;
                    symbol = quantity > averageQuantity ? 'up' : quantity < averageQuantity ? 'down' : null;
                } else {
                    // If the farmer isn't found in the previous data, the entry isn't abnormal
                    isAbnormal = false;
                    symbol = null;
                }
            }

            const newRow = {

                symbol: symbol,
                centerName: storedData?.centerName,
                farmer_code: code,
                shift: storedData?.session,
                farmerName: inputValue,
                id: farmerId,
                milkType: milkType,
                fat: fat,
                snf: snf,
                quantity: quantity,
                kg: kg,
                rate: rate,
                incentiveRate: incentiveRate,
                ratePerLitre: ratePerLtr,
                amount: finalAmount, // Use the finalAmount here
                code: code

            };

            if (isEditMode && selectedId !== null) {

                const transformedEditData = {
                    vcc_farmer_id: centerId,
                    direct_farmer_id: farmerId,
                    route_id: editData.route_id,
                    milk_type: editData.milk_type,
                    bill_date: selectedDate,
                    session: sessionDay,
                    bill_no: editData.bill_no,
                    tot_fat: newRow.fat,
                    tot_snf: newRow.snf,
                    tot_qty_litre: newRow.quantity,
                    tot_qty_kg: newRow.kg,
                    tot_rate: newRow.rate,
                    tot_amount: newRow.amount,
                    incentive_amount: newRow.incentiveRate,
                    farmer_code: newRow.farmer_code,
                    unique_id: editData?.unique_id,
                    device_type: "WEB",
                    code: newRow.code,
                    collection_details: [
                        {
                            tot_fat: newRow.fat,
                            tot_snf: newRow.snf,
                            tot_qty_litre: newRow.quantity,
                            tot_qty_kg: newRow.kg,
                            tot_rate: newRow.rate,
                            tot_amount: newRow.amount,
                            incentive_amount: newRow.incentiveRate,
                            cans_number: numericValue
                        },
                    ],
                };

                setPrinterData(newRow);

                // Edit method
                const updateMethod = async () => {
                    setIsButtonDisabled(true)
                    const url = `/api/vcc-collection/${rowId}`
                    // Send the data to the server
                    const response = await put(url, transformedEditData);
                    try {
                        if (response.status) {
                            setBill_no(response.data[0].bill_no);
                            setIsButtonDisabled(false);

                            setListData((prevListData) => {
                                const updatedListData = prevListData.map((row) =>
                                    row.id === rowId ? { ...row, ...response.data[0] } : row
                                );
                                return updatedListData;
                            });

                            searchDownRef.current.clearInputValueAndFocus();
                            clearSearchInput();

                            if (printer) {
                                setShow(true);
                                setTimeout(() => {
                                    handlePrint();

                                }, 100);
                            }
                            searchDownRef.current.setAutofocus(true);
                        } else {
                            setIsButtonDisabled(false);
                        }

                    } catch (error) {
                        console.error("Error while posting data:", error);
                    } finally {
                        setIsButtonDisabled(false);
                    }

                }

                updateMethod()
                // Reset fields
                setFat('')
                setSnf('')
                setQuantity('')
                setRate('')
                setKg('')
                setIsEditMode(false);
                setSelectedId(null);
                setInputValue('');
                setMilkType('')
                setFarmerName('')
                setRowId('')
                setFindLast(null)
                codeRef.current?.focus();

            } else {
                // Here, it checks if the farmer already exists in tableData and is not in edit mode
                const existingFarmer = listData.find(row => row.farmer_code === code);

                if (existingFarmer) {
                    setPendingRow(newRow); // Set the newRow in state if farmer already exists
                    setShowConfirmationModal(true);
                } else {
                    addOrUpdateRow(newRow); // If farmer doesn't exist, add the new row
                }
            }
            setRemoveButton(false)

        } else {
            toast.error("Form is invalid")
        }
    }

    function getCurrentDateTime() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDateTime;
    }


    const addOrUpdateRow = async (newRow) => {
        try {
            let updatedData;
            if (isEditMode && selectedId !== null) {
                // Update the row in the state
                updatedData = (mode === "add" ? [...tableData] : [...abnormal]);
                updatedData[selectedId] = newRow;
            } else {
                // Add the new row to the state
                updatedData = isEditMode ? [...tableData] : [...tableData, newRow];
            }


            // Update the state with the new table data
            if (mode === "add") {
                setTableData(updatedData);
            } else {
                setAbnormal(updatedData);
            }


            setPrinterData(newRow);

            // Prepare the data to be sent to the server
            const transformedData = [{
                local_id: 0,

                vcc_farmer_id: farmerId,
                direct_farmer_id: centerId,
                route_id: routeId,
                milk_type: milkType,
                bill_no: 1,
                bill_date: selectedDate,
                session: sessionDay,
                unique_id: generateRandomId(),
                ctime: getCurrentDateTime(),
                tot_fat: newRow.fat,
                tot_snf: newRow.snf,
                tot_qty_litre: newRow.quantity,
                tot_qty_kg: newRow.kg,
                tot_rate: (newRow.rate).toFixed(2),
                tot_amount: (newRow.amount).toFixed(2),
                incentive_amount: newRow.incentiveRate,
                device_type: "WEB",
                collection_details: [
                    {
                        tot_amt: (newRow.amount).toFixed(2),
                        tot_fat: newRow.fat,
                        tot_snf: newRow.snf,
                        tot_qty_litre: parseFloat(newRow.quantity).toFixed(2),
                        tot_qty_kg: parseFloat(newRow.kg).toFixed(2),
                        tot_rate: (newRow.rate).toFixed(2),
                        incentive_amount: newRow.incentiveRate,
                        cans_number: numericValue
                    },
                ],
            }]

            const url = "/api/vcc-collection/create";
            setIsButtonDisabled(true)
            // Send the data to the server
            const response = await post(url, transformedData);

            if (response.status) {
                setBill_no(response.data[0].bill_no);

                if (!isEditMode) {
                    setListData((prevListData) => {
                        const newListData = [...prevListData, response.data[0]];
                        return newListData.sort((a, b) => b["bill_no"] - a["bill_no"]); // Sort in descending order
                    });
                    searchDownRef.current.clearInputValueAndFocus();
                    clearSearchInput();
                    if (printer) {
                        setShow(true);
                        setTimeout(() => {
                            handlePrint();
                        }, 100);
                    }

                }
                searchDownRef.current.setAutofocus(true);
            }
        } catch (error) {
            console.error("Error while posting data:", error);
        } finally {
            setIsButtonDisabled(false);
        }

        // Reset fields
        setFat('');
        setSnf('');
        setKg('');
        setQuantity('');
        setRate('');
        setIsEditMode(false);
        setSelectedId(null);
        setInputValue('');
        setMilkType('')
        setFarmerName('')

        codeRef.current?.focus();
    };

    const handleYesClick = () => {
        if (pendingRow) {
            addOrUpdateRow(pendingRow);
            setPendingRow(null);  // Reset the pendingRow after adding it
        }
        setShowConfirmationModal(false); // Close the confirmation modal
    };

    const pattern = /^\d*\.?\d{0,2}$/;

    const hasTwoDecimals = (value) => {
        return pattern.test(value);
    }

    const handleInputChange = (e, setValue, setError) => {
        const { value, name } = e.target;
        setValue(value);

        if (name === 'start1') {  // If the input field is for 'quantity'
            const parsedValue = parseFloat(value);
            if (!isNaN(parsedValue)) {
                const calculatedValue = (parsedValue * kgFactor)
                const decimalPlaces = 1;

                const convertedNumber = Math.floor(calculatedValue * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
                setKg(convertedNumber);
            } else {
                setKg(''); // set it to empty or some default value if the parsed value is NaN
            }
        }

        // Validate for exactly two decimals
        if (!hasTwoDecimals(value)) {
            setError(`${name.charAt(0).toUpperCase() + name.slice(1)} must be a number with exactly two decimal places.`);
        } else {
            setError(""); // Clear the error message
        }
    }
    const buttonStyle = {
        padding: '0.7px 4px ',
        fontSize: '10px',
        lineHeight: '1',
    };

    const singlePrint = (row) => {
        const data = {
            fat: parseFloat(row.tot_fat).toFixed(2) || 0,
            snf: parseFloat(row.tot_snf).toFixed(2) || 0,
            quantity: parseFloat(row.tot_qty_litre).toFixed(2) || 0,
            rate: parseFloat(row.tot_rate).toFixed(2) || 0,
            amount: parseFloat(row.tot_amount).toFixed(2) || 0,
        }

        setPrinterData(data)
        setPrintFrName(row.name)
        setCode(row.farmer_code)
        setBill_no(row.bill_no)
        setShow(true)
        setTimeout(() => {
            handlePrint()
        }, 100);

    }

    const plantColumn = (handleCell, handleEdit, selectedId,) => [
        {
            name: "BILL NO",
            selector: (row) => (
                <div>
                    {row.bill_no}{' '}
                    {row.device_type === 'DPU' && <Icon name="tablet"></Icon>}
                    {row.device_type === 'WEB' && <Icon name="b-si" />}
                </div>
            ),
            center: true,
            minWidth: '50px',
            sortable: true,
            sortDescending: true

        },
        {
            name: "FARMER CODE",
            selector: row => row.farmer_code,
            center: true,
            minWidth: '120px',

        },
        {
            name: "FARMER NAME",
            selector: row => row.name,
            center: true,
            minWidth: '150px',

        },

        {
            name: "MILK TYPE",
            selector: row => row.milk_type === "0" ? "cow" : row.milk_type === "1" ? "buffalo" : row.milk_type === "2" ? "both" : "",
            center: true,

        },
        {
            name: "FAT",
            selector: (row) => {
                const isManualModeFat = row?.collection_details ? JSON.parse(row?.collection_details)?.[0]?.is_manual_mode?.fat === "1" : null;
                // const isManualModeFat1 = JSON.parse(row?.vcc_collection_details?.collection_details)?.[0]?.is_manual_mode?.fat === "1";
                const formattedTotFat = parseFloat(row.tot_fat).toFixed(2);

                // Conditionally apply a style or class based on the condition
                const style = {
                    color: isManualModeFat ? 'blue' : 'inherit',
                };

                return (
                    <span style={style}>
                        {formattedTotFat}
                    </span>
                );
            },
            center: true,
            minWidth: '50px',
        },


        {
            name: "SNF",
            selector: row => {
                const isManualMode = row?.collection_details ? JSON.parse(row.collection_details)?.[0]?.is_manual_mode?.snf === "1" : null;
                const snfValue = parseFloat(row.tot_snf).toFixed(2);

                const style = {
                    color: isManualMode ? 'blue' : 'inherit',
                };

                return (
                    <span style={style}>
                        {snfValue}
                    </span>
                );
            },
            center: true,
            minWidth: '60px',
        },

        {
            name: "QTY(Kg)",
            selector: row => {
                const isManualMode = row?.collection_details ? JSON.parse(row.collection_details)?.[0]?.is_manual_mode?.qty === "1" : null;
                const Value = parseFloat(row.tot_qty_kg).toFixed(2);

                const style = {
                    color: isManualMode ? 'blue' : 'inherit',
                };

                return (
                    <span style={style}>
                        {Value}
                    </span>
                );
            },
            center: true,


        },
        {
            name: "QTY(Lit)",
            selector: row => {
                const isManualMode = row?.collection_details ? JSON.parse(row.collection_details)?.[0]?.is_manual_mode?.qty === "1" : null;
                const Value = parseFloat(row.tot_qty_litre).toFixed(2);

                const style = {
                    color: isManualMode ? 'blue' : 'inherit',
                };

                return (
                    <span style={style}>
                        {Value}
                    </span>
                );
            },
            center: true,

        },

        {
            name: "ACTION",
            center: true,

            cell: (row) => {
                if (selectedId !== null && row.id !== rowId) {
                    return null;  // Return nothing if a row is selected and it's not the current row
                }

                return (
                    <div  >
                        <Button
                            className="mx-1"
                            style={buttonStyle}
                            variant="primary"
                            onClick={() => handleEdit(row, row.id)}
                        >
                            <Icon name="edit"></Icon>
                        </Button>

                        {selectedId === null && (
                            <Button
                                className="mx-1"
                                style={buttonStyle}
                                variant="info"
                                onClick={() => singlePrint(row)}
                            >
                                <Icon name="printer"></Icon>
                            </Button>
                        )}
                    </div>
                );
            },
            sortable: false,
            hide: "md",
        }
    ]


    const rowStyles = [
        {
            when: (row) => row.id === rowId,
            style: {
                backgroundColor: '#04af70', // Or any other color of your choice
            },
        },
    ];

    const handleSave = () => {
        // Initialize isValid to true.
        let isValid = true;

        // Clear any existing error.
        setCodeError('');

        // If inputValue is empty and tableData is also empty, set the error message.
        if (mode === "add") {
            if (!inputValue && tableData.length === 0) {
                setCodeError('Farmer Code is required.');
                isValid = false;
            }
            // If tableData is empty, show toast error but don't set `isValid` to false.
            if (tableData.length === 0) {
                toast.error('Please fill rate chart values');
                isValid = false;
            }
        }

        if (isValid) {
            const dataToSave = {
                id: id,
                type: type,
                centerName: storedData?.centerName || '',
                code: inputValue,
                tableData: tableData
            };

            // Retrieve the current data from localStorage
            let dataArrayToSave = JSON.parse(localStorage.getItem('procurementData')) || [];

            // Check if an object with the same id exists
            const existingDataIndex = dataArrayToSave.findIndex(item => item.id === dataToSave.id);

            if (existingDataIndex !== -1) {
                // If exists, retrieve the maximum id from the current tableData for this item
                const maxTableDataId = dataArrayToSave[existingDataIndex].tableData.length > 0
                    ? Math.max(...dataArrayToSave[existingDataIndex].tableData.map(item => item.id))
                    : 0;

                // Update the id for each tableData item in dataToSave to make it incremental
                dataToSave.tableData = dataToSave.tableData.map((item, index) => {
                    return { ...item, id: maxTableDataId + index + 1 };
                });

                // Append the tableData to the existing item's tableData
                dataArrayToSave[existingDataIndex].tableData = [
                    ...dataArrayToSave[existingDataIndex].tableData,
                    ...dataToSave.tableData
                ];
                // Update other properties as well (like centerName, code, etc.) if needed
                dataArrayToSave[existingDataIndex].centerName = dataToSave.centerName;
                dataArrayToSave[existingDataIndex].code = dataToSave.code;
            } else {
                // Otherwise, add a new object
                dataArrayToSave = [...dataArrayToSave, dataToSave];
            }



            navigate('/direct-vendor')
            // Exit edit mode and reset the selected ID
            setIsEditMode(false);
            setSelectedId(null);
            setMilkType('')

        }
    }


    const [removeButton, setRemoveButton] = useState(false)

    const handleFarmerCode = (value) => {
        // Simple validation check
        if (!value || value.trim() === '') {
            return 'Farmer name with Code is required.';
        }
        return ''; // No error
    };


    const setType = (e) => {


        const value = e.target.value
        setTypeValue(value)
        setFat('')
        setSnf('')
        setQuantity('')
        setKg('')

        const inputValue = e.target.value.toUpperCase(); // Convert input to uppercase

        const pattern = /^[CB]*$/;

        // Check if the input matches the pattern
        if (pattern.test(inputValue)) {
            // Update the state only if the input is valid
            setTypeValue(inputValue);
        } else {
            setTypeValue('')
        }

        const data = rateChartId?.ratechart_id[0]
        let chartID = 0
        if (inputValue === "C" || inputValue === "B") {

            if (inputValue === "C") {
                if (data?.cow) {
                    chartID = data?.cow;
                } else {
                    chartID = data?.common;
                }
            } else if (inputValue === "B") {
                if (data?.buffalo) {
                    chartID = data?.buffalo;
                } else {
                    chartID = data?.common;
                }
            }


            // chartID = inputValue === "cow" ? rateChartId.ratechart_id[0]?.cow : inputValue === "buffalo" ? rateChartId?.buffalo : ''
        }
        if (inputValue === "C" || inputValue === "B") {
            const url = `/api/rate-chart/${chartID}/details`;

            const fn = async () => {
                try {
                    const response = await get(url);
                    if (response.status) {
                        const data = response.data
                        setRateChartData(data.range_jsonmap)
                        setFlatOnlyData(data.range_jsonmap)
                        const type = data.rate_type === 1 ? "FLATONLY" : data.rate_type === 2 ? "FATSNF" : data.rate_type === 3 ? "FAT" : data.rate_type === 4 ? "SNF" : data.rate_type === 5 ? "TS" : "FLAT"
                        setRateChartType(type)
                        if (data.rate_type === 3) {
                            setFatData(data?.range_jsonmap)
                        } else if (data.rate_type === 4) {
                            setSnfData(data?.range_jsonmap)
                        } else if (data.rate_type === 5) {
                            setTsData(data?.range_jsonmap)
                        } else {
                            setFlatData(data?.range_jsonmap)
                        }


                    }
                } catch (error) {
                    console.error("Error while posting data:", error);
                }

            }
            fn()

        }
    }


    const handleNumericValueChange = (value) => {
        setNumericValue(value);
    };

    const handleName = (name) => {
        // setInputValue(name)
    }
    const handleCenter = (name) => {
        // setInputValue(name)
    }
    const hanldeRoute = (name) => {

    }


    const handleSession = (e) => {
        setSessionDay(e.target.value);
        document.getElementById('btncheck1').blur();
        const body = {
            route_id: routeId,
            from_date: e.target.value,
            to_date: e.target.value,
            from_session: selectedDate,
            to_session: selectedDate,
            center_id: id,
            farmer_id: farmerId
        }

        if (body) {
            fetchListData(body)
        }

    };



    const printForData = {
        date: selectedDate,
        session: sessionDay,
        route_name: routeName
    }

    const fetchListData = async (body) => {
        const url = '/api/vcc-collection/list'
        const response = await postToast(url, body)

        if (response.status) {
            setListData(response?.data?.data.sort((a, b) => b["bill_no"] - a["bill_no"]));
        }
    }

    const handleDate = (value) => {
        setSelectedDate(value)
        const body = {
            route_id: routeId,
            from_date: value,
            to_date: value,
            from_session: sessionDay,
            to_session: sessionDay,
            center_id: id,
            farmer_id: farmerId
        }

        if (body) {
            fetchListData(body)
        }
    }

    const handlePaginate = (currentPage, itemPerPage, searchValue) => {
        const body = {
            page: currentPage,
            page_count: itemPerPage,
            search: searchValue,
            route_id: routeId,
            from_date: selectedDate,
            to_date: selectedDate,
            from_session: sessionDay,
            to_session: sessionDay,
            center_id: id,
            farmer_id: farmerId

        }

        if (body)
            fetchListData(body)
    }




    return (
        <Layout title="VLCC Form" content="container">

            <Row className="g-gs">
                <Col xxl="12">
                    <Block.Head >
                        <Block.HeadBetween>
                            <Block.HeadContent>
                                <h3 >
                                    VLCC Collection
                                </h3>
                            </Block.HeadContent>
                            <div className="d-flex justify-content-end ">
                                <Button
                                    as="label"
                                    variant="primary"
                                    onClick={() => navigate("/vcc-list")}
                                >
                                    <Icon name="eye"></Icon>
                                    View List
                                </Button>
                            </div>
                        </Block.HeadBetween>
                    </Block.Head>


                    <Card className="card-gutter-md">
                        <Card.Body>
                            <ConfirmationModal
                                show={showConfirmationModal}
                                title="Existing Farmer"
                                body="This farmer already exists. Do you still want to add?"
                                onCancel={() => {
                                    clearSearchInput()
                                    handleRemove()
                                    setShowConfirmationModal(false)
                                }}
                                onConfirm={handleYesClick}
                            />
                            <ConfirmationModal
                                show={showDeleteModal}
                                title="Delete Confirmation"
                                askReason
                                onCancel={cancelDelete}
                                onConfirm={(reason) => confirmDeleteForFatRate(reason)}
                            />

                            <Row className="g-gs">

                                <Col xxl="12">
                                    <Row className="g-gs">
                                        <Col sm="3">
                                            <SearchDown
                                                ref={searchDownRef}
                                                data={routeList}
                                                placeholder="Route Code"
                                                name="Route Code"
                                                nextInputRef={routeRef}
                                                onSelect={(selectedItem, type, data, error) => {
                                                    handleInputRoute(selectedItem, type, data, error)
                                                }}
                                                value={routeName}
                                                func={hanldeRoute}
                                            />
                                            {errorMsg && <div className="text-danger">{errorMsg}</div>}

                                        </Col>
                                        <Col sm="3">
                                            <CodeDropDown
                                                data={centerList}
                                                placeholder="Center Code"
                                                name="Center Code"
                                                nextInputRef={farmerRef}
                                                onValidate={handleFarmerCode}
                                                onSelect={(selectedItem, type, data, error) => {
                                                    handleInputCenter(selectedItem, type, data, error)
                                                }}
                                                value={centerName}
                                                func={handleCenter}
                                                inputRef={centerRef}
                                            />
                                        </Col>
                                        <Col lg="2">
                                            <Form.Label htmlFor="start">Date</Form.Label>
                                            <Form.Group className="form-group" controlId="validationCustom01">

                                                <div className="form-control-wrap">
                                                    <DatePicker onDateChange={(value) => handleDate(value)} value={selectedDate} />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col lg="2">
                                            <Form.Group className="form-group" >
                                                <Form.Label htmlFor="start">Session</Form.Label>
                                                <div className="form-control-wrap">
                                                    <ButtonGroup aria-label="Basic checkbox toggle button group">
                                                        <input type="radio" className="btn-check" name='session' id="btncheck1" autoComplete="off" value="M" checked={sessionDay === "M"}
                                                            onChange={handleSession} />
                                                        <Button as="label" variant="outline-primary" htmlFor="btncheck1"><Icon name="sun-fill"></Icon></Button>

                                                        <input type="radio" className="btn-check" name='session' id="btncheck2" autoComplete="off" value="E" checked={sessionDay === "E"}
                                                            onChange={handleSession} />
                                                        <Button as="label" variant="outline-primary" htmlFor="btncheck2"><Icon name="moon-fill"></Icon></Button>
                                                    </ButtonGroup>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="2">
                                            <Form.Group className="form-group" >
                                                <Form.Label htmlFor="start">Printer</Form.Label>
                                                <div className="form-control-wrap">
                                                    <ButtonGroup aria-label="Basic checkbox toggle button group">
                                                        <input type="radio" className="btn-check" name='printer' id="btn" autoComplete="off" value="off"
                                                            checked={printer === true}
                                                            onChange={() => {
                                                                setPrinter(true)
                                                                localStorage.setItem('printer', true)
                                                            }}
                                                        />
                                                        <Button as="label" variant="outline-success" htmlFor="btn"><Icon name="check"></Icon></Button>

                                                        <input type="radio" className="btn-check" name='printer' id="btn1" autoComplete="off" value="on"
                                                            checked={printer === false}
                                                            onChange={() => {
                                                                setPrinter(false)
                                                                localStorage.setItem('printer', false)
                                                            }}
                                                        />
                                                        <Button as="label" variant="outline-danger" htmlFor="btn1"><Icon name="cross"></Icon></Button>
                                                    </ButtonGroup>
                                                </div>
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                </Col>

                                {/* Remaining controls can follow the grid layout you provided */}
                                <Card className="card-gutter-md">
                                    <Card.Body>

                                        <Col lg='12'>
                                            <Row className="g-gs">
                                                <Col sm="4">
                                                    <Row className="g-gs">
                                                        <Col sm="4">
                                                            <SearchDownCode
                                                                ref={searchDownRef}
                                                                data={farmerData}
                                                                placeholder="Code"
                                                                name="Code"
                                                                nextInputRef={fatRef}
                                                                onValidate={handleFarmerCode}
                                                                onSelect={(selectedItem, type, data, error) => {
                                                                    handleInput(selectedItem, type, data, error)
                                                                }}
                                                                value={inputValue}
                                                                func={handleName}
                                                                keyPress={handleKeyPress}
                                                            />
                                                        </Col>

                                                        <Col sm={inputValue ? "5" : "8"} >
                                                            <Form.Group className="form-group" >
                                                                <Form.Label htmlFor="start" >Farmer Name</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control
                                                                        type="text"
                                                                        name='farmername'
                                                                        value={farmerName}
                                                                        autoComplete='off'
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        {(milkType === "2") &&
                                                            <Col sm="3" >
                                                                <Form.Group className="form-group" >
                                                                    <Form.Label htmlFor="start" >Milktype(C/B)</Form.Label>
                                                                    <div className="form-control-wrap">
                                                                        <Form.Control
                                                                            type="text"
                                                                            name='milkType'
                                                                            value={typeValue}
                                                                            autoComplete='off'
                                                                            onChange={setType}
                                                                            maxLength={1}
                                                                        />
                                                                    </div>
                                                                </Form.Group>

                                                            </Col>}

                                                        {(milkType === "0") &&
                                                            <Col sm="3" >
                                                                <Form.Group className="form-group" >
                                                                    <Form.Label htmlFor="start" >Milktype</Form.Label>
                                                                    <div className="form-control-wrap">
                                                                        <Form.Control
                                                                            type="text"
                                                                            name='milkType'
                                                                            value={dummyCow}
                                                                            autoComplete='off'
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </Form.Group>

                                                            </Col>}
                                                        {(milkType === "3") &&
                                                            <Col sm="2" >
                                                                <Form.Group className="form-group" >
                                                                    <Form.Label htmlFor="start" >Milktype</Form.Label>
                                                                    <div className="form-control-wrap">
                                                                        <Form.Control
                                                                            type="text"
                                                                            name='milkType'
                                                                            value={dummyBuffalo}
                                                                            autoComplete='off'
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>}
                                                    </Row>
                                                </Col>

                                                <Col lg="4">
                                                    <Row className="g-gs">
                                                        <Col sm="4">
                                                            <Form.Group className="form-group" >
                                                                <Form.Label htmlFor="start" className='mx-4'>FAT%</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control
                                                                        type="text"
                                                                        name='start'
                                                                        data-field="fat"
                                                                        value={fat}
                                                                        placeholder="Fat"
                                                                        onKeyDown={handleKeyDown}
                                                                        onKeyUp={(e) => handleKeyUp(e, setFat)}
                                                                        onBlur={(e) => handleBlur(e, setFat)}
                                                                        onChange={(e) => handleInputChange(e, setFat, setFatError)}
                                                                        autoComplete='off'
                                                                        ref={fatRef}
                                                                        required
                                                                        isInvalid={!!fatError} // Use isInvalid prop for bootstrap validation styling
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {fatError}
                                                                    </Form.Control.Feedback>
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm="4">
                                                            <Form.Group className="form-group" >
                                                                <Form.Label htmlFor="end" className='mx-4'>SNF%</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control
                                                                        type="text"
                                                                        name='end'
                                                                        data-field="snf"
                                                                        autoComplete='off'
                                                                        value={snf}
                                                                        ref={snfRef}
                                                                        onKeyDown={handleKeyDown}
                                                                        onKeyUp={(e) => handleKeyUp(e, setSnf)}
                                                                        placeholder="Snf"
                                                                        required
                                                                        onBlur={(e) => handleBlur(e, setSnf, setSnfError)}
                                                                        onChange={(e) => handleInputChange(e, setSnf, setSnfError)}
                                                                        isInvalid={!!snfError}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {snfError}
                                                                    </Form.Control.Feedback>
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="4">
                                                            <Form.Group className="form-group" >
                                                                <Form.Label htmlFor="start" className='mx-3'>QTY(Lit)</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control
                                                                        type="text"
                                                                        name='start1'
                                                                        value={quantity}
                                                                        data-field="quantity"
                                                                        ref={quantityRef}
                                                                        autoComplete='off'
                                                                        placeholder="Qty(Lit)"
                                                                        onKeyDown={handleKeyDown}
                                                                        onKeyUp={handleKeyUp}
                                                                        required
                                                                        onChange={(e) => handleInputChange(e, setQuantity, setQuantityError)}
                                                                        onBlur={(e) => handleBlur(e, setQuantity, setQuantityError)}
                                                                        isInvalid={!!quantityError} // Use isInvalid prop for bootstrap validation styling

                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {quantityError}
                                                                    </Form.Control.Feedback>
                                                                </div>
                                                            </Form.Group>
                                                        </Col>



                                                        {type === "vlcc" && <Col lg="3">
                                                            <Form.Group className="form-group" >
                                                                <Form.Label htmlFor="start" className='mx-3'>QTY(Lit)</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control
                                                                        type="text"
                                                                        name='start1'
                                                                        value={quantity}
                                                                        autoComplete='off'
                                                                        placeholder="Qty(Lit)"
                                                                    />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>}
                                                    </Row>
                                                </Col>
                                                <Col md="2">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>CANS</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <Tags removeItemButton type="text" placeholder="Enter Cans" value={numericValue}
                                                                onValueChange={(value) => handleNumericValueChange(value)} ref={tagsRef} />
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                {type === "vlcc" && <Col lg="3">
                                                    <Row className="g-gs">
                                                        <Col lg="6">
                                                            <Form.Group className="form-group" >
                                                                <Form.Label htmlFor="end" className='mx-4'>RATE</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control

                                                                        type="text"
                                                                        name='endSnf'
                                                                        placeholder="rate"
                                                                        autoComplete='off'
                                                                        required
                                                                        disabled
                                                                        value={fat && snf ? (parseFloat(rate)).toFixed(2) : 0.00}
                                                                    />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col sm="6" >
                                                            <Form.Group className="form-group" >
                                                                <Form.Label htmlFor="price" className='mx-4'>AMOUNT</Form.Label>
                                                                <div className="form-control-wrap">
                                                                    <Form.Control
                                                                        type="text"
                                                                        name='price'
                                                                        placeholder="amount"
                                                                        required
                                                                        autoComplete='off'
                                                                        disabled
                                                                        value={(rate * parseFloat(quantity) || 0.00).toFixed(2)}
                                                                    />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>}


                                                <Col lg='1'>
                                                    <Button
                                                        type='submit'
                                                        style={{ marginTop: "32px" }}
                                                        variant="primary"
                                                        ref={buttonRef}
                                                        disabled={isButtonDisabled}
                                                        onClick={handleSubmit}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleSubmit()
                                                            }
                                                        }}
                                                    >
                                                        <Icon name={isEditMode ? "edit" : "plus"} />
                                                    </Button>

                                                </Col>
                                                <Col lg='1'>
                                                    <Button
                                                        as="label"
                                                        style={{ marginTop: "32px", marginLeft: "-30px" }}
                                                        variant="danger"
                                                        onClick={handleRemove}
                                                    >
                                                        <Icon name="cross" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Card.Body>
                                </Card>

                                <Block >
                                    <Card>

                                        <DataTable
                                            persistTableHead
                                            tableClassName="data-table-head-light table-responsive"
                                            className={"data-table-head-light table-responsive"}
                                            conditionalRowStyles={rowStyles}
                                            data={listData}
                                            columns={plantColumn(handleDelete, editFunc, selectedId)}
                                            onPaginate={handlePaginate} page_value={pagination}
                                        />

                                    </Card>
                                </Block>

                                <Col lg='12' className="mt-6">

                                    <Button
                                        as="label"
                                        variant="success"
                                        onClick={handleSave}
                                    >
                                        Save
                                    </Button>

                                </Col>

                                <div id="element-to-print">
                                    {show && <MyComponent
                                        printerData={printerData}
                                        storedData={printForData}
                                        farmername={printFrName}
                                        route_name={routeName}
                                        code={code}
                                        ref={printRef}
                                        bill_no={bill_no}
                                    />}
                                </div>

                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row >
        </Layout >

    )
}

export default VlccForm