import { createSlice } from "@reduxjs/toolkit";
import { FarmerVlccDetails } from "../../thunks/vlcc-thunk/VlccFarmerDetails";

const vlccFarmerDetailSlice = createSlice({
    name: "FarmerDetails",
    initialState: {
        details: [],
        isLoading: false,
        error: null

    },
    extraReducers(builder) {
        builder.addCase(FarmerVlccDetails.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(FarmerVlccDetails.fulfilled, (state, action) => {
            state.isLoading = false
            state.details = action.payload

        })
        builder.addCase(FarmerVlccDetails.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })
    }
})

export const vlccFarmerDetailsReducer = vlccFarmerDetailSlice.reducer
