import React, { useEffect, useState } from 'react'
import Layout from "../../../layout/default";
import { Button, Card, Col, Nav, Tab } from 'react-bootstrap';
import { Block, Icon } from '../../../components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PrinterSetting from '../settings/PrinterSetting';
import EquipmentSetting from '../settings/EquipmentSetting';
import CollectionSetting from '../settings/CollectionSetting';



const SettingsViewPage = () => {
    const [activeTab, setActiveTab] = useState("collection-settings");
    const navigate = useNavigate()

    const { url, id } = useParams()


    const handleTabChange = (key) => {
        setActiveTab(key);
        navigate(`/settings-view/${key}/${id ? id : 0}`)
    };


    useEffect(() => {
        if (url) {
            setActiveTab(url)
        }

        if (activeTab === "collection-settings") {
            // fetchData();
        } else if (activeTab === "equipment-settings") {
            // fetchVlcc();
        } else if (activeTab === "printer-settings") {
            // dispatch(fetchFarmerList())
        }

    }, [activeTab, url]);

    return (
        <Layout title="Settings View" content="container">

            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Settings </Block.Title>

                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>

            <Card>
                <Card.Body>

                    <Tab.Container id="pills-tabs-example" activeKey={activeTab}
                        onSelect={handleTabChange}>
                        <Nav variant="pills" className="mb-3">
                            <Nav.Item>
                                <Nav.Link eventKey="collection-settings">Collection Settings</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="equipment-settings">Equipment Settings</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="printer-settings">Printer Settings</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="collection-settings">
                                <Card>
                                    <Card.Body>
                                        <div className='mt-2'>
                                            <CollectionSetting />
                                        </div>
                                    </Card.Body>
                                </Card>

                            </Tab.Pane>

                            <Tab.Pane eventKey="equipment-settings">
                                <Card>
                                    <Card.Body>
                                        <div className='mt-2'>
                                            <EquipmentSetting />
                                        </div>

                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane eventKey="printer-settings">
                                <Col xxl="12">

                                    <div className='mt-2'>
                                        <PrinterSetting />
                                    </div>

                                </Col>

                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Card.Body>
            </Card>
        </Layout>
    )
}

export default SettingsViewPage