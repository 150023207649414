import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import { Form, ListGroup } from 'react-bootstrap';
import './SearchDown.css';

export const SearchDownCode = React.forwardRef(({ autoFocus, keyPress, error, value, func, data, placeholder, onValidate, onSelect, name, nextInputRef, errors }, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [validationError, setValidationError] = useState('');
    const listItemRefs = useRef([]);
    const containerRef = useRef(null);
    const inputRef = useRef(null);
    const [autofocus, setAutofocus] = useState(false);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    useEffect(() => {
        if (autoFocus) {
            // Focus on the input field
            inputRef.current && inputRef.current.focus();
        }
    }, [autoFocus]);


    console.log(inputValue, "value***");

    useImperativeHandle(ref, () => ({
        clearInputValue: () => {
            setInputValue('');
            setHighlightedIndex(-1);
            setShowResults(true);
        },
        focusInput: () => {
            inputRef.current && inputRef.current.focus();
        },
        clearInputValueAndFocus: () => {
            setInputValue('');
            setHighlightedIndex(-1);
            setShowResults(false);
            inputRef.current && inputRef.current.focus();
        },
        setAutofocus: (value) => {
            setAutofocus(value);
        },
    }));






    const handleKeyDown = (e) => {
        let newIndex = highlightedIndex;

        if (e.key === 'ArrowDown') {
            if (highlightedIndex < searchResults.length - 1) {
                newIndex = highlightedIndex + 1;
            } else {
                newIndex = 0;
            }
        } else if (e.key === 'ArrowUp') {
            if (highlightedIndex > 0) {
                newIndex = highlightedIndex - 1;
            } else {
                newIndex = searchResults.length - 1;
            }
        } else if (e.key === 'Enter' && highlightedIndex !== -1) {
            const selectedItem = searchResults[highlightedIndex];
            if (onSelect) {
                // Check if the selected item includes data value

                const existsInResults = searchResults.includes(selectedItem);
                console.log("selectedItem", selectedItem);

                // onSelect(selectedItem.code, selectedItem.id, selectedItem);
                onSelect(selectedItem.code, selectedItem.id, selectedItem);
                setValidationError('');
                nextInputRef.current?.focus();

                setInputValue(selectedItem.code);
                func(selectedItem.code)
                setShowResults(false);

            }
            return;
        }

        setHighlightedIndex(newIndex);

        if (listItemRefs.current[newIndex]) {
            listItemRefs.current[newIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest'
            });
        }
    };
    const handleInputChange = (e) => {
        const value = e.target.value.toLowerCase();
        setInputValue(value);

        // Check for empty value
        if (!value.trim()) {
            setValidationError(errors);
            setSearchResults(data); // Show the entire list
            setShowResults(true); // Display the results dropdown
            return; // exit early
        } else {
            setValidationError(''); // reset validation error
        }

        const results = data.filter(item =>
            item.code.toString().includes(value) || item.name.toLowerCase().includes(value)
        );
        setSearchResults(results);

        if (results.length > 0) {
            setHighlightedIndex(0);
            setShowResults(true);
            setValidationError(''); // reset validation error if any
        } else {
            setHighlightedIndex(-1);
            setShowResults(false);
            setValidationError(errors);
        }

        if (onValidate) {
            const externalError = onValidate(value);
            console.log(externalError, "externalError");
            if (externalError) {
                setValidationError(externalError);
            }
        }
    }



    const handleOutsideClick = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            setShowResults(false);
        }
    };

    const handleInputFocus = () => {
        // Show all data when input is focused
        setSearchResults(data);
        setHighlightedIndex(-1); // Optional: Reset highlighted index
        setShowResults(true);
        setValidationError(''); // Reset validation error
        keyPress()
    }

    useEffect(() => {
        // When the component mounts, add the mousedown listener
        document.addEventListener("mousedown", handleOutsideClick);

        // When the component unmounts, remove the mousedown listener
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const hanldleDelete = () => {
        keyPress()
    }



    return (
        <div ref={containerRef} onKeyDown={handleKeyDown} className="dropdown-container">
            <Form.Group className="form-group">
                <Form.Label>{name}</Form.Label>
                <span className="me-2">
                    <i className="fa fa-exclamation-triangle text-danger">
                        *
                    </i>
                </span>
                <Form.Control
                    type="text"
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}

                    isInvalid={!!error}
                    ref={inputRef}
                />
                {validationError && <div className="text-danger">{validationError}</div>}
            </Form.Group>
            {showResults && (
                <ListGroup className="dropdown-results scrollable-list">
                    {searchResults.map((item, index) => (
                        <ListGroup.Item
                            key={item.code}
                            ref={(el) => (listItemRefs.current[index] = el)}
                            tabIndex="0"
                            onClick={(e) => {
                                e.preventDefault();
                                setInputValue(item.code);
                                setValidationError('');
                                if (onSelect) {
                                    const existsInResults = searchResults.includes(item);
                                    onSelect(item.code, item.id, item, existsInResults);
                                }
                                func(item.code)

                                setShowResults(false);
                                nextInputRef?.current?.focus();
                            }}
                            className={index === highlightedIndex ? 'highlighted-item' : ''}
                        >
                            {item.code}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            )}
        </div>
    );
});


