import { createSlice } from "@reduxjs/toolkit";
import { Settings } from "../../thunks/settings-thunk/SettingsThunk";

const SettingsSlice = createSlice({
    name: "Settings",
    initialState: {
        settings: [],
        isLoading: false,
        error: null

    },
    extraReducers(builder) {
        builder.addCase(Settings.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(Settings.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            console.log("Data received:", action.payload);
        })
        builder.addCase(Settings.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })

    }
})


export const SettingsReducer = SettingsSlice.reducer
