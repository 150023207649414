import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../api/Api";


export const Settings = createAsyncThunk('settings/fetch', async (body) => {
    try {
        const endpoint = `/api/settings/update`;
        const response = await post(endpoint, body);
        console.log("API response:", response);
        return response.data;
    } catch (error) {
        console.error("API error:", error);
        throw error; // Rethrow the error to be caught in the rejected action
    }
});

export const SettingsDetails = createAsyncThunk('settingsDeatils/fetch', async (id) => {
    try {
        const endpoint = `/api/settings/${id}/details`;
        const response = await get(endpoint);
        console.log("API response:", response);
        return response.data;
    } catch (error) {
        console.error("API error:", error);
        throw error; // Rethrow the error to be caught in the rejected action
    }
});