import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Icon } from '../../../components';

function ConfirmationModal({
    show,
    title,
    body,
    onConfirm,
    onCancel,
    confirmLabel = <Icon name="check"></Icon>,
    cancelLabel = <Icon name="cross"></Icon>,
    askReason = false
}) {
    const [reason, setReason] = useState('');
    const [reasonError, setReasonError] = useState(false); // to show an error message if reason is empty

    const handleConfirm = () => {
        if (askReason && reason.trim() === '') {
            setReasonError(true);
            return;
        }
        onConfirm(reason);
    };

    return (
        <Modal show={show} onHide={onCancel} size='sm'>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
                {askReason && (
                    <>
                        <Form.Label>Reason for removal:</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={reason}
                            onChange={(e) => {
                                setReason(e.target.value);
                                setReasonError(false); // reset error state on change
                            }}
                            isInvalid={reasonError}
                            placeholder="Provide a reason..."
                        />
                        {reasonError && <Form.Control.Feedback type="invalid">Please provide a reason.</Form.Control.Feedback>}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onCancel}>{cancelLabel}</Button>
                <Button variant="success" onClick={handleConfirm}>{confirmLabel}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationModal;
