import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, put } from "../../../api/Api";


export const routeMaster = createAsyncThunk('routeMaster/fetch', async () => {
    try {
        const endpoint = `/api/route/list`;

        const response = await get(endpoint);
        console.log("API response:", response);

        return response.data;
    } catch (error) {
        console.error("API error:", error);
        throw error; // Rethrow the error to be caught in the rejected action
    }
});

export const routeCode = createAsyncThunk('routeCode/code', async () => {
    try {
        const endpoint = `/api/route/get-upcoming-code`;

        const response = await get(endpoint);
        console.log("API response:", response);

        return response.data;
    } catch (error) {
        console.error("API error:", error);
        throw error; // Rethrow the error to be caught in the rejected action
    }
});