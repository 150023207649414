import React, { useEffect, useState } from 'react';
import { Col, Form, ButtonGroup, Button } from 'react-bootstrap'; // Assuming you are using Bootstrap components
import { Icon } from '../../../../components';

const Session = ({ onChange, value }) => {
    const handleStartSession = (value) => {
        if (onChange) {
            onChange(value);
        }
    };
    return (
        <>
            <Form.Group className="form-group">
                <div className="form-control-wrap">
                    <ButtonGroup aria-label="Basic checkbox toggle button group">
                        <input
                            type="radio"
                            className="btn-check"
                            name="sessionStart"
                            id="btncheckStart1"
                            autoComplete="off"
                            value="M"
                            checked={value === "M"}
                            onChange={() => handleStartSession('M')}
                        />
                        <Button as="label" variant="outline-primary" htmlFor="btncheckStart1">
                            <Icon name="sun-fill"></Icon>
                        </Button>

                        <input
                            type="radio"
                            className="btn-check"
                            name="sessionStart"
                            id="btncheckStart2"
                            autoComplete="off"
                            value="E"
                            checked={value === "E"}
                            onChange={() => handleStartSession('E')}
                        />
                        <Button as="label" variant="outline-primary" htmlFor="btncheckStart2">
                            <Icon name="moon-fill"></Icon>
                        </Button>
                    </ButtonGroup>
                </div>
            </Form.Group>
        </>
    )
}

export default Session