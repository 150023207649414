import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../../api/Api";


export const FarmerVlccDetails = createAsyncThunk('farmerDetails/fetch', async (id) => {
    try {
        const endpoint = `/api/vcc-farmer/${id}/details`;

        const response = await get(endpoint);
        console.log("API response:", response);

        return response.data;
    } catch (error) {
        console.error("API error:", error);
        throw error; // Rethrow the error to be caught in the rejected action
    }
});