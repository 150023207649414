import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, put } from "../../../api/Api";


export const rateChartList = createAsyncThunk('rateChart/fetch', async (id) => {
    try {
        const endpoint = `/api/rate-chart/${id}/list`;

        const response = await get(endpoint);
        console.log("API response:", response);

        return response.data;
    } catch (error) {
        console.error("API error:", error);
        throw error; // Rethrow the error to be caught in the rejected action
    }
});

export const rateChartUpdate = createAsyncThunk('rateChart/update', async ({ id, data }) => {
    try {
        const endpoint = `/api/rate-chart/${id}`;

        console.log(id, data, "Body data-=-->");
        const response = await put(endpoint, data);
        console.log("API response:", response);

        return response.data;
    } catch (error) {
        console.error("API error:", error);
        throw error; // Rethrow the error to be caught in the rejected action
    }
});

export const rateChartStatus = createAsyncThunk('rateChart/update', async ({ id, name }) => {
    try {
        const endpoint = `/api/rate-chart/status-update/${id}`;

        console.log(id, name, "Body data-=-->");
        const response = await put(endpoint, name);
        console.log("API response:", response);

        return response.data;
    } catch (error) {
        console.error("API error:", error);
        throw error; // Rethrow the error to be caught in the rejected action
    }
});

export const rateChartDetails = createAsyncThunk('rateChart/details', async (id) => {
    try {
        const endpoint = `/api/rate-chart/${id}/details`;

        console.log(id, "Body data-=-->");
        const response = await get(endpoint);
        console.log("API response:", response);

        return response.data;
    } catch (error) {
        console.error("API error:", error);
        throw error; // Rethrow the error to be caught in the rejected action
    }
});