import React from 'react'
import { Modal, Button, Col } from 'react-bootstrap';
import { Icon } from '../../../components';
import { dateReverseS, dateSplit, hasExpired, timeSplit } from '../shared/Date';
import { get } from '../api/Api';

const GenerationModal = ({ id, show, data, regenerate, regenerateOneTime, onClose, oneTime, closeLabel = "Close" }) => {

    const generateView = () => {

        const fetchList = async () => {
            const url = `/api/vlcc/generate-token/${id}`;
            try {
                const response = await get(url);
                if (response.status) {
                    console.log("Server response:", response.data);
                    if (!oneTime) {
                        regenerate(id)
                    } else {
                        regenerateOneTime(id)
                    }
                }

            } catch (error) {
                console.error("Error while posting data:", error);
            }
        }
        fetchList()
    }

    return (
        <Modal show={show} onHide={onClose} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>{!oneTime ? "Generation Key" : "Temporary Access Token"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!oneTime && <div className="container">
                    <Col xl="12" className="text-center">
                        <p><strong>Registration Key&nbsp;&nbsp;:</strong> &nbsp;&nbsp;{data?.register_key}  <Button size='sm' variant="success" onClick={generateView}><Icon name="reload-alt"></Icon></Button> </p>
                        {data?.status === "0" && (
                            <p>
                                <strong>Expiry Time&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong>&nbsp;&nbsp;
                                <span style={{ backgroundColor: hasExpired(dateReverseS(dateSplit(data?.expiry_time)), timeSplit(data?.expiry_time)) ? '#ff4d4d' : 'transparent' }}>
                                    {dateReverseS(dateSplit(data?.expiry_time))} {timeSplit(data?.expiry_time)}
                                </span>
                            </p>
                        )}
                        {data?.status === "1" && <p><strong>Login Time &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong>{data?.login_time} </p>}
                        {data?.status === "1" && <p><strong>Login Location&nbsp;&nbsp;&nbsp;&nbsp;:</strong>{data?.login_location} </p>}
                    </Col>
                </div>}
                {oneTime && <div className="container">
                    <Col xl="12" className="text-center">
                        <p><strong>Access Token&nbsp;&nbsp;:</strong> &nbsp;&nbsp;{data}</p>
                    </Col>
                </div>}
            </Modal.Body>
        </Modal >
    );
}

export default GenerationModal