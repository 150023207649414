import React, { useEffect, useRef, useState } from 'react'
import Layout from "../../../layout/default";
import { Button, Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { ChartLine } from '../../../components/Chart/Charts';
import { Block, ChartLabel, DateRangePicker, Icon, Media } from '../../../components';
import { Colors } from '../../../utilities';
import hexRGB from '../../../utilities/hexRGB';
import './Procurement.css'
import { SearchDown } from '../shared/dropDown/SearchDown';



export const Report = () => {
    const [dateRange, setDateRange] = useState({ start: null, end: null });
    const [showResults, setShowResults] = useState(false);
    const focusedItemRef = useRef(null);
    const [farmerName, setFarmerName] = useState('')
    const [nameError, setNameError] = useState('')
    const [dateError, setDateError] = useState('')



    // Bounce rate chart
    let fatChart = {
        labels: ["1st May", "2nd May", "3rd May", "4th May", "5th May", "6th May", "7th May"],
        yAxis: false,
        xAxis: false,
        maxTicksLimit: 4,
        datasets: [
            {
                tension: 0,
                label: "This Month",
                borderWidth: 2,
                borderColor: Colors.success,
                backgroundColor: hexRGB(Colors.success, 0.2),
                pointBackgroundColor: Colors.success,
                pointBorderColor: Colors.white,
                fill: true,
                data: [120, 130, 110, 105, 115, 108, 118]
            }
        ]
    };
    // Bounce rate chart
    let snfChart = {
        labels: ["1st May", "2nd May", "3rd May", "4th May", "5th May", "6th May", "7th May"],
        yAxis: false,
        xAxis: false,
        maxTicksLimit: 4,
        datasets: [
            {
                tension: 0,
                label: "This Month",
                borderWidth: 2,
                borderColor: Colors.primary,
                backgroundColor: hexRGB(Colors.primary, 0.2),
                pointBackgroundColor: Colors.primary,
                pointBorderColor: Colors.white,
                fill: true,
                data: [120, 130, 110, 105, 115, 108, 118]
            }
        ]
    };
    // Bounce rate chart
    let qunatityChart = {
        labels: ["1st May", "2nd May", "3rd May", "4th May", "5th May", "6th May", "7th May"],
        yAxis: false,
        xAxis: false,
        maxTicksLimit: 4,
        datasets: [
            {
                tension: 0,
                label: "This Month",
                borderWidth: 2,
                borderColor: Colors.info,
                backgroundColor: hexRGB(Colors.info, 0.2),
                pointBackgroundColor: Colors.info,
                pointBorderColor: Colors.white,
                fill: true,
                data: [120, 130, 110, 105, 115, 108, 118]
            }
        ]
    };

    let data = [
        { date: "11/10/2023", rate: 520 },
        { date: "12/10/2023", rate: 525 },
        { date: "13/10/2023", rate: 510 },
        { date: "14/10/2023", rate: 530 },
        { date: "15/10/2023", rate: 535 },
        { date: "16/10/2023", rate: 540 },
        { date: "17/10/2023", rate: 532 }
    ];
    let labels = data.map(item => item.date);
    let rateValues = data.map(item => item.rate);
    // Calculate average rate
    let averageRate = rateValues.reduce((sum, rate) => sum + rate, 0) / rateValues.length;
    // Bounce rate chart
    let rateChart = {
        labels: labels,
        yAxis: false,
        xAxis: false,
        maxTicksLimit: 4,
        datasets: [
            {
                tension: 0,
                label: "This Month",
                borderWidth: 2,
                borderColor: Colors.secondary,
                backgroundColor: hexRGB(Colors.secondary, 0.2),
                pointBackgroundColor: Colors.secondary,
                pointBorderColor: Colors.white,
                fill: true,
                data: rateValues
            }
        ]
    };
    const handleDateRangeChange = (start, end) => {
        setDateRange({ start, end });
        // Use the date range for any other logic you need
    };

    const validateName = (value) => {
        if (!value || value.trim() === '') {
            setNameError('Farmer Name is required.');
            return false;
        } else {
            // Add other validation checks as needed
            setNameError('');
        }

    }
    const farmerData = [
        { code: 1, name: 'kuppusamy', milkType: "both", incentive: true, incentiveRate: 1.00 },
        { code: 2, name: 'ramasamy', milkType: "cow", incentive: true, incentiveRate: 2.00 },
        { code: 3, name: 'munusamy', milkType: "cow", incentive: false, incentiveRate: 0.00 },
        { code: 4, name: 'rangasamy', milkType: "buffalo", incentive: true, incentiveRate: 2.00 },
        { code: 5, name: 'velusamy', milkType: "buffalo", incentive: true, incentiveRate: 1.00 },
        { code: 6, name: 'karuppasamy', milkType: "cow", incentive: false, incentiveRate: 0.00 },
        { code: 7, name: 'aarusamy', milkType: "buffalo", incentive: true, incentiveRate: 1.00 },
        { code: 8, name: 'muniyandi', milkType: "buffalo", incentive: true, incentiveRate: 1.00 },
        { code: 9, name: 'velu', milkType: "cow", incentive: false, incentiveRate: 0.00 },
        { code: 10, name: 'balu', milkType: "cow", incentive: true, incentiveRate: 1.00 },
        { code: 11, name: 'ramu', milkType: "buffalo", incentive: true, incentiveRate: 3.00 },
        { code: 12, name: 'raju', milkType: "buffalo", incentive: false, incentiveRate: 0.00 },
        { code: 13, name: 'kumar', milkType: "buffalo", incentive: true, incentiveRate: 1.00 },
        { code: 14, name: 'sathish', milkType: "both", incentive: false, incentiveRate: 0.00 },
        { code: 15, name: 'boopathi', milkType: "buffalo", incentive: true, incentiveRate: 1.00 },
        { code: 16, name: 'karthi', milkType: "both", incentive: false, incentiveRate: 0.00 },
        { code: 17, name: 'ramesh', milkType: "both", incentive: true, incentiveRate: 1.00 },
    ]




    const handleClick = () => {

        if (!dateRange.start || !dateRange.end) {

            setDateError("Please select a date range.");
            return;
        }

        const data = {
            farmerName: farmerName,
            dateStart: dateRange.start,
            dateEnd: dateRange.end
        }
    }

    return (
        <Layout title="Form controls" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h3">Reports</Block.Title>

                    </Block.HeadContent>

                </Block.HeadBetween>
            </Block.Head>

            <Card className="card-gutter-md mb-2">
                <Card.Body>
                    <Row className="g-gs mb-1" >
                        <Col sm="3" >

                            <SearchDown
                                data={farmerData}
                                placeholder="Farmer Name"
                                onValidate={validateName}
                                onSelect={(selectedItem) => {
                                    setFarmerName(selectedItem)
                                }}
                            />


                        </Col>
                        <Col lg="4">
                            <Form.Group className="form-group">
                                <Form.Label>Start and End Range</Form.Label>
                                <DateRangePicker format={'dd/mm/yyyy'} onDateRangeChange={handleDateRangeChange} />

                            </Form.Group>
                            {dateError && <div className="text-danger">{dateError}</div>}
                        </Col>
                        <Col lg="4">
                            <Button
                                as="label"
                                style={{ marginTop: "32px" }}
                                variant="primary"
                                onClick={handleClick}
                            >
                                Check
                            </Button>
                        </Col>

                    </Row>
                </Card.Body>
            </Card>
            <Row className="g-gs">
                <Col sm="6" xxl="6">
                    <Card className="h-100">
                        <Card.Body>
                            <div className="card-title-group align-items-start">
                                <div className="card-title">
                                    <h4 className="title">Fat Range</h4>
                                </div>
                                <Media size="sm" shape="circle" variant="warning-soft">
                                    <Icon name="activity-round-fill"></Icon>
                                </Media>
                            </div>
                            <div className="mt-2 mb-4">
                                <div className="amount h1">1,853</div>
                                <div className="d-flex align-items-center smaller">
                                    <div className="change up">
                                        <Icon name="upword-alt-fill"></Icon>
                                        <span className="ms-1">10%</span>
                                    </div>
                                    <span className="text-light">From last week</span>
                                </div>
                            </div>
                            <div className="nk-chart-analytics-session">
                                <ChartLine data={fatChart} />
                            </div>
                            <ChartLabel.Group className="justify-content-between mt-1">
                                <ChartLabel className="chart-label-small">
                                    1 May
                                </ChartLabel>
                                <ChartLabel className="chart-label-small">
                                    7 May
                                </ChartLabel>
                            </ChartLabel.Group>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm="6" xxl="6">
                    <Card className="h-100">
                        <Card.Body>
                            <div className="card-title-group align-items-start">
                                <div className="card-title">
                                    <h4 className="title">Snf Range</h4>
                                </div>
                                <Media size="sm" shape="circle" variant="warning-soft">
                                    <Icon name="activity-round-fill"></Icon>
                                </Media>
                            </div>
                            <div className="mt-2 mb-4">
                                <div className="amount h1">1,853</div>
                                <div className="d-flex align-items-center smaller">
                                    <div className="change up">
                                        <Icon name="upword-alt-fill"></Icon>
                                        <span className="ms-1">10%</span>
                                    </div>
                                    <span className="text-light">From last week</span>
                                </div>
                            </div>
                            <div className="nk-chart-analytics-session">
                                <ChartLine data={snfChart} />
                            </div>
                            <ChartLabel.Group className="justify-content-between mt-1">
                                <ChartLabel className="chart-label-small">
                                    1 May
                                </ChartLabel>
                                <ChartLabel className="chart-label-small">
                                    7 May
                                </ChartLabel>
                            </ChartLabel.Group>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm="6" xxl="6">
                    <Card className="h-100">
                        <Card.Body>
                            <div className="card-title-group align-items-start">
                                <div className="card-title">
                                    <h4 className="title">Quantity Range</h4>
                                </div>
                                <Media size="sm" shape="circle" variant="warning-soft">
                                    <Icon name="activity-round-fill"></Icon>
                                </Media>
                            </div>
                            <div className="mt-2 mb-4">
                                <div className="amount h1">1,853</div>
                                <div className="d-flex align-items-center smaller">
                                    <div className="change up">
                                        <Icon name="upword-alt-fill"></Icon>
                                        <span className="ms-1">10%</span>
                                    </div>
                                    <span className="text-light">From last week</span>
                                </div>
                            </div>
                            <div className="nk-chart-analytics-session">
                                <ChartLine data={qunatityChart} />
                            </div>
                            <ChartLabel.Group className="justify-content-between mt-1">
                                <ChartLabel className="chart-label-small">
                                    1 May
                                </ChartLabel>
                                <ChartLabel className="chart-label-small">
                                    7 May
                                </ChartLabel>
                            </ChartLabel.Group>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm="6" xxl="6">
                    <Card className="h-100">
                        <Card.Body>
                            <div className="card-title-group align-items-start">
                                <div className="card-title">
                                    <h4 className="title">Rate Range</h4>
                                </div>
                                <Media size="sm" shape="circle" variant="warning-soft">
                                    <Icon name="activity-round-fill"></Icon>
                                </Media>
                            </div>
                            <div className="mt-2 mb-4">
                                <div className="amount h1">{averageRate.toFixed(2)}</div>
                                <div className="d-flex align-items-center smaller">
                                    <div className="change up">
                                        <Icon name="upword-alt-fill"></Icon>
                                        <span className="ms-1">10%</span>
                                    </div>
                                    <span className="text-light">From last week</span>
                                </div>
                            </div>
                            <div className="nk-chart-analytics-session">
                                <ChartLine data={rateChart} />
                            </div>
                            <ChartLabel.Group className="justify-content-between mt-1">
                                <ChartLabel className="chart-label-small">
                                    1 May
                                </ChartLabel>
                                <ChartLabel className="chart-label-small">
                                    7 May
                                </ChartLabel>
                            </ChartLabel.Group>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
}
