import { useLayoutEffect, useRef, useState } from 'react'
import { DateRangePicker } from 'vanillajs-datepicker';
import { Form, InputGroup } from 'react-bootstrap';

function DatePicker({ onDateRangeChange, className, ...props }) {
  const { autoHide, clearBtn, format, maxView, pickLevel, startView, title, todayBtn, weekStart } = props;
  const dateInput = useRef(null);


  useLayoutEffect(() => {
    let opt = {
      title: title || '',
      buttonClass: 'btn btn-md',
      autohide: autoHide || true,
      clearBtn: clearBtn || false,
      todayBtn: todayBtn || false,
      format: format || 'yyyy-mm-dd',

      maxView: maxView || 3,
      pickLevel: pickLevel || 0,
      startView: startView || 0,
      weekStart: weekStart || 0
    };
    new DateRangePicker(dateInput.current, opt);

    const startInput = dateInput.current.querySelector('input[name="start"]');
    const endInput = dateInput.current.querySelector('input[name="end"]');

    const handleChange = () => {
      if (onDateRangeChange) {
        const startDate = startInput.value;
        const endDate = endInput.value;
        onDateRangeChange(startDate, endDate);
      }
    };

    startInput.addEventListener('changeDate', handleChange);
    endInput.addEventListener('changeDate', handleChange);

   
  }, [onDateRangeChange])

  return (
    <InputGroup className={className} ref={dateInput}>
      <Form.Control placeholder="dd-mm-yyyy" type="text" autoComplete="off" name="start" />
      <InputGroup.Text>to</InputGroup.Text>
      <Form.Control placeholder="dd-mm-yyyy" type="text" autoComplete="off" name="end" />
    </InputGroup>
  )
}

export default DatePicker