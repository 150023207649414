import React, { useEffect, useRef, useState } from 'react'
import { Block, DatePicker, Icon } from '../../../../components'
import { Button, ButtonGroup, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import Layout from "../../../../layout/default";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import '../Procurement.css'
import { SearchDown } from '../../shared/dropDown/SearchDown';
export const VlccEntry = () => {

    const [tableView, setTableView] = useState(true)
    const [centerName, setCenterName] = useState('');
    const [session, setSession] = useState('');
    const [validTill, setValidTill] = useState('');
    const [validTillError, setValidTillError] = useState('')
    const [formData, setFormData] = useState([])
    const idRef = useRef(0);
    const [fullscreenMd, setFullscreenMd] = useState(false);
    const [method, setMethod] = useState('')
    const [methodError, setMethodError] = useState('')
    const [sessionError, setSessionError] = useState('')
    const [procurementData, setProcurementData] = useState([])
    const [viewData, setViewData] = useState([])
    const navigate = useNavigate();
    const [routeName, setRouteName] = useState('')
    const [nameError, setNameError] = useState('')
    const dateRef = useRef(null);



    useEffect(() => {
        const data = localStorage.getItem('vlcc-entry')
        const parsedData = JSON.parse(data);
        setFormData(parsedData ? parsedData : []);
        const storedData = localStorage.getItem('procurementData');
        const ProData = JSON.parse(storedData);
        setProcurementData(ProData ? ProData : []);
    }, [])

    const buttonStyle = {
        padding: '0.7px 7px ',
        fontSize: '10px',
        lineHeight: '1',
    };

    const handleModeChange = (mode) => {
        setMethod(mode);
        setMethodError('');  // Clear the error whenever a mode is selected
    };
    const handleSession = (session) => {
        setSession(session);
        setSessionError('');  // Clear the error whenever a mode is selected
    };

    const currentDate = new Date();
    const thirtyDaysAgo = new Date(currentDate);
    thirtyDaysAgo.setDate(currentDate.getDate() - 7);

    const [selectedDate, setSelectedDate] = useState('');


    const handleView = () => {
        setTableView(!tableView)

    }

    const handleValidTill = (value) => {

        validateValidTill(value)
        // Extract only numbers from the entered value
        const numberValue = value.replace(/[^0-9]/g, "");
        if (numberValue) {
            setValidTill(numberValue)
        } else {
            setValidTill('')
        }
    };


    const validateValidTill = (value) => {
        if (!value || value.trim() === '') {
            setValidTillError('Valid time is required.');
            return false;
        }
        // Add other validation checks as needed
        setValidTillError('');
        return true;
    }

    const handleSubmit = () => {
        let isValid = true;

        if (!routeName) {
            setNameError('Route Name is required.');
            isValid = false;
        } else {
            setNameError('');
        }

        if (!validTill) {
            setValidTillError('Valid time is required.');
            isValid = false;
        } else {
            setValidTillError('');
        }
        if (!session) {
            setSessionError('Session is required.');
            isValid = false;
        } else {
            setSessionError('');
        }

        if (!method) {
            setMethodError('Action is required.');
            isValid = false;
        } else {
            setMethodError('');
        }

        // Check if centerName already exists in formData
        const matchingCenter = formData.find(data => data.routeName === routeName);

        if (matchingCenter) {
            // Check if session already exists for the matching center
            if (matchingCenter.session === session) {
                toast.error("Center Name with this session already exists in the form data.");
                isValid = false;
            } else {
                toast.info("Center Name exists but session is different. New session will be added.");
            }
        }

        if (isValid) {
            const overAll = {
                id: idRef.current,
                name: routeName,
                selectedDate,
                session,
                status: 'incomplete',
                milkType: "Cow",
                validTill,
                method
            };
            idRef.current += 1;
            setFormData(prevData => {
                const updatedData = [...prevData, overAll];
                // Store updatedData in localStorage
                localStorage.setItem('vlcc-entry', JSON.stringify(updatedData));

                return updatedData;
            });

            setTableView(!tableView);
            setCenterName('')
            setValidTill('')
        }
    }


    const viewColumn = [

        {
            name: "S.NO",
            selector: row => (row.id) + 1,
            center: true
        },
        {
            name: "FARMER NAME",
            selector: row => row.farmerName,
            center: true
        },
        {
            name: 'FAT',
            selector: row => row.fat,
            center: true
        },
        {
            name: 'SNF',
            selector: row => row.snf,
            center: true
        },
        {
            name: 'QUANTITY',
            selector: row => row.quantity,
            center: true
        },
        {
            name: 'RATE',
            selector: row => row.rate,
            center: true
        }, {
            name: 'AMOUNT',
            selector: row => row.amount,
            center: true
        },

    ]


    const procurementColumn = (handleEye) => [
        {
            name: "Route Name",
            selector: row => row.name,
        },
        {
            name: "Date",
            selector: row => row.selectedDate,
            center: true
        },
        {
            name: "Session",
            selector: row => row.session,
            center: true
        },
        {
            name: "status",
            selector: row => row.status,
            center: true
        },
        {
            name: "Action",
            center: true,

            cell: (row) => {
                return (
                    <div>
                        <Button
                            style={buttonStyle}
                            className="mx-1"
                            variant="success"
                            onClick={() => navigate(`/procurement-form/${row.id}?mode=edit&type=vlcc-entry`)}
                        >
                            <Icon name="plus"></Icon>
                        </Button>
                        <Button
                            style={buttonStyle}
                            className="mx-1"
                            variant="primary"
                            onClick={() => navigate(`/procurement-form/${row.id}?mode=edit&type=vlcc-entry`)}
                        >
                            <Icon name="edit"></Icon>
                        </Button>

                        <Button
                            style={buttonStyle}
                            className="mx-1"
                            variant="info"
                            onClick={() => navigate(`/pro-list/${row.id}?type=vlcc-entry`)}
                        >
                            <Icon name="eye"></Icon>
                        </Button>

                    </div>
                );
            },
            sortable: false,
            hide: "md",
        }
    ]

    const handleEye = (name) => {
        const data = procurementData.filter((el) => el.centerName === name)
        setViewData(data[0]?.tableData)
        setFullscreenMd(true)
    }



    const handleDate = (value) => {
        setSelectedDate(value)
    }
    const route = [
        { code: 1, name: 'AVINASIPALAYAM', milkType: "both", incentive: true, incentiveRate: 1.00 },
        { code: 2, name: 'SAIBABA COLONY', milkType: "cow", incentive: true, incentiveRate: 2.00 },
        { code: 3, name: 'RSPURAM', milkType: "cow", incentive: false, incentiveRate: 0.00 },
        { code: 4, name: 'RACECOURSE', milkType: "buffalo", incentive: true, incentiveRate: 2.00 },
        { code: 5, name: 'RAMNAGAR', milkType: "buffalo", incentive: true, incentiveRate: 1.00 },
        { code: 6, name: 'GANDHIPURAM', milkType: "cow", incentive: false, incentiveRate: 0.00 },
        { code: 7, name: 'AVRAMPALAYAM', milkType: "buffalo", incentive: true, incentiveRate: 1.00 },
        { code: 8, name: 'GANAPATHY', milkType: "buffalo", incentive: true, incentiveRate: 1.00 },

    ]
    const validateName = (value) => {
        if (!value || value.trim() === '') {
            setNameError('Farmer Name is required.');
            return false;
        } else {
            // Add other validation checks as needed
            setNameError('');
        }

    }

    return (
        <Layout title="VLCC Entry" content="container">

            <Row className="g-gs ">
                <Col xxl="12">
                    <div className="gap gy-4">
                        <div className="gap-col">
                            <Row className="g-gs">
                                <Col xxl="12">
                                    <Row className="g-gs">
                                        <Col lg='12'  >
                                            <div className="d-flex justify-content-end">
                                                <Button
                                                    as="label"
                                                    variant="primary"
                                                    onClick={handleView}
                                                >
                                                    <Icon name={tableView ? 'plus' : "eye"} className="mx-1" />
                                                    {tableView ? 'Add' : "View"}
                                                </Button>
                                            </div>
                                            <h3 >
                                                VLCC Reception Entry
                                            </h3>
                                            <Card className="card-gutter-md custom-card mt-1" >
                                                <Card.Body className="card-body-custom">

                                                    {tableView !== true &&
                                                        <Row className="g-gs mt-1">
                                                            <Col lg="3">
                                                                <SearchDown
                                                                    data={route}
                                                                    placeholder="Route Name"
                                                                    nextInputRef={dateRef}
                                                                    name="Route Name"
                                                                    onValidate={validateName}
                                                                    onSelect={(selectedItem) => {
                                                                        setRouteName(selectedItem)
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col lg="2">
                                                                <Form.Label htmlFor="PrimaryRoute">
                                                                    Date
                                                                </Form.Label>
                                                                <Form.Group className="form-group" controlId="validationCustom01">
                                                                    <div className="form-control-wrap">
                                                                        <DatePicker
                                                                            value={selectedDate}
                                                                            onDateChange={handleDate}
                                                                            minDate={thirtyDaysAgo}
                                                                            maxDate={currentDate}
                                                                        />
                                                                    </div>

                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="2">
                                                                <Form.Label htmlFor="PrimaryRoute">Session</Form.Label>
                                                                <Form.Group className="form-group" controlId="validationCustom01" >
                                                                    <ButtonGroup aria-label="Session Selection">
                                                                        <input type="radio" className="btn-check" value='am' name="SESSION_NAME_PLACEHOLDER" id="SESSION_ID_PLACEHOLDER_1" autoComplete="off" onClick={() => handleSession('AM')} />
                                                                        <Button as="label" variant="outline-primary" htmlFor="SESSION_ID_PLACEHOLDER_1"><Icon name="sun-fill"></Icon></Button>

                                                                        <input type="radio" className="btn-check" value='pm' name="SESSION_NAME_PLACEHOLDER" id="SESSION_ID_PLACEHOLDER_2" autoComplete="off" onClick={() => handleSession('PM')} />
                                                                        <Button as="label" variant="outline-primary" htmlFor="SESSION_ID_PLACEHOLDER_2"><Icon name="moon-fill"></Icon></Button>
                                                                    </ButtonGroup>
                                                                    {!session && <div className="text-danger">{sessionError}</div>}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="2">
                                                                <Form.Label htmlFor="Valid">
                                                                    Valid till
                                                                </Form.Label>
                                                                <div className="flex-container">
                                                                    <Form.Control
                                                                        className="flex-input"
                                                                        type="text"
                                                                        name='Valid'
                                                                        id="Valid"
                                                                        placeholder="Valid till hrs"
                                                                        required
                                                                        value={validTill}
                                                                        isInvalid={!!validTillError}
                                                                        onChange={(e) => handleValidTill(e.target.value)}
                                                                    />
                                                                    <div className="flex-label">hrs</div>
                                                                </div>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {validTillError}
                                                                </Form.Control.Feedback>
                                                            </Col>


                                                            <Col lg="2">
                                                                <Form.Label htmlFor="PrimaryRoute">
                                                                    Action
                                                                </Form.Label>
                                                                <Form.Group className="form-group" controlId="validation" >
                                                                    <ButtonGroup aria-label="Mode Selection" >
                                                                        <input type="radio" className="btn-check" value='ADD' name="MODE_NAME_PLACEHOLDER" id="MODE_ID_PLACEHOLDER_1" autoComplete="off" onClick={() => handleModeChange('ADD')} />
                                                                        <Button as="label" variant="outline-primary" htmlFor="MODE_ID_PLACEHOLDER_1"><Icon name="plus"></Icon>ADD</Button>
                                                                        <input type="radio" className="btn-check" value='EDIT' name="MODE_NAME_PLACEHOLDER" id="MODE_ID_PLACEHOLDER_2" autoComplete="off" onClick={() => handleModeChange('EDIT')} />
                                                                        <Button as="label" variant="outline-primary" htmlFor="MODE_ID_PLACEHOLDER_2"><Icon name="edit"></Icon>EDIT</Button>
                                                                    </ButtonGroup>
                                                                    {!method && <div className="text-danger">{methodError}</div>}
                                                                </Form.Group>

                                                            </Col>
                                                            <Col lg='2' className="mt-3">
                                                                <Button variant="success" onClick={handleSubmit}>Submit</Button>
                                                            </Col>
                                                        </Row>}


                                                    {tableView && <Col lg="12" className='mt-2'>
                                                        <Block >
                                                            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                                                                <DataTable persistTableHead className={"data-table-head-light table-responsive"} tableClassName="data-table-head-light table-responsive" data={formData} columns={procurementColumn(handleEye)} />
                                                            </Card>
                                                        </Block>
                                                    </Col>}
                                                    <Modal size='lg' show={fullscreenMd} onHide={() => setFullscreenMd(false)}>

                                                        <Modal.Body>
                                                            <DataTable persistTableHead className={"data-table-head-light table-responsive"} tableClassName="data-table-head-light table-responsive" data={viewData} columns={viewColumn} />
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="danger" onClick={() => setFullscreenMd(false)}>Cancel</Button>

                                                        </Modal.Footer>
                                                    </Modal>




                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </div>
                    </div>
                </Col>
            </Row>

        </Layout>
    )
}
