import React, { forwardRef, useState } from 'react';
import './Printer.css';
import { dateReverseH, dateSplit } from '../Date';

const CustomDataTable = forwardRef(({ headerData, setIsPrinting, tableData }, ref) => {

    const [from, setFrom] = useState(null)
    console.log("Selector==>", tableData);

    console.log("headerData", headerData)

    const columns = [
        { name: 'Code', selector: 'farmer_code', width: '7%' },
        { name: 'Name', selector: 'farmer_name', width: '20%' },
        { name: 'Bank', selector: 'bank.BankName', width: '17%' },
        { name: 'Account Number', selector: 'bank.AccNo', width: '24%' },
        { name: 'IFSC', selector: 'bank.ifsc', width: '18%' },
        { name: 'Total Amount', selector: 'total_amount', width: '13%' },
    ];

    const cashColumns = [
        { name: 'Code', selector: 'farmer_code', width: '10%' },
        { name: 'Name', selector: 'farmer_name', width: '20%' },
        { name: 'Total Amount', selector: 'total_amount', width: '30%' },
        { name: 'Signature', selector: '', width: '40%' },
    ];



    const groupedData = {};


    tableData?.forEach((entry) => {
        const key = `${entry.farmer_name}_${entry.farmer_code}`;
        if (!groupedData[key]) {
            groupedData[key] = {
                farmer_name: entry.farmer_name,
                farmer_code: entry.farmer_code,
                start_date: dateReverseH(dateSplit(entry.bill_date)),
                to_date: dateReverseH(dateSplit(entry.bill_date)),
                start_session: entry.session,
                to_session: entry.session,
                total_quantity: parseFloat(entry.tot_qty_litre),
                total_amount: parseFloat(entry.tot_amount),
                mode_of_payment: JSON.parse(entry.mode_payment_info)?.[0]?.mode_of_payment,
                bank: JSON.parse(entry.mode_payment_info)?.[0]?.bank_info

            };
        } else {
            groupedData[key].to_date = dateReverseH(dateSplit(entry.bill_date));
            groupedData[key].to_session = entry.session;
            groupedData[key].total_quantity += parseFloat(entry.tot_qty_litre);
            groupedData[key].total_amount += parseFloat(entry.tot_amount);
        }
    });



    console.log(groupedData, "groupedData");

    const totalAmounts = {};
    Object.values(groupedData).forEach((group) => {
        const paymentMode = group.mode_of_payment;
        if (!totalAmounts[paymentMode]) {
            totalAmounts[paymentMode] = 0;
        }
        totalAmounts[paymentMode] += group.total_amount;
    });

    const styles = {
        printTable: {
            margin: '20px auto', // Adjust the margin as needed
            width: '90%',        // Adjust the width as needed
        },
    };




    const printContent = (
        <div>
            <div id="print-table" ref={ref} style={styles.printTable}>
                <div className="data-table-top">
                    <h3>ASM Dairy</h3>
                </div>
                {/* Iterate over unique payment modes and render tables */}
                {[...new Set(Object.values(groupedData).map(group => group.mode_of_payment))].map((paymentMode, index) => (

                    <div key={index}>
                        {paymentMode === 1 && <>
                            <h5 style={{ marginTop: '20px', marginBottom: '20px', fontWeight: 'bold' }}>
                                FROM: {dateReverseH(headerData.from_date)}({headerData.from_session}) TO: {dateReverseH(headerData.to_date)}({headerData.to_session})
                            </h5>
                            <h3 style={{ marginTop: '20px', marginBottom: '20px', fontWeight: 'bold' }}>
                                BANK
                            </h3>
                            <table className="print-table">
                                <thead>
                                    <tr>
                                        {/* Render table headers */}
                                        {columns.map((column) => (
                                            <th key={column.selector || column.name} style={{ width: column.width || 'auto' }}>{column.name} </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Render table rows for the specific payment mode */}
                                    {Object.values(groupedData).map((group, groupIndex) => {
                                        if (group.mode_of_payment === paymentMode) {
                                            return (
                                                <tr key={groupIndex}>
                                                    {columns.map((column) => (
                                                        <td key={column.name} style={{ width: column.width || 'auto' }}>
                                                            {column.selector.startsWith('bank.') ? group.bank[column.selector.split('.')[1]] : group[column.selector]}
                                                        </td>
                                                    ))}
                                                </tr>
                                            );
                                        }
                                        return null;
                                    })}

                                </tbody>
                            </table>
                            {totalAmounts[paymentMode] && (
                                <span style={{ textAlign: 'right', marginLeft: '10px', fontWeight: 'bold' }}>
                                    (Total Amount: {totalAmounts[paymentMode].toFixed(2)})
                                </span>
                            )}
                        </>}
                        {paymentMode === 0 && <>
                            <h5 style={{ marginTop: '20px', marginBottom: '20px', fontWeight: 'bold' }}>
                                FROM: {headerData.from_date}({headerData.from_session}) TO: {headerData.to_date}({headerData.to_session})
                            </h5>
                            <h3 style={{ marginTop: '20px', marginBottom: '20px', fontWeight: 'bold' }}>
                                Cash
                            </h3>
                            <table className="print-table">
                                <thead>
                                    <tr>
                                        {/* Render table headers for Cash payment mode */}
                                        {cashColumns.map((column) => (
                                            <th key={column.selector || column.name}>{column.name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Render table rows for the Cash payment mode */}
                                    {Object.values(groupedData).map((group, groupIndex) => {
                                        if (group.mode_of_payment === paymentMode) {
                                            return (
                                                <tr key={groupIndex}>
                                                    {cashColumns.map((column) => (
                                                        <td key={column.name} >
                                                            {typeof column.selector === 'function' ? column.selector(group) : group[column.selector]}
                                                        </td>
                                                    ))}
                                                </tr>
                                            );
                                        }
                                        return null;
                                    })}
                                </tbody>
                            </table>
                            {totalAmounts[paymentMode] && (
                                <span style={{ textAlign: 'right', marginLeft: '10px', fontWeight: 'bold' }}>
                                    (Total Amount: {totalAmounts[paymentMode].toFixed(2)})
                                </span>
                            )}
                        </>}
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <div className="data-table-wrapper"  >
            {printContent}
        </div>
    );
});

export default CustomDataTable;
