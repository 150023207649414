import { configureStore } from "@reduxjs/toolkit";
import { DirectCollectionReducer } from "./slices/direct-farmer/DirectCollectionSlice";
import { ProfileReducer } from "./slices/profile/ProfileSlice";
import { RateChartReducer } from "./slices/rate-chart/RateChartSlice";
import { RouteCodeReducer, RouteReducer } from "./slices/route/RouteSlice";
import { FarmerDetailsReducer } from "./slices/direct-farmer/FarmerDetailsSlice";
import { createFarmerReducer } from "./slices/direct-farmer/DirectFarmerCreate";
import { updateFarmerReducer } from "./slices/direct-farmer/DirectFarmerUpdate";
import { VlccCollectionReducer } from "./slices/vlcc-farmer/VlccCollectionList";
import { createVlccFarmerReducer } from "./slices/vlcc-farmer/VlccFarmerCreate";
import { updateVlccFarmerReducer } from "./slices/vlcc-farmer/VlccCollectionUpdate";
import { vlccFarmerDetailsReducer } from "./slices/vlcc-farmer/VlccFarmerDetails";
import { RateChartDetailsReducer } from "./slices/rate-chart/RateChartDetailsSlice";
import { SettingsReducer } from "./slices/settings/SettingsSlice";
import { SettingsDetailsReducer } from "./slices/settings/SettingsDetailsSlice";
import { createDirectCollectionReducer } from "./slices/direct-collection-slice/DirectCreateSlice";
import { updateDirectCollectionReducer } from "./slices/direct-collection-slice/DirectUpdateSlice";
import { VlccRouteCenterReducer } from "./slices/vlcc-farmer/VlccCenterRouteSlice";


export const store = configureStore({
    reducer: {
        // Direct Farmer:
        directFarmerList: DirectCollectionReducer,
        createFarmer: createFarmerReducer,
        updateFarmer: updateFarmerReducer,
        DirectEntryList: DirectCollectionReducer,
        farmerDetails: FarmerDetailsReducer,

        // Vlcc Farmer:
        vlccFarmerList: VlccCollectionReducer,
        createVlccFarmer: createVlccFarmerReducer,
        updateVlccFarmer: updateVlccFarmerReducer,
        vlccFarmerDetails: vlccFarmerDetailsReducer,
        vlccRouteCenter: VlccRouteCenterReducer,

        // Direct Collection
        directCollectionCreate: createDirectCollectionReducer,
        directCollectionUpdate: updateDirectCollectionReducer,


        // Profile:
        profile: ProfileReducer,

        // RateChart:
        rateChart: RateChartReducer,
        rateChartDetails: RateChartDetailsReducer,

        // Route:
        route: RouteReducer,
        routeCode: RouteCodeReducer,

        // Settings
        settings: SettingsReducer,
        SettingsDetails: SettingsDetailsReducer

    }
})


export * from './thunks/direct-thunk/DirectFarmer'
export * from './thunks/profile-thunk/Profile'
export * from './thunks/ratechart-thunk/RateChartThunk'
export * from './thunks/route-thunk/RouteThunk'
export * from './thunks/direct-thunk/DirectEntryList'
export * from './thunks/direct-thunk/FarmerDetailsThunk'
export * from './thunks/vlcc-thunk/VlccCollection'
export * from './thunks/vlcc-thunk/VlccFarmerDetails'
export * from './thunks/vlcc-thunk/VlccEntryList'
export * from './thunks/settings-thunk/SettingsThunk'




