import { useEffect, useState } from "react";
import { Dropdown, Form, Button, Badge } from 'react-bootstrap';
import classNames from "classnames";
import SimpleBar from 'simplebar-react';
import io from 'socket.io-client';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Layout from '../../../layout/default';
import { ChatList, CustomDropdownMenu, CustomDropdownToggle, Icon, Image, MediaGroup, Media, MediaText, Message, ChatMedia } from '../../../components';
import socketIOClient from 'socket.io-client';
import { Break } from "../../../utilities";
import { getNode, postNode } from "../../masters/api/Api";

function ChatsPage() {
    const [sidebar, setSidebar] = useState(false);
    const [mobileView, setMobileView] = useState(false);
    const [active, setActive] = useState('');
    const [selected_id, setSelected_id] = useState('');
    const [inputValue, setInputValue] = useState('');
    const ENDPOINT = 'http://localhost:5000';
    const [userList, setUserList] = useState([])
    const commands = {
        analyse: "[0x40, 0x04, 0x07, 0x00, 0x00, 0x43]",
        read: "[0x40, 0x02, 0x47, 0x05]",
        exit: "[0x40, 0x04, 0x01, 0x0A, 0x00, 0x4F]",
        ok: "[0x40, 0x04, 0x01, 0x04, 0x00, 0x41]",
        test: "[0x40, 0x04, 0x05, 0x00, 0x00, 0x41]",
        weekly: "[0x40, 0x04, 0x09, 0x01, 0x14, 0x58]",
        daily: "[0x40, 0x04, 0x09, 0x00, 0x14, 0x59]",
        send: "[0x40,0x05,0x4A,0x03,0x00,0x01,0x0D]"
    };
    function calculateLRC(hexArray) {
        // Initialize LRC as 0
        let lrc = 0;

        // Iterate through each hexadecimal value
        hexArray.forEach(hex => {
            // Convert hex to decimal
            let decimalValue = parseInt(hex, 16);
            // Perform XOR operation with current LRC
            lrc ^= decimalValue;
        });

        // Convert the final LRC value to a two-character hexadecimal string
        let lrcHex = lrc.toString(16).toUpperCase();
        if (lrcHex.length < 2) {
            lrcHex = '0' + lrcHex;
        }

        return lrcHex;
    }


    useEffect(() => {
        // const dataBytes = ["40", "05", "4A", "03", "00", "05"];
        const dataBytes = ['40', '05', '4A', '03', '00', '10'];

        const lrcValue = calculateLRC(dataBytes);
        console.log("LRC Value:", lrcValue);

        const socket = socketIOClient(ENDPOINT);

        // Join the chat room
        socket.emit("join chat", "143");

        // Handle receiving a private message
        socket.on('private message', (message) => {
            console.log('Received private message:', message);
        });

        socket.on('userList', (list) => {
            console.log('Received list:', list);
            setUserList(list)
        });


        // Handle receiving a general message
        socket.on('message', (message) => {
            console.log('Received message:', message);
            // setChatLog(prevLog => [...prevLog, message]);
        });

        // Clean up the connection when the component unmounts
        return () => {
            socket.disconnect();
        };
    }, []);

    const buttonFun = () => {
        const socket = socketIOClient(ENDPOINT);
        socket.emit("message", "hitttdoiiiit");
    }

    console.log("selected_id", selected_id);

    const sendMessage = () => {
        const socket = socketIOClient(ENDPOINT);
        // socket.emit('message', inputValue);
        socket.emit('private message', { from: "143", content: inputValue, to: selected_id, key: "read" });
        // socket.emit("join chat", selectedChat._id);
        setInputValue('');
    };

    const sendKey = (cmd, key) => {
        const socket = socketIOClient(ENDPOINT);
        // socket.emit('message', inputValue);
        socket.emit('private message', { from: "143", content: cmd, to: selected_id, key });
        socket.emit("join chat", "143");
    }



    const [userDetails, setUserDetails] = useState({})



    const create_msg = async (data) => {
        const url = `/chat/message/create`;
        try {
            const response = await postNode(url, data);
            if (response.status) {
                setInputValue('')
                // setLoading(false);
            } else {
                // setLoading(false);
            }
        } catch (error) {
            console.error("Error while posting data:", error);
            // setLoading(false);
        }
    };


    const user_list = async () => {
        const url = `/chat/list`;
        try {
            const response = await getNode(url);
            if (response.status) {
                setUserList(response.data);
                // setLoading(false);
            } else {
                // setLoading(false);
            }
        } catch (error) {
            console.error("Error while posting data:", error);
            // setLoading(false);
        }
    };

    const online_list = async () => {
        const url = `/chat/online-users`;
        try {
            const response = await getNode(url);
            if (response.status) {
                // setUserList(response.data);
                // setLoading(false);
            } else {
                // setLoading(false);
            }
        } catch (error) {
            console.error("Error while posting data:", error);
            // setLoading(false);
        }
    };

    const user_details = async (id) => {
        const url = `/chat/details/${id}`;
        try {
            const response = await getNode(url);
            if (response.status) {
                setUserDetails(response.data);
                // setLoading(false);
            } else {
                // setLoading(false);
            }
        } catch (error) {
            console.error("Error while posting data:", error);
            // setLoading(false);
        }
    };

    useEffect(() => {
        user_list()
        // online_list()
    }, []);


    // toggle chat profiel class
    const chatProfileClasses = classNames({
        'nk-chat-profile': true,
        'show-profile': sidebar,
        'toggle-collapsed': window.innerWidth < Break.xxl
    });

    // toggle chat parent class
    const parentClasses = classNames({
        'nk-chat': true,
        'profile-shown': sidebar && window.innerWidth > Break.xxl
    });

    // toggle chat body class
    const chatBodyClasses = classNames({
        'nk-chat-body': true,
        'show-chat': mobileView
    });

    const windowResize = () => {
        if (window.innerWidth > Break.xxl) {
            setSidebar(true);
        } else {
            setSidebar(false);
        }
    };

    // chat profile handle
    const handleChatToggle = () => {
        setSidebar(!sidebar);
    }

    // handle chat list toggle
    const handleChatListToggle = (ev, id, c_id) => {
        setActive(ev);
        setSelected_id(c_id)
        user_details(id)
        // const socket = socketIOClient(ENDPOINT);
        // socket.emit("join chat", id);
        // const message = {
        //     type: 'message_list',
        //     id
        // };
        // sendMessage(JSON.stringify({ type: 'message_list', id }));


        if (window.innerWidth < Break.md) {
            setMobileView(true);
        }
    }

    // hide showing chat
    const handleChatListHide = () => {
        setMobileView(false);
    }

    useEffect(() => {
        window.addEventListener("resize", windowResize);

        windowResize();

        return () => {
            window.removeEventListener("resize", windowResize);
        };

    }, []);


    const mediaData = [
        {
            id: 1,
            src: '/images/avatar/a.jpg'
        },
        {
            id: 2,
            src: '/images/avatar/b.jpg'
        },
        {
            id: 3,
            src: '/images/avatar/c.jpg'
        },
        {
            id: 4,
            src: '/images/avatar/d.jpg'
        },
        {
            id: 5,
            src: '/images/avatar/e.jpg'
        },
        {
            id: 6,
            src: '/images/avatar/f.jpg'
        },
        {
            id: 7,
            src: '/images/avatar/g.jpg'
        },
        {
            id: 8,
            src: '/images/avatar/h.jpg',
            icon: 'play-fill'
        }
    ];


    return (
        <Layout content="stretch" title="Message | Chats">
            <div className={parentClasses} id="chatWrap">
                <div className="nk-chat-aside">
                    <div className="nk-aside-heading pt-4">
                        <div className="gap-col">
                            <h2 className="title">Chats</h2>
                        </div>
                        <Button onClick={() => buttonFun()}> Click</Button>
                        <div className="gap-col">
                            <Dropdown>
                                <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                                    <Icon name="more-h"></Icon>
                                </Dropdown.Toggle>
                                <Dropdown.Menu as={CustomDropdownMenu} align="end">
                                    <div className="dropdown-content py-1">
                                        <ul className="link-list is-compact">
                                            <li>
                                                <a href="#new-chat">
                                                    <em className="icon ni ni-plus"></em>
                                                    <span>New Chat</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#new-contact">
                                                    <em className="icon ni ni-user"></em>
                                                    <span>New Contact</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <SimpleBar className="nk-chat-aside-scroll">
                        <div className="nk-aside-content py-2">
                            <Form.Group className="form-group">
                                <div className="form-control-wrap">
                                    <div className="form-control-icon end">
                                        <Icon name="search"></Icon>
                                    </div>
                                    <Form.Control type="text" className="rounded-pill" id="searchChat" placeholder="Search" />
                                </div>
                            </Form.Group>
                        </div>
                        <ChatList>
                            {userList?.map((item, index) => {
                                return (
                                    <ChatList.Item
                                        key={index}
                                        onClick={() => handleChatListToggle(index, item._id, item.center_id)}
                                        className={active === index ? 'active' : ''}
                                    >
                                        <MediaGroup>
                                            <Media shape="circle" middle>

                                            </Media>
                                            <MediaText>
                                                <span className="title">
                                                    <span>{item.center_name}</span>

                                                </span>
                                                <span className="text smaller">
                                                    <span>{item.is_online === true ? <Badge bg="success" >Online</Badge> : <Badge bg="danger" >Offline</Badge>}</span>

                                                </span>
                                            </MediaText>
                                        </MediaGroup>
                                    </ChatList.Item>
                                )
                            })}
                        </ChatList>
                    </SimpleBar>
                </div>



                <div className={chatBodyClasses} id="chatBody">
                    <div className="nk-chat-head">
                        <ul className="nk-chat-head-tools me-1 ms-n1 d-md-none">
                            <li>
                                <Button size="sm" variant="zoom" className="btn-icon toggle-close-chat" onClick={handleChatListHide}>
                                    <Icon name="arrow-left"></Icon>
                                </Button>
                            </li>
                        </ul>
                        <MediaGroup>

                            <MediaText>
                                <span className="title"><span>{userDetails?.center_name}</span></span>
                                <span>{userDetails.is_online === true ? <Badge bg="success" >Online</Badge> : <Badge bg="danger" >Offline</Badge>}</span>
                            </MediaText>
                        </MediaGroup>
                        <ul className="nk-chat-head-tools gap gx-md-2 me-n2">
                            <li>
                                <Button size="md" variant="zoom" className="btn-icon">
                                    <Icon name="call"></Icon>
                                </Button>
                            </li>
                            <li>
                                <Button size="md" variant="zoom" className="btn-icon">
                                    <Icon name="video"></Icon>
                                </Button>
                            </li>
                            <li>
                                <Button size="md" variant="zoom" className={`btn-icon toggle-chat-profile ${sidebar ? 'active' : 'inActive'}`} onClick={handleChatToggle}>
                                    <Icon name="info-fill"></Icon>
                                </Button>
                            </li>
                        </ul>
                    </div>
                    <SimpleBar className="nk-chat-panel">
                        <div className="nk-message-wrap">
                            <Message avatar="/images/avatar/a.jpg" isYou>
                                <ul className="nk-message-bubbles">
                                    <li>
                                        <div className="nk-message-bubble has-media">
                                            <ul className="nk-message-media">
                                                <li>
                                                    <Image src="/images/avatar/a.jpg" alt="" />
                                                </li>
                                                <li>
                                                    <Image src="/images/avatar/c.jpg" alt="" />
                                                </li>
                                            </ul>
                                            <ul className="nk-message-meta">
                                                <li>15:42</li>
                                                <li>Unread</li>
                                            </ul>
                                        </div>
                                        <Message.Actions />
                                    </li>
                                </ul>
                            </Message>
                            <Message isMe>
                                <ul className="nk-message-bubbles">
                                    <li>
                                        <div className="nk-message-bubble">
                                            <div className="nk-message-text"> Hi, yes, David have found it, ask our concierge 👀 </div>
                                            <ul className="nk-message-meta">
                                                <li>15:42</li>
                                                <li>Seen</li>
                                            </ul>
                                        </div>
                                        <Message.Actions />
                                    </li>
                                </ul>
                            </Message>
                            <Message avatar="/images/avatar/a.jpg" isYou>
                                <ul className="nk-message-bubbles">
                                    <li>
                                        <div className="nk-message-bubble">
                                            <div className="nk-message-text">Hello! Have you seen my backpack anywhere in office?</div>
                                            <ul className="nk-message-meta">
                                                <li>15:42</li>
                                            </ul>
                                        </div>
                                        <Message.Actions />
                                    </li>
                                </ul>
                            </Message>
                            <Message isMe>
                                <ul className="nk-message-bubbles">
                                    <li>
                                        <div className="nk-message-bubble">
                                            <div className="nk-message-text">While you win in love, you continue to win in other things.</div>
                                            <ul className="nk-message-meta">
                                                <li>15:42</li>
                                            </ul>
                                        </div>
                                        <Message.Actions />
                                    </li>
                                </ul>
                            </Message>
                            <Message avatar="/images/avatar/a.jpg" isYou>
                                <ul className="nk-message-bubbles">
                                    <li>
                                        <div className="nk-message-bubble">
                                            <div className="nk-message-text">Didn't I tell you not to put your phone on charge just because it's the weekend?</div>
                                            <ul className="nk-message-meta">
                                                <li>12:42</li>
                                            </ul>
                                        </div>
                                        <Message.Actions />
                                    </li>
                                    <li>
                                        <div className="nk-message-bubble">
                                            <div className="nk-message-text">next time you'll be awake at this hour why not now</div>
                                            <ul className="nk-message-meta">
                                                <li>12:48</li>
                                            </ul>
                                        </div>
                                        <Message.Actions />
                                    </li>
                                </ul>
                            </Message>
                        </div>
                    </SimpleBar>
                    <div className="nk-chat-form">
                        <div className="nk-chat-input">
                            <ul className="nk-chat-input-btns">
                                <li>
                                    <Button size="sm" variant="zoom" className="btn-icon text-light">
                                        <Icon name="mic"></Icon>
                                    </Button>
                                </li>
                                <li>
                                    <Button size="sm" variant="zoom" className="btn-icon text-light">
                                        <Icon name="clip"></Icon>
                                    </Button>
                                </li>
                            </ul>
                            <div className="nk-chat-input-field">
                                <div className="form-control-wrap">
                                    <Form.Control size="md" type="text" placeholder="Start a new message" className="form-control-plaintext" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                                </div>
                                <div className="form-control-wrap">
                                    {Object.keys(commands).map((key) => (
                                        <Button className="mx-1" key={key} onClick={() => sendKey(commands[key], key)}>
                                            {key.charAt(0).toUpperCase() + key.slice(1)}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                            <div className="nk-chat-input-send">
                                <Button size="sm" variant="zoom" className="btn-icon text-light" onClick={() => sendMessage()}>
                                    <Icon name="send"></Icon>
                                </Button>
                            </div>
                        </div>
                    </div>
                    {window.innerWidth < Break.xxl && sidebar && (
                        <div onClick={handleChatToggle} className="nk-overlay"></div>
                    )}
                    <SimpleBar className={chatProfileClasses} data-overlay>
                        <div className="nk-aside-content py-4 border-bottom border-light position-relative">
                            <MediaGroup column className="text-center py-2">
                                <Media size="big" shape="circle" middle>
                                    <Image src="/images/avatar/c.jpg" alt="" />
                                </Media>
                                <MediaText>
                                    <h3 className="mb-1">David Moore</h3>
                                    <span className="text smaller"><span>Product Designer @uikings</span></span>
                                </MediaText>
                            </MediaGroup>
                            <Dropdown className="position-absolute top-0 end-0 p-4">
                                <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-zoom btn-icon">
                                    <Icon name="more-h"></Icon>
                                </Dropdown.Toggle>
                                <Dropdown.Menu as={CustomDropdownMenu} align="end">
                                    <div className="dropdown-content py-1">
                                        <ul className="link-list is-compact">
                                            <li>
                                                <a href="/#"><Icon name="bell-off"></Icon><span>Mute</span></a>
                                            </li>
                                            <li>
                                                <a href="/#"><Icon name="user-cross"></Icon><span>Block</span></a>
                                            </li>
                                            <li>
                                                <a href="/#"><Icon name="alert"></Icon><span>Report</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="nk-aside-content py-4">
                            <div className="gap gy-3">
                                <div className="gap-col">
                                    <h5 className="title">Options</h5>
                                    <ul className="link-list link-list-base-light is-compact">
                                        <li>
                                            <a href="/#">
                                                <Icon name="edit"></Icon>
                                                <span>Edit Nickname</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#">
                                                <Icon name="search"></Icon>
                                                <span>Search In Conversation</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#">
                                                <Icon name="color-palette"></Icon>
                                                <span>Change Color</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#">
                                                <Icon name="bell-off"></Icon>
                                                <span>Mute Notifications</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="gap-col">
                                    <h5 className="title">Files</h5>
                                    <ul className="link-list link-list-base-light is-compact">
                                        <li>
                                            <a href="/#">
                                                <Icon name="folder-list"></Icon>
                                                <span>Data_Component.zip</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#">
                                                <Icon name="file-pdf"></Icon>
                                                <span>Checkout_responsive.pdf</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#">
                                                <Icon name="img"></Icon>
                                                <span>Commercial.jpeg</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="gap-col">
                                    <h5 className="title mb-3">Media</h5>
                                    <ChatMedia className="gap g-2 list4col">
                                        {mediaData.map((item) =>
                                            <ChatMedia.Item key={item.id} src={item.src} icon={item.icon} />
                                        )}
                                    </ChatMedia>
                                </div>
                            </div>
                        </div>
                    </SimpleBar>
                </div>
            </div>
        </Layout>
    )
}

export default ChatsPage;
