import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import Swal from "sweetalert2";
import {
  Card,
  Form,
  Col,
  Row,
  ButtonGroup,
  Button,
  Container,
} from "react-bootstrap";
import { Icon } from "../../components";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../masterCSS/DefaultCollectionSetting.css";

function CollectionSettingNew() {
  //SET LIMIT Start
  const [setLimitType, setsetLimitType] = useState("BILL"); // Default to 'Own', change this if needed
  const [selectedCowBuffaloOption, setSelectedOption] = useState(null);

  const handlesetLimitTypeChanges = (event) => {
    setsetLimitType(event.target.value);
  };

  //SET LIMIT END

  //Cancel Button
  const alertConfirm = () => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes ",
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deleted", "You successfully deleted this file", "success");
      }
    });
  };

  return (
    <Layout title="Form controls" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Collection Setting</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Masters
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Link
                  to="/store/products/CategoriesData"
                  className="btn btn-primary btn-md d-md-none"
                >
                  <Icon name="eye" />
                  <span>View</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/listing/CenterList"
                  className="btn btn-primary d-none d-md-inline-flex"
                >
                  <Icon name="eye" />
                  <span>View List</span>
                </Link>
              </li>
            </ul>
            {/* <button
              id="toggleFormFields"
              className="btn btn-primary"
              onClick={() => setShowFormFields(true)}
            >
              <Icon name="plus" />
              <span>Add Fields</span>
            </button> */}
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>
      <Block.Head page>
        <Block.HeadContent>
          {/* {showFormFields && <FarmerCreationFields />}{" "} */}
          {/* Conditionally render the form */}

          <Card>
            <Card.Body>
              {/* {<Form onSubmit={handleSubmit}> */}

              <Form>
                <Row className="mb-2">
                  <Col md="4" style={{ marginBottom: "0.5rem" }}>
                    <label htmlFor="chooseMode" className="form-label">
                      Choose Mode
                    </label>
                    <Form.Group>
                      <Form.Label className="mb-4 mx-2">Fat%</Form.Label>
                      <ButtonGroup aria-label="Basic radio toggle button group">
                        <input
                          type="radio"
                          className="btn-check"
                          name="Fat"
                          id="FatAutomatic"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="FatAutomatic"
                        >
                          Automatic
                        </Button>

                        <input
                          type="radio"
                          className="btn-check"
                          name="Fat"
                          id="FatManual"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="FatManual"
                        >
                          Manual
                        </Button>

                        <input
                          type="radio"
                          className="btn-check"
                          name="Fat"
                          id="FatBoth"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="FatBoth"
                        >
                          Both
                        </Button>
                      </ButtonGroup>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="mb-4 mx-2">Snf%</Form.Label>
                      <ButtonGroup aria-label="Basic radio toggle button group">
                        <input
                          type="radio"
                          className="btn-check"
                          name="snf"
                          id="snfAutomatic"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="snfAutomatic"
                        >
                          Automatic
                        </Button>

                        <input
                          type="radio"
                          className="btn-check"
                          name="snf"
                          id="snfManual"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="snfManual"
                        >
                          Manual
                        </Button>

                        <input
                          type="radio"
                          className="btn-check"
                          name="snf"
                          id="snfBoth"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="snfBoth"
                        >
                          Both
                        </Button>
                      </ButtonGroup>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="mb-4 mx-2">Qty%</Form.Label>
                      <ButtonGroup aria-label="Basic radio toggle button group">
                        <input
                          type="radio"
                          className="btn-check"
                          name="Qty"
                          id="QtyAutomatic"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="QtyAutomatic"
                        >
                          Automatic
                        </Button>

                        <input
                          type="radio"
                          className="btn-check"
                          name="Qty"
                          id="QtyManual"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="QtyManual"
                        >
                          Manual
                        </Button>

                        <input
                          type="radio"
                          className="btn-check"
                          name="Qty"
                          id="QtyBoth"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="QtyBoth"
                        >
                          Both
                        </Button>
                      </ButtonGroup>
                    </Form.Group>
                  </Col>

                  <Col md="4">
                    <label htmlFor="decimalMode" className="form-label">
                      Decimal Mode
                    </label>
                    <Form.Group>
                      <Form.Label className="mb-4 mx-2">Fat%</Form.Label>
                      <ButtonGroup aria-label="Basic radio toggle button group">
                        <input
                          type="radio"
                          className="btn-check"
                          name="FatDecimal"
                          id="Fat"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="Fat"
                        >
                          0.00
                        </Button>

                        <input
                          type="radio"
                          className="btn-check"
                          name="FatDecimal"
                          id="FatRoundoff"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="FatRoundoff"
                        >
                          Round.Off 0.0
                        </Button>

                        <input
                          type="radio"
                          className="btn-check"
                          name="FatDecimal"
                          id="FatDecimal"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="FatDecimal"
                        >
                          Truncate 0.0
                        </Button>
                      </ButtonGroup>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="mb-4 mx-2">Snf%</Form.Label>
                      <ButtonGroup aria-label="Basic radio toggle button group">
                        <input
                          type="radio"
                          className="btn-check"
                          name="snfDecimal"
                          id="snf0.00"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="snf0.00"
                        >
                          0.00
                        </Button>

                        <input
                          type="radio"
                          className="btn-check"
                          name="snfDecimal"
                          id="snfRoundOff"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="snfRoundOff"
                        >
                          Round.Off 0.0
                        </Button>

                        <input
                          type="radio"
                          className="btn-check"
                          name="snfDecimal"
                          id="snfTruncate"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="snfTruncate"
                        >
                          Truncate 0.0
                        </Button>
                      </ButtonGroup>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="mb-4 mx-2">Qty%</Form.Label>
                      <ButtonGroup aria-label="Basic radio toggle button group">
                        <input
                          type="radio"
                          className="btn-check"
                          name="DecimalMode"
                          id="QTY0.00"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="QTY0.00"
                        >
                          0.00
                        </Button>

                        <input
                          type="radio"
                          className="btn-check"
                          name="DecimalMode"
                          id="QTYRoundOff"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="QTYRoundOff"
                        >
                          Round.Off 0.0
                        </Button>

                        <input
                          type="radio"
                          className="btn-check"
                          name="DecimalMode"
                          id="QTYTruncate"
                          autoComplete="off"
                        />
                        <Button
                          as="label"
                          variant="outline-primary"
                          htmlFor="QTYTruncate"
                        >
                          Truncate 0.0
                        </Button>
                      </ButtonGroup>
                    </Form.Group>
                  </Col>
                </Row>

                {/* ROW 2*/}

                <Row className="mb-3">
                  <Col sm={2}>
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="validationCustom03">
                        Set Limit
                        <span className="me-2">
                          <i className="fa fa-exclamation-triangle text-danger">
                            *
                          </i>
                        </span>
                      </Form.Label>
                      <div className="form-control-wrap">
                        <ButtonGroup aria-label="Basic radio toggle button group">
                          <input
                            type="radio"
                            className="btn-check"
                            name="paymentCycleType"
                            id="weeklyRadio"
                            value="SetLimitYes"
                            autoComplete="off"
                            checked={setLimitType === "SetLimitYes"}
                            onChange={handlesetLimitTypeChanges}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="weeklyRadio"
                          >
                            Yes
                          </Button>

                          <input
                            type="radio"
                            className="btn-check"
                            name="paymentCycleType"
                            id="SetLimitNO"
                            value="SetLimitNO"
                            autoComplete="off"
                            checked={setLimitType === "SetLimitNO"}
                            onChange={handlesetLimitTypeChanges}
                          />
                          <Button
                            as="label"
                            variant="outline-primary"
                            htmlFor="SetLimitNO"
                          >
                            No
                          </Button>
                        </ButtonGroup>
                      </div>
                    </Form.Group>

                    {setLimitType === "SetLimitYes" && (
                      <Form.Group>
                        <Container className="Container">
                          <Row className="button-row">
                            <Col md={4} sm={12}>
                              <Form.Label htmlFor="WeeklySession">
                                Set Limit :
                              </Form.Label>
                              <br />
                              <ButtonGroup aria-label="Basic radio toggle button group">
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio"
                                  id="btnradio1"
                                  autoComplete="off"
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="btnradio1"
                                >
                                  Cow/Buffalo
                                </Button>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio"
                                  id="btnradio2"
                                  autoComplete="off"
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="btnradio2"
                                >
                                  Both
                                </Button>
                              </ButtonGroup>
                            </Col>
                          </Row>
                        </Container>
                        {/* //WeB View */}
                        <Container className="WebContainer">
                          <div
                            className="display-flex position-absolute top-0 end-0 p-5"
                            style={{ marginRight: "100px" }}
                          >
                            <Col className="col-4 ml-auto">
                              <Form.Label htmlFor="exampleFormControlInputText6">
                                Set Limit:
                              </Form.Label>
                              <ButtonGroup aria-label="Basic radio toggle button group">
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="setLimitField"
                                  id="cowBuffalowSetlimit"
                                  autoComplete="off"
                                  onClick={() =>
                                    setSelectedOption("cowBuffalow")
                                  }
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="cowBuffalowSetlimit"
                                >
                                  Cow/Buffalow
                                </Button>

                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="setLimitField"
                                  id="bothSetLimit"
                                  autoComplete="off"
                                  onClick={() => setSelectedOption("both")}
                                />
                                <Button
                                  as="label"
                                  variant="outline-primary"
                                  htmlFor="bothSetLimit"
                                >
                                  Both
                                </Button>
                              </ButtonGroup>

                              {selectedCowBuffaloOption === "cowBuffalow" && (
                                <Col>
                                  <Form.Group controlId="Cow">
                                    <Form.Label>Min - Cow</Form.Label>

                                    <Form.Control
                                      type="text" // Use type="text" instead of type="numeric"
                                      placeholder="Enter FAT"
                                      name="Cow"
                                      id="Cow"
                                      autoComplete="off"
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9.]/g,
                                          ""
                                        );
                                      }}
                                      onKeyPress={(e) => {
                                        const validCharacters = /^[0-9.]$/;
                                        if (!validCharacters.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />

                                    <Form.Control
                                      type="text" // Use type="text" instead of type="numeric"
                                      placeholder="Enter SNF"
                                      name="Cow"
                                      id="Cow"
                                      autoComplete="off"
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9.]/g,
                                          ""
                                        );
                                      }}
                                      onKeyPress={(e) => {
                                        const validCharacters = /^[0-9.]$/;
                                        if (!validCharacters.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />

                                    <Form.Control
                                      type="text" // Use type="text" instead of type="numeric"
                                      placeholder="Enter QTY"
                                      name="Cow"
                                      id="Cow"
                                      autoComplete="off"
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9.]/g,
                                          ""
                                        );
                                      }}
                                      onKeyPress={(e) => {
                                        const validCharacters = /^[0-9.]$/;
                                        if (!validCharacters.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  </Form.Group>

                                  <Form.Group controlId="Cow">
                                    <Form.Label>Max - Cow</Form.Label>

                                    <Form.Control
                                      type="text" // Use type="text" instead of type="numeric"
                                      placeholder="Enter FAT"
                                      name="Cow"
                                      id="Cow"
                                      autoComplete="off"
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9.]/g,
                                          ""
                                        );
                                      }}
                                      onKeyPress={(e) => {
                                        const validCharacters = /^[0-9.]$/;
                                        if (!validCharacters.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />

                                    <Form.Control
                                      type="text" // Use type="text" instead of type="numeric"
                                      placeholder="Enter SNF"
                                      name="Cow"
                                      id="Cow"
                                      autoComplete="off"
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9.]/g,
                                          ""
                                        );
                                      }}
                                      onKeyPress={(e) => {
                                        const validCharacters = /^[0-9.]$/;
                                        if (!validCharacters.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />

                                    <Form.Control
                                      type="text" // Use type="text" instead of type="numeric"
                                      placeholder="Enter QTY"
                                      name="Cow"
                                      id="Cow"
                                      autoComplete="off"
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9.]/g,
                                          ""
                                        );
                                      }}
                                      onKeyPress={(e) => {
                                        const validCharacters = /^[0-9.]$/;
                                        if (!validCharacters.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                              )}

                              {selectedCowBuffaloOption === "both" && (
                                <Col>
                                  <Form.Group controlId="Cow">
                                    <Form.Label>Cow</Form.Label>
                                    <Form.Control
                                      type="text" // Use type="text" instead of type="numeric"
                                      placeholder="Enter Cow"
                                      name="Cow"
                                      id="Cow"
                                      autoComplete="off"
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9.]/g,
                                          ""
                                        );
                                      }}
                                      onKeyPress={(e) => {
                                        const validCharacters = /^[0-9.]$/;
                                        if (!validCharacters.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                              )}
                            </Col>
                          </div>
                        </Container>
                      </Form.Group>
                    )}
                  </Col>

                  <Col sm={2}>
                    <Form.Label htmlFor="exampleFormControlInputText6">
                      Add Previous Session Date
                    </Form.Label>
                    <br />
                    <ButtonGroup aria-label="Basic radio toggle button group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="PreviousSession"
                        id="PreviousSessionYES"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="PreviousSessionYES"
                      >
                        YES
                      </Button>

                      <input
                        type="radio"
                        className="btn-check"
                        name="PreviousSession"
                        id="PreviousSessionNO"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="PreviousSessionNO"
                      >
                        NO
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>

                {/* ROW 3*/}
                <Row className="mb-3">
                  <Col sm={2}>
                    <Form.Label htmlFor="exampleFormControlInputText6">
                      Sample Testing
                    </Form.Label>
                    <br />
                    <ButtonGroup aria-label="Basic radio toggle button group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="SampleTesting"
                        id="SampleTestingYES"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="SampleTestingYES"
                      >
                        YES
                      </Button>

                      <input
                        type="radio"
                        className="btn-check"
                        name="SampleTesting"
                        id="SampleTestingNO"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="SampleTestingNO"
                      >
                        NO
                      </Button>
                    </ButtonGroup>
                  </Col>

                  <Col sm={2}>
                    <Form.Label htmlFor="exampleFormControlInputText6">
                      Allow Date Change
                    </Form.Label>
                    <br />
                    <ButtonGroup aria-label="Basic radio toggle button group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="AllowDateChange"
                        id="AllowDateChangeYES"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="AllowDateChangeYES"
                      >
                        YES
                      </Button>

                      <input
                        type="radio"
                        className="btn-check"
                        name="AllowDateChange"
                        id="AllowDateChangeNO"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="AllowDateChangeNO"
                      >
                        NO
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>

                {/* ROW 4*/}
                <Row className="mb-3">
                  <Col sm={2}>
                    <Form.Label htmlFor="exampleFormControlInputText6">
                      Enable Can No
                    </Form.Label>
                    <br />
                    <ButtonGroup aria-label="Basic radio toggle button group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="EnableCanNo"
                        id="EnableCanNoYES"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="EnableCanNoYES"
                      >
                        YES
                      </Button>

                      <input
                        type="radio"
                        className="btn-check"
                        name="EnableCanNo"
                        id="EnableCanNoNO"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="EnableCanNoNO"
                      >
                        NO
                      </Button>
                    </ButtonGroup>
                  </Col>

                  <Col sm={2}>
                    <Form.Label htmlFor="exampleFormControlInputText6">
                      Delete Collection Data
                    </Form.Label>
                    <br />
                    <ButtonGroup aria-label="Basic radio toggle button group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="DeleteCollectionData"
                        id="DeleteCollectionDataYES"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="DeleteCollectionDataYES"
                      >
                        YES
                      </Button>

                      <input
                        type="radio"
                        className="btn-check"
                        name="DeleteCollectionData"
                        id="DeleteCollectionDataNO"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="DeleteCollectionDataNO"
                      >
                        NO
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>

                {/* ROW 5 */}
                <Row>
                  <Col sm={2}>
                    <Form.Label htmlFor="exampleFormControlInputText6">
                      Print Previous Bill
                    </Form.Label>
                    <br />
                    <ButtonGroup aria-label="Basic radio toggle button group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="PrintPreviousBill"
                        id="PrintPreviousBillYES"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="PrintPreviousBillYES"
                      >
                        YES
                      </Button>

                      <input
                        type="radio"
                        className="btn-check"
                        name="PrintPreviousBill"
                        id="PrintPreviousBillNO"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="PrintPreviousBillNO"
                      >
                        NO
                      </Button>
                    </ButtonGroup>
                  </Col>

                  <Col sm={2}>
                    <Form.Label htmlFor="exampleFormControlInputText6">
                      Allow Zero Value In Rate
                    </Form.Label>
                    <br />
                    <ButtonGroup aria-label="Basic radio toggle button group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="ZeroValueInRate"
                        id="ZeroValueInRateYES"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="ZeroValueInRateYES"
                      >
                        YES
                      </Button>

                      <input
                        type="radio"
                        className="btn-check"
                        name="ZeroValueInRate"
                        id="ZeroValueInRateNO"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="ZeroValueInRateNO"
                      >
                        NO
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12">
                    <div
                      className="gap-col"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <ul className="d-flex align-items-center gap g-3">
                        <li>
                          <Button
                            className="mt-2"
                            variant="danger"
                            onClick={() => alertConfirm()}
                          >
                            Cancel
                          </Button>

                          {/* Cancel
                            </Button> */}
                        </li>
                        <li>
                          <Col className="col-12">
                            <Button
                              className="mt-2"
                              variant="success"
                              type="submit"
                              // onClick={handleSubmit}
                            >
                              Submit
                            </Button>
                          </Col>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>

          {/* OLD CODE */}
        </Block.HeadContent>
      </Block.Head>
    </Layout>
  );
}

export default CollectionSettingNew;
