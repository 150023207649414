
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();


export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate()
    const login = () => {
        setIsLoggedIn(true);
        localStorage.setItem('loggedin', true)
        navigate('/')
    };

    useEffect(() => {
        // Set up a listener for token changes
        const handleTokenChange = () => {
            const token = localStorage.getItem('token');
            setIsLoggedIn(!!token);
            if (!token) navigate('/auth-login')
        };

        window.addEventListener('storage', handleTokenChange);

        return () => {
            window.removeEventListener('storage', handleTokenChange);
        };
    }, []);

    return (
        <AuthContext.Provider value={{ isLoggedIn, login }}>
            {children}
        </AuthContext.Provider>
    );
};
