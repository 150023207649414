
import CryptoJS from 'crypto-js';


var key = CryptoJS.enc.Base64.parse("c90zMT+Aj5IgfpRly9fcWugvb2wNw0BWiaXf0l5+iAU=")
var iv = CryptoJS.enc.Base64.parse("rTZDCZ5o9XvehZ9Y5ajLvg==")

export const Cryptography = {
    encrypt: function (data) {
        let stringValue = JSON.stringify(data);
        let value = (stringValue) ? CryptoJS.AES.encrypt(stringValue, key, { iv: iv }).toString() : false;
        return value;
    },
    decrypt: function (data) {
        let decryptedString = (data) ? CryptoJS.AES.decrypt(data, key, { iv: iv }).toString(CryptoJS.enc.Utf8) : false;
        let value = decryptedString ? JSON.parse(decryptedString) : null;
        return value;
    }
};