import { Link } from 'react-router-dom';

function Logo() {
  return (
    <Link to="/" className="logo-link">
      <div className="logo-wrap">
        <img src={"/images/asmlogo.png"} alt="" className="logo-image" style={{ width: '50px', height: '50px' }} />

        <span style={{ fontSize: '19px ', marginLeft: '10px', marginTop: '10px', marginBottom: '20px', color: 'white' }}>ASM Dairy</span>
      </div>
    </Link>
  );
}

export default Logo;
