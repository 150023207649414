import { extractDateFromTimestamp } from "../Date";

export const transformDataEdit = (formData, code) => {
    const communi = [{ communication: formData.communication, email: "", sms: '', whatsappNo: '', }]

    const producerInfo = [{
        door_no: formData.doorno,
        village_name: formData.villageName,
        taluk_name: formData.taulkName, address: formData.address, location: "0,0",
        district: formData.city, state: formData.state, pincode: formData.pincode,
    }]
    const milk = { milk_type: formData.rateChart ? ["cow"] : formData.buffaloChart ? ["buffalo"] : formData.commonChart ? ["common"] : '' }
    const payment = [{
        mode_of_payment: formData.paymentMethod, // Sample mapping
        bank_info: formData.modeOfPayment === "Bank" ? {
            BankName: formData.BankName,
            BranchName: formData.BranchName,
            HolderName: formData.HolderName,
            AccNo: formData.AccNo,
            ifsc: formData.ifsc,
            AccType: formData.AccType
        } : formData.modeOfPayment === "UPI" ? {
            upi: formData.UPIiD
        } : {},
    }]
    const dataForStorage = {
        route_id: code,
        name: formData.centerName,
        code: formData.centerCode,
        onboard_date: extractDateFromTimestamp(new Date()),
        gender: 1,
        mobile_no: 9944440000,
        alternate_no: 9944440000, // You have a typo here. It should be 'alternate'
        communication_info: communi,
        type: 1,
        device_info: "",
        producer_info: producerInfo,
        milk_info: milk,
        payment_cycle: [{
            payment_cycle: formData.paymentCycle,
            session: formData.session,
            custom: { select: formData.custom, value: formData.value }
        }],
        mode_payment_info: payment,
        other_info: [{

        },], // You can use an empty object directly
        ratechart_id: formData.milkTypeChart === 0 ? [{ cow: formData.cow_chart_id, buffalo: formData.buffalo_chart_id }] :
            formData.milkTypeChart === 1 ? [{ common: formData.common_chart_id }] : [{}],
        incentive_info: { incentive: formData.incentive, incentive_info: { cow: parseFloat(formData.cowIncentive), buffalo: parseFloat(formData.buffaloIncentive) } },
        kg_ltr_factor: (parseFloat(formData.convertKgLit)).toFixed(3),

        ratechart_type: formData.milkTypeChart === 0 ? [{
            mode: formData.milkTypeChart,
            cow: formData.rateChart, buffalo: formData.buffaloChart
        }] :
            formData.milkTypeChart === 1 ? [{ mode: formData.milkTypeChart, common: formData.commonChart }] : [{}],
        session_info: [{}],
        action_info: [{ title: "Farmer Settings", type: "yesorno", default: formData.farmerSetting === "yes" ? 0 : 1 },
        { title: "Rate Settings", type: "yesorno", default: formData.rateSetting === "yes" ? 0 : 1 },
        { title: "Equipment Settings", type: "yesorno", default: formData.equipmentSetting === "yes" ? 0 : 1 },
        { title: "Collection Settings", type: "yesorno", default: formData.collectionSetting === "yes" ? 0 : 1 },
        { title: "Printer Settings", type: "yesorno", default: formData.printerSetting === "yes" ? 0 : 1 },
        { title: "Payment Cycle Edit", type: "yesorno", default: formData.paymentCycleEdit === "yes" ? 0 : 1 },
        { title: "Incentive Edit", type: "yesorno", default: formData.incentiveEdit === "yes" ? 0 : 1 }],
    };

    return dataForStorage;
};


export const transformData = (formData, code) => {
    const communi = [{ communication: formData.communication, email: "", sms: '', whatsappNo: '', }]

    const producerInfo = [{
        village_name: formData.villageName,
        taluk_name: formData.taulkName, address: formData.address, location: "0,0",
        email_id: "", district: formData.city, state: formData.state, pincode: formData.pincode,
    }]
    const milk = { milk_type: formData.rateChart ? "0" : formData.buffaloChart ? "1" : formData.commonChart ? "2" : '' }
    const payment = [{
        mode_of_payment: formData.paymentMethod, // Sample mapping
        bank_info: formData.modeOfPayment === "Bank" ? {
            BankName: formData.BankName,
            BranchName: formData.BranchName,
            HolderName: formData.HolderName,
            AccNo: formData.AccNo,
            ifsc: formData.ifsc,
            AccType: formData.AccType
        } : formData.modeOfPayment === "UPI" ? {
            upi: formData.UPIiD
        } : {},
    }]
    const dataForStorage = {
        route_id: code,
        name: formData.centerName,
        code: formData.centerCode,
        gender: 1,
        mobile_no: 9944440000,
        alternate_no: 9944440000, // You have a typo here. It should be 'alternate'
        communication_info: communi,
        onboard_date: extractDateFromTimestamp(new Date()),
        device_type: "WEB",
        device_info: "",
        type: 1,
        producer_info: producerInfo,
        location: "0,0",
        milk_info: milk,
        payment_cycle: [{
            payment_cycle: formData.paymentCycle,
            session: formData.session,
            custom: { select: formData.custom, value: formData.value }
        }],
        mode_payment_info: payment,
        other_info: [{

        },], // You can use an empty object directly
        ratechart_id: formData.milkTypeChart === 0 ? [{ cow: formData.cow_chart_id, buffalo: formData.buffalo_chart_id }] :
            formData.milkTypeChart === 1 ? [{ common: formData.common_chart_id }] : [{}],
        incentive_info: { incentive: formData.incentive, incentive_info: { cow: parseFloat(formData.cowIncentive), buffalo: parseFloat(formData.buffaloIncentive) } },
        kg_ltr_factor: (parseFloat(formData.convertKgLit)).toFixed(3),

        ratechart_type: formData.milkTypeChart === 0 ? [{
            mode: formData.milkTypeChart,
            cow: formData.rateChart, buffalo: formData.buffaloChart
        }] :
            formData.milkTypeChart === 1 ? [{ mode: formData.milkTypeChart, common: formData.commonChart }] : [{}],
        session_info: [{}],
        action_info: [{ title: "Farmer Settings", type: "yesorno", default: formData.farmerSetting === "yes" ? 0 : 1 },
        { title: "Rate Settings", type: "yesorno", default: formData.rateSetting === "yes" ? 0 : 1 },
        { title: "Equipment Settings", type: "yesorno", default: formData.equipmentSetting === "yes" ? 0 : 1 },
        { title: "Collection Settings", type: "yesorno", default: formData.collectionSetting === "yes" ? 0 : 1 },
        { title: "Printer Settings", type: "yesorno", default: formData.printerSetting === "yes" ? 0 : 1 },
        { title: "Payment Cycle Edit", type: "yesorno", default: formData.paymentCycleEdit === "yes" ? 0 : 1 },
        { title: "Incentive Edit", type: "yesorno", default: formData.incentiveEdit === "yes" ? 0 : 1 }],
    };

    return dataForStorage;
};