import { createSlice } from "@reduxjs/toolkit";
import { updateVlccFarmer } from "../../thunks/vlcc-thunk/VlccCollection";

export const updateVlccFarmerSlice = createSlice({
    name: "updateFarmer",
    initialState: {
        data: [],
        isLoading: false,
        error: null

    },
    extraReducers(builder) {
        builder.addCase(updateVlccFarmer.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateVlccFarmer.fulfilled, (state, action) => {
            state.isLoading = false;
            const updatedIndex = state.data.findIndex((farmer) => farmer.id === action.payload.id);
            if (updatedIndex !== -1) {
                state.data[updatedIndex] = action.payload;
            }
            console.log("Updated farmer:", action.payload);
        });
        builder.addCase(updateVlccFarmer.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        });
    },
});

export const updateVlccFarmerReducer = updateVlccFarmerSlice.reducer;
