import React from 'react';
import { ClipLoader } from 'react-spinners';
import './Loader.css'
const Loader = ({ loading }) => {
    return (
        <div className={`loader-container ${loading ? 'visible' : 'hidden'}`}>
            <div className="loader">
                <ClipLoader size={35} color={'#3498db'} loading={loading} />
            </div>
        </div>
    );
};

export default Loader;