import { useState, useEffect } from 'react';
import useWebSocket from 'react-use-websocket';


const useWebSocketConnection = (initialMessage) => {
    const socketUrl = 'ws://192.168.0.112:8000';
    const [directFarmer, setDirectFarmer] = useState([]);
    const [vlccFarmer, setVlccFarmer] = useState([]);
    const [days, setDays] = useState('');
    const [timeoutId, setTimeoutId] = useState(null);
    // const { lastMessage, readyState, sendMessage } = useWebSocket(socketUrl, {
    //     onOpen: () => {
    //         console.log('WebSocket connection opened');
    //         sendMessage(JSON.stringify(initialMessage));
    //     },
    //     onClose: (event) => {
    //         console.log('WebSocket connection closed:', event);
    //     },
    //     onMessage: (event) => {
    //         const details = JSON.parse(event.data);
    //         console.log('Received message Details:', details);

    //         if (details) {
    //             setDirectFarmer(details?.data?.direct_farmer || []);
    //             setVlccFarmer(details?.data?.vlcc_farmer || []);
    //         }
    //     },
    //     shouldReconnect: (closeEvent) => true, // Customize this based on your needs
    // });

    const DaysFunc = (value) => {
        setDays(value); // Update days state with the input value
        sendMessage(JSON.stringify({ days: value }));
        console.log("value", JSON.stringify({ days: value }));
    };


    const { lastMessage, readyState, sendMessage } = useWebSocket(socketUrl, {
        onOpen: () => {
            console.log('WebSocket connection opened');
            sendMessage(JSON.stringify(initialMessage));

            // Set timeout for 10 seconds
            const id = setTimeout(() => {
                console.log('WebSocket data not received within 10 seconds. Closing connection.');
                if (readyState === WebSocket.OPEN) {
                    sendMessage('Closing due to timeout');
                }
            }, 10000);
            setTimeoutId(id);
        },
        onClose: (event) => {
            console.log('WebSocket connection closed:', event);
            clearTimeout(timeoutId); // Clear timeout on close
        },
        shouldReconnect: (closeEvent) => true, // Customize this based on your needs
    });

    // Clear timeout on unmount
    useEffect(() => {
        return () => {
            if (timeoutId !== null) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    // Log the lastMessage to the console whenever it changes
    useEffect(() => {
        if (lastMessage) {
            // console.log('Received message:', JSON.parse(lastMessage.data));
            console.log('Received message:', lastMessage);
            const details = JSON.parse(lastMessage.data);
            console.log('Received message Details:', details);

            if (details) {
                setDirectFarmer(details?.data?.direct_farmer);
                setVlccFarmer(details?.data?.vlcc_farmer);
            }
        }
    }, [lastMessage]);


    return { directFarmer, vlccFarmer, days, DaysFunc, lastMessage, readyState };
};

export default useWebSocketConnection;

//   const { lastMessage, readyState, sendMessage } = useWebSocket(socketUrl, {
//     onOpen: () => {
//       console.log('WebSocket connection opened');
//       sendMessage(JSON.stringify({ bill_date: billDate ? billDate : undefined }));

//       // Set timeout for 10 seconds
//       const id = setTimeout(() => {
//         console.log('WebSocket data not received within 10 seconds. Closing connection.');
//         if (readyState === WebSocket.OPEN) {
//           sendMessage('Closing due to timeout');
//         }
//       }, 10000);
//       setTimeoutId(id);
//     },
//     onClose: (event) => {
//       console.log('WebSocket connection closed:', event);
//       clearTimeout(timeoutId); // Clear timeout on close
//     },
//     shouldReconnect: (closeEvent) => true, // Customize this based on your needs
//   });

// // Clear timeout on unmount
// useEffect(() => {
//   return () => {
//     if (timeoutId !== null) {
//       clearTimeout(timeoutId);
//     }
//   };
// }, [timeoutId]);

// // Log the lastMessage to the console whenever it changes
// useEffect(() => {
//   if (lastMessage) {
//     // console.log('Received message:', JSON.parse(lastMessage.data));
//     console.log('Received message:', lastMessage);
//     const details = JSON.parse(lastMessage.data);
//     console.log('Received message Details:', details);

//     if (details) {
//       setDirectFarmer(details?.data?.direct_farmer);
//       setVlccFarmer(details?.data?.vlcc_farmer);
//     }
//   }
// }, [lastMessage]);