import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Dropdown, Button, Offcanvas, Alert } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';

import { Logo, Image, Icon, MediaGroup, MediaText, Media, LinkList, LinkListItem, CustomDropdownToggle, CustomDropdownMenu, Schedule } from '../../../components';

import Menu from './Menu'

import ToggleSidebar from '../Toggle/Sidebar'
import ToggleNavbar from '../Toggle/Navbar'

import { useLayout, useLayoutUpdate } from './../LayoutProvider'
import { useNavigate } from 'react-router-dom';
import { get } from '../../../pages/masters/api/Api';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ProfileDetails } from '../../../pages/masters/store/store';
import ConfirmationModal from '../../../pages/masters/modal/ConfirmationModal';

function QuickNav({ className, ...props }) {
    const compClass = classNames({
        "nk-quick-nav": true,
        [className]: className,
    })
    return (
        <ul className={compClass}>{props.children}</ul>
    )
}

function QuickNavItem({ className, ...props }) {
    const compClass = classNames({
        "d-inline-flex": true,
        [className]: className,
    });
    return (
        <div className={compClass}>{props.children}</div>
    );
}





function Header() {
    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const layout = useLayout();
    const layoutUpdate = useLayoutUpdate();
    const navigate = useNavigate()

    const compClass = classNames({
        "nk-header nk-header-fixed": true,
        [`is-${layout.headerVariant}`]: layout.headerVariant,
    });

    const navClass = classNames({
        "nk-header-menu nk-navbar": true,
        "navbar-active": layout.headerActive,
        // eslint-disable-next-line
        "navbar-mobile": layout.headerTransition || eval(`layout.breaks.${layout.headerCollapse}`) > window.innerWidth,
    });

    // offcanvas
    const handleOffcanvasClose = () => setShowOffcanvas(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const dispatch = useDispatch()

    const handleLogOut = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('role')
        navigate('/auth-login')

    }

    useEffect(() => {
        dispatch(ProfileDetails())
    }, [dispatch])

    const { data, isLoading, error } = useSelector((state) => state.profile)



    useEffect(() => {
        if (data)
            localStorage.setItem("role", data.user_role)
    }, [data])


    const modalStatus = () => {
        setShowConfirmationModal(true)
    }

    const handleYesClick = () => {
        handleLogOut()
        setShowConfirmationModal(false); // Close the confirmation modal
    };

    return (
        <>
            <ConfirmationModal
                show={showConfirmationModal}
                title="Logout"
                body="Are you sure?"
                onCancel={() => {
                    setShowConfirmationModal(false)
                }}
                onConfirm={handleYesClick}
            />
            <div className={compClass}>
                <div className="container-fluid">
                    <div className="nk-header-wrap">
                        <div className="nk-header-logo">
                            <ToggleSidebar variant="zoom" icon='menu' />
                            <ToggleNavbar className="me-2" />
                            <Logo />
                        </div>
                        {layout.headerActive && <div className="navbar-overlay" onClick={layoutUpdate.headerMobile}></div>}
                        <nav className={navClass}>
                            <Menu />
                        </nav>
                        <div className="nk-header-tools">
                            <QuickNav>


                                <Dropdown as={QuickNavItem}>
                                    <Dropdown.Toggle bsPrefix as={CustomDropdownToggle}>

                                        <div className="d-inline-flex d-sm-none">
                                            <Media shape="circle" size="md">
                                                <Image src='/images/avatar/c.jpg' staticImage thumbnail />
                                            </Media>
                                        </div>
                                        <div className="d-none d-sm-flex">
                                            <Media shape="circle">
                                                <Image src='/images/avatar/c.jpg' staticImage thumbnail />
                                            </Media>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-md" as={CustomDropdownMenu}>
                                        <div className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                                            <MediaGroup>
                                                <Media size="xl" shape="circle">
                                                    <Image src='/images/avatar/c.jpg' staticImage thumbnail />
                                                </Media>
                                                <MediaText>
                                                    <div className="lead-text">{data.user_name}</div>
                                                    <span className="sub-text">{data.user_role}</span>
                                                </MediaText>
                                            </MediaGroup>
                                        </div>
                                        <div className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                                            <LinkList>
                                                <LinkListItem to="/admin/profile"><Icon name="user"></Icon><span>My Profile</span></LinkListItem>

                                            </LinkList>
                                        </div>

                                        <div className="dropdown-content dropdown-content-x-lg py-3">

                                            <Button onClick={modalStatus}><Icon name="signout"></Icon><span>Log Out</span></Button>

                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </QuickNav>
                        </div>
                    </div>
                </div>
            </div>

            <Offcanvas className="offcanvas-size-lg" placement="end" show={showOffcanvas} onHide={handleOffcanvasClose}>
                <Offcanvas.Header closeButton className="border-bottom border-light">
                    <Offcanvas.Title>Recent Notification</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <SimpleBar>
                        <Schedule>
                            <Schedule.Item symbol="active">
                                <span className="smaller">2:12 PM</span>
                                <div className="h6">Added 3 New Images</div>
                                <ul className="d-flex flex-wrap gap g-2 pt-2">
                                    <li>
                                        <Media size="xxl">
                                            <Image src="/images/product/a.jpg" alt="gallery" thumbnail />
                                        </Media>
                                    </li>
                                    <li>
                                        <Media size="xxl">
                                            <Image src="/images/product/b.jpg" alt="gallery" thumbnail />
                                        </Media>
                                    </li>
                                    <li>
                                        <Media size="xxl">
                                            <Image src="/images/product/c.jpg" alt="gallery" thumbnail />
                                        </Media>
                                    </li>
                                </ul>
                            </Schedule.Item>
                            <Schedule.Item symbol="active">
                                <span className="smaller">4:23 PM</span>
                                <div className="h6">Invitation for creative designs pattern</div>
                            </Schedule.Item>
                            <Schedule.Item symbol="active" contentClass="nk-schedule-content-no-border">
                                <span className="smaller">10:30 PM</span>
                                <div className="h6">Task report - uploaded weekly reports</div>
                                <div className="list-group-dotted mt-3">
                                    <div className="list-group-wrap">
                                        <div className="p-3">
                                            <MediaGroup>
                                                <Media className="rounded-0">
                                                    <Image src="/images/icon/file-type-pdf.svg" alt="icon" />
                                                </Media>
                                                <MediaText className="ms-1">
                                                    <a href="#download" className="title">Modern Designs Pattern</a>
                                                    <span className="text smaller">1.6.mb</span>
                                                </MediaText>
                                            </MediaGroup>
                                        </div>
                                        <div className="p-3">
                                            <MediaGroup>
                                                <Media className="rounded-0">
                                                    <Image src="/images/icon/file-type-doc.svg" alt="icon" />
                                                </Media>
                                                <MediaText className="ms-1">
                                                    <a href="#download" className="title">Cpanel Upload Guidelines</a>
                                                    <span className="text smaller">18kb</span>
                                                </MediaText>
                                            </MediaGroup>
                                        </div>
                                        <div className="p-3">
                                            <MediaGroup>
                                                <Media className="rounded-0">
                                                    <Image src="/images/icon/file-type-code.svg" alt="icon" />
                                                </Media>
                                                <MediaText className="ms-1">
                                                    <a href="#download" className="title">Weekly Finance Reports</a>
                                                    <span className="text smaller">10mb</span>
                                                </MediaText>
                                            </MediaGroup>
                                        </div>
                                    </div>
                                </div>
                            </Schedule.Item>
                            <Schedule.Item symbol="active">
                                <span className="smaller">3:23 PM</span>
                                <div className="h6">Assigned you to new database design project</div>
                            </Schedule.Item>
                            <Schedule.Item symbol="active" contentClass="nk-schedule-content-no-border flex-grow-1">
                                <span className="smaller">5:05 PM</span>
                                <div className="h6">You have received a new order</div>
                                <Alert variant="info" className="mt-2">
                                    <div className="d-flex">
                                        <Icon size="lg" name="file-code" className="opacity-75"></Icon>
                                        <div className="ms-2 d-flex flex-wrap flex-grow-1 justify-content-between">
                                            <div>
                                                <h6 className="alert-heading mb-0">Business Template - UI/UX design</h6>
                                                <span className="smaller">Shared information with your team to understand and contribute to your project.</span>
                                            </div>
                                            <div className="d-block mt-1">
                                                <Button size="md" variant="info">
                                                    <Icon name="download"></Icon>
                                                    <span>Download</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Alert>
                            </Schedule.Item>
                            <Schedule.Item symbol="active">
                                <span className="smaller">2:45 PM</span>
                                <div className="h6">Project status updated successfully</div>
                            </Schedule.Item>
                        </Schedule>
                    </SimpleBar>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Header