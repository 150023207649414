import { Row, Col, Card, Button } from 'react-bootstrap';
import { ChartLine } from "../../../components/Chart/Charts";
import { Colors } from '../../../utilities/index';
import hexRGB from '../../../utilities/hexRGB';
import { useEffect, useState } from 'react';
import { findStartMidEnd } from '../shared/Date';
import { ChartLabel, Icon, Media } from '../../../components';
import SocketFilter from '../shared/Filter/SocketFilter';
import useWebSocketConnection from '../api/Socket';
import { postToastNode } from '../api/Api';


const FarmerChart = () => {

    const initialMessage = { days: "today" }
    const [topFarmerData, setTopFarmerData] = useState([])
    const [attendence, setAttendence] = useState(null)
    const [belowFarmerData, setBelowFarmerData] = useState([])
    const [showFilter, setShowFilter] = useState(false)
    const [showDays, setShowDays] = useState("This Week")
    const [directChartData, setDirectChartData] = useState([])
    const [vlccChartData, setVlccChartData] = useState([])
    const { directFarmer, vlccFarmer, days, DaysFunc, lastMessage, readyState } = useWebSocketConnection(initialMessage);

    const attendenceDate = (value) => {
        const data = {
            date: value.date,
            session: value.session
        }
        fetchAttendence(data)
    }

    const fetchAttendence = async (payload) => {
        const url = `/api/farmerDayStatus`;

        try {
            const response = await postToastNode(url, payload);
            if (response.status) {
                console.log("response", response.data.farmer);
                const data = response.data.farmer
                setAttendence(data)

                // if (chartData) {
                //   setAtttendenceData(chartData)
                // }

                // setLoading(false)
            } else {
                // setLoading(false)
            }
        } catch (error) {
            console.error("Error while posting data:", error);
        }
    }

    const fetchTopFarmer = async () => {
        const url = `/api/minAndMaxOfFarmer`;
        const payload = {
            count: 5,
            date: "2024-02-02",
            session: "M"
        }

        try {
            const response = await postToastNode(url, payload);
            if (response.status) {
                console.log("response", response.data);
                const data = response.data.farmer
                setTopFarmerData(data.maxOfLitreFarmer)
                setBelowFarmerData(data.minOfLitreFarmer)
                // setLoading(false)
            } else {
                // setLoading(false)
            }
        } catch (error) {
            console.error("Error while posting data:", error);
        }
    }

    const filterDayFunc = (value) => {
        if (value === "this_week") {
            return "This Week";
        } else if (value === "last_week") {
            return "Last Week";
        } else if (value === "this_month") {
            return "This Month";
        } else if (value === "last_month") {
            return "Last Month";
        } else {
            return ""
        }
    }

    const handleFilter = (value) => {
        console.log("value===>", value);
        const initialMessage = {
            days: value.days,
            session: value.session
        }
        const show = filterDayFunc(value.days)
        // set Days in days State
        setShowDays(show)
        fetchChartreport(initialMessage)
    }

    const fetchChartreport = async (payload) => {
        const url = `/api/overallreport`;
        try {
            const response = await postToastNode(url, payload);
            if (response.status) {
                console.log("response", response.data);
                const data = response.data
                setDirectChartData(data.direct_farmer)
                setVlccChartData(data.vlcc_farmer)
                // setLoading(false)
            } else {
                // setLoading(false)
            }
        } catch (error) {
            console.error("Error while posting data:", error);
        }
    }

    useEffect(() => {
        const payload = {
            date: "2024-02-02",
            session: "M"
        }

        fetchAttendence(payload)
        const initialMessage = {
            days: "this_week",
            session: ""
        }
        fetchChartreport(initialMessage)
    }, []);


    useEffect(() => {
        fetchTopFarmer()
    }, []);

    const chartLabels = findStartMidEnd(directChartData?.dates)?.map(date => (
        <ChartLabel className="chart-label-small" key={date}>
            {date}
        </ChartLabel>
    ));

    let totalClientsChart = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        yAxis: false,
        xAxis: false,
        maxTicksLimit: 4,
        datasets: [
            {
                tension: .4,
                label: "Total Clients",
                borderColor: Colors.success,
                backgroundColor: hexRGB(Colors.success, 0.2),
                borderWidth: 4,
                pointBorderColor: 'transparent',
                pointBackgroundColor: 'transparent',
                pointHoverBackgroundColor: Colors.success,
                borderCapStyle: "round",
                fill: true,
                data: [38, 40, 23, 80, 70, 110]
            }
        ]
    };

    // session Fat avg chart
    let fat_avg_data = {
        labels: directChartData?.avg?.map(item => item.label),
        yAxis: false,
        xAxis: false,
        maxTicksLimit: 4,
        datasets: [
            {
                tension: .4,
                label: "Avg.Fat",
                borderWidth: 2,
                borderColor: Colors.primary,
                backgroundColor: hexRGB(Colors.primary, 0.2),
                pointBackgroundColor: Colors.primary,
                pointBorderColor: Colors.white,
                fill: true,
                data: directChartData?.avg?.map(item => item.avg_tot_fat)
            }
        ]
    };

    // session Snf avg chart
    let snf_avg_data = {
        labels: directChartData?.avg?.map(item => item.label),
        yAxis: false,
        xAxis: false,
        datasets: [
            {
                tension: .4,
                label: "Avg.Snf",
                borderColor: Colors.success,
                borderWidth: 2,
                backgroundColor: hexRGB(Colors.success, 0.2),
                pointBackgroundColor: Colors.success,
                pointBorderColor: Colors.white,
                fill: true,
                data: directChartData?.avg?.map(item => item.avg_tot_snf)
            }
        ]
    };


    // session Snf avg chart
    let lit_avg_data = {
        labels: directChartData?.avg?.map(item => item.label),
        yAxis: false,
        xAxis: false,
        datasets: [
            {
                tension: .4,
                label: "Avg.Lit",
                borderColor: Colors.mustedYellow,
                borderWidth: 2,
                backgroundColor: hexRGB(Colors.mustedYellow, 0.2),
                pointBackgroundColor: Colors.mustedYellow,
                pointBorderColor: Colors.white,
                pointHoverBackgroundColor: Colors.mustedYellow,
                fill: true,
                data: directChartData?.avg?.map(item => item.avg_tot_qty_litre)
            }
        ]
    };

    // session Snf avg chart
    let qty_avg_data = {
        labels: directChartData?.avg?.map(item => item.label),
        yAxis: false,
        xAxis: false,
        datasets: [
            {
                tension: .4,
                label: "Avg.KG",
                borderColor: Colors.hotPink,
                borderWidth: 2,
                backgroundColor: hexRGB(Colors.hotPink, 0.2),
                pointBorderColor: Colors.white,
                pointBackgroundColor: Colors.hotPink,
                pointHoverBackgroundColor: Colors.white,
                fill: true,
                data: directChartData?.avg?.map(item => item.avg_tot_qty_kg)
            }
        ]
    };



    return (
        <Card>
            <div>
                <Button
                    className='d-flex float-end mb-2'
                    as="label"
                    variant="primary"
                    onClick={() => setShowFilter(!showFilter)}
                >
                    <Icon name='filter' />
                </Button>
            </div>



            <Card className='mt-2'>
                <Card.Body >
                    <Col lg="12" className='mb-2 '>

                        <Row className="g-gs">

                            <Col sm="3" xxl="3">
                                <Card className="h-100 " >
                                    <Card.Body>
                                        <div className="card-title-group align-items-start" >
                                            <div className="card-title">
                                                <h4 className="title">Avg.Fat</h4>
                                            </div>
                                            <Media size="sm" shape="circle" variant="success-soft">
                                                <Icon name="bar-chart-fill"></Icon>
                                            </Media>
                                        </div>

                                        <div className="amount h1">{directFarmer?.currentDate?.avg_tot_fat}</div>
                                        <div className="d-flex align-items-center smaller">
                                            <div className={directFarmer?.currentDate?.tot_fat_symbol === "up" ? "change up" : "change down"}>
                                                <Icon name={directFarmer?.currentDate?.tot_fat_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                                                <span className="ms-1">{directFarmer?.currentDate?.tot_fat_percentage}%</span>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm="3" xxl="3">
                                <Card className="h-100 mb-2" >
                                    <Card.Body>
                                        <div className="card-title-group align-items-start" >
                                            <div className="card-title">
                                                <h4 className="title">Avg.Snf</h4>
                                            </div>
                                            <Media size="sm" shape="circle" variant="success-soft">
                                                <Icon name="bar-chart-fill"></Icon>
                                            </Media>
                                        </div>

                                        <div className="amount h1">{directFarmer?.currentDate?.avg_tot_snf}</div>

                                        <div className="d-flex align-items-center smaller">
                                            <div className={directFarmer?.currentDate?.tot_snf_symbol === "up" ? "change up" : "change down"}>
                                                <Icon name={directFarmer?.currentDate?.tot_snf_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                                                <span className="ms-1">{directFarmer?.currentDate?.tot_snf_percentage}%</span>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm="3" xxl="3">
                                <Card className="h-100 mb-2" >
                                    <Card.Body>
                                        <div className="card-title-group align-items-start" >
                                            <div className="card-title">
                                                <h4 className="title">Avg.Ltr</h4>
                                            </div>
                                            <Media size="sm" shape="circle" variant="success-soft">
                                                <Icon name="bar-chart-fill"></Icon>
                                            </Media>
                                        </div>

                                        <div className="amount h1">{directFarmer?.currentDate?.avg_tot_qty_litre}</div>
                                        <div className="d-flex align-items-center smaller">
                                            <div className={directFarmer?.currentDate?.avg_tot_qty_litre_symbol === "up" ? "change up" : "change down"}>
                                                <Icon name={directFarmer?.currentDate?.avg_tot_qty_litre_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                                                <span className="ms-1">{directFarmer?.currentDate?.tot_qty_litre_percentage}%</span>
                                            </div>
                                        </div>

                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm="3" xxl="3">
                                <Card className="h-100 mb-2" >
                                    <Card.Body>
                                        <div className="card-title-group align-items-start" >
                                            <div className="card-title">
                                                <h4 className="title">Avg.Kg</h4>
                                            </div>
                                            <Media size="sm" shape="circle" variant="success-soft">
                                                <Icon name="bar-chart-fill"></Icon>
                                            </Media>
                                        </div>

                                        <div className="amount h1">{directFarmer?.currentDate?.avg_tot_qty_kg}</div>
                                        <div className="d-flex align-items-center smaller">
                                            <div className={directFarmer?.currentDate?.tot_qty_kg_symbol === "up" ? "change up" : "change down"}>
                                                <Icon name={directFarmer?.currentDate?.tot_qty_kg_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                                                <span className="ms-1">{directFarmer?.currentDate?.tot_qty_kg_percentage}%</span>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row>
                    </Col>
                </Card.Body>
            </Card>

            <Card.Body>

                <Card>
                    <Col sm="12" >
                        <div className='d-flex'>
                            <div style={{ marginBottom: "10px", backgroundColor: '#ff9966', fontWeight: "bold", borderRadius: '5px', textAlign: "center", alignItems: 'center', justifyContent: 'center', width: "100px" }}>
                                {showDays}
                            </div>

                            <div className='mx-3'>
                                <Button
                                    style={{ height: "25px", width: "20px" }}
                                    variant="primary"
                                    onClick={() => setShowFilter(!showFilter)}
                                >
                                    <Icon name='filter' />
                                </Button>
                            </div>

                        </div>
                    </Col>
                    {showFilter && <SocketFilter handleFilter={handleFilter} />}
                    <Card.Body>

                        <Row className="g-gs">
                            <Col sm="6" xxl="3">
                                <Card className="h-100">
                                    <Card.Body>
                                        <div className="card-title-group align-items-start">
                                            <div className="card-title">
                                                <h4 className="title">Avg.Fat</h4>
                                            </div>
                                            <Media size="sm" shape="circle" variant="success-soft">
                                                <Icon name="bar-chart-fill"></Icon>
                                            </Media>
                                        </div>
                                        <div className="mt-2 mb-4">
                                            <div className="amount h1">{directChartData?.avgDirectFarmer?.avg_tot_fat ? (directChartData?.avgDirectFarmer?.avg_tot_fat) : ''}</div>
                                            <div className="d-flex align-items-center smaller">
                                                <div className={directChartData?.avgDirectFarmer?.tot_fat_symbol === "up" ? "change up" : "change down"}>
                                                    <Icon name={directChartData?.avgDirectFarmer?.tot_fat_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                                                    <span className="ms-1">{directChartData?.avgDirectFarmer?.tot_fat_percentage}%</span>
                                                </div>
                                                <span className="text-light">{days}</span>
                                            </div>
                                        </div>
                                        <div className="nk-chart-analytics-session">
                                            <ChartLine data={fat_avg_data} />
                                        </div>
                                        <ChartLabel.Group className="justify-content-between mt-1">
                                            {chartLabels}
                                        </ChartLabel.Group>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm="6" xxl="3">
                                <Card className="h-100">
                                    <Card.Body>
                                        <div className="card-title-group align-items-start">
                                            <div className="card-title">
                                                <h4 className="title">Avg.Snf</h4>
                                            </div>
                                            <Media size="sm" shape="circle" variant="success-soft">
                                                <Icon name="bar-chart-fill"></Icon>
                                            </Media>
                                        </div>
                                        <div className="mt-2 mb-4">
                                            <div className="amount h1">{directChartData?.avgDirectFarmer?.avg_tot_snf ? (directChartData?.avgDirectFarmer?.avg_tot_snf) : ''}</div>
                                            <div className="d-flex align-items-center smaller">
                                                <div className={directChartData?.avgDirectFarmer?.tot_snf_symbol === "up" ? "change up" : "change down"}>
                                                    <Icon name={directChartData?.avgDirectFarmer?.tot_snf_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                                                    <span className="ms-1">{directChartData?.avgDirectFarmer?.tot_snf_percentage}%</span>
                                                </div>
                                                <span className="text-light">{days}</span>
                                            </div>
                                        </div>
                                        <div className="nk-chart-analytics-session">
                                            <ChartLine data={snf_avg_data} />
                                        </div>
                                        <ChartLabel.Group className="justify-content-between mt-1">
                                            {chartLabels}
                                        </ChartLabel.Group>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm="6" xxl="3">
                                <Card className="h-100">
                                    <Card.Body>
                                        <div className="card-title-group align-items-start">
                                            <div className="card-title">
                                                <h4 className="title">Avg.Lit</h4>
                                            </div>
                                            <Media size="sm" shape="circle" variant="warning-soft">
                                                <Icon name="activity-round-fill"></Icon>
                                            </Media>
                                        </div>
                                        <div className="mt-2 mb-4">
                                            <div className="amount h1">{directChartData?.avgDirectFarmer?.avg_tot_qty_litre ? (directChartData?.avgDirectFarmer?.avg_tot_qty_litre) : ''}</div>
                                            <div className="d-flex align-items-center smaller">
                                                <div className={directChartData?.avgDirectFarmer?.avg_tot_qty_litre_symbol === "up" ? "change up" : "change down"}>
                                                    <Icon name={directChartData?.avgDirectFarmer?.avg_tot_qty_litre_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                                                    <span className="ms-1">{(directChartData?.avgDirectFarmer?.tot_qty_litre_percentage)}%</span>
                                                </div>
                                                <span className="text-light">{days}</span>
                                            </div>
                                        </div>
                                        <div className="nk-chart-analytics-session">
                                            <ChartLine data={lit_avg_data} />
                                        </div>
                                        <ChartLabel.Group className="justify-content-between mt-1">
                                            {chartLabels}
                                        </ChartLabel.Group>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm="6" xxl="3">
                                <Card className="h-100">
                                    <Card.Body>
                                        <div className="card-title-group align-items-start">
                                            <div className="card-title">
                                                <h4 className="title">Avg.Kg</h4>
                                            </div>
                                            <Media size="sm" shape="circle" variant="info-soft">
                                                <Icon name="tag-fill"></Icon>
                                            </Media>
                                        </div>
                                        <div className="mt-2 mb-4">
                                            <div className="amount h1">{directChartData?.avgDirectFarmer?.avg_tot_qty_kg ? (directChartData?.avgDirectFarmer?.avg_tot_qty_kg) : ''}</div>
                                            <div className="d-flex align-items-center smaller">
                                                <div className={directChartData?.avgDirectFarmer?.tot_qty_kg_symbol === "up" ? "change up" : "change down"}>
                                                    <Icon name={directChartData?.avgDirectFarmer?.tot_qty_kg_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                                                    <span className="ms-1">{directChartData?.avgDirectFarmer?.tot_qty_kg_percentage}%</span>
                                                </div>
                                                <span className="text-light">{days}</span>
                                            </div>
                                        </div>
                                        <div className="nk-chart-analytics-session">
                                            <ChartLine data={qty_avg_data} />
                                        </div>
                                        <ChartLabel.Group className="justify-content-between mt-1">
                                            {chartLabels}
                                        </ChartLabel.Group>
                                    </Card.Body>
                                </Card>
                            </Col>


                            <Col md="3">
                                <Card className="h-100">
                                    <Card.Body>
                                        <div className="d-flex flex-column flex-sm-row-reverse align-items-sm-center justify-content-sm-between gx-xl-5">
                                            <div className="nk-chart-project-earnings">
                                                <ChartLine data={totalClientsChart} />
                                            </div>
                                            <div className="card-title mb-0 mt-4 mt-sm-0">
                                                <h5 className="title mb-3 mb-xl-5">Total Litres</h5>
                                                <div className="amount h1">{directChartData?.avgDirectFarmer?.tot_qty_litre}</div>
                                                <div className="d-flex align-items-center smaller flex-wrap">
                                                    <div className={directChartData?.avgDirectFarmer?.avg_tot_qty_litre_symbol === "up" ? "change up" : "change down"}>
                                                        <Icon name={directChartData?.avgDirectFarmer?.tot_qty_kg_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon>
                                                        {directChartData?.avgDirectFarmer?.tot_qty_litre_percentage}%
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md="3">
                                <Card className="h-100">
                                    <Card.Body>
                                        <div className="d-flex flex-column flex-sm-row-reverse align-items-sm-center justify-content-sm-between gx-xl-5">
                                            <div className="nk-chart-project-earnings">
                                                <ChartLine data={totalClientsChart} />
                                            </div>
                                            <div className="card-title mb-0 mt-4 mt-sm-0">
                                                <h5 className="title mb-3 mb-xl-5">Total KG</h5>
                                                <div className="amount h1">{directChartData?.avgDirectFarmer?.tot_qty_kg}</div>
                                                <div className="d-flex align-items-center smaller flex-wrap">
                                                    <div className={directChartData?.avgDirectFarmer?.avg_tot_qty_litre_symbol === "up" ? "change up" : "change down"}>
                                                        <Icon name={directChartData?.avgDirectFarmer?.tot_qty_kg_symbol === "up" ? "upword-alt-fill" : "downward-alt-fill"}></Icon> {directChartData?.avgDirectFarmer?.tot_qty_kg_percentage}%
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row>
                    </Card.Body>
                </Card>
            </Card.Body>
        </Card>

    )
}

export default FarmerChart