import {
  Card,
  Form,
  FloatingLabel,
  Col,
  Row,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import { Icon } from "semantic-ui-react";
import Block from "../../components/Block/Block";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

// import Layout from "../../layout/default";

const RouteMasterFields = () => {
  let storedId;

  const [validated, setValidated] = useState(false);
  const [Routeid, setRouteid] = useState("");
  const [RouteName, setRouteName] = useState("");
  const [RouteCode, setRouteCode] = useState("");
  const [errorMessages] = useState([]);


  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } 
 
  };

  
  return (
    <form>
      <Row>
        <Col md="4">
          <label htmlFor="exampleInputRouteName" className="form-label">
            Route Name
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleInputRouteName"
            aria-describedby="idHelp"
          />
        </Col>

        <Col md="4">
          <label htmlFor="exampleInputRouteCode" className="form-label">
            Route Code
          </label>
          <input
            type="number"
            className="form-control"
            id="exampleInputRouteCode"
            disabled
          />
        </Col>
      </Row>
<br/>
      <Row>
        <Col className="col-12">
          <div className="gap-col">
            <ul className="d-flex align-items-center gap g-3">
              <li>
                {/* // if Routeid is there change Add button to Update button */}
                <Button type="submit" onSubmit={handleSubmit} variant="success">
                  {Routeid ? "Update" : "Save"}
                </Button>
                {/* // if Routeid is there change Add button to Update button */}
              </li>
              <li>
                <Button
                  variant="danger"
                  onClick={(e) => {
                    e.preventDefault();
                    Swal.fire({
                      icon: "warning",
                      title: "Are you sure you want to cancel?",
                      text: "You won't be able to revert this!",
                      width: "320px",
                      height: "10px", // Set the width of the alert box
                      autoClose: 1000,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        window.location.href = "/masters/Router"; // Redirect to the specified page
                        localStorage.clear();
                      }
                    });
                  }}
                >
                  Cancel
                </Button>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default RouteMasterFields;
