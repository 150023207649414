import React, { useLayoutEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { Form } from 'react-bootstrap';
import Choices from 'choices.js';

const Tags = forwardRef(({ onValueChange, value, disabled, className, placeholder, removeItemButton }, ref) => {
  const tagsInput = useRef(null);
  const choicesInstanceRef = useRef(null);

  const [tags, setTags] = useState([])

  // State to store preset items


  // const clearSelectedValues = () => {
  //   console.log(selectedValues, "valieooo");
  //   console.log('value',value);
  //   console.log(tags,"tags==>");
  //   tags.removeItemsByValue(value);


  // if (tagsInput.current) {
  //   const select = tagsInput.current;
  //   const selectedValue = select.value;

  //   // Check if the selected value is empty or not
  //   if (selectedValue) {
  //     // Remove the selected value from Choices.js
  //     // tags.removeItemsByValue(selectedValue);

  //     // Clear the input field value
  //     select.value = '';

  //     // Update the selectedValues in local state by filtering out the selectedValue
  //     setSelectedValues(selectedValues.filter((value) => value !== selectedValue));
  //   }
  // }
  // };

  // const resetChoicesInstance = () => {
  //   if (tagsInput.current && tags) {
  //     // Destroy the current Choices instance
  //     tags.destroy();
  //   }
  // };

  // useImperativeHandle(ref, () => ({
  //   resetChoicesInstance,
  // }));


  useLayoutEffect(() => {
    if (!tagsInput.current) return;  // Check if ref is assigned
    let plainText = tagsInput.current.classList.contains('form-control-plaintext') && `choices__inner-plaintext`;
    let containerInner = `choices__inner ${plainText && plainText}`;

    const choices = new Choices(tagsInput.current, {
      silent: false,
      allowHTML: true,
      placeholder: true,
      placeholderValue: placeholder,
      removeItemButton: false || removeItemButton,
      classNames: {
        containerInner: 'choices__inner',

      }
    });


    setTags(choices)

    choicesInstanceRef.current = choices;

    const handleInputChange = (e) => {
      // choices.clearInput()
      const select = tagsInput.current;
      if (onValueChange) {
        onValueChange(select.value);

      }
    };

    // Add an event listener to react to value changes on tagsInput
    tagsInput.current.addEventListener('change', handleInputChange);

  }, [onValueChange, removeItemButton])

  const removeSelectedValue = () => {
    if (choicesInstanceRef.current) {
      const selectedValue = choicesInstanceRef.current.getValue();
      if (selectedValue) {
        choicesInstanceRef.current.removeActiveItemsByValue(selectedValue);
      }
    }
  };
   // Function to clear all selected items
   const clearAllSelectedValues = () => {
    if (choicesInstanceRef.current) {
      choicesInstanceRef.current.clearChoices();
    }
  };

  useImperativeHandle(ref, () => ({
    removeSelectedValue,
    clearAllSelectedValues
  }));



  return (
    <>
      <Form.Control className={className} ref={tagsInput} defaultValue={value} disabled={disabled}></Form.Control>
    </>
  )
});

export default Tags;
