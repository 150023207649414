import React, { useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'

const SocketFilter = ({ handleFilter }) => {

    const [filterData, setFilterData] = useState({
        days: "this_week",
        session: ""
    })

    const handleCheck = () => {
        handleFilter(filterData)
    }

    return (
        <Card className="card-gutter-md  mb-2">
            <Card.Body>
                <Col xxl="12">
                    <Row className="g-gs">
                        <Col md="2" >
                            <Form.Group className="form-group">
                                <div className="form-control-wrap">

                                    <select className="form-select" id="exampleInputDistrict" value={filterData.days}
                                        onChange={(e) => setFilterData({ ...filterData, days: e.target.value })}  >
                                        <option value="last_week"> Last Week </option>
                                        <option value="this_week"> This Week </option>
                                        <option value="last_month"> Last Month </option>
                                        <option value="this_month"> This Month </option>
                                    </select>

                                </div>
                            </Form.Group>
                        </Col>
                        <Col md="2" >
                            <Form.Group className="form-group">
                                <div className="form-control-wrap">

                                    <select className="form-select" id="exampleSession" value={filterData.session}
                                        onChange={(e) => setFilterData({ ...filterData, session: e.target.value })} >
                                        <option value=""> All </option>
                                        <option value="M"> Morning </option>
                                        <option value="E"> Evening </option>

                                    </select>

                                </div>
                            </Form.Group>
                        </Col>
                        <Col lg="2">
                            <Col>
                                <Button
                                    as="label"

                                    variant="primary"
                                    onClick={handleCheck}
                                >
                                    Get Data
                                </Button>
                            </Col>
                        </Col>

                    </Row>
                </Col>
            </Card.Body>
        </Card>
    )
}

export default SocketFilter