import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post, put } from "../../../api/Api";



export const fetchFarmerList = createAsyncThunk('directFarmerList/fetch', async (body) => {
    try {
        const endpoint = "/api/direct-farmer/list";
        const url = `${endpoint}`;

        console.log("Fetching data from:", url);

        const response = await post(endpoint, body);
        console.log("API response:", response);

        return response.data;
    } catch (error) {
        console.error("API error:", error);
        return error;
    }
});

// Create Farmer (POST)
export const createFarmer = createAsyncThunk('directFarmerList/create', async (farmerData) => {
    try {
        const endpoint = "/api/direct-farmer/create";

        console.log("Creating farmer with data:", farmerData);

        const response = await post(endpoint, farmerData);
        console.log("API response:", response);

        return response.data; // Success case

    } catch (error) {
        console.error("API error:", error);
        return error;
    }
});


// Update Farmer (PUT)
export const updateFarmer = createAsyncThunk('directFarmerList/update', async (updateData) => {
    try {

        console.log("Updating farmer with data:", updateData);
        const endpoint = `/api/direct-farmer/${updateData.id}`;
        const response = await put(endpoint, updateData.transformedData);
        console.log("API response:", response);

        return response.data;
    } catch (error) {
        console.error("API error:", error);
        return error;
    }
});
