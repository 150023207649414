import { createSlice } from "@reduxjs/toolkit";
import { fetchVlccFarmerList } from "../../thunks/vlcc-thunk/VlccCollection";


const vlccCollectionSlice = createSlice({
    name: "vlccFarmer",
    initialState: {
        data: [],
        isLoading: false,
        error: null

    },
    extraReducers(builder) {
        builder.addCase(fetchVlccFarmerList.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchVlccFarmerList.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            console.log("Data received:", action.payload);
        })
        builder.addCase(fetchVlccFarmerList.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
        })


    }
})

export const VlccCollectionReducer = vlccCollectionSlice.reducer
