import React, { forwardRef } from 'react';

const MyComponent = forwardRef((props, ref) => {
    const {
        storedData,
        code,
        farmername,
        printerData,
        bill_no,
        route_name
    } = props;

    console.log(props, "props");

    const logo = require("../../../../assets/images/mask/asm_100.png")

    function formatCustomDateWithTime(inputDate) {
        console.log(inputDate, "inputDate");


        // Get the current time without AM/PM (e.g., '16:08:23')
        const currentTime = new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });

        // Combine the date and time
        const formattedDate = `${inputDate}`;

        return formattedDate;
    }

    const headerStyle = {
        fontSize: '12px',
        fontWeight: 'bold',
        textAlign: 'center',
        lineHeight: '1',
    };

    const centerInfoContainerStyle = {
        textAlign: 'left',
    };

    const centerInfoStyle = {
        fontSize: '11px',
        marginTop: '17px',
        display: 'inline-block',
    };

    const labelStyle = {
        width: '55px', // Set a fixed width for labels
        display: 'inline-block',
        fontWeight: 'bold',

    };
    const labelStyle1 = {
        width: '60px', // Set a fixed width for labels
        display: 'inline-block',
        fontWeight: 'bold',

    };

    const tableStyle = {
        width: '90%',
        margin: '10px auto',
        borderCollapse: 'collapse',
    };

    const cellStyle = {
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        borderLeft: 'none',
        borderRight: 'none',
        textAlign: "right",
        padding: '8px',
    };

    const printStyles = `
        @media print {
            body {
                font-family: Arial, sans-serif;
                font-size: 12px;
                line-height: 1;
            }

            p, th, td {
                font-weight: bold;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                color: black;
            }

            @page {
                size: auto;
                margin: 5mm;
            }
            .rowPage{
                display:inline-flex;
            }
        }
    `;

    const showData = `${formatCustomDateWithTime(storedData.date)}  (${storedData.session})`
    const showName = storedData.route?.name
    return (
        <div ref={ref}>
            <style>{printStyles}</style>
            <div style={headerStyle}>
                <img src={logo} alt='ASM DAIRY' />
                <p style={{ marginTop: "1px" }}>ASM DAIRY</p>
                <p style={{ marginTop: "-11px" }}>99769 79999</p>

            </div>
            <div style={centerInfoContainerStyle}>
                <div style={centerInfoStyle}>
                    <p style={{ marginTop: "1px" }}>
                        <span style={labelStyle}>Route&ensp;&ensp;:</span>
                        <span style={{ display: 'inline-block' }}>{route_name}</span>
                    </p>
                    <p style={{ marginTop: "-10px" }}>
                        <span style={labelStyle}>No&ensp;&ensp;&ensp;&ensp;&ensp;:</span>
                        <span style={{ display: 'inline-block' }}>{bill_no}</span>
                    </p>
                    <p style={{ marginTop: "-10px" }}>
                        <span style={labelStyle}>Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>
                        <span style={{ display: 'inline-block' }}>{showData}</span>
                    </p>

                    <p style={{ marginTop: "-10px" }}>
                        <span style={labelStyle}>Code&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>
                        <span style={{ display: 'inline-block' }}>{code}</span>
                    </p>
                    <p style={{ marginTop: "-10px" }}>
                        <span style={labelStyle}>Name&nbsp;&nbsp;&nbsp;&nbsp;:</span>
                        <span style={{ display: 'inline-block' }}>{farmername}</span>
                    </p>
                </div>
            </div>

            <table style={tableStyle}>
                <thead>
                    <tr>
                        <th style={{ ...cellStyle, fontWeight: 'bold' }}>Fat</th> {/* Increase the font weight of table header */}
                        <th style={{ ...cellStyle, fontWeight: 'bold' }}>SNF</th>
                        <th style={{ ...cellStyle, fontWeight: 'bold' }}>Qty</th>
                        <th style={{ ...cellStyle, fontWeight: 'bold' }}>Rate</th>
                        <th style={{ ...cellStyle, fontWeight: 'bold' }}>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={cellStyle}>{printerData?.fat}</td>
                        <td style={cellStyle}>{printerData?.snf}</td>
                        <td style={cellStyle}>{printerData?.quantity}</td>
                        <td style={cellStyle}>{(parseFloat(printerData?.rate)).toFixed(2) || 0}</td>
                        <td style={cellStyle}>{(parseFloat(printerData?.amount)).toFixed(2) || 0}</td>
                    </tr>
                </tbody>
            </table>
            <p style={{ ...headerStyle, textAlign: 'center', marginTop: '10px' }}>
                Thank you
            </p>
        </div>
    );
});

export default MyComponent;
