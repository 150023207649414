import React, { useEffect, useState } from 'react'
import Layout from "../../../../layout/default";
import { Button, Card, Nav, Tab } from 'react-bootstrap';
import DataTable from '../../../../components/DataTable/DataTable';
import { get, post, postToast, put } from '../../api/Api';
import { Block, Icon } from '../../../../components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../shared/Loader';

import BankDetailsModal from '../../modal/BankDetails';
import FarmerDetailsModal from '../../modal/FarmerDetails';
import ConfirmationModal from '../../modal/ConfirmationModal';
import AdvanceFilter from '../../shared/Filter/AdvanceFilter';
import { fetchFarmerList } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { timestamp } from '../../shared/Date';
import VccAdvanceFilter from '../../shared/Filter/VccAdvanceFilter';
import GenerationModal from '../../modal/GenerationModal';
import { getColumnCenter, getColumnDirect, getColumnVlcc } from '../../shared/Service';
const FarmerView = () => {

    const [data, setData] = useState([])
    const [vlccData, setVlccData] = useState([])
    const [centerListData, setCenterListData] = useState([])
    const [activeTab, setActiveTab] = useState("direct");
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [farmerValue, setFarmerValue] = useState('')
    const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
    const [showCodeModal, setShowCodeModal] = useState(false);
    const [clickData, setClickData] = useState([])
    const [tokenData, settokenData] = useState(null)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [mId, setMId] = useState(null)
    const [name, setName] = useState(null)
    const [directFilter, setDirectFilter] = useState(false)
    const [vlccFilter, setVlccFilter] = useState(false)
    const [type, setType] = useState(null)
    const { id } = useParams()
    const [clickId, setClickId] = useState(null)
    const dispatch = useDispatch()
    const [showKeyModal, setShowKeyModal] = useState(false);
    const [showKeyModalOneTime, setShowKeyModalOneTime] = useState(false);
    const [directFilterData, setDirectFilterData] = useState([])
    const [sortData, setSortData] = useState(null)


    const toggleBankDetailsModal = () => {
        setShowBankDetailsModal(!showBankDetailsModal);
    };

    const toggleCodeModal = () => {
        setShowCodeModal(!showCodeModal)
    }


    const handleCodeDetails = (id) => {
        const url = `/profile-page/${id}/direct`;
        window.open(url, '_blank');
    }

    const handlePaginate = (currentPage, itemPerPage, searchValue) => {

        const body = {
            page: currentPage,
            page_count: itemPerPage,
            search: searchValue,
            route_id: activeTab === "direct" ? directFilterData.route_id : '',
            farmer_start_code: activeTab === "direct" ? directFilterData.start_code : '',
            farmer_end_code: activeTab === "direct" ? directFilterData.end_code : '',
            payment_cycle: activeTab === "direct" ? directFilterData.payment_cycle : '',
            farmer_id: activeTab === "direct" ? directFilterData.farmer_id : '',
            mode_of_payment: activeTab === "direct" ? directFilterData.mode_payment : "",
            center_id: activeTab === "direct" ? directFilterData.center_id : '',
            type: activeTab === "direct" ? ["2"] : activeTab === "collection-center" ? ["1"] : '',
            order_by: activeTab === "direct" ? getColumnDirect(sortData?.column) : activeTab === "vlcc" ? getColumnVlcc(sortData?.column) : activeTab === "collection-center" ? getColumnCenter(sortData?.column) : '',
            order_type: sortData?.direction,

        }


        if (body)
            if (activeTab === "direct") {
                filtered_list(body);
            } else if (activeTab === "vlcc") {
                fetchVlcc(body);
            } else if (activeTab === "collection-center") {
                filtered_list(body);
            }
    }


    const fetchVlcc = async (body) => {
        setLoading(true)
        const url = `/api/vcc-farmer/list`;
        try {
            const response = await post(url, body);
            if (response.status) {
                console.log("Server farmer response:", response.data);
                setVlccData(response.data?.data);
                const pageValue = response.data
                updatePagination(pageValue);
                setLoading(false);
            } else {
                setLoading(false);
            }

        } catch (error) {
            console.error("Error while posting data:", error);
            setLoading(false);
        }
    };



    const farmerType = (value) => {
        setFarmerValue(value);
    };

    useEffect(() => {
        if (id) {
            setActiveTab(id)
        }
        if (activeTab === "direct") {
            const body = { type: ["2"] }
            filtered_list(body);
        } else if (activeTab === "vlcc") {
            fetchVlcc();
        } else if (activeTab === "collection-center") {
            const body = { type: ["1"] }
            filtered_list(body);
        }

    }, [activeTab, id, dispatch]);

    const handleTabChange = (key) => {
        setActiveTab(key);
        navigate(`/farmer-view/${key}`)
    };

    const handleStatus = (id, name) => {
        const updateStatus = async () => {
            setLoading(true)
            const url = `/api/direct-farmer/status-update/${id}`;
            try {
                const response = await put(url, name);
                if (response.status) {
                    console.log("Server response:", response.data);
                    setLoading(false)
                    filtered_list()
                    dispatch(fetchFarmerList())

                }
            } catch (error) {
                console.error("Error while posting data:", error);
            }
        };
        updateStatus();
    }

    const handleVlccStatus = (id, name) => {
        const updateStatus = async () => {

            const url = `/api/vcc-farmer/status-update/${id}`;
            try {
                const response = await put(url, name);
                if (response.status) {
                    console.log("Server response:", response.data);
                    fetchVlcc()
                }
            } catch (error) {
                console.error("Error while posting data:", error);
            }
        };
        updateStatus();
    }

    const buttonStyle = {
        padding: '0.7px 7px ',
        fontSize: '10px',
        lineHeight: '1',
    };
    const centerContentStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    };


    const handleEdit = (id, data) => {
        navigate(`/FarmerCreations/${id}?type=direct`)
    }

    const handleVlccEdit = (id, data) => {
        navigate(`/vcc-farmer/${id}`)
    }


    function formatPaymentCycle(row) {

        if (row.payment_cycle === "daily") {
            if (row.session === "morning") {
                return "D - Mor";
            } else if (row.session === "evening") {
                return "D - Eve";
            } else if (row.session === "both") {
                return "D - Both";
            }
        } else if (row.payment_cycle === "weekly") {
            const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
            const sessionIndex = parseInt(row.session);
            if (!isNaN(sessionIndex) && sessionIndex >= 0 && sessionIndex < daysOfWeek.length) {
                return "W - " + daysOfWeek[sessionIndex];
            }
        }

        // Return a default value or handle other cases as needed
        return "Unknown";
    }

    function formatPaymentCycleCC(row) {
        if (row.payment_cycle === "daily") {
            if (row.session === "0" || row.session === 0) {
                return "D - Mor";
            } else if (row.session === "1" || row.session === 1) {
                return "D - Eve";
            } else if (row.session === "2" || row.session === 2) {
                return "D - Both";
            }
        } else if (row.payment_cycle === "weekly") {
            const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
            const sessionIndex = parseInt(row.session);
            if (!isNaN(sessionIndex) && sessionIndex >= 0 && sessionIndex < daysOfWeek.length) {
                return "W - " + daysOfWeek[sessionIndex];
            }
        }

        // Return a default value or handle other cases as needed
        return "Unknown";
    }

    // {modeOfPayment(row.mode_payment_info[0])}
    const modeOfPayment = (data) => {
        return data[0]?.mode_of_payment === 0 ? "CASH" : data[0]?.mode_of_payment === 1 ? "BANK" : data[0]?.mode_of_payment === 2 ? "UPI" : ""
    }

    const modeOfPaymentCC = (data) => {
        return data.mode_of_payment ? "CASH" : data.mode_of_payment ? "BANK" : data.mode_of_payment ? "UPI" : ""
    }

    const columns = [
        {
            name: "R.Code",
            selector: row => row.route.code,
            center: true,
            width: '9%',
            sortable: true
        },
        {
            name: "Route Name",
            selector: row => row.route.name,
            center: false,
            width: '20%',
            sortable: true,
            left: true
        },
        {
            name: "Code",
            selector: (row) => (

                <span
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    onClick={() => {
                        handleCodeDetails(row.id)
                    }}
                >
                    {row.code}
                </span>

            ),
            center: true,
            width: '7%',
            sortable: true
        },
        {
            name: "Farmer Name",
            selector: row => row.name,
            left: true,
            center: false,
            width: '14%',
            sortable: true
        },
        {
            name: "Mobile",
            selector: row => row.mobile_no,
            center: true,
            width: '12%',
            sortable: true
        },
        {
            name: 'Payment',
            selector: (row) => (

                <span
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    onClick={() => {
                        if (row.mode_payment_info[0].mode_of_payment !== 0)
                            toggleBankDetailsModal()
                        setClickData(row.mode_payment_info)
                    }}
                >
                    {modeOfPayment(row.mode_payment_info)}
                </span>

            ),
            center: true,
            width: '9%',
            sortable: true,
        },
        {
            name: "Cycle",
            selector: row => formatPaymentCycle(row.payment_cycle[0]),
            center: true,
            width: '8%',
            sortable: true
        },
        {
            name: "Status",
            cell: (row) => (
                <div style={centerContentStyle}>
                    <Button className=" mx-1" style={buttonStyle} onClick={() => modalStatus(row.id, row.name, "direct")} variant={row.status === '1' ? "success" : "danger"}><Icon name={row.status === '1' ? "check" : "cross"}></Icon></Button>
                </div>
            ),
            right: true,
            width: '9%',

        },
        {
            name: "Action",
            cell: (row) => (
                <Button
                    className="mx-1"
                    style={buttonStyle}
                    variant="primary"
                    onClick={() => handleEdit(row.id, row)}
                >
                    <Icon name="edit"></Icon>
                </Button>
            ),
            right: true,
            width: '10%',
        }
    ]

    const vlccColumn = [
        {
            name: "Center Name",
            selector: row => `${row.direct_farmers.code}-${row.direct_farmers.name}`,
            left: true,
            sortable: true,
            width: '20%',
        },
        {
            name: "Code",
            selector: (row) => (
                <span
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    onClick={() => {
                        // navigate(`/vlcc-profile/${row.id}/vlcc`)
                        const url = `/vlcc-profile/${row.id}/vlcc`;
                        window.open(url, '_blank');
                    }}
                >
                    {row.code}
                </span>

            ),
            center: true,
            width: '7%',
            sortable: true
        },


        {
            name: "Farmer Name",
            selector: row => row.name,
            left: true,
            center: false,
            sortable: true,

        },
        {
            name: "Mobile Number",
            selector: row => row.mobile_no,
            center: true,
            sortable: true
        },
        {
            name: 'Payment',
            selector: (row) => (
                <span
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    onClick={() => {
                        if (row.mode_payment_info[0].mode_of_payment !== 0)
                            toggleBankDetailsModal()
                        setClickData(row.mode_payment_info)
                    }}
                >
                    {modeOfPayment(row.mode_payment_info)}
                </span>
            ),
            center: true,
            width: '9%',
            sortable: true,
        },
        {
            name: "Device Type",
            selector: (row) => (
                <div>
                    {row.device_type === 'DPU' && <Icon name="mobile" />}
                    {row.device_type === 'WEB' && <Icon name="b-si" />}
                </div>
            ),
            center: true,
            sortable: true,
        },
        {
            name: "Status",
            cell: (row) => (
                <div style={centerContentStyle}>
                    <Button className=" mx-1" style={buttonStyle} onClick={() => modalStatus(row.id, row.name, "vlcc")} variant={row.status === '1' ? "success" : "danger"}><Icon name={row.status === '1' ? "check" : "cross"}></Icon></Button>
                </div>
            ),
            right: true,

        },
        {
            name: "Action",
            cell: (row) => (
                <Button
                    className="mx-1"
                    style={buttonStyle}
                    variant="primary"
                    onClick={() => handleVlccEdit(row.id, row)}
                >
                    <Icon name="edit"></Icon>
                </Button>
            ),
            right: true,
        }
    ]

    const listColumn = [
        {
            name: "Route",
            selector: row => `${row.route.code}-${row.route.name}`,
            center: true,
            sortable: true
        },
        {
            name: "Code",
            selector: (row) => (

                <span
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    onClick={() => {
                        const url = `/profile-page/${row.id}/center`;
                        window.open(url, '_blank');
                    }}
                >
                    {row.code}
                </span>

            ),
            center: true,
            width: '7%',
            sortable: true
        },
        {
            name: "Center Name",
            selector: row => row.name,
            center: true,
            sortable: true
        },
        {
            name: 'Payment',
            selector: (row) => (

                <span
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    onClick={() => {
                        if (row.mode_payment_info[0].mode_of_payment > 0)
                            toggleBankDetailsModal()
                        setClickData(row.mode_payment_info)
                    }}
                >
                    {modeOfPaymentCC(row.mode_payment_info[0])}
                </span>



            ),
            center: true,
            width: '9%',
            sortable: true,
        },
        {
            name: "Cycle",
            selector: row => formatPaymentCycleCC(row.payment_cycle[0]),
            center: true,
            width: '8%',
            sortable: true
        },
        {
            name: "Status",
            cell: (row) => (
                <div style={centerContentStyle}>
                    <Button className=" mx-1" style={buttonStyle} onClick={() => modalStatus(row.id, row.name, "direct")} variant={row.status === '1' ? "success" : "danger"}><Icon name={row.status === '1' ? "check" : "cross"}></Icon></Button>
                </div>
            ),
            right: true,
            width: '8%',
        },
        {
            name: "ACTION",
            right: true,
            maxWidth: '220px',
            cell: (row) => {

                return (
                    <div>
                        <Button
                            className="mx-1"
                            style={buttonStyle}
                            variant="secondary"
                            onClick={() => handleNavigateSettings(row.id)}
                        >
                            <Icon name="setting-alt"></Icon>
                        </Button>
                        <Button
                            className="mx-1"
                            style={buttonStyle}
                            variant="primary"
                            onClick={() => handleNavigate(row.id)}
                        >
                            <Icon name="edit"></Icon>
                        </Button>
                        <Button
                            className="mx-1"
                            style={buttonStyle}
                            variant="info"
                            onClick={() => generateView(row.id)}
                        >
                            <Icon name="lock-alt"></Icon>
                        </Button>
                        <Button
                            className="mx-1"
                            style={buttonStyle}
                            variant="success"
                            onClick={() => generateViewOneTime(row.id)}
                        >
                            <Icon name="signin"></Icon>
                        </Button>

                    </div>
                );
            },
            sortable: false,
            hide: "md",
        }
    ]

    const modalStatus = (id, name, type) => {
        setType(type)
        setName(name)
        setMId(id)
        setShowConfirmationModal(true)
    }

    const handleYesClick = () => {

        if (type === "direct") {
            handleStatus(mId, name)
        } else if (type === "vlcc") {
            handleVlccStatus(mId, name)
        }

        setShowConfirmationModal(false); // Close the confirmation modal
    };

    const filtered_list = async (body) => {
        const url = `/api/direct-farmer/filtered_list`;
        setLoading(true);
        try {
            const response = await postToast(url, body);
            if (response.status) {
                console.log("Server farmer response:", response.data.data);
                setData(response.data.data);
                const pageValue = response.data
                updatePagination(pageValue);
                if (body?.type?.includes('1')) {
                    setCenterListData(response.data.data)

                    // update function for pagination
                    updatePagination(pageValue);

                }
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error("Error while posting data:", error);
            setLoading(false);
        }
    };

    const vccFiltered_list = async (body) => {
        setLoading(true);
        const url = `/api/vcc-farmer/filtered_list`;
        try {
            const response = await postToast(url, body);
            if (response.status) {
                console.log("Server farmer response:", response.data);
                setVlccData(response.data?.data);
                const pageValue = response.data
                updatePagination(pageValue);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error("Error while posting data:", error);
            setLoading(false);
        }
    };


    const handleFilter = (value) => {
        const body = {
            route_id: value.route_id,
            farmer_start_code: value.start_code,
            farmer_end_code: value.end_code,
            payment_cycle: value.payment_cycle,
            farmer_id: value.farmer_id,
            mode_of_payment: value.mode_payment,
            type: ["2"],
            route: value.route
        }

        setDirectFilterData(body)
        if (body) {
            filtered_list(body)
        }
    }

    const handleFilterVcc = (value) => {



        const body = {
            route_id: value.route_id,
            farmer_start_code: value.start_code,
            farmer_end_code: value.end_code,
            payment_cycle: value.payment_cycle,
            farmer_id: value.farmer_id,
            mode_of_payment: value.mode_payment,
            center_id: value.center_id

        }

        if (body) {
            vccFiltered_list(body)
        }
    }
    const generateView = (id) => {
        setClickId(id)
        fetchList(id)
        setShowKeyModal(true)
    }

    const generateViewOneTime = (id) => {
        setClickId(id)
        fetchListOneTime(id)
        setShowKeyModalOneTime(true)
    }

    const fetchList = async (id) => {
        setLoading(false);
        const url = `/api/vlcc/token-details/${id}`;
        try {
            const response = await get(url);
            if (response.status) {
                console.log("Server response:", response.data);
                const data = response.data
                setClickData(data ? data : [])
                setLoading(false)
            }

        } catch (error) {
            console.error("Error while posting data:", error);
            setLoading(false)
        }
    }

    const fetchListOneTime = async (id) => {
        const url = `/api/vlcc/getAccessCode/${id}`;
        try {
            const response = await get(url);
            if (response.status) {
                console.log("Server response:", response.data);
                const data = response.token
                settokenData(data)
                setLoading(false)
            }

        } catch (error) {
            console.error("Error while posting data:", error);
            setLoading(false)
        }
    }

    const handleNavigateSettings = (id) => {
        navigate(`/settings-view/collection-settings/${id}`)
    }

    const handleNavigate = (id) => {
        navigate(`/collection-center/${id}`)
    }

    const [pagination, setPagination] = useState({
        page: 1,
        page_count: 25,
        search: '',
        total_count: null
    })
    const updatePagination = ({ current_page, per_page, total }) => {
        setPagination({
            ...pagination,
            page: current_page,
            page_count: per_page,
            total_count: total,

        });
    };
    const toggleKeyModal = () => {
        setShowKeyModal(!showKeyModal)
    }
    const toggleKeyModalOneTime = () => {
        setShowKeyModalOneTime(!showKeyModalOneTime)
    }

    const sort = (column, direction) => {
        const data = {
            column: column.name, direction
        }
        console.log("sort", data);
        setSortData(data)
        handlePaginate("", pagination.page_count, pagination.search)
    }


    return (
        <Layout title="Farmer View" content="container">
            <div>
                <Loader loading={loading} />
            </div>
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Farmer List </Block.Title>

                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>

            <Card>
                <Card.Body>

                    {showBankDetailsModal && (
                        <BankDetailsModal
                            show={showBankDetailsModal}
                            bankDetails={clickData}
                            onClose={toggleBankDetailsModal}
                        />
                    )}
                    {showCodeModal && (
                        <FarmerDetailsModal
                            show={showCodeModal}
                            farmerDetails={clickData}
                            onClose={toggleCodeModal}
                        />
                    )}
                    {showKeyModal && (
                        <GenerationModal
                            show={showKeyModal}
                            data={clickData}
                            id={clickId}
                            onClose={toggleKeyModal}
                            regenerate={fetchList}
                        />
                    )}
                    {showKeyModalOneTime && (
                        <GenerationModal
                            show={showKeyModalOneTime}
                            data={tokenData}
                            id={clickId}
                            onClose={toggleKeyModalOneTime}
                            regenerateOneTime={fetchListOneTime}
                            oneTime
                        />
                    )}
                    <Tab.Container id="pills-tabs-example" activeKey={activeTab}
                        onSelect={handleTabChange}>
                        <Nav variant="pills" className="mb-3">
                            <Nav.Item>
                                <Nav.Link eventKey="direct">Direct Farmer</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="vlcc">VLCC Farmer</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="collection-center">Collection Center</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="direct">
                                <Card>
                                    <Card.Body>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <Link
                                                    to="/FarmerCreations/0"
                                                    className="btn btn-primary d-none d-md-inline-flex mx-3"
                                                >
                                                    <Icon name="plus" />
                                                    <span>Create Farmer</span>
                                                </Link>
                                            </div>
                                            <div>
                                                <Button
                                                    className="btn btn-secondary d-none d-md-inline-flex"
                                                    onClick={() => setDirectFilter(!directFilter)}
                                                >
                                                    <Icon name="filter" />

                                                </Button>
                                            </div>
                                        </div>

                                        {directFilter && <AdvanceFilter getFilterValue={handleFilter} />}
                                        <div >

                                            <DataTable tableClassName="data-table-head-light table-responsive" data={data} page_value={pagination} directFilterValue={directFilterData}
                                                columns={columns} farmerType={farmerType} actions filterBank onPaginate={handlePaginate} sort={sort}
                                            />
                                        </div>
                                    </Card.Body>
                                </Card>

                            </Tab.Pane>
                            <Tab.Pane eventKey="vlcc">
                                <Card>
                                    <Card.Body>

                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <Link
                                                    to="/vcc-farmer/0"
                                                    className="btn btn-primary d-none d-md-inline-flex mx-3"
                                                >
                                                    <Icon name="plus" />
                                                    <span>Create Farmer</span>
                                                </Link>
                                            </div>
                                            <div>
                                                <Button
                                                    className="btn btn-secondary d-none d-md-inline-flex"
                                                    onClick={() => setVlccFilter(!vlccFilter)}
                                                >
                                                    <Icon name="filter" />
                                                </Button>

                                            </div>
                                        </div>

                                        {vlccFilter && <VccAdvanceFilter getFilterValue={handleFilterVcc} farmer />}
                                        <div>
                                            <DataTable tableClassName="data-table-head-light table-responsive" data={vlccData}
                                                columns={vlccColumn} farmerType={farmerType} filterBank page_value={pagination} onPaginate={handlePaginate}
                                                sort={sort}
                                            />
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane eventKey="collection-center">
                                <Card className="card-gutter-md mt-2 mb-2">
                                    <Card.Body>

                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <Link
                                                    to="/collection-center/0"
                                                    className="btn btn-primary d-none d-md-inline-flex mx-3"
                                                >
                                                    <Icon name="plus" />
                                                    <span>Create Center</span>
                                                </Link>
                                            </div>
                                            <div>
                                                <Button
                                                    className="btn btn-secondary d-none d-md-inline-flex"
                                                    onClick={() => setVlccFilter(!vlccFilter)}
                                                >
                                                    <Icon name="filter" />
                                                </Button>

                                            </div>
                                        </div>

                                        <DataTable tableClassName="data-table-head-light table-responsive" data={centerListData}
                                            columns={listColumn} onPaginate={handlePaginate} page_value={pagination} sort={sort}
                                        />
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                    <ConfirmationModal
                        show={showConfirmationModal}
                        title="Status change"
                        body="Are you sure?"
                        onCancel={() => {
                            setShowConfirmationModal(false)
                        }}
                        onConfirm={handleYesClick}
                    />
                </Card.Body>

            </Card>



        </Layout>
    )
}

export default FarmerView