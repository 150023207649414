import React, { useEffect, useRef, useState } from 'react'
import Layout from "../../../../layout/default";
import { Block, Icon } from '../../../../components';
import { Button, Card } from 'react-bootstrap';
import DataTable from '../../../../components/DataTable/DataTable';
import { useNavigate } from 'react-router-dom';
import { get, postToast } from '../../api/Api';
import Loader from '../../shared/Loader';
import { dateReverseH, dateSplit, timeSplit } from '../../shared/Date';
import VccAdvanceFilter from '../../shared/Filter/VccAdvanceFilter';
import { useReactToPrint } from 'react-to-print';
import PrintComponentVlcc from '../../shared/printer/MultiplePrinterVlcc';
import { getVlccColumnDisplayName } from '../../shared/Service';

const VlccList = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [listData, setListData] = useState([])
    const [filters, setFilters] = useState({});
    const [data, setData] = useState([])
    const [showFilters, setShowFilters] = useState(false);
    const printRef = useRef();
    const [show, setShow] = useState(false)
    const [fatEnd, setFatEnd] = useState('');
    const [snfStart, setSnfStart] = useState('');
    const [snfEnd, setSnfEnd] = useState('');
    const [headerData, setHeaderData] = useState(null)
    const [bodyData, setBodyData] = useState(null)
    const [advance, setAdvance] = useState(false)
    const [routeData, setRouteData] = useState([])
    const [printData, setPrintData] = useState([])
    const [allData, setAllData] = useState([])
    const [sortData, setSortData] = useState(null)

    const [pagination, setPagination] = useState({
        page: 1,
        page_count: 25,
        search: '',
        total_count: null
    })
    const updatePagination = ({ current_page, per_page, total }) => {
        setPagination({
            ...pagination,
            page: current_page,
            page_count: per_page,
            total_count: total
        });
    };

    const fetchRoute = async () => {
        const url = "/api/route/list";
        try {
            const response = await get(url);
            if (response.status) {
                setRouteData(response.data)
            }
        } catch (error) {
            console.error("Error while fetching data:", error);
        }
    };
    useEffect(() => {
        fetchRoute()
    }, [])


    const toggleFilters = () => {
        setShowFilters(prev => !prev);
        setFilters({});
    };

    const addNavigate = () => {
        // navigate(`/procurement-form/${dateDetails.route_id}/${dateDetails.id}?mode=add&type=direct`)
    }

    const reportNavigate = () => {
        navigate('/report')
    }

    const handleFilterChange = (columnId, value) => {
        setFilters(prev => ({ ...prev, [columnId]: value }));
    };

    useEffect(() => {
        // fetchCenterList()
    }, [])

    const rowStyles = [
        {
            when: row => row.isAbnormal, // Check for the isAbnormal property
            style: {
                backgroundColor: '#FFFFE0', // Or any other color of your choice
            },
        },
    ]

    const filterInputStyle = {
        width: '95%',
        padding: '5px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '11px'
    };

    const filteredData = listData?.filter(row =>
        Object.entries(filters).every(
            ([key, value]) =>
                !value ||
                String(row[key]).toLowerCase().includes(String(value).toLowerCase())
        )
    );

    const dateFormat = (date) => {
        return `${dateReverseH(dateSplit(date))} ${timeSplit(date)}`
    }

    const entryList = (showFilters, handleFilterChange) => {
        const column = [
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Route name"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('Routename', e.target.value)}
                    />
                    : "Routename",
                selector: (row) => `${row.route_code} - ${row.route_name}`,
                sortable: !showFilters,
                minWidth: "120px",
                reorder: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Date"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('date', e.target.value)}
                    />
                    : "date",
                selector: (row) => dateFormat(row.bill_date),
                sortable: !showFilters,
                minWidth: "150px",
                center: true,
                reorder: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="M/E"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('session', e.target.value)}
                    />
                    : "M/E",
                selector: (row) => row.session,
                sortable: !showFilters,
                width: "66px",
                center: true,
                reorder: true
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Bill"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('BillNo', e.target.value)}
                    />
                    : "Bill",
                selector: (row) => (
                    <div>
                        {row.bill_no}{' '}
                        {row.device_type === 'DPU' && <Icon name="tablet"></Icon>}
                        {row.device_type === 'WEB' && <Icon name="b-si" />}
                    </div>
                ),
                sortable: !showFilters,
                minWidth: "40px",
                center: true,
                reorder: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Center"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('Center', e.target.value)}
                    />
                    : "Center",
                selector: (row) => `${row.center_code} - ${row.center_name}`,
                sortable: !showFilters,
                minWidth: "130px",
                center: true,
                reorder: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Producer"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('farmerCode', e.target.value)}
                    />
                    : "code",
                selector: (row) => (

                    <span
                        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                        onClick={() => {
                            const url = `/vlcc-profile/${row.vcc_farmer_id}/vlcc`;
                            window.open(url, '_blank');
                        }}
                    >
                        {row.farmer_code}
                    </span>

                ),
                sortable: !showFilters,
                minWidth: "60px",
                left: true,
                reorder: true,
                wrap: true,
            },

            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Producer"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('farmerName', e.target.value)}
                    />
                    : "farmerName",
                selector: (row) => row.name,
                sortable: !showFilters,
                minWidth: "130px",
                left: true,
                reorder: true,
                wrap: true,

            },

            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="FAT"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('fat', e.target.value)}
                    />
                    : "fat",
                cell: (row) => {
                    const fatValue = parseFloat(row.tot_fat);
                    const isManualMode = row?.collection_details ? JSON.parse(row.collection_details)?.[0]?.is_manual_mode?.fat === "1" : null;
                    // Check if the value is outside the range (3.8 to 4.8)
                    const isOutOfRange = fatValue < 3.8 || fatValue > 4.8;
                    // Apply red color if the value is below the range, blue if above the range, otherwise inherit
                    const cellStyle = {
                        color: isOutOfRange ? (fatValue < 3.8 ? "red" : "blue") : 'inherit',
                    };
                    return <div style={cellStyle}>{row.tot_fat ? parseFloat(row.tot_fat).toFixed(2) : ''} {isManualMode ? "*" : ""}</div>;
                },
                sortable: !showFilters,

                right: true,
                minWidth: "50px",
                reorder: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="SNF"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('snf', e.target.value)}
                    />
                    : "snf",

                cell: (row) => {
                    const snfValue = parseFloat(row.tot_snf);
                    const isManualMode = row?.collection_details ? JSON.parse(row.collection_details)?.[0]?.is_manual_mode?.snf === "1" : null;
                    // Check if the value is outside the range (3.8 to 4.8)
                    const isOutOfRange = snfValue < 7.7 || snfValue > 8.5;
                    // Apply red color if the value is below the range, blue if above the range, otherwise inherit
                    const cellStyle = {
                        color: isOutOfRange ? (snfValue < 7.7 ? "red" : "blue") : 'inherit',
                    };

                    return <div style={cellStyle}>{row.tot_snf ? parseFloat(row.tot_snf).toFixed(2) : ''} {isManualMode ? "*" : ''}</div>;
                },
                right: true,
                minWidth: "50px",
                reorder: true,
                sortable: !showFilters,

            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Lit"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('quantity', e.target.value)}
                    />
                    : "Lit",
                selector: row => {
                    const isManualMode = row?.collection_details ? JSON.parse(row.collection_details)?.[0]?.is_manual_mode?.qty === "1" : null;
                    const Value = parseFloat(row.tot_qty_litre).toFixed(2);



                    return (
                        <span >
                            {Value}{isManualMode ? "*" : ''}
                        </span>
                    );
                },
                sortable: !showFilters,
                right: true,
                minWidth: "60px",
                reorder: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="KG"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('kg', e.target.value)}
                    />
                    : "KG",
                selector: row => {
                    const isManualMode = row?.collection_details ? JSON.parse(row.collection_details)?.[0]?.is_manual_mode?.qty === "1" : null;
                    const Value = parseFloat(row.tot_qty_kg).toFixed(1);


                    return (
                        <span >
                            {Value} {isManualMode ? "*" : ''}
                        </span>
                    );
                },
                sortable: !showFilters,
                right: true,
                minWidth: "70px",
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Rate"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('rate', e.target.value)}
                    />
                    : "rate",
                selector: (row) => (row.tot_rate).toFixed(2),
                sortable: !showFilters,
                minWidth: "50px",
                // omit: role?.data?.user_role !== "admin",
                reorder: true,
                right: true
            },
        ]

        const hasAtLeastOneIncentive = data?.some(row => (row.incentive_amount).toFixed(2) !== 0);

        if (hasAtLeastOneIncentive) {
            column.push({
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Incentive"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('incentiveRate', e.target.value)}
                    />
                    : "incentive",
                selector: (row) => (row.incentive_amount).toFixed(2),
                sortable: !showFilters,
                right: true,
                minWidth: "65px",
            });
        }
        column.push({
            name: showFilters
                ? <input
                    type="text"
                    placeholder="Total"
                    style={filterInputStyle}
                    onChange={(e) => handleFilterChange('amount', e.target.value)}
                />
                : "Total",
            selector: (row) => (
                <div style={{ textAlign: 'right !important' }}>
                    {(row.tot_amount).toFixed(2).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </div>
            ),

            sortable: !showFilters,
            right: true,
            minWidth: "80px",
            reorder: true,
        });

        return column;
    }

    const [columns, setColumns] = useState(entryList(showFilters, handleFilterChange));

    useEffect(() => {
        setColumns(entryList(showFilters, handleFilterChange));
    }, [showFilters]);

    const fetchData = async (reqBody) => {
        const url = "/api/vcc-collection/list";

        try {
            const response = await postToast(url, reqBody);
            if (response.status) {
                setListData(response.data.data)
                const pageValue = response.data
                setHeaderData(response?.summary)
                updatePagination(pageValue);
                setLoading(false)
            } else {
                setLoading(false)
            }
        } catch (error) {
            console.error("Error while fetching data:", error);
            setLoading(false)
        }
    };

    useEffect(() => {
        const body = {}
        fetchData(body)
    }, [])

    const handleAdvance = () => {
        setAdvance(!advance)
        setFatEnd('')
        setFatEnd('')
        setSnfStart('')
        setSnfEnd('')
    }

    const handleFilterValue = (value) => {

        setBodyData(value)
        const reqBody = {
            from_date: value.startDate,
            from_session: value.sessionDay,
            to_date: value.endDate,
            to_session: value.sessionNight,
            center_id: value.center_id,
            route_id: value.route_id,
            vcc_farmer_id: value.farmer_id,
            mode_of_payment: value.mode_payment
        }
        fetchData(reqBody)
    }

    const handlePaginate = (currentPage, itemPerPage, searchValue, sortingData) => {
        setLoading(true)
        const body = {
            page: currentPage,
            page_count: itemPerPage,
            search: searchValue,
            from_date: bodyData?.startDate,
            from_session: bodyData?.sessionDay,
            to_date: bodyData?.endDate,
            to_session: bodyData?.sessionNight,
            center_id: bodyData?.center_id,
            route_id: bodyData?.route_id,
            vcc_farmer_id: bodyData?.farmer_id,
            order_by: sortingData ? getVlccColumnDisplayName(sortingData?.column) : getVlccColumnDisplayName(sortData?.column),
            order_type: sortingData?.direction ? sortingData?.direction : sortData?.direction

        }

        if (body) {
            fetchData(body)
        }

    }

    const handleFunc = async () => {
        const body = {
            page: 1,
            page_count: 1000,
            search: "",
            from_date: bodyData?.startDate,
            from_session: bodyData?.sessionDay,
            to_date: bodyData?.endDate,
            to_session: bodyData?.sessionNight,
            center_id: bodyData?.center_id,
            route_id: bodyData?.route_id,
            vcc_farmer_id: bodyData?.farmer_id,
            method: "print"
        }

        await fetchPrintData(body);
        setShow(true)

        setTimeout(() => {
            handlePrint()
        }, 500);

    }

    const handlePrint = useReactToPrint({

        content: () => printRef.current,
        onBeforePrint: () => {
            setShow(true);
        },
        onAfterPrint: () => {
            setShow(false);
        },

    });

    const fetchPrintData = async (body) => {
        const url = "/api/vcc-collection/list";

        try {
            const response = await postToast(url, body);
            if (response.status) {
                setPrintData(response.data.data)
                const pageValue = response.data
                updatePagination(pageValue);
                setAllData(response.params)
                setLoading(false)
            } else {
                setLoading(false)
            }

        } catch (error) {
            console.error("Error while fetching data:", error);
            setLoading(false)
        }
    };

    const sort = (column, direction) => {
        const data = {
            column: column.name, direction
        }

        setSortData(data)

        handlePaginate("", pagination.page_count, pagination.search, data)
    }

    return (
        <Layout title="VLCC List" content="container">
            <Loader loading={loading} />

            <Block.Head >
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <h3 >
                            VLCC Entry List
                        </h3>
                    </Block.HeadContent>
                    <div className="d-flex justify-content-end ">
                        <Button
                            as="label"
                            variant="primary"
                            onClick={() => navigate("/")}
                        >
                            <Icon name=""></Icon>
                            Back
                        </Button>
                    </div>
                </Block.HeadBetween>
            </Block.Head>

            <Card className="card-gutter-md custom-card mt-1" >
                <Card.Body className="card-body-custom">

                    <Block >
                        <Button
                            className='mt-2 d-flex float-end'
                            as="label"
                            variant="primary"
                            onClick={handleAdvance}
                        >
                            <Icon name='filter' />
                        </Button>

                        {advance && <VccAdvanceFilter getFilterValue={handleFilterValue} />}

                        <div>
                            <DataTable tableClassName="data-table-head-light table-responsive" conditionalRowStyles={rowStyles} data={filteredData} actions print
                                columns={columns} showFilters={showFilters} toggleFilters={toggleFilters} printFunc={handleFunc}
                                navigate={addNavigate} reportPage={reportNavigate} headerData={allData} headerValue={headerData} directFilterValue={bodyData}
                                tableHead onPaginate={handlePaginate} page_value={pagination} sort={sort} type={"VLCC"} />
                        </div>
                    </Block>
                    <div id="element-to-print">
                        {show && <PrintComponentVlcc
                            ref={printRef}
                            data={printData}
                            headerData={allData}
                        />}
                    </div>
                </Card.Body>
            </Card>
        </Layout>
    )
}

export default VlccList