import React, { useEffect, useState } from 'react'
import Layout from "../../../layout/default";
import { Block, DatePicker, DateRangePicker, Icon } from '../../../components';
import { Button, ButtonGroup, Card, Col, Form, Row } from 'react-bootstrap';
import DataTable from '../../../components/DataTable/DataTable';
import { useNavigate } from 'react-router-dom';
import { get, post, postToast } from '../api/Api';
import { toast } from 'react-toastify';
import Loader from '../shared/Loader';
import { SearchDown } from '../shared/dropDown/SearchDown';
import { useRef } from 'react';
import { dateReverseH, dateSplit } from '../shared/Date';

const AllEntryView = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [listData, setListData] = useState([])
    const [filters, setFilters] = useState({});
    const [data, setData] = useState([])
    const [dateRange, setDateRange] = useState({ start: null, end: null })
    const [showFilters, setShowFilters] = useState(false);
    const [sessionDay, setSessionDay] = useState(null);
    const [sessionNight, setSessionNight] = useState(null);
    const [fatEnd, setFatEnd] = useState('');
    const [snfStart, setSnfStart] = useState('');
    const [snfEnd, setSnfEnd] = useState('');
    const [farmerList, setFarmerList] = useState([])
    const [farmerName, setFarmerName] = useState('')
    const [code, setCode] = useState(null)
    const [inputValue, setInputValue] = useState('');
    const [farmerId, setFarmerId] = useState(null)
    const [codeError, setCodeError] = useState('')
    const [advance, setAdvance] = useState(false)
    const [endDate, setEndDate] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const routeNameRef = useRef(null)

    const toggleFilters = () => {
        setShowFilters(prev => !prev);
        setFilters({});
    };

    const addNavigate = () => {
        // navigate(`/procurement-form/${dateDetails.route_id}/${dateDetails.id}?mode=add&type=direct`)
    }


    const reportNavigate = () => {
        navigate('/report')
    }

    const handleFilterChange = (columnId, value) => {
        setFilters(prev => ({ ...prev, [columnId]: value }));
    };





    const rowStyles = [
        {
            when: row => row.isAbnormal, // Check for the isAbnormal property
            style: {
                backgroundColor: '#FFFFE0', // Or any other color of your choice
            },
        },
    ]

    const filterInputStyle = {
        width: '95%',
        padding: '5px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '11px'
    };

    const filteredData = listData?.filter(row =>
        Object.entries(filters).every(
            ([key, value]) =>
                !value ||
                String(row[key]).toLowerCase().includes(String(value).toLowerCase())
        )
    );
    const entryList = (showFilters, handleFilterChange) => {
        const column = [
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Route name"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('Routename', e.target.value)}
                    />
                    : "Routename",
                selector: (row) => `${row.route_code} - ${row.route_name}`,
                sortable: !showFilters,
                minWidth: "200px"


            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Date"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('date', e.target.value)}
                    />
                    : "date",
                selector: (row) => dateReverseH(dateSplit(row.bill_date)),
                sortable: !showFilters,
                minWidth: "120px"
            },


            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Session"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('session', e.target.value)}
                    />
                    : "session",
                selector: (row) => row.session,
                sortable: !showFilters,

            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Bill No"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('BillNo', e.target.value)}
                    />
                    : "BillNo",
                selector: (row) => row.bill_no,
                sortable: !showFilters,

            },

            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Code"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('code', e.target.value)}
                    />
                    : "code",
                selector: (row) => row.farmer_code,
                sortable: !showFilters,

            },

            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Producer"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('farmerName', e.target.value)}
                    />
                    : "farmerName",
                selector: (row) => row.name,
                sortable: !showFilters,
                minWidth: "200px"

            },



            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="FAT"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('fat', e.target.value)}
                    />
                    : "fat",
                selector: (row) => {
                    const collectionDetails = JSON.parse(row.collection_details);
                    return (parseFloat(collectionDetails[0].tot_fat)).toFixed(2);
                },
                sortable: !showFilters,
                center: true,

            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="SNF"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('snf', e.target.value)}
                    />
                    : "snf",
                selector: (row) => {
                    const collectionDetails = JSON.parse(row.collection_details);
                    return (parseFloat(collectionDetails[0].tot_snf)).toFixed(2);
                }, sortable: !showFilters,
                center: true,

            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Lit"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('quantity', e.target.value)}
                    />
                    : "Lit",
                selector: (row) => {
                    const collectionDetails = JSON.parse(row.collection_details);
                    return (parseFloat(collectionDetails[0].tot_qty_litre)).toFixed(2);
                },
                sortable: !showFilters,
                right: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="KG"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('kg', e.target.value)}
                    />
                    : "KG",
                selector: (row) => {
                    const collectionDetails = JSON.parse(row.collection_details);
                    return (parseFloat(collectionDetails[0].tot_qty_kg)).toFixed(2);
                },
                sortable: !showFilters,
                right: true,
            },
            {
                name: showFilters
                    ? <input
                        type="text"
                        placeholder="Rate"
                        style={filterInputStyle}
                        onChange={(e) => handleFilterChange('rate', e.target.value)}
                    />
                    : "rate",
                selector: (row) => {
                    const collectionDetails = JSON.parse(row.collection_details);
                    return (parseFloat(collectionDetails[0].tot_rate)).toFixed(2);
                },
                sortable: !showFilters,
                right: true,

            },
        ]

        const hasAtLeastOneIncentive = data?.some(row => {
            const collectionDetails = JSON.parse(row.collection_details);
            return parseFloat(collectionDetails[0].incentive_amount) !== 0;
        });

        if (hasAtLeastOneIncentive) {
            column.push({
                name: showFilters
                    ? (
                        <input
                            type="text"
                            placeholder="Incentive"
                            style={filterInputStyle}
                            onChange={(e) => handleFilterChange('incentiveRate', e.target.value)}
                        />
                    )
                    : "incentive",
                selector: (row) => {
                    const collectionDetails = JSON.parse(row.collection_details);
                    return parseFloat(collectionDetails[0].incentive_amount).toFixed(2);
                },
                sortable: !showFilters,
                center: true,
                minWidth: "70px",
            });
        }


        // ... your other columns here (after the incentive column)

        column.push({
            name: showFilters
                ? <input
                    type="text"
                    placeholder="Total"
                    style={filterInputStyle}
                    onChange={(e) => handleFilterChange('amount', e.target.value)}
                />
                : "Total",
            selector: (row) => (
                <div style={{ textAlign: 'right !important' }}>
                    {parseFloat(JSON.parse(row.collection_details)[0].tot_amt).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </div>
            ),

            sortable: !showFilters,
            right: true,
        });

        return column;
    }

    const [columns, setColumns] = useState(entryList(showFilters, handleFilterChange));

    useEffect(() => {
        setColumns(entryList(showFilters, handleFilterChange));
    }, [showFilters]);


    const rangeData = {
        from_date: selectedDate,
        from_session: sessionDay,
        to_date: endDate,
        to_session: sessionNight,
        direct_farmer_id: farmerId,
    }


    const fetchData = async () => {
        const url = "/api/direct-collection/list";

        try {
            const response = await postToast(url, rangeData);
            if (response.status) {
                setListData(response.data.data)
                setLoading(false)
            } else {
                setLoading(false)
            }
        } catch (error) {
            console.error("Error while fetching data:", error);
            setLoading(false)
        }
    };


    const handleCheck = () => {
        fetchData()
    };

    useEffect(() => {
        fetchData()
    }, [])
    const handleValidate = () => {
        if (!dateRange.start || !dateRange.end) {
            toast.error("Both start and end dates are required");
            return false; // Validation failed
        }

        return true; // All validations passed
    }



    const handleInput = (value, fId, data) => {
        setFarmerName(data.name)
        setCode(data.code)
        setInputValue(value)
        setFarmerId(fId)
    }

    const handleFarmerCode = (value) => {
        // Simple validation check
        if (!value || value.trim() === '') { // Check if the value is just whitespace or empty
            setCodeError('Farmer name with Code is required.');
        } else {
            // Clear error if value is valid
            setCodeError('');
        }
    };

    const handleAdvance = () => {
        setAdvance(!advance)
        setFatEnd('')
        setFatEnd('')
        setSnfStart('')
        setSnfEnd('')
    }

    const handleSession = (e) => {
        setSessionDay(e.target.value);
        document.getElementById('btncheck1').blur();

    };
    const handleEndSession = (e) => {
        setSessionNight(e.target.value);
        document.getElementById('btncheck1').blur();

    };

    const handleName = (name) => {
        // setFrName(name)
    }

    const fetchFarmer = async () => {
        const url = `/api/direct-farmer/list`;
        try {
            const response = await get(url);
            if (response.status) {
                setFarmerList(response.data)
            }

        } catch (error) {
            console.error("Error while posting data:", error);
        }
    }

    useEffect(() => {
        fetchFarmer()
    }, [])

    const empty = {}
    const fetchList = async () => {
        const url = `/api/direct-collection/list`;
        try {
            const response = await postToast(url, empty);
            if (response.status) {
                setListData(response.data.data)
            }
        } catch (error) {
            console.error("Error while posting data:", error);
        }
    }


    useEffect(() => {
        fetchList()
    }, [])


    return (
        <Layout title="Overall List" content="container">

            <Block.Head >
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <h3 >
                            Overall Entry List
                        </h3>
                    </Block.HeadContent>
                    <div className="d-flex justify-content-end ">
                        <Button
                            as="label"
                            variant="primary"
                            onClick={() => navigate("/direct-vendor/active")}
                        >
                            <Icon name=""></Icon>
                            Back
                        </Button>
                    </div>
                </Block.HeadBetween>
            </Block.Head>

            <Card className="card-gutter-md custom-card mt-1" >
                <Card.Body className="card-body-custom">

                    <Button
                        className='mt-2'
                        as="label"
                        variant="primary"
                        onClick={handleAdvance}
                    >
                        Advance Filter
                    </Button>
                    {advance &&
                        <Card className="card-gutter-md mt-2 mb-2">
                            <Card.Body>
                                <Col xxl="12">
                                    <Row className="g-gs">
                                        <Col lg="2">
                                            <Form.Label htmlFor="start">Start date</Form.Label>
                                            <Form.Group className="form-group" controlId="validationCustom01">

                                                <div className="form-control-wrap">
                                                    <DatePicker onDateChange={(value) => setSelectedDate(value)} value={selectedDate} />
                                                </div>
                                            </Form.Group>
                                        </Col>

                                        <Col lg="2">
                                            <Form.Group className="form-group" >
                                                <Form.Label htmlFor="start">Start Session</Form.Label>
                                                <div className="form-control-wrap">
                                                    <ButtonGroup aria-label="Basic checkbox toggle button group">
                                                        <input type="radio" className="btn-check" name='session' id="btncheck1" autoComplete="off" value="M"
                                                            onChange={handleSession} />
                                                        <Button as="label" variant="outline-primary" htmlFor="btncheck1"><Icon name="sun-fill"></Icon></Button>

                                                        <input type="radio" className="btn-check" name='session' id="btncheck2" autoComplete="off" value="E"
                                                            onChange={handleSession} />
                                                        <Button as="label" variant="outline-primary" htmlFor="btncheck2"><Icon name="moon-fill"></Icon></Button>
                                                    </ButtonGroup>
                                                </div>
                                            </Form.Group>
                                        </Col>


                                        <Col lg="2">
                                            <Form.Label htmlFor="start">End date</Form.Label>
                                            <Form.Group className="form-group" controlId="validationCustom01">

                                                <div className="form-control-wrap">
                                                    <DatePicker onDateChange={(value) => setEndDate(value)} value={endDate} />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="4">
                                            <Row className="g-gs">
                                                <Col lg="4">
                                                    <Form.Group className="form-group" >
                                                        <Form.Label htmlFor="start">End Session</Form.Label>
                                                        <div className="form-control-wrap">
                                                            <ButtonGroup aria-label="Basic checkbox toggle button group">
                                                                <input type="radio" className="btn-check" name='sessionEnd' id="btncheckEnd1" autoComplete="off" value="M"
                                                                    onChange={handleEndSession} />
                                                                <Button as="label" variant="outline-primary" htmlFor="btncheckEnd1"><Icon name="sun-fill"></Icon></Button>

                                                                <input type="radio" className="btn-check" name='sessionEnd' id="btncheckEnd2" autoComplete="off" value="E"
                                                                    onChange={handleEndSession} />
                                                                <Button as="label" variant="outline-primary" htmlFor="btncheckEnd2"><Icon name="moon-fill"></Icon></Button>
                                                            </ButtonGroup>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg="8">
                                                    <SearchDown
                                                        data={farmerList}
                                                        placeholder="Farmer Name"
                                                        name="Farmer Name"
                                                        onValidate={handleFarmerCode}
                                                        onSelect={(selectedItem, type, data) => {
                                                            handleInput(selectedItem, type, data)
                                                        }}
                                                        nextInputRef={routeNameRef}
                                                        func={handleName}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col lg="2">
                                            <Col xxl="12">
                                                <Row className="g-gs">

                                                    <Col>
                                                        <Button
                                                            as="label"
                                                            style={{ marginTop: "32px" }}
                                                            variant="primary"
                                                            onClick={handleCheck}
                                                        >
                                                            Get Data
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>

                                        </Col>

                                    </Row>


                                </Col>
                            </Card.Body>
                        </Card>}



                    {filteredData.length !== 0 && <Col lg="12" className='mt-2'>
                        <Block >
                            <DataTable tableClassName="data-table-head-light table-responsive" conditionalRowStyles={rowStyles} data={filteredData} actions
                                columns={columns} add showFilters={showFilters} toggleFilters={toggleFilters} navigate={addNavigate} reportPage={reportNavigate} filter

                            />
                        </Block>
                    </Col>}

                </Card.Body>
            </Card>


        </Layout>
    )
}

export default AllEntryView